import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import cx from 'classnames'

import { fetchHelper } from 'tools/FetchHelper'
import UpdateMessage from 'Components/UpdateMessage'

import SettingSection from './SettingSection'
import './index.css'

const AutoAssign = (props) => {
	const { t } = useTranslation();

    const [settings, setSettings] = useState(null)
    const botSettingRef = useRef(null) //origin setting
    const [isEdited, setIsEdited] = useState(false) // set to false
	const [isFetching, setFetching] = useState(false)
    const [isUpdating, setUpdating] = useState(null)

    const pressCancel = () => {
        setSettings(botSettingRef.current.setting || [])
		setIsEdited(false)
    }

	const updateBot = async () => {
		if (isUpdating) return
		if (!isEdited) return
        // check setting valid
		const postBody = {
			"setting" : settings
				.filter(aSetting => !!aSetting.opId)
				.map((aSetting) => {
					return {
						"opId" : aSetting.opId,
						"keywords" : aSetting.keywords
					}
				})
		}
		setUpdating(true)
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/bot/autoAssign`, postBody, {
				fetchQAbot: true
			});
			const res = result.data

			if (res.status === "OK") {
				botSettingRef.current = postBody
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('update bot auto assign setting fail', error.message)
		} finally {
			setUpdating(false)
			setIsEdited(false)
		}
	}

	useEffect(() => {
		const fetchBotSetting = async () => {
			setFetching(true)
			try {
				const result = await fetchHelper.get(`/channel/${props.channelId}/bot/autoAssign`, {
                    fetchQAbot: true
                });
				const res = result.data

				if (res.status === "OK") {
                    setSettings(res.data.setting || [])
                    botSettingRef.current = res.data
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				console.log('get bot default fail', error.message)
			} finally {
				setFetching(false)
			}
		}
		fetchBotSetting()
	}, [props.channelId])

	return (
		<div className="auto-assign-page">
			<div className="page-title">
				<Link to={`/console/channel/${props.channelId}/bot`}>{t('bot.title')}</Link>
				<i className="far fa-chevron-right" />
				{t('bot.autoAssign')}
			</div>
			<div className="page-des">{t('bot.autoAssignDes')}</div>
			<div className="bot-setting-content">
				<div className="setting-part">
					{!isFetching && settings ?
                        <SettingSection {...props} settings={settings} setSettings={setSettings} setIsEdited={setIsEdited}/>
                        :
						<div className="icon-loading">
							<i className="fal fa-spinner-third fa-spin" />
						</div>
					}
				</div>
                <div className="button-group">
                    <button
                        className="c-btn"
                        onClick={pressCancel}
                    >{t('cancel')}</button>
                    <button className={cx("c-btn c-btn--primary", { 'is-disabled': !isEdited })}
                        onClick={() => updateBot()}>
                        {isUpdating ?
                            <i className="fal fa-spinner-third fa-spin" /> :
                            t('save')
                        }
                    </button>
                    <UpdateMessage isUpdating={isUpdating}/>
                </div>
            </div>
		</div>
	)
}

export default AutoAssign;