import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import zhTW from 'locales/zh-TW'
import enUS from 'locales/en-US.json'

i18n
	.use(initReactI18next)
	.init({
		debug: process.env.REACT_APP_ENV === "production" ? false : true,
		lng: "zh-TW",
		resources: {
			'zh-TW': {
				translation: zhTW
			},
			'en': {
				translation: enUS
			}
		},
		interpolation: {
			escapeValue: false,
			format: (value, format, lng) => {
            	if(value instanceof Number) return String(value);
            	return value;
            }
        }
	});

window.i18n = i18n;

export default i18n;