import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'

import enums from 'enums'

import VoteActivityVoteOption from './VoteActivityVoteOption'

const VoteActivityVotePage = (props) => {
	const { t } = useTranslation()
	const {vote} = props
	const {activity, voteOption, setVoteOption} = props

	const isSingleSelect = activity.voteSetting.selectionType === enums.ActivityVoteSelectionType.single

	const isOptionValid = () => {
		if (voteOption.length < 1) return false
		return true
	}

	const isVoteOptionContain = (currenOptionValue) => {
		const existOption = voteOption.find((aOption)=>{
			return aOption.optionValue === currenOptionValue
		})
		return !!existOption
	}

	const checkChanged = (optionValue) => {
		if (activity.voteSetting.selectionType === enums.ActivityVoteSelectionType.single) {
			if (isVoteOptionContain(optionValue)) {
				setVoteOption([])
				return
			}
			setVoteOption([{
				"optionValue" : optionValue
			}])
			return
		}

		let voteOptionCopy =  voteOption.slice()
		if (!isVoteOptionContain(optionValue)) {
			voteOptionCopy.push({"optionValue" : optionValue})
		}
		else {
			voteOptionCopy = voteOptionCopy.filter((aOption)=>{
				return aOption.optionValue !== optionValue
			})
		}
		setVoteOption(voteOptionCopy)
	}


	return (
		<div className="vote-choose">
			<div className="vote-options">
				{activity.voteSetting.options.map((aOption, i) => {
					return (
						<VoteActivityVoteOption
							key={`a-vote-option-${i}`}
							inputId={`a-vote-option-${i}`}
							checkChanged={() => {checkChanged(aOption.optionValue)}}
							isSingleSelect={isSingleSelect}
							optionValue={aOption.optionValue}
							isSelect={isVoteOptionContain(aOption.optionValue)}
						/>
					)
				})}
			</div>


			<div className="button-section">
				<button className={cx("line-button close-button")}
					onClick={() => {props.onClose()}}>
					{t('activity.votePage.cancel')}
				</button>
				<button className={cx("line-button", {"disabled" : !isOptionValid()})}
					onClick={() => {
						if (!isOptionValid()) return
						vote()
					}}>
					{t('activity.votePage.send')}
				</button>
			</div>
		</div>
	)
}

export default VoteActivityVotePage;
