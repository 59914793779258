import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import enums from 'enums'
import { fetchHelper } from 'tools/FetchHelper'

import NormalActivityContent from './NormalActivityContent'
import VoteActivityContent from './VoteActivity/VoteActivityContent'

import './index.css';

const LineActivity = (props) => {
	const { t } = useTranslation();
	const currentUri = window.location.href.split('#')[0]

	let urlParams = qs.parse(props.location.search, { ignoreQueryPrefix: true })
	if (urlParams["liff.state"]) {
		urlParams = qs.parse(urlParams["liff.state"], { ignoreQueryPrefix: true })
	}

	const channelId = urlParams ? urlParams.channelId : null
	const activityId = urlParams ? urlParams.activityId : null
	const source = urlParams ? urlParams.source : null
	const type = urlParams ? urlParams.type || "activity" : "activity" // or "vote" for get correct liff


	const [isFetching, setFetching] = useState(true)
	const [userData, setUserData] = useState(null)
	const [chatRoomData, setChatRoom] = useState()
	const [liffId, setLiffId] = useState(null)

	const createChatRoom = useCallback(async (profile) => {
		setFetching(true)
		try {
			const result = await fetchHelper.post(`/activity/${activityId}/chatroom`, {
				lineUserId: profile.userId,
				userName: profile.displayName,
				userAvatar: profile.pictureUrl,
				channelId: channelId
			}, {
				fetchQAbot: true
			});
			const res = result.data

			if (res.status === "OK") {
				setChatRoom(res.data)
				document.title = res.data.channel.name
				setFetching(false)
				// if (res.data.isCreate === false) {
				// 	attendActivity(res.data, profile)
				// } else {
				// 	setFetching(false)
				// }
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false)
			console.log('create chatroom fail', error.message)
		}
	}, [activityId, channelId])

	useEffect(()=>{
		if(!userData) return
		createChatRoom(userData)
	}, [userData, createChatRoom])

	useEffect(() => {
		const getProfile = () => {
			window.liff.getProfile()
			.then(profile => {
				setUserData(profile)
			})
			.catch((err) => {
			  console.log('error', err);
			});
		}

		if (!liffId) return

		if (!window.liff) {
			window.location.reload()
		}

		window.liff
			.init({liffId: liffId})
			.then(()=>{
				console.log(window.liff.isLoggedIn())
				if (!window.liff.isLoggedIn()) {
					window.liff.login({
						redirectUri: currentUri
					})
				}
				getProfile()
			})

	}, [liffId])

	useEffect(() => {
		const getLiffId = async () => {
			try {
				const result = await fetchHelper.get(`/channel/${channelId}/liff/id?type=${type}`);
				const res = result.data
				setLiffId(res.data)
			} catch (error) {
				setLiffId("1653935656-5gPjNjzB")
				console.log('get liffId Error', error.message)
			}
		}
		getLiffId()
	}, [channelId])


	return (
		<div className="line-form">
			{isFetching ?
				<div className="icon-loading">
					<i className="fal fa-spinner-third fa-spin" />
				</div> :
				<div className="line-activity">
					{chatRoomData.activity.type === enums.ActivityType.normal &&
						<NormalActivityContent
							userData={userData}
							activityId={activityId}
							chatRoomData={chatRoomData}
						/>

					}
					{chatRoomData.activity.type === enums.ActivityType.vote &&
						<VoteActivityContent
							userData={userData}
							chatRoomData={chatRoomData}
							activityId={activityId}
							isScan={source==='scan'}
						/>
					}
				</div>
			}
		</div>
	)
}

export default LineActivity;
