const permissionEnum = {
	type: {
		admin: 1,
		op: 2,
		csr: 3,
		1: 'admin',
		2: 'op',
		3: 'csr'
	},
	admin: {
		goToChannelPath: 'dashboard',
		allowFunction: [
			'dashboard', 'message', 'market', 'broadcast', 'activity', 'bot', 'setting', 'members',
			'friends', 'tags', 'autoAssignKeyword']
	},
	op: {
		goToChannelPath: 'dashboard',
		allowFunction: [
			'dashboard', 'message', 'market', 'broadcast', 'activity', 'bot', 'members', 'friends',
			'tags', 'autoAssignKeyword']
	},
	csr: {
		goToChannelPath: 'message',
		allowFunction: ['message']
	}
}
export default permissionEnum;