import React, { useState, useRef } from 'reactn';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import Highlight from 'react-highlighter'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import zhTW from 'dayjs/locale/zh-tw'

import Tooltip from 'Components/Tooltip';
import TagsInputer from 'Components/TagsInputer'
import enums from 'enums'
import './index.css'

dayjs.locale('zh-tw', zhTW)
dayjs.extend(relativeTime)

const FriendRow = ({ friend, message, friendInfo, onFriendSelect, onStarClick, onHandleClick, searchValue, msgRead, isCurrentFriend}) => {
	const { t } = useTranslation();

	const isCallOp = friend.activateStatus ? friend.activateStatus.includes(enums.ChatRoomActivateStatusType.isCallOp) : false
	const isStar = friend.activateStatus ? friend.activateStatus.includes(enums.ChatRoomActivateStatusType.isStar) : false
	const isHandle = friend.activateStatus ? friend.activateStatus.includes(enums.ChatRoomActivateStatusType.isHandled) : false

	return (
		<div className={cx('a-friend', { 'is-current-friend': isCurrentFriend })} onClick={() => {
			if (friend.hasUnreadMsg) {
				msgRead()
			}
			onFriendSelect()
		}}>
			<figure className={cx({ 'show-noti': friend.hasUnreadMsg })}>
				<img alt="" src={friend.userData.avatar} />
				<div className="noti-dot" />
			</figure>
			<div className="friend-info">
				<div className="name-n-info">
					<div className="friend-name">
						{!!searchValue ?
							<Highlight search={searchValue}>{friend.userData.name}</Highlight> :
							friend.userData.name}
					</div>

					<i className={cx("fal fa-user-headset", { 'hidden': !isCallOp })} />
					<Tooltip>
						<i className={cx("fal fa-star clickable-icon", { 'active': isStar })}
							onClick={(e) => {
								e.stopPropagation()
								onStarClick()}}
							data-tip={t(`message.searchFilter.type.star`)}
							data-class="icon-title"
						/>
					</Tooltip>
					<Tooltip>
						<i className={cx("fal fa-comment-alt-check clickable-icon", { 'active': isHandle })}
							onClick={(e) => {
								e.stopPropagation()
								onHandleClick()}}
							data-tip={t(`message.searchFilter.type.done`)}
							data-class="icon-title"
						/>
					</Tooltip>
				</div>
				{message &&
					<div className="lastest-msg u-truncate">
						{!!searchValue ?
							<Highlight search={searchValue}>{message.text}</Highlight>	:
							message.text
						}
					</div>
				}
				{friendInfo && friendInfo.text &&
					<div className="search-result">
						{!!searchValue ?
							<Highlight search={searchValue}>{friendInfo.text}</Highlight> :
							friendInfo.text
						}
					</div>
				}
				{friendInfo && friendInfo.tag &&
					<div className="search-result">
						<TagsInputer
							tags={friendInfo.tag}
							isDisplayMode={true}
						/>
					</div>

				}
				<div className="time-from-now">{dayjs(friend.lastMessageDtm).fromNow()}</div>
			</div>
		</div>
	)
}

FriendRow.defaultProps = {
	onFriendSelect: () => {},
	searchValue: null
}

const FriendList = (props) => {
	const { t } = useTranslation();
	const { searchValue, hasSearch, searching, searchResult, noMoreFriendSearchResult, currentSearchValueType, searchResultPage, searchChat } = props
	const { friends, moreFriendsFetching, noMoreFriend, fetchMoreFriends } = props
	const { currentFriend, setCurrent, msgRead, toggleChatRoomStatus } = props

	const friendListRef = useRef(null)
	const friendsSearchResultRef = useRef(null)
	const [ hideNameInLine, setHideNameInLine ] = useState(false)
	const [ hideSubName, setHideSubName ] = useState(false)
	const [ hidePhone, setHidePhone ] = useState(false)
	const [ hideEmail, setHideEmail ] = useState(false)

	const {setDisplayChat} = props // for mobile

	const onFriendSelect = (chatRoom) => {
		setCurrent(chatRoom)
		setDisplayChat(true)
	}

	const onScrollMoreFriends = () => {
		const scrollRatio = friendListRef.current.scrollTop / (friendListRef.current.scrollHeight - friendListRef.current.clientHeight)
		if (scrollRatio > 0.9 && !noMoreFriend && !moreFriendsFetching) {
			fetchMoreFriends([], friends)
		}
	}

	const onScrollMoreChatResult = () => {
		const scrollRatio = friendsSearchResultRef.current.scrollTop / (friendsSearchResultRef.current.scrollHeight - friendsSearchResultRef.current.clientHeight)
		if (scrollRatio > 0.9 && !noMoreFriendSearchResult && !searching &&
			currentSearchValueType === enums.SearchValueType.history
		) {
			searchResultPage.current = searchResultPage.current + 1
			searchChat()
		}
	}

	return (
		<>
			{hasSearch ?
			<div className="friends-search-result" ref={friendsSearchResultRef} onScroll={onScrollMoreChatResult}>
				{searching &&
				<div className="loading-section">
					<div className="icon-loading">
						<i className="fal fa-spinner-third fa-spin" />
					</div>
				</div>}

				<div className="result-list">
					{currentSearchValueType === enums.SearchValueType.userInfo &&
					<div className="friends-result">
						<div className="result-title">
							{t("message.nameInLine")} {`(${searchResult.nameTotal ? searchResult.nameTotal : "0"})`}
							<div className="expand-icon" onClick={()=>setHideNameInLine(!hideNameInLine)}>
								<i className={cx({"fal fa-angle-down": !hideNameInLine }, {"fal fa-angle-right": hideNameInLine} )} />
							</div>
						</div>
						{!hideNameInLine && searchResult && searchResult.name && searchResult.name.map((aFriend, i) => (
						<FriendRow
							friend={aFriend.chatRoom}
							key={`a-friend-name-${i}`}
							message={aFriend.latestMessage ? aFriend.latestMessage.message : null}
							onFriendSelect={() => onFriendSelect(aFriend.chatRoom)}
							onStarClick={() => toggleChatRoomStatus(aFriend.chatRoom, enums.ChatRoomActivateStatusType.isStar)}
							onHandleClick={() => toggleChatRoomStatus(aFriend.chatRoom, enums.ChatRoomActivateStatusType.isHandled)}
							searchValue={searchValue}
							msgRead={() => msgRead(aFriend)}
							isCurrentFriend={aFriend.chatRoom.guid === currentFriend.guid}
						/>))}

						<div className="result-title">
							{t("message.subName")} {`(${searchResult.subNameTotal ? searchResult.subNameTotal : "0"})`}
							<div className="expand-icon" onClick={()=>setHideSubName(!hideSubName)}>
								<i className={cx({"fal fa-angle-down": !hideSubName }, {"fal fa-angle-right": hideSubName })} />
							</div>
						</div>
						{!hideSubName && searchResult && searchResult.subName && searchResult.subName.map((aFriend, i) => (
						<FriendRow
							friend={aFriend.chatRoom}
							key={`a-friend-subname-${i}`}
							friendInfo={{"text": `${t('message.subNameBriefTitle')}${aFriend.chatRoom.userData.subName}`}}
							onFriendSelect={() => onFriendSelect(aFriend.chatRoom)}
							onStarClick={() => toggleChatRoomStatus(aFriend.chatRoom, enums.ChatRoomActivateStatusType.isStar)}
							onHandleClick={() => toggleChatRoomStatus(aFriend.chatRoom, enums.ChatRoomActivateStatusType.isHandled)}
							searchValue={searchValue}
							msgRead={() => msgRead(aFriend)}
							isCurrentFriend={aFriend.chatRoom.guid === currentFriend.guid}
						/>))}

						<div className="result-title">
							{t("message.phone")} {`(${searchResult.phoneTotal ? searchResult.phoneTotal : "0"})`}
							<div className="expand-icon" onClick={()=>setHidePhone(!hidePhone)}>
								<i className={cx({"fal fa-angle-down": !hidePhone }, {"fal fa-angle-right": hidePhone})} />
							</div>
						</div>
						{!hidePhone && searchResult && searchResult.phone && searchResult.phone.map((aFriend, i) => (
						<FriendRow
							friend={aFriend.chatRoom}
							key={`a-friend-phone-${i}`}
							friendInfo={{"text": `${t('message.phoneBriefTitle')}${aFriend.chatRoom.userData.phone}`}}
							onFriendSelect={() => onFriendSelect(aFriend.chatRoom)}
							onStarClick={() => toggleChatRoomStatus(aFriend.chatRoom, enums.ChatRoomActivateStatusType.isStar)}
							onHandleClick={() => toggleChatRoomStatus(aFriend.chatRoom, enums.ChatRoomActivateStatusType.isHandled)}
							searchValue={searchValue}
							msgRead={() => msgRead(aFriend)}
							isCurrentFriend={aFriend.chatRoom.guid === currentFriend.guid}
						/>))}

						<div className="result-title">
							{t("message.email")} {`(${searchResult.emailTotal ? searchResult.emailTotal : "0"})`}
							<div className="expand-icon" onClick={()=>setHideEmail(!hideEmail)}>
								<i className={cx({"fal fa-angle-down": !hideEmail}, {"fal fa-angle-right": hideEmail})} />
							</div>
						</div>
						{!hideEmail && searchResult && searchResult.email && searchResult.email.map((aFriend, i) => (
						<FriendRow
							friend={aFriend.chatRoom}
							key={`a-friend-email-${i}`}
							friendInfo={{"text": `${t('message.emailBriefTitle')}${aFriend.chatRoom.userData.email}`}}
							onFriendSelect={() => onFriendSelect(aFriend.chatRoom)}
							onStarClick={() => toggleChatRoomStatus(aFriend.chatRoom, enums.ChatRoomActivateStatusType.isStar)}
							onHandleClick={() => toggleChatRoomStatus(aFriend.chatRoom, enums.ChatRoomActivateStatusType.isHandled)}
							searchValue={searchValue}
							msgRead={() => msgRead(aFriend)}
							isCurrentFriend={aFriend.chatRoom.guid === currentFriend.guid}
						/>))}
					</div>}

					{currentSearchValueType === enums.SearchValueType.tag &&
					<div className="chat-result">
						<div className="result-title">
							{t("message.tag")} {`(${searchResult.tagTotal ? searchResult.tagTotal : "0"})`}
						</div>
						{searchResult.tag.map((aFriend, i) => (
						<FriendRow
							friend={aFriend.chatRoom}
							key={`a-friend-tag-${i}`}
							friendInfo={aFriend.result ? aFriend.result : null}
							onFriendSelect={() => onFriendSelect(aFriend.chatRoom)}
							onStarClick={() => toggleChatRoomStatus(aFriend.chatRoom, enums.ChatRoomActivateStatusType.isStar)}
							onHandleClick={() => toggleChatRoomStatus(aFriend.chatRoom, enums.ChatRoomActivateStatusType.isHandled)}
							searchValue={searchValue}
							msgRead={() => msgRead(aFriend)}
							isCurrentFriend={aFriend.chatRoom.guid === currentFriend.guid}
						/>))}
					</div>}

					{currentSearchValueType === enums.SearchValueType.history &&
					<div className="chat-result">
						<div className="result-title">
							{t("message.chat")} {`(${searchResult.historyTotal ? searchResult.historyTotal : "0"})`}
						</div>
						{searchResult.history.map((aFriend, i) => (
						<FriendRow
							friend={aFriend.chatRoom}
							key={`a-friend-history-${i}`}
							message={aFriend.message ? aFriend.message.message : null}
							onFriendSelect={() => {
								let user = aFriend.chatRoom
								user["currentSearchMsg"] = aFriend.message
								onFriendSelect(user)
							}}
							onStarClick={() => toggleChatRoomStatus(aFriend.chatRoom, enums.ChatRoomActivateStatusType.isStar)}
							onHandleClick={() => toggleChatRoomStatus(aFriend.chatRoom, enums.ChatRoomActivateStatusType.isHandled)}
							searchValue={searchValue}
							msgRead={() => msgRead(aFriend)}
							isCurrentFriend={aFriend.chatRoom.guid === currentFriend.guid}
						/>))}

						{!noMoreFriendSearchResult && !searching &&
							<div className="load-more">
								{t('loadmore')}
							</div>
						}
						{searching &&
							<div className="icon-loading">
								<i className="fal fa-spinner-third fa-spin" />
							</div>
						}
					</div>
					}
				</div>
			</div>
			:
			<div className="friends-list" ref={friendListRef} onScroll={onScrollMoreFriends}>
				{friends.slice().sort((a, b) => {
					if (a.activateStatus && a.activateStatus.includes(enums.ChatRoomActivateStatusType.isCallOp)) return -1
					if (b.activateStatus && b.activateStatus.includes(enums.ChatRoomActivateStatusType.isCallOp)) return 1

					return dayjs(a.lastMessageDtm || '2019-01-01').isAfter(dayjs(b.lastMessageDtm || '2019-01-01')) ? -1 : 1
				})
				.map((aFriend, i) => (
					<FriendRow
						friend={aFriend}
						message={aFriend.latestMessage ? aFriend.latestMessage.message : null}
						key={`a-friend-${aFriend.guid}-${i}`}
						onFriendSelect={() => onFriendSelect(aFriend)}
						onStarClick={() => toggleChatRoomStatus(aFriend, enums.ChatRoomActivateStatusType.isStar)}
						onHandleClick={() => toggleChatRoomStatus(aFriend, enums.ChatRoomActivateStatusType.isHandled)}
						msgRead={() => msgRead(aFriend)}
						isCurrentFriend={aFriend.guid === currentFriend.guid}
					/>
				))}

				{!noMoreFriend && !moreFriendsFetching &&
					<div className="load-more">
						{t('loadmore')}
					</div>
				}
				{moreFriendsFetching &&
					<div className="icon-loading">
						<i className="fal fa-spinner-third fa-spin" />
					</div>
				}
			</div>}
		</>
	)
}

export default FriendList;