import React, { useState, useRef } from 'react';
import './index.css'

const ClickToCopyInput = (props) => {
	const {value} = props

	const inputRef = useRef()
	const [copySuccess, setCopySuccess] = useState(false)

	const copyToClipboard = (e) => {
		inputRef.current.focus()
		inputRef.current.select();
		document.execCommand('copy')
		setCopySuccess(true)
	}
	return (
		<div className="click-to-copy-input">
			<div className="c-txt__input c-txt__input--media">
				<input
					ref={inputRef}
					className="c-txt__input c-txt__input--bare c-txt__input--media__body"
					type="text"
					readOnly
					defaultValue={value}
				/>
				<div className="c-txt__input--media__figure" onClick={copyToClipboard}>
					{copySuccess ?
						<i className="fal fa-check" /> :
						<i className="fal fa-copy" />
					}
				</div>
			</div>
		</div>

	)
}

export default ClickToCopyInput;
