import React from 'react';
import AddKeywordModal from './AddKeywordModal'
import './index.css'

/**
 * @property {array} keywords array of object, 設定指定ＯＰ的關鍵字
 *      - keywords : array of object
 *          - keywordId: keyword.guid
 *          - text: keyword.text
 * @property {string} placeholder
 * @property {bool} disabled true: 刪除關鍵字的x不會顯示
 * @property {array} recentKeywordDir  [{"name":"常用關鍵字", "tags": [{"text": ""}]}]
 * @property {function} removeKeyword params: index of keyword
 * @property {function} addKeyword params: new tag
 */
const AddKeywordBlock = (props) => {
    const { keywords, placeholder, disabled, recentKeywordDir } = props
    const { removeKeyword, addKeyword } = props

    return (
		<div className="add-keyword-block">
            <div className="input-section">
                <AddKeywordModal
                    channelId={props.channelId}
                    recentKeywordDir={recentKeywordDir}
                    onTagClick={(tag)=>{
                        addKeyword(tag)
                    }}
                />
                {placeholder && keywords.length === 0 &&
                    <span className="placeholder">{placeholder}</span>
                }
                {keywords.map((aKeyword, i) => {
                    return (
                        <AKeyword
							key={`a-keyword-tag-${i}`}
							value={aKeyword.text}
							disabled={disabled}
							onDelete={() => removeKeyword(i)}
                        />
                    )
                })}

            </div>
		</div>
    )
}

AddKeywordBlock.defaultProps = {
    keywords: [],
    disabled: false
}

const AKeyword = (props) => {
	const {value, onDelete, disabled} = props
	return (
		<div className="a-tag">
			<span>{value}</span>
			{!disabled &&
				<i className="fal fa-times" onClick={() => {onDelete()}} />
			}
		</div>
	);
}

export default AddKeywordBlock