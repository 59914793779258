import React, { useState, useEffect, useRef, useCallback } from 'reactn';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';
import enums from 'enums'

import { getUser } from 'Login'
import { fetchHelper } from 'tools/FetchHelper'
import ChatHistory from 'Components/ChatHistory'
import UserData from '../UserData'
import './index.css'

const MessageCenterRight = (props) => {
	const { t } = useTranslation();

	const { owners, currentFriend, setChangedFriendDetail, disableOwnerSelector} = props
	const { msgRead, toggleChatRoomStatus } = props

	const [ isBotActive, setBotActive ] = useState(true)

	const botActiveTimeout = useRef();
	const isMount = useRef(false)

	const {isDisplayChat, setDisplayChat} = props

	const updateBotActive = useCallback(async (active) => {
		try {
			const result = await fetchHelper.post(`/chatRoom/${currentFriend.guid}/bot/active`, {
				"isBotActive": active
			}, {
				fetchQAbot: true
			});
			const res = result.data

			if (res.status === "OK") {

			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('update bot qas fail', error.message)
		}
	}, [currentFriend.guid])

	const setBotActiveTimeout = () => {
		if (botActiveTimeout.current) window.clearTimeout(botActiveTimeout.current)
		// auto active bot after 10 min
		botActiveTimeout.current = setTimeout(() => {
			setBotActive(true)
			updateBotActive(true)
		}, 600000);
	}

	const setChatRoomOwner = async (chatRoom, owner) => {
		if(chatRoom.inChargeOp.GUID === owner.GUID) {
			return
		}

		try {
			const postbody = {
				"assignOp": {
					"type": owner.type,
					"GUID": owner.GUID
				}
			}
			const result = await fetchHelper.post(
				`/channel/${props.channelId}/chatRoom/${chatRoom.guid}/assign/operator`, postbody);
			if (result.statusText === 'OK') {
				let chatRoomCopy = chatRoom
				chatRoomCopy.inChargeOp = owner
				setChangedFriendDetail(chatRoomCopy)
			}
		} catch (error) {
			console.log('toggle handled fail', error.message)
		}
	}

	useEffect(() => {
		const getBotActive = async () => {
			try {
				const result = await fetchHelper.get(`/chatRoom/${currentFriend.guid}/bot/active`, {
					fetchQAbot: true
				});
				const res = result.data
				if (res.status === "OK") {
					if (result.data.data === false) {
						setBotActiveTimeout()
					}
					setBotActive(result.data.data)
				} else {
					throw new Error(result.errorMessage);
				}

			} catch (error) {
				console.log('getBotActive fail', error.message)
			}
		}

		if (currentFriend.guid) {
			getBotActive()
		}
	}, [currentFriend])

	useEffect(() => {
		isMount.current = true
		return () => {
			// clear timeout when component unmount
			if (botActiveTimeout.current) window.clearTimeout(botActiveTimeout.current)
			isMount.current = false
		}
	}, [])

	// header var
	const [displayUserData, setDisplayUserData] = useState(false)

	const isCurrentFriendStar = currentFriend.activateStatus ? currentFriend.activateStatus.includes(enums.ChatRoomActivateStatusType.isStar) : false
	const isCurrentFriendHandle = currentFriend.activateStatus ? currentFriend.activateStatus.includes(enums.ChatRoomActivateStatusType.isHandled) : false
	const displayRemoveOwnerOption = currentFriend.inChargeOp && currentFriend.inChargeOp.type === enums.ChatRoomAssignOwnerType.op

	return (
		<div className={cx("message-center-right", {"mobile-show" : isDisplayChat})}>
			<div className="mc-header">
				{currentFriend.guid &&
					<div className="user-header">
						<div className="user-avatar">
							<div className="mobile-back-to-list"
								onClick={()=>{setDisplayChat(false)}} >
								<i className="fas fa-angle-left"></i>
							</div>
							<figure>
								<img alt="" src={currentFriend.userData.avatar} />
							</figure>
							<div className="user-avatar-name-n-owner">
								<div className="user-name">{currentFriend.userData.name}</div>
								<div className="user-owner-select">
									<ThemeProvider theme={window.menuTheme}>
									{ !disableOwnerSelector ?
										<Menu
											maxHeight="500px"
											trigger={({ ref, isOpen }) => {
												return (
													<div className={cx("c-txt__input--select", "user-owner-select-input", { 'is-open': isOpen })} ref={ref}>
														{currentFriend.inChargeOp && currentFriend.inChargeOp.name ?
															`已指派給 ${currentFriend.inChargeOp.name}` : t(`message.noOwner`)}
													</div>
												)
											}}
										>
										{ displayRemoveOwnerOption &&
											<Item
												className="c-txt__input--select-item"
												key={"removeOwner"}
												onClick={() => {
													setChatRoomOwner(currentFriend, {type:enums.ChatRoomAssignOwnerType.none})
												}}>
												{t(`message.removeOwner`)}
											</Item>
										}
											<Item
												className="c-txt__input--select-item"
												key={"no"}
												onClick={() => {
													setChatRoomOwner(
														currentFriend,
														{
															type:enums.ChatRoomAssignOwnerType.op,
															GUID:getUser().guid,
															name:getUser().name
														})
												}}>
												{t(`message.setOwnerToMyself`)}
											</Item>
											{ owners.filter((aOwner)=>{
													return aOwner.GUID !== getUser().guid} )
												.map((aOwner, i) => (
												<Item
													className="c-txt__input--select-item"
													key={aOwner.name}
													onClick={() => {
														setChatRoomOwner(
															currentFriend,
															{
																type:enums.ChatRoomAssignOwnerType.op,
																GUID:aOwner.GUID,
																name:aOwner.name
															})
													}}>
													{aOwner.name}
												</Item>
											))}
										</Menu>
										:
										<div className={cx("c-txt__input", "disabled", "user-owner-select-input")} >
											{currentFriend.inChargeOp && currentFriend.inChargeOp.name ?
												`已指派給 ${currentFriend.inChargeOp.name}` : t(`message.noOwner`)}
										</div>
									}
									</ThemeProvider>
								</div>
							</div>
						</div>
						<div className="user-control">
							<div className={cx("button", {"active": isBotActive})}
								onClick={() => {
									console.log("set active to ", !isBotActive)
									setBotActive(!isBotActive)
									updateBotActive(!isBotActive)

									if (isBotActive) {
										setBotActiveTimeout()
									}
								}}>
								{t('message.robotActive')}
							</div>
							<div className={cx("button", {"active": isCurrentFriendStar})}
								onClick={() => {
									toggleChatRoomStatus(currentFriend, enums.ChatRoomActivateStatusType.isStar)
								}}>
								關注對話
							</div>
							<div className={cx("button", {"active": isCurrentFriendHandle})}
								onClick={() => {
									toggleChatRoomStatus(currentFriend, enums.ChatRoomActivateStatusType.isHandled)
								}}>
								處理完畢
							</div>
							<div className={cx("button", {"active": displayUserData})}
								onClick={() => setDisplayUserData(!displayUserData)}>
								i
							</div>
						</div>
					</div>
				}
			</div>
			<div className="mc-content">
				<div className="user-section">
					{currentFriend.guid &&
					<ChatHistory
						{...props}
						user={currentFriend}
						guid={currentFriend.guid}
						msgRead={() => msgRead(currentFriend)}
						updateChatRoomMessage={(replyMsg) => props.updateChatRoomMessage(replyMsg)}
						updateCallOpAlert={props.updateCallOpAlert}
						updateUnReadAlert={props.updateUnReadAlert}
						deactivateBot={() => {
							setBotActive(false)
							updateBotActive(false)
							setBotActiveTimeout()
						}}
					/>}
					{displayUserData && currentFriend.guid &&
					<UserData
						user={currentFriend}
						{...props}
					/>}
				</div>
			</div>
		</div>
	)
}

export default MessageCenterRight;