import React, { useState, useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd'
import cx from 'classnames'
import { useTranslation } from 'react-i18next';

import DNDTags from './DNDTags'
import TagAddModal from 'Components/TagsInputer/TagAddModal'
import TagControlModal from './TagControlModal';

const ENTER_KEY = 13;
const DIR_MAX_LAYER = 3;

const EmptyTagDropSection = ({  aDir, moveTag, tags }) => {
	const { t } = useTranslation();
	const [{ isTagOver }, dropTag] = useDrop({
		accept: 'tag',
		drop: ({ draggedTag, draggedTagIndex, fromDirGUID }) => {
			moveTag(draggedTag, draggedTagIndex, fromDirGUID, tags.length)
		},
		collect: monitor => ({
			isTagOver: !!monitor.isOver()
		}),
	})

	return (
		<div
			ref={dropTag}
			className="empty-tag-drop-section"
		>
			{isTagOver ?
				<div className="preview-tag"/> :
				tags && tags.length > 0 ? "" : t('tags.addTags')
			}
		</div>
	)
}

const TagSection = ({
	aDir, allTags, createTag, moveTag, deleteTag, deleteTagFromList, checkDuplicateTag,
	createDir, updateDir, deleteDir, mergeDir, channelId,
	hasChild = false, indent = 0, isDirDraggable = false
}) => {
	const { t } = useTranslation();
	const [isTitleEditable, setTitleEditable] = useState(aDir.isEditable)
	const [tagDir, setTagDir] = useState(aDir)

	const [unableDelModalOpen, setUnableDelModal] = useState(false)
	const [unableMergeModalOpen, setUnableMergeModal] = useState(false)

	const titleRef = useRef()

	const checkIfTwoDirCanMerge = (indentA, dirB) => {
		const acceptableLayers = DIR_MAX_LAYER - (indentA + 1);

		if (indentA >= 2) {
			return false
		}

		if (acceptableLayers > 0) {
			let dirBLayers = 1;
			if (dirB.childs && dirB.childs.length > 0) {
				dirBLayers += 1;
				let haveThirdLayer = false
				dirB.childs.map(subDir => {
					if (!haveThirdLayer && subDir.childs && subDir.childs.length > 0) {
						dirBLayers += 1;
						haveThirdLayer = true
					}
					return subDir
				})
			}

			if (dirBLayers > acceptableLayers) {
				return false
			}
		}
		return true
	}

	// dnd control
	const [{ isDragging }, drag] = useDrag({
		item: {
			type: 'list',
			draggedDir: aDir,
			draggedIndent: indent,
			guid: aDir.guid
		},
		canDrag: () => isDirDraggable && !isTitleEditable,
		collect: monitor => {
			// console.log(indent, monitor)

			return {
				isDragging: !!monitor.isDragging(),
			}
		},
	})
	const [{ isDirOver }, dropDir] = useDrop({
		accept: 'list',
		drop: ({ type, draggedDir, draggedIndent, guid}) => {
			if (aDir.guid !== draggedDir.guid && !draggedDir.isDefault) {
				// chekc if two dir can merge
				const canMerge = checkIfTwoDirCanMerge(indent, draggedDir)

				if (canMerge) {
					mergeDir(draggedDir)
				} else {
					setUnableMergeModal(true)
				}
			}
		},
		collect: monitor => ({
			isDirOver: !!monitor.isOver()
		}),
	})


	useEffect(() => {
		if (isTitleEditable && titleRef.current) {
			titleRef.current.select()
		}
	}, [isTitleEditable])

	return (
		<div
			ref={dropDir}
			className={cx(
				"a-tag-section",
				`indent-${indent}`,
				{
					'is-over': isDirOver,
					'is-dragging': isDragging,
					'has-child-dir': hasChild,
					'can-drag': isDirDraggable && !isTitleEditable
				}
			)}
		>
			<div
				ref={drag}
				className="section-top"
			>
				<div className="title-part">
					<div className="indent">
						{indent === 0 &&
							<figure>
								<img src="https://lineline.s3.amazonaws.com/systemFiles/tag-management-1dot.svg" alt=""/>
							</figure>
						}
						{indent === 1 &&
							<figure>
								<img src="https://lineline.s3.amazonaws.com/systemFiles/tag-management-2dot.svg" alt=""/>
							</figure>
						}
						{indent === 2 &&
							<i className="far fa-ellipsis-v"  />
						}
					</div>
					{isTitleEditable ?
						<input
							ref={titleRef}
							value={tagDir.name || ""}
							onChange={(e) => setTagDir({
								...tagDir,
								name: e.target.value
							})}
							onBlur={() => {
								setTitleEditable(false)
								updateDir(tagDir)
							}}
							onKeyDown={(e) => {
								if (e.keyCode === ENTER_KEY) {
									setTitleEditable(false)
									updateDir(tagDir)
								}
							}}
						/> :
						tagDir.name
					}
				</div>
				<div className="control-buttons">
					{!tagDir.isDefault &&
						<>
							<i
								className={"far fa-pen icon-normal"}
								onClick={() => {
									setTitleEditable(true)
								}}
							/>
							{/* tooltip is incompatible with react dnd see here: https://github.com/react-dnd/react-dnd/issues/577 */}
							<div className="add-class-button">
								<i
									className={cx("far fa-folder-plus icon-normal", { disabled: indent >= 2})}
									onClick={() => {
										if (indent < 2) {
											createDir()
										}
									}}
								/>
								{indent >= 2 &&
									<div className="custom-tooltip">
										{t('tags.dirLevelMax')}
									</div>
								}
							</div>
							<i className="far fa-trash icon-delete" onClick={() => {
								const hasTags = aDir.tags && aDir.tags.length > 0
								const hasDirs = aDir.childs && aDir.childs.length > 0
								if (hasTags || hasDirs) {
									setUnableDelModal(true);
								} else {
									deleteDir()
								}
							}}/>
						</>
					}
				</div>
			</div>
			<div
				className="section-bottom"
				ref={dropDir}
			>
				<TagAddModal
					allTags={allTags}
					onTagAdd={(addTags) => {
						if (addTags.length > 0) {
							createTag(addTags[0].text)
						}
					}}
				/>
				{aDir.tags && aDir.tags.length > 0 &&
					aDir.tags.map((aTag, i) => {
						return (
							<DNDTags
								channelId={channelId}
								moveTag={moveTag}
								deleteTag={deleteTag}
								deleteTagFromList={deleteTagFromList}
								checkDuplicateTag={checkDuplicateTag}
								key={`a-dir-tags-${aTag.guid}`}
								aDir={aDir}
								index={i}
								tag={aTag}
							/>
						)
					})
				}
				<EmptyTagDropSection
					tags={aDir.tags}
					moveTag={moveTag}
					aDir={aDir}
				/>
			</div>
			<TagControlModal
				title={t('tags.deleteDirNoti')}
				des={t('tags.deleteDirDes')}
				isModalOpen={unableDelModalOpen}
				onRequestClose={() => setUnableDelModal(false)}
			/>
			<TagControlModal
				title={t('tags.dirMoveLimit')}
				des={t('tags.dirMoveLimitDes')}
				isModalOpen={unableMergeModalOpen}
				onRequestClose={() => setUnableMergeModal(false)}
			/>
		</div>
	)
}


export default TagSection;