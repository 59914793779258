import React from 'react';
import { Route, Switch } from 'react-router-dom'
import KeywordManagement from './KeywordManagement'
import KeywordList from './KeywordList'
import './index.css'

const AutoAssignKeyword = (props) => {
    return (
        <div className="auto-assign-keyword">
            <Switch>
                <Route path={`${props.match.path}/all`} component={(routerProps) =>
                    <KeywordList
                        {...props}
                        {...routerProps}
                    />
                } />
                <Route path={props.match.path} render={(routerProps) => <KeywordManagement
                    {...props}
                    {...routerProps}
                />} />
            </Switch>

        </div>
    )
}
export default AutoAssignKeyword