import React from 'react';
import cx from 'classnames';

import Avatar from './Avatar';
import NameAndTime from './NameAndTime';

import './index.css';


const Tamplate = (props) => {
	return (
		<div className={cx("chat-dialogue template",
			{ 'hide-avatar': props.hideAvatar },
			props.msg.templateType
		)}>
			{!props.hideAvatar &&
				<Avatar {...props} />
			}
			<div className="dialogue-content">
				{!props.hideAvatar &&
					<NameAndTime {...props} />
				}
				{props.msg.templateType === 'buttons' &&
					<div className="buttons">
						<div className="message">
							{props.text}
						</div>
						<div className="actions">
							{props.msg.actions.map((aAction, i) => (
								<div className="a-action" key={`chat-action-${i}`}>
									{aAction.text}
								</div>
							))}
						</div>
					</div>
				}
			</div>
		</div>
	)
}

export default Tamplate;

/*
data : {
	"type" : "template",
	"altText" : "歡迎使用Jetfi 東京美食，請選擇以下的服務",
	"templateType" : "carousel", # type: buttons, confirm, carousel, imageCarousel, support "carousel" only now
	"columns" : [{
		"imageUrl" : "https://japan-solomo.s3.amazonaws.com/images/japan-solomo-banner-3.jpg",
		"title" : "口袋裡的吃貨",
		"text" : "歡迎使用Jetfi 東京美食，請選擇以下的服務",
		"actions" : [{
			"type" : "location",  # type : location, postback, message, uri
			"label" : "查詢附近美食"
		}, {
			"type" : "uri",
			"label" : "地圖查找餐廳",
			"uri" : self._generateLiffUrl(redirectUrl="/restaurant?mode=map")
		}]
	}, {
		"imageUrl" : "https://gnavi-console.s3.ap-northeast-2.amazonaws.com/sys/defaultMember.jpeg",
		"title" : "會員功能",
		"text" : "您可以從這裡查詢所有訂單和訊息",
		"actions" : [{
			"type" : "message",
			"label" : "查詢訂單",
			"text" : "查詢訂單"
		}, {
			"type" : "uri",
			"label" : "查看客服訊息",
			"uri" : self._generateLiffUrl(redirectUrl="/chat")
		}]
	}]
*/