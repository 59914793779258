import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import dayjs from 'dayjs'

import enums from 'enums'
import { fetchHelper } from 'tools/FetchHelper'


import TagsInputer from 'Components/TagsInputer'
import ChatPreviewBlock from 'Components/ChatPreviewBlock'
import AddQuestionBlock from 'Components/AddQuestionBlock'
import ImageUploader from 'Components/ImageUploader'
import CreateMsg from 'Components/CreateMsg'
import UpdateMessage from 'Components/UpdateMessage'
import DateSetting from './DateSetting'
import VoteSetting from './VoteSetting'

const ActivityContent = (props) => {
	const { t } = useTranslation();
	const [isUpdating, setUpdating] = useState(null)
	const activityId = props.activity.guid

	const activityType = props.activity.type || enums.ActivityType.normal
	const isDelete = props.activity.isDelete || false

	const [name, setName] = useState(props.activity.name || "")

	const [coverImage, setCoverImage] = useState(props.activity.coverImagePath || "")
	const [uploadCover, setUploadCover] = useState(false)
	const [uploadCoverErrorMsg, setUploadCoverErrorMsg] = useState("")
	const defaultCoverImage = activityType === enums.ActivityType.normal
		? "https://lineline.s3.ap-northeast-2.amazonaws.com/systemFiles/normal_activity.jpg"
		: "https://lineline.s3.ap-northeast-2.amazonaws.com/systemFiles/vote_activity.jpg"


	const [messages, setMsg] = useState(props.activity.reply || [])

	const [questions, setQuestions] = useState(
		props.activity.keywords ? props.activity.keywords.map(aKey => ({value: aKey})): []
	)

	const [tags, setTags] = useState(props.activity.tags || [])

	const [expiredText, setExpiredText] = useState(props.activity.expiredReplyText || "")

	const [startDate, setStartDate] = useState(
		props.activity.startDtm ? dayjs(props.activity.startDtm).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')
	)
	const [expiredType, setExpiredType] = useState(props.activity.expireDateType === enums.activityExpiredDateType.specificDay ?
		'specificDay' : 'notAssign') // notAssign or specificDay
	const [endDate, setEndDate] = useState(
		props.activity.endDtm ? dayjs(props.activity.endDtm).format('YYYY-MM-DD') : null)


	const [optionType, setOptionType] = useState(
		props.activity && props.activity.voteSetting ? props.activity.voteSetting.optionType : enums.ActivityVoteOptionType.text)
	const [selectionType, setSelectionType] = useState(
		props.activity && props.activity.voteSetting ? props.activity.voteSetting.selectionType : enums.ActivityVoteSelectionType.single)
	const [publicResult, setPublicResult] = useState(
		props.activity && props.activity.voteSetting ? props.activity.voteSetting.publicResult : true)

	const [voteOptions, setVoteOptions] = useState(
		props.activity && props.activity.voteSetting ? props.activity.voteSetting.options : [{"optionValue": "", "tags": []}])


	const [voteDoneReplyKeyword, setVoteDoneReplyKeyword] =  useState(props.activity.voteDoneReplyKeyword || "")
	const [voteDoneReply, setVoteDoneReply] =  useState(props.activity.voteDoneReply || [])

	const checkInputValidation = () => {
		if (!name) return false;
		if (!startDate) return false;
		if (expiredType === 'specificDay' && !endDate) return false
		if (!expiredText) return false
		if (enums.ActivityType[activityType] === undefined) return false

		if (activityType === enums.ActivityType.normal && messages.length === 0) return false
		if (activityType === enums.ActivityType.vote && voteOptions.length === 0) return false

		return true
	}
	const isDisabled = (key) => {
		if (activityType === enums.ActivityType.normal) return false
		if (isDelete) {
			if (key === "expiredReplyText") return false
			else return true
		}
		if (!props.activity.startDtm) return false

		const now = dayjs()
		if (now < dayjs(props.activity.startDtm)) {
			return false
		}
		else { // 活動中or結束
			if (["keywords", "expiredReplyText", "coverImagePath", "voteDoneReplyKeyword"].includes(key)) return false
			else return true
		}

	}

	const generateActivityData = () => {
		let activity = {
			name: name,
			type: activityType,
			coverImagePath: coverImage,
			startDtm: dayjs(startDate).format('YYYY-MM-DD'),
			endDtm: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
			expireDateType: enums.activityExpiredDateType[expiredType],
			keywords: questions.map(aKey => aKey.value),
			expiredReplyText: expiredText,
			tags : tags
		}
		if (activity.type === enums.ActivityType.normal) {
			activity.reply = messages
		}
		else if (activity.type === enums.ActivityType.vote) {
			activity.voteSetting = {
				publicResult: publicResult,
				optionType: optionType,
				selectionType: selectionType,
				options: voteOptions
			}

			activity.voteDoneReplyKeyword = voteDoneReplyKeyword
			activity.voteDoneReply = voteDoneReply
		}
		return activity
	}

	const updateActivity = async () => {
		if (!checkInputValidation() || isUpdating) return;
		setUpdating(true)
		try {
			const postBody = generateActivityData()
			const result = await fetchHelper.post(activityId ? `/activity/${activityId}` : `channel/${props.channelId}/activity`,
				postBody, {
				fetchQAbot: true
			});

			const res = result.data
			if (res.status === "OK") {
				if (!activityId) {
					window.location.replace(`/console/channel/${props.channelId}/activity/${res.data.guid}`)
				}
				setUpdating(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setUpdating(false);
			console.log('update activity fail', error.message)
		}
	}

	return (
		<div className="activity-content">
			<div className="activity-setting-content">
				<div className="setting-part">
					<div className="sub-title">{t('activity.activityName')+"*"}</div>
					<input
						className="c-txt__input"
						value={name || ""}
						onChange={(e) => setName(e.target.value)}
						disabled={isDisabled("name")}
					/>
					<DateSetting
						title={t('activity.activityDate')+"*"}
						description={t('activity.activityDateDes')}
						activityType={activityType}
						expiredType={expiredType}
						disabled={isDisabled("activityDate")}
						startDate={startDate || null}
						startDateSelectPeriod={{
							periodDateStart: dayjs(),
							...(expiredType === 'specificDay' && endDate && {
								periodDateEnd: endDate
							})
						}}
						onStartSelect={(date) => setStartDate(date)}
						setExpiredType={(e) => {setExpiredType(e)}}
						endDate={endDate || null}
						endDateSelectPeriod={{
							periodDateStart: startDate,
						}}
						onEndSelect={(date) => setEndDate(date)}
					/>

					{ activityType === enums.ActivityType.vote &&
						<VoteSetting
							allTags={props.allTags}
							disabled={isDisabled("voteSetting")}
							optionType={optionType}
							setOptionType={setOptionType}
							selectionType={selectionType}
							setSelectionType={setSelectionType}
							voteOptions={voteOptions}
							setVoteOptions={setVoteOptions}
							publicResult={publicResult}
							setPublicResult={setPublicResult}
							channelId={props.channelId}
						/>
					}

					<div className="sub-title">{t("activity.activityCover")}</div>
					<div className="activity-cover-image">
						<ImageUploader
							disabled={isDisabled("coverImagePath")}
							imageUrl={coverImage}
							filename={""}
							startUpload={uploadCover}
							errorMsg={uploadCoverErrorMsg}
							onClick={() => setUploadCover(true)}
							onUploadEventFired={() => {
								setUploadCover(false)
							}}
							onFileLoadStart={() => {
								setCoverImage(null)
								setUploadCoverErrorMsg("")
							}}
							onUpLoaded={(result) => {
								setCoverImage(result.url)
								setUploadCoverErrorMsg("")
							}}
							onError={(e) => setUploadCoverErrorMsg(e)}
							folderType={enums.FileUploadType.channel}
							channelId={props.channelId}
							fileUploadFrom={enums.FileUploadFrom.bot}
							imgRestriction={t('activity.activityCoverDes')}
							defaultImg={defaultCoverImage}
						/>
					</div>

					<div className="sub-title">{t('bot.setTriggerQuestion')}*</div>
					<div className="sub-des">{t('activity.triggerDes')}</div>
					<div className={cx("activity-keywords")}>
						<AddQuestionBlock
							disabled={isDisabled("keywords")}
							{...props}
							questions={questions}
							onChange={(result) => setQuestions(result)}
						/>
					</div>

					<div className="sub-title">{t('activity.activityTags')}</div>
					<div className="sub-des">{t('activity.activityTagsDes')}</div>
					<TagsInputer
						allTags={props.allTags}
						isDisplayMode={isDisabled("tags")}
						hideAddbutton={isDisabled("tags")}
						tags={tags}
						onChange={(result) => setTags(result)}
						channelId={props.channelId}
						modalPlacement={"bottom-start"}
					/>

					{ activityType === enums.ActivityType.normal &&
						<>
						<div className="sub-title">{t('activity.activityMsgs')}*</div>
						<div className="activity-reply">
							<CreateMsg
								{...props}
								messages={messages}
								onChange={(messages) => setMsg(messages)}
								limit={5}
								fileUploadFrom={enums.FileUploadFrom.bot}
							/>
						</div>
						</>
					}
				</div>
				{ activityType === enums.ActivityType.normal &&
					<div className="preview-part">
						<div className="sub-title">{t('preview')}</div>
						<ChatPreviewBlock
							{...props}
							messages={messages}
							sender={{
								type: 'bot'
							}}
						/>
					</div>
				}
				<div className="activity-setting-content">
					<div className="sub-title">{t('activity.expiredText')}*</div>
					<div className="sub-des">{t('activity.expiredTextDes')}</div>
					<textarea
						disabled={isDisabled("expiredReplyText")}
						className="c-txt__input c-txt__input--area"
						value={expiredText || ""}
						onChange={(e) => setExpiredText(e.target.value)}
					/>
				</div>

				{ activityType === enums.ActivityType.vote &&
					<>
					<div className="sub-title">{t('activity.votDoneKeyword')}</div>
					<div className="sub-des">{t('activity.votDoneKeywordDes')}</div>
					<input
						className="c-txt__input"
						value={voteDoneReplyKeyword || ""}
						onChange={(e) => setVoteDoneReplyKeyword(e.target.value)}
						disabled={isDisabled("voteDoneReplyKeyword")}
					/>
					<div className="sub-title">{t('activity.votDoneReply')}</div>
					<div className="sub-des">{t('activity.votDoneReplyDes')}</div>

					<div className="vote-done-reply">
						<CreateMsg
							{...props}
							messages={voteDoneReply}
							onChange={(messages) => setVoteDoneReply(messages)}
							limit={5}
							fileUploadFrom={enums.FileUploadFrom.bot}
						/>
					</div>

					</>
				}
			</div>
			<div className="button-group">
				<button className="c-btn"
					onClick={()=>{props.history.goBack()}}
					>{t('cancel')}</button>
				<button className={cx(
					"c-btn c-btn--primary",
					{ 'is-disabled': !checkInputValidation() }
				)} onClick={() => updateActivity()}>
					{isUpdating ?
						<i className="fal fa-spinner-third fa-spin" /> :
						t('save')
					}
				</button>

				<UpdateMessage isUpdating={isUpdating}/>
			</div>
		</div>
	)
}

ActivityContent.defaultProps = {
	activity: {}
}

export default ActivityContent;
