import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';
import cx from 'classnames';
import dayjs from 'dayjs'

import enums from 'enums';
import UploadModal from 'Components/Uploader/UploadModal';
import RichImageModal from './RichImageModal';
import ActivityModal from './ActivityModal'
import { fetchHelper } from 'tools/FetchHelper'

import Tooltip from 'Components/Tooltip';
import { getPreviewByfileContentType, formatBytes } from 'Components/Uploader'

import './index.css'


const msgTypeOptions = ['text', 'file', 'imagemap', 'activityCard']

const AMessage = (props) => {
	const { t } = useTranslation();

	if (props.message.contentUrl && !props.message.extension) {
		const contentUrlArr = props.message.contentUrl.split('.')
		props.message.extension = contentUrlArr[contentUrlArr.length-1]
	}

	const [msgType, setMsgType] = useState(props.message.type || 'text') // text or image, audio, video, file or imagemap
	const [msgObj, setMsg] = useState(props.message || {})
	const [textInputValue, setText] = useState(props.message.text || null)
	const [showUploadMoadl, setUploadModal] = useState(false)

	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) return
		if (['file', 'imagemap', 'activityCard'].includes(msgType)) {
			setUploadModal(true)
		}
	}, [msgType])

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		props.onMsgUpdate(msgObj)
	}, [msgObj])

	return (
		<div className="a-message">
			<div className="top-section">
				<div className="left-section">
					<span>{t('message.msgType')}</span>
					<ThemeProvider theme={window.menuTheme}>
						<Menu
							trigger={({ ref, isOpen }) => {
								return (
									<div className={cx("c-txt__input--select filter-select", { 'is-open': isOpen })} ref={ref}>
										{['file', 'image', 'audio', 'video'].includes(msgType) ? t(`message.file`) : t(`message.${msgType}`)}
									</div>
								)
							}}
						>
							{msgTypeOptions.map((aType, i) => (
								<Item
									className="c-txt__input--select-item"
									key={aType}
									onClick={() => {
										setMsgType(aType)
										setMsg({
											type: aType
										})
									}}>
									{t(`message.${aType}`)}
								</Item>
							))}
						</Menu>
					</ThemeProvider>
					{['file', 'image', 'audio', 'video', 'imagemap', 'activityCard'].includes(msgType) &&
						<button
							className="c-btn c-btn--primary"
							onClick={() => setUploadModal(true)}
						>
							{['file', 'image', 'audio', 'video'].includes(msgType) && t('upload')}
							{msgType === 'imagemap' && t('edit')}
							{msgType === 'activityCard' && t('select')}
						</button>
					}
				</div>
				{ !props.isLock &&
					<i className="far fa-trash" onClick={() => props.onDelete()} />
				}
			</div>
			<div className="select-content">
				{msgType === 'text' &&
					<textarea
						className="c-txt__input c-txt__input--area"
						value={textInputValue || ""}
						name="msg-textarea"
						onChange={(e) => setText(e.target.value)}
						onBlur={(e) => setMsg({
							...msgObj,
							text: e.target.value
						})}
					/>
				}
				{(msgType === 'file' || ['image', 'audio', 'video'].includes(msgType)) &&
					<div className="file-upload">
						<UploadModal
							isModalOpen={showUploadMoadl}
							onRequestClose={() => setUploadModal(false)}
							folderType={enums.FileUploadType.channel}
							onUpLoaded={(result) => setMsg({
								...msgObj,
								contentUrl: result.url,
								...result
							})}
							channelId={props.channelId}
							fileUploadFrom={props.fileUploadFrom}
						/>
						{msgType === 'file' && msgObj.guid &&
							<div className="link-file">
								{(() => {
									const { previewUrl, isImage } = getPreviewByfileContentType(msgObj.contentType, msgObj.fileUrl)
									return  (
										<figure className={cx({ preview: !isImage })}>
											<img src={previewUrl} alt=""/>
										</figure>
									)
								})()}
								<div className="file-info">
									<div className="file-name u-truncate">{msgObj.name}</div>
									<div className="file-ext">{t('message.fileExt')}： .{msgObj.extension}</div>
									<div className="file-size">{t('message.fileSize')}： {formatBytes(msgObj.size, 2, t('message.unrecognized'))}</div>
									<div className="expire-date">{t('message.fileDownloadExpiredDate')}： {msgObj.expireDtm ?
										t('message.ExpiredDateFromNow', { expiredDate: dayjs(msgObj.expireDtm).format('YYYY/MM/DD') }) :
										'-'
									}</div>
								</div>
							</div>
						}
						{['image', 'audio', 'video'].includes(msgType) && msgObj.contentUrl &&
							<div className="uploaded-file">
								{['jpeg', 'jpg', 'png'].includes(msgObj.extension) &&
									<figure>
										<img src={msgObj.contentUrl} alt=""/>
									</figure>
								}
								{['m4a', 'x-m4a'].includes(msgObj.extension) &&
									<div className="media-icon">
										<i className="fal fa-file-audio" />
									</div>
								}
								{msgObj.extension === 'mp4' &&
									<div className="media-icon">
										<i className="fal fa-file-video" />
									</div>
								}
								<div className="file-info">
									<div className="file-name">{msgObj.name}</div>
									{msgObj.guid &&
										<>
											<div className="file-ext">{t('message.fileExt')}： .{msgObj.extension}</div>
											<div className="file-size">{t('message.fileSize')}： {formatBytes(msgObj.size, 2, t('message.unrecognized'))}</div>
											<div className="expire-date">{t('message.fileDownloadExpiredDate')}： {msgObj.expireDtm ?
												t('message.ExpiredDateFromNow', { expiredDate: dayjs(msgObj.expireDtm).format('YYYY/MM/DD') }) :
												'-'
											}</div>
										</>
									}
								</div>
							</div>
						}
					</div>
				}
				{msgType === 'imagemap' &&
					<div className="image-n-text">
						<RichImageModal
							{...props}
							isModalOpen={showUploadMoadl}
							onRequestClose={() => setUploadModal(false)}
							initialMsg={msgObj}
							onSaved={(form) => setMsg({
								...msgObj,
								...form,
							})}
							fileUploadFrom={props.fileUploadFrom}
						/>
						{msgObj.imageUrl &&
							<div className="uploaded-file">
								<figure>
									<img src={msgObj.imageUrl} alt=""/>
								</figure>
								<div className="file-info">
									{msgObj.title}
								</div>
							</div>
						}
					</div>
				}
				{msgType === 'activityCard' &&
					<div className="activity-content">
						<ActivityModal
							isModalOpen={showUploadMoadl}
							onRequestClose={() => setUploadModal(false)}
							channelId={props.channelId}
							selectedActivityGuid={msgObj.activityGuid || null}
							allActivitys={props.selectableActivity}
							onSaved={(selectActivity)=>{
								setMsg({
									type: "activityCard",
									activityGuid: selectActivity.guid,
									title: selectActivity.name,
									image: selectActivity.coverImagePath,
									text: `${dayjs(selectActivity.startDtm).format('YYYY/MM/DD')} -
									${selectActivity.endDtm? dayjs(selectActivity.endDtm).format('YYYY/MM/DD'): ""}`
								})
							}}
						/>
						{msgObj.activityGuid &&
							<>
							<div className="activity-image">
								<figure>
									<img src={msgObj.image} alt=""/>
								</figure>
							</div>
							<div className="activity-info">
								<div className="text">
									{t('message.activityName') + msgObj.title}
								</div>
								<div className="text">
									{t('message.activityDuration') + msgObj.text}
								</div>
							</div>
							</>
						}
					</div>
				}
			</div>
		</div>
	)
}

const CreateMsg = (props) => {
	const { t } = useTranslation();
	const isLock = props.isLock || false
	const [messages, setMsg] = useState(props.messages.length < 1 ? [{
		type: 'text',
		hash: Math.random().toString(36).substring(7)
	}] : props.messages.map(x => ({ ...x, hash: Math.random().toString(36).substring(7) })))

	const activityType = props.activityType || 'notExpired'
	const [isFetching, setFetching] = useState(false)
	const [selectableActivity, setSelectableActivity] = useState(props.selectableActivity || [])

	useEffect(() => {
		const fetchAllSelectableActivity = async () => {
			setFetching(true)
			try {
				const result = await fetchHelper.get(`/channel/${props.channelId}/activity`, {
					params: {
						size: 100,
						type: activityType
					},
					fetchQAbot: true
				});
				const res = result.data
				if (res.status === "OK") {
					setSelectableActivity(res.data.activity)
					setFetching(false)
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				console.log('get all activities fail', error.message)
			}
		}

		if (!!props.setSelectableActivity) return
		fetchAllSelectableActivity()

	}, [props.selectableActivity])


	const isFirstRun = useRef(true);

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}

		props.onChange(messages.filter(aMsg => {
			if (!aMsg.type) return false;
			if (aMsg.type === 'text') return !!aMsg.text
			if (['image', 'audio', 'video'].includes(aMsg.type)) return !!aMsg.contentUrl
			if (aMsg.type === 'file') return !!aMsg.guid

			if (aMsg.type === 'imagemap') return !!aMsg.imageUrl
			if (aMsg.type === 'activityCard') return !! aMsg.activityGuid

			return false
		}))
	}, [messages])

	return (
		<>
		{isFetching ?
			<div className="icon-loading">
				<i className="fal fa-spinner-third fa-spin" />
			</div>
		:

			<div className="create-new-msg">
				{messages.map((aMsg, i) => (
					<AMessage
						isLock={isLock}
						{...props}
						message={aMsg}
						key={`new-msg-${aMsg.hash}-${i}`}
						onMsgUpdate={(msg) => {
							const msgCopy = messages.slice()
							msgCopy[i] = msg
							setMsg(msgCopy)
						}}
						onDelete={() => {
							const msgCopy = messages.slice()
							msgCopy.splice(i, 1)
							setMsg(msgCopy)
						}}
						selectableActivity={selectableActivity}
					/>
				))}
				{ !isLock &&
					<Tooltip>
						<button
							className={cx(
								"c-btn c-btn--primary more-msg-button",
								{ 'is-disabled': props.limit ? messages.length >= props.limit : false }
							)}
							onClick={() => {
								if (props.limit && messages.length >= props.limit) return;
								const msgCopy = messages.slice()
								msgCopy.push({ type: 'text' })
								setMsg(msgCopy)
							}}
							data-tip={t('tooltip.maxAnswers')}
							data-offset="{ 'left': -45 }"
							data-tip-disable={props.limit ? messages.length < props.limit : true}
						>
							{t('message.moreMessages')}
						</button>
					</Tooltip>
				}
			</div>
		}
		</>
	)
}

CreateMsg.defaultProps = {
	onChange: () => {},
	messages: []
}

export default CreateMsg;