import React, { useState, useEffect, useRef, setGlobal, useGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import zhTW from 'dayjs/locale/zh-tw'
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';
import shortid from 'shortid';
import { Link } from 'react-router-dom'

import { fetchHelper } from 'tools/FetchHelper'
import { logoutUser, getUser } from 'Login'

import './index.css'

dayjs.locale('zh-tw', zhTW)
dayjs.extend(relativeTime)


const Header = (props) => {
	const { t } = useTranslation();
	const [isFetching, setFetching] = useState(false);
	const [notifications, setNotify] = useGlobal('notifications')
	const [unReadNotify, setUnRead] = useGlobal('unReadNotify')
	const [latestReadNotifyDtm, setLastRead] = useGlobal('latestReadNotifyDtm')
	const [noMoreNoti, setNoMoreNoti] = useState(false)
	const page = useRef(1)
	const notiListRef = useRef(null)

	const onNotiListScroll = () => {
		if (notiListRef.current.scrollTop === notiListRef.current.scrollHeight - notiListRef.current.clientHeight) {
			fetchAllNoti(true)
		}
	}

	const fetchAllNoti = async (fetchMore=false) => {
		if (isFetching) return;
		if (noMoreNoti) return;
		setFetching(true);

		if (fetchMore) {
			page.current += 1;
		}
		try {
			const result = await fetchHelper.get('/notify', {
				params: {
					page: page.current,
					size: 25
				}
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {

				setNotify([].concat(notifications, res.data.notifyList.map(aNoti => ({
					...aNoti,
					hash: shortid.generate()
				}))))
				setUnRead(res.data.unReadNotifyCount)
				setLastRead(res.data.latestReadNotifyDtm)

				if (res.data.notifyList.length === 0) {
					setNoMoreNoti(true)
				}
				setFetching(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false);
			console.log('fecth all notify fail', error.message)
		}
	}

	const notiRead = async () => {
		try {
			const result = await fetchHelper.post(`/notify/read`);
			const res = result.data

			if (res.status === "OK") {
				setGlobal({
					unReadNotify: 0,
				})
			} else {
				throw new Error(res.errorMessage)
			}
		} catch (error) {
			console.log('noti read failed', error)
		}
	}

	useEffect(() => {
		fetchAllNoti()
	}, []);

	return (
		<div className="console-header">
			<div className="logo">
				<figure><img src="https://lineline.s3.amazonaws.com/icons/line-bagel-logo2.svg" alt=""/></figure>
			</div>
			<div className="right-part">
				<ThemeProvider>
					<Menu
						arrow
						placement="bottom"
						id="noti-container"
						trigger={({ ref, isOpen }) => {
							return (
								<div className="noti-icon" ref={ref} onClick={() => {
									if (!isOpen) {
										notiRead()
									}
								}}>
									<i className="fal fa-bell" />
									{unReadNotify > 0 &&
										<div className="dot">{unReadNotify}</div>
									}
								</div>
							)
						}}
					>
						<div className="noti-list" ref={notiListRef} onScroll={onNotiListScroll}>
							{notifications.length > 0 && notifications.filter(
								aNoti =>  dayjs(aNoti.messageData.createDtm).isAfter(dayjs(latestReadNotifyDtm))
								).length === 0 &&
								<div className="noti-status">{t('channel.noMoreNewNoti')}</div>
							}

							{notifications.map((aNoti, i) => {
								let sender = aNoti.sender.op
								if (aNoti.sender.type && aNoti.sender.type === 'bot') {
									sender = {
										picture: "https://lineline.s3.amazonaws.com/icons/robot.svg"
									}
								}

								let linkUrl = `/console/channel/${aNoti.messageData.channelGUID}/message`
								if(aNoti.messageData.chatRoomName) {
									linkUrl = linkUrl + `?search=${aNoti.messageData.chatRoomName}`
								}

								return (
									<Link
										to={linkUrl}
										className={cx(
											"a-notification",
											{ 'is-read': dayjs(aNoti.messageData.createDtm).isBefore(dayjs(latestReadNotifyDtm)) },
											{ 'is-last': i+1 === notifications.length}
										)}
										key={`a-notification-${aNoti.hash}-${i}`}
									>
										<figure>
											<img src={sender.picture} alt=""/>
											<div className="read-cover" />
										</figure>
										<div className="noti-info">
											<div className="action">{aNoti.messageData.text}</div>
											<div className="channel-name-n-time">
												<div className="channel-name">{aNoti.messageData.channelName}</div>
												<div className="noti-time">{dayjs(aNoti.messageData.createDtm).fromNow()}</div>
											</div>
										</div>
									</Link>
								)
							})}
							{noMoreNoti &&
								<div className="noti-status">{t('channel.noMoreNotify')}</div>
							}
							{isFetching &&
								<div className="icon-loading">
									<i className="fal fa-spinner-third fa-spin" />
								</div>
							}
						</div>
					</Menu>
				</ThemeProvider>
				<ThemeProvider theme={window.menuTheme}>
					<Menu
						trigger={({ ref }) => {
							return (
								<div className="user-avatar" ref={ref}>
									<figure className={cx("c-avatar", { 'default': !getUser().avatar })}>
										<img alt="" src={getUser().avatar} />
									</figure>
									<span> {getUser().name} </span>
									<i className="far fa-angle-down" />
								</div>
							)
						}}
					>
						<Item className="c-txt__input--select-item" onClick={() => logoutUser()}>{t('login.logout')}</Item>
					</Menu>
				</ThemeProvider>
			</div>

		</div>
	)
}

export default Header;