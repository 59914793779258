import React, { useState } from 'reactn';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';
import qs from 'qs'

import cx from 'classnames';
import enums from 'enums'
import './index.css'

const SearchBar = (props) => {
	const { t } = useTranslation();
	const { owners, location, disableOwnerFilter} = props
	const { searchByKeyword } = props
	const ENTER_KEY = 13

	// var general
	const { searchBarExtend, setSearchBarExtend } = props
	const [ keywordChange, setKeywordChange] = useState(false)

	// var friend
	const { currentOwnerFilter, setCurrentOwnerFilter, currentChatRoomTypeFilter, setCurrentChatRoomTypeFilter } = props
	// var search
	const { currentSearchValueType, setCurrentSearchValueType, searchValue, setSearchValue } = props

	const searchValueTypeOptions = ['userInfo', 'tag', 'history']
	const chatRoomTypeFilterOptions = ['all', 'pending', 'star', 'done']

	const closeSearchBarInput = () => {
		setSearchBarExtend(false)
		setSearchValue(undefined)

		const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true })
		delete urlParams["search"];
		window.history.pushState('', '', `?${qs.stringify(urlParams)}`);
	}

	return (
		<div className="search-bar">
			{ !searchBarExtend ?
			<div className="search-bar-dispatch-filter">
				<div className="search-bar-dispatch-filter-part">
					<ThemeProvider theme={window.menuTheme}>
					{ !disableOwnerFilter ?
						<Menu
							maxHeight="500px"
							trigger={({ ref, isOpen }) => {
								return (
									<div className={cx("c-txt__input--select filter-select", { 'is-open': isOpen })} ref={ref}>
										{!!currentOwnerFilter ? currentOwnerFilter.name : t(`message.searchFilter.owner.default`)}
									</div>
								)
							}}
						>
							<Item
								className="c-txt__input--select-item"
								key={"all"}
								onClick={() => {
									setCurrentOwnerFilter(null)
								}}>
								{t(`message.searchFilter.owner.default`)}
							</Item>
							<Item
								className="c-txt__input--select-item"
								key={"no"}
								onClick={() => {
									setCurrentOwnerFilter({"name" : t(`message.searchFilter.owner.noOwner`), "type" : enums.ChatRoomAssignOwnerType.none})
								}}>
								{t(`message.searchFilter.owner.noOwner`)}
							</Item>
							{owners.map((aOwner, i) => (
								<Item
									className="c-txt__input--select-item"
									key={aOwner.name}
									onClick={() => {
										setCurrentOwnerFilter(aOwner)
									}}>
									{aOwner.name}
								</Item>
							))}
						</Menu>
						:
						<div className={cx("filter-select c-txt__input disabled")} >
							{!!currentOwnerFilter ? currentOwnerFilter.name : t(`message.searchFilter.owner.default`)}
						</div>
					}
					</ThemeProvider>
					<ThemeProvider theme={window.menuTheme}>
						<Menu
							trigger={({ ref, isOpen }) => {
								return (
									<div className={cx("c-txt__input--select filter-select", { 'is-open': isOpen })} ref={ref}>
										{t(`message.searchFilter.type.${enums.ChatRoomStatusType[currentChatRoomTypeFilter]}`)}
									</div>
								)
							}}
						>
							{chatRoomTypeFilterOptions.map((aType, i) => (
								<Item
									className="c-txt__input--select-item"
									key={aType}
									onClick={() => {
										setCurrentChatRoomTypeFilter(enums.ChatRoomStatusType[aType])
									}}>
									{t(`message.searchFilter.type.${aType}`)}
								</Item>
							))}
						</Menu>
					</ThemeProvider>
				</div>
				<div className="search-bar-dispatch-icon">
					<div className="c-txt__input--media__figure">
						<i className="far fa-search"
							onClick={() => setSearchBarExtend(true)}
						/>
					</div>
				</div>
			</div>
			:
			<div className="search-bar-input">
				<ThemeProvider theme={window.searchFiltermenuTheme}>
					<Menu trigger={({ ref, isOpen }) => {
						return (
							<div className={cx("c-txt__input--select filter-select", { 'is-open': isOpen })} ref={ref}>
								{t(`message.searchValueFilter.type.${enums.SearchValueType[currentSearchValueType]}`)}
							</div>
						)
					}}>
						{searchValueTypeOptions.map((aType, i) => (
							<Item
								className="c-txt__input--select-item"
								key={aType}
								onClick={() => {
									setCurrentSearchValueType(enums.SearchValueType[aType])
								}}>
								{t(`message.searchValueFilter.type.${aType}`)}
							</Item>
						))}
					</Menu>
				</ThemeProvider>
				<div className={cx("c-txt__input c-txt__input--media", { valid: !!searchValue })}>
					<input
						className="c-txt__input c-txt__input--bare c-txt__input--media__body"
						placeholder={t('search')}
						type="text"
						value={searchValue || ""}
						onChange={(e) => {
							setKeywordChange(true)
							setSearchValue(e.target.value)
						}}
						onBlur={() => {
							if (keywordChange) {
								searchByKeyword()
								setKeywordChange(false)
							}
						}}
						onKeyDown={(e) => {
							if (e.keyCode === ENTER_KEY && !!e.target.value) {
								searchByKeyword()
								setKeywordChange(false)
							}
						}}
					/>
					<div className="c-txt__input--media__figure">
						<i
							className="far fa-search"
							onClick={() => searchByKeyword()}/>
					</div>
				</div>
				<div className="c-txt__input--media__figure">
					<i className="far fa-times"
						onClick={() => closeSearchBarInput() }
					/>
				</div>
			</div>}
		</div>
	)
}

export default SearchBar;
