import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'

import enums from 'enums'
import { fetchHelper } from 'tools/FetchHelper'

import VoteActivityVoteOption from './VoteActivityVoteOption'

const VoteActivityResultPage = (props) => {
	const { t } = useTranslation()
	const {activity, voteOption} = props
	const {voteResult, setVoteResult, isVote, isUnfollow} = props
	const isSingleSelect = activity.voteSetting.selectionType === enums.ActivityVoteSelectionType.single

	const [isFetchResult, setFetchResult] = useState(true)

	useEffect(()=>{
		const fetchVoteResult = async () => {
			try {
				const result = await fetchHelper.get(`/activity/${props.activityId}/vote/result`,{
					fetchQAbot: true
				});
				const res = result.data
				if (res.status === "OK") {
					setVoteResult(res.data)
					setFetchResult(false)
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				console.log('fetch activity result fail', error.message)
			}
		}
		setFetchResult(true)
		fetchVoteResult()
	}, [props.activityId])


	const isVoteOptionContain = (currenOptionValue) => {
		const existOption = voteOption.find((aOption)=>{
			return aOption.optionValue === currenOptionValue
		})
		return !!existOption
	}

	return (
		<div className="vote-result">
			{isFetchResult ?
			<div className="icon-loading">
				<i className="fal fa-spinner-third fa-spin" />
			</div>
				:
			<div className="vote-options">
				{activity.voteSetting.options.map((aOption, i) => {
					return (
						<VoteActivityVoteOption
							key={`a-vote-option-${i}`}
							inputId={`a-vote-option-${i}`}
							isSingleSelect={isSingleSelect}
							optionValue={aOption.optionValue}
							isSelect={isVoteOptionContain(aOption.optionValue)}
							isDisabled={true}
							showCount={true}
							count={voteResult.result[aOption.optionValue] || 0}
						/>
					)
				})}
			</div>
			}
			{isVote && isUnfollow &&
				<div className="vote-hint">請加入好友完成投票</div>
			}
			<div className="button-section">
				<button className={cx("line-button close-button")}
					onClick={() => {
						if(isVote) {
							props.onCloseAfterVoteDone()
						}
						else {
							props.onClose()
						}
					}}>
					{isVote ?
						t('activity.voteCompelete')
					:
						t('activity.votePage.close')
					}
				</button>
			</div>
		</div>
	)
}

export default VoteActivityResultPage;
