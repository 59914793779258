import React, { useState, useEffect, useRef } from 'react';

import RenderBotSheet from 'Components/RenderBotSheet'
import { fetchHelper } from 'tools/FetchHelper'
import { useTranslation } from 'react-i18next';

import './index.css';

const LineForm = (props) => {
	const { t } = useTranslation();
	const match =  props.match;
	const channelId = match.params ? match.params.channelId : null
	const [isFetching, setFetching] = useState(true)
	const [isUpdating, setUpdating] = useState(false)
	const [forms, setForm] = useState([])
	const [userData, setUserData] = useState(null)
	const updateField = useRef({})
	const [liffId, setLiffId] = useState(null)

	const formatUserDatafromForm= () => {
		const userData = {
			tags: []
		}
		const tagPattern = /^tags\d/
		Object.keys(updateField.current).map(aKey => {
			if (tagPattern.test(aKey)) {
				if (updateField.current[aKey].length > 0) {
					const newTags = updateField.current[aKey].filter(aTag => userData.tags.findIndex(x => x.text === aTag.text) < 0)

					userData.tags = [].concat(userData.tags, newTags)
				}
			} else {
				userData[aKey] = updateField.current[aKey]
			}

			return aKey
		})
		return userData
	}

	const updateBotDefault = async () => {
		const formData = formatUserDatafromForm()

		setUpdating(true)
		try {
			const result = await fetchHelper.post(`/channel/${channelId}/customer/form/fill`, {
				"snsUserId": userData.userId,
				"userData": formData
			}, {
				fetchQAbot: true
			});
			const res = result.data

			if (res.status === "OK") {
				setUpdating(false)
				window.liff.closeWindow()
				window.liff.sendMessages([{
					type: 'text',
					text: t('liff.finish')
				}])
				.then(() => {
					console.log('message sent');
				})
				.catch((err) => {
					console.log('error', err);
				});
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setUpdating(false)
			console.log('update bot default fail', error.message)
		}
	}


	useEffect(() => {
		const fetchForms = async (user) => {
			setFetching(true)
			try {

				await fetchHelper.post(`/channel/${channelId}/liff/chatRoom`, {
					lineUserId: user.userId,
					userName: user.displayName,
					userAvatar: user.pictureUrl,
					checkFollowState: true
				});

				const result = await fetchHelper.get(`/channel/${channelId}/customer/form`, {
					params: {
						snsid: user.userId
					},
					fetchQAbot: true
				});
				const res = result.data

				if (res.status === "OK") {
					setForm(res.data.formColumns)
					if (res.data.channelName) {
						document.title = res.data.channelName
					}
					setFetching(false);
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				setFetching(false);
				console.log('get bot forms', error.message)
			}
		}

		const getProfile = () => {
			window.liff.getProfile()
			.then(profile => {
				if (channelId) {
					setUserData(profile)
					fetchForms(profile)
				}
			})
			.catch((err) => {
			  console.log('error', err);
			});
		}

		if (!liffId) return

		if (window.liff === undefined) {
			window.location.reload()
		}

		window.liff
			.init({liffId: liffId})
			.then(()=>{
				console.log(window.liff.isLoggedIn())
				if (!window.liff.isLoggedIn()) {
					window.liff.login()
				}
				getProfile()
			})

	}, [liffId, channelId])


	useEffect(() => {
		const getLiffId = async () => {
			try {
				const result = await fetchHelper.get(`/channel/${channelId}/liff/id?type=form`);
				const res = result.data
				setLiffId(res.data)
			} catch (error) {
				setLiffId("1653935656-5gPjNjzB")
				console.log('get liffId Error', error.message)
			}
		}
		getLiffId()
	}, [channelId])


	return (
		<div className="line-form">
			{isFetching ?
				<div className="icon-loading">
					<i className="fal fa-spinner-third fa-spin" />
				</div> :
				<>
					<RenderBotSheet
						forms={forms}
						onChange={(result) => updateField.current = result}
						onSubmit={() => {}}
						onCancel={() => {}}
					/>
					<div className="sheet-buttons">
						<button className="c-btn c-btn--primary" onClick={() => updateBotDefault()}>
							{isUpdating ?
								<i className="fal fa-spinner-third fa-spin" /> :
								t('submit')
							}
						</button>
						<button className="c-btn" onClick={() => {
							window.liff.closeWindow()
							window.liff.sendMessages([{
								type: 'text',
								text: t('liff.cancel')
							}])
							.then(() => {
								console.log('message sent');
							})
							.catch((err) => {
								console.log('error', err);
							});
						}}>
							{t('cancel')}
						</button>
					</div>
				</>
			}
		</div>
	)
}

export default LineForm;