import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import cx from 'classnames'

import AddQuestionBlock from 'Components/AddQuestionBlock'
import ChatPreviewBlock from 'Components/ChatPreviewBlock'
import CreateMsg from 'Components/CreateMsg'
import { fetchHelper } from 'tools/FetchHelper'
import enums from 'enums'

import './index.css'

const OneToOne = (props) => {
	const { t } = useTranslation();
	const [messages, setMsg] = useState([])
	const [questions, setQuestions] = useState([])
	const [isFetching, setFetching] = useState(false)
	const [isUpdating, setUpdating] = useState(false)

	const updateBotCallOp = async () => {
		if (questions.length === 0) return;
		setUpdating(true)
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/bot/callOp`, {
				keywords: questions.map(x => x.value),
				reply: messages
			}, {
				fetchQAbot: true
			});
			const res = result.data

			if (res.status === "OK") {
				setUpdating(false)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setUpdating(false)
			console.log('update bot callOp fail', error.message)
		}
	}

	useEffect(() => {
		const fetchBotCallOp = async () => {
			setFetching(true)
			try {
				const result = await fetchHelper.get(`/channel/${props.channelId}/bot/callOp`, {
					fetchQAbot: true
				});
				const res = result.data

				if (res.status === "OK") {
					const data = res.data
					setQuestions(data.keywords.map(str => ({ value: str })))
					setMsg(data.reply)
					setFetching(false);
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				setFetching(false);
				console.log('get bot callOp fail', error.message)
			}
		}


		fetchBotCallOp()
	}, [props.channelId])

	return (
		<div className="one-to-one-page">
			<div className="page-title">
				<Link to={`/console/channel/${props.channelId}/bot`}>{t('bot.title')}</Link>
				<i className="far fa-chevron-right" />
				{t('bot.oneToOne')}
			</div>
			<div className="page-des">{t('bot.oneToOneDes')}</div>
			<div className="bot-setting-content">
				<div className="setting-part">
					{isFetching ?
						<div className="icon-loading">
							<i className="fal fa-spinner-third fa-spin" />
						</div> :
						<>
							<div className="sub-title">{t('bot.setTriggerQuestion')}</div>
							<div className="sub-des">{t('bot.triggerDes')}</div>
							<div className={cx("trigger-question", { error: questions.length === 0 })}>
								<AddQuestionBlock
									{...props}
									questions={questions}
									onChange={(result) => setQuestions(result)}
									{...(questions.length === 0 && {
										placeholder: t('bot.atLeastOneQuestion')
									})}
								/>
							</div>
							<div className="sub-title">{t('bot.oneToOneRespone')}</div>
							<CreateMsg
								{...props}
								messages={messages}
								onChange={(messages) => setMsg(messages)}
								limit={5}
								fileUploadFrom={enums.FileUploadFrom.bot}
							/>
							<div className="button-group">
								<button className="c-btn">{t('cancel')}</button>
								<button
									className={cx("c-btn c-btn--primary",
										{ 'is-disabled': questions.length === 0 }
									)}
									onClick={() => updateBotCallOp()}
								>
									{isUpdating ?
										<i className="fal fa-spinner-third fa-spin" /> :
										t('save')
									}
								</button>
							</div>
						</>
					}
				</div>
				<div className="preview-part">
					<div className="sub-title">{t('preview')}</div>
					<ChatPreviewBlock
						{...props}
						messages={messages}
						sender={{
							type: 'bot'
						}}
					/>
				</div>
			</div>
			<div className="button-group">
				<button className="c-btn">{t('cancel')}</button>
				<button
					className={cx("c-btn c-btn--primary",
						{ 'is-disabled': questions.length === 0 }
					)}
					onClick={() => updateBotCallOp()}
				>
					{isUpdating ?
						<i className="fal fa-spinner-third fa-spin" /> :
						t('save')
					}
				</button>
			</div>
		</div>
	)
}

export default OneToOne;