import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'

import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';

import { fetchHelper } from 'tools/FetchHelper'
import AddKeywordBlock from './AddKeywordBlock'

/**
 *
 * @property {object} aSetting
 *      - keywords : array of object
 *          - keywordId: keyword.guid
 *          - text: keyword.text
 *      - opId: operator.guid
 * @property {array} opList 顯示在選擇業務的dropdownList裡
 * 		- opId
 * 		- name
 * @property {array} recentKeywordDir 顯示在AddKeywordBlock的modal裡
 * 		- name
 * 		- guid
 * 		- tags
 * @property {function} onDelete param: index
 * @property {function} updateCurrentSetting param: newSetting
 */

const ASetting = (props) => {
    const { t } = useTranslation();
	const {aSetting, recentKeywordDir, opList} = props
	const {updateCurrentSetting, onDelete} = props

    const setOpId = (opId) => {
        updateCurrentSetting({
            ...aSetting,
            "opId" : opId
        })
    }

    const removeKeyword = (index) => {
		let newKeywords = aSetting.keywords.slice()
		newKeywords.splice(index, 1)
        updateCurrentSetting({
            ...aSetting,
            "keywords" : newKeywords
        })
    }

    const addKeyword = (newKeyword) => {
		let newKeywords = aSetting.keywords.slice()
		newKeywords = newKeywords.filter((item) => item.keywordId !== newKeyword.guid)
		newKeywords.unshift({
			"keywordId" : newKeyword.guid,
			"text" : newKeyword.text
		})
        updateCurrentSetting({
            ...aSetting,
            "keywords" : newKeywords
        })
    }
    return (
		<div className="a-setting">
            <div className="top-section">
                <div className="left-section">
                    <div className="title">{t('bot.autoAssignText.autoAssignSetting.chooseOp')}</div>
                    <div className="dropdown-list">
                        <ThemeProvider theme={{
                            ...window.menuTheme,
                            'menus.menu_view': `
                                && {
                                    min-width: 150px;
                                    max-width: 150px;
                                    max-height: 300px;
                                    overflow-y: auto;
                                }
                            `
                        }}>
                            <Menu
                                trigger={({ ref, isOpen }) => {
                                    return (
                                        <div className={cx("c-txt__input--select op-select", { 'is-open': isOpen })} ref={ref}>
                                            {aSetting.opId && opList.find(op => op.opId === aSetting.opId) ?
                                                opList.find(op => op.opId === aSetting.opId).name :
                                                t('bot.autoAssignText.autoAssignSetting.plzChoose')
                                            }
                                        </div>
                                    )
                                }}
                                >
                                {opList.map((aOp, i) => (
                                    <Item
                                    className="c-txt__input--select-item"
                                    key={`aOp-${i}`}
                                    onClick={() => {
                                        setOpId(aOp.opId)
                                    }}>
                                        {aOp.name}
                                    </Item>
                                ))}
                            </Menu>
                        </ThemeProvider>
                    </div>
                </div>
				<i className="far fa-trash delete-icon" onClick={onDelete} />
            </div>
            <AddKeywordBlock
                channelId={props.channelId}
                keywords={aSetting.keywords}
                recentKeywordDir={recentKeywordDir}
                placeholder={t('bot.autoAssignText.autoAssignSetting.placeholder')}
                removeKeyword={removeKeyword}
                addKeyword={addKeyword}
            />
        </div>
    )
}


/**
 *
 * @property {function} setIsEdited
 */
const SettingSection = (props) => {
    const { t } = useTranslation();
	const { settings, setSettings, setIsEdited } = props

    const [isFetching, setFetching] = useState(false)
    const [recentKeywordDir, setRecentKeywordDir] = useState([]) // [{"name":"", "tags": [{"text": ""}]}]
    const [opList, setOpList] = useState([])

    const updateSetting = (index, aSetting) => {
        let settingCopy = settings.slice()
        settingCopy[index] = aSetting
		setSettings(settingCopy)
		setIsEdited(true)
	}
	const deleteSetting = (index) => {
		let settingCopy = settings.slice()
		settingCopy.splice(index, 1)
		setSettings(settingCopy)
		setIsEdited(true)
	}

	const addSetting = () => {
        let settingCopy = settings.slice()
		settingCopy.push({
			"opId" : null,
			"keywords" : []
		})
		setSettings(settingCopy)
		setIsEdited(true)
	}

	// fetch recent keyword list & oplist
	useEffect(() => {
        let isCancelled  = false
        const getRecentKeywords = async () => {
            setFetching(true)
            try {
                const result = await fetchHelper.get(`/channel/${props.channelId}/bot/autoAssign/keywords`, {
                    fetchQAbot: true
                });
                const res = result.data
                if (isCancelled) return
                if (res.status === "OK") {
                    setRecentKeywordDir([{
                        "name" : t('bot.autoAssignText.autoAssignSetting.recentKeyword'),
                        "tags" : res.data.keywords
                    }])
                } else {
                    throw new Error(result.errorMessage);
                }
            } catch (error) {
                console.log('get all tags fail', error.message)
            } finally{
                setFetching(false)
            }
        }
		const getOperators = async () => {
			try {
                const result = await fetchHelper.get(`/channel/${props.channelId}/op/list`);
                if (isCancelled) return
				const res = result.data.data
				setOpList(res.map((item) => {
					return {
						name: item.name,
						opId: item.GUID
					}
				} ))
			} catch (error) {
				console.log('get channel op fail', error.message)
			}
		}
		getOperators()
        getRecentKeywords()
        return () => {
            isCancelled = true
        }
	}, [props.channelId])

	const opIdsInSetting = settings.map(setting => setting.opId)

    return (
        <div className="setting-section">
			<div className="sub-title">{t('bot.autoAssignText.autoAssignSetting.title')}</div>
			<div className="sub-des">{t('bot.autoAssignText.autoAssignSetting.des')}</div>
            { !isFetching && recentKeywordDir && opList &&
                <div className="settings">
                    {settings.map((aSetting, index)=>{
                        return (
                            <ASetting
                                key={`a-setting-index-${index}`}
                                {...props}
								recentKeywordDir={recentKeywordDir}
								opList={ opList.filter((op) => !opIdsInSetting.includes(op.opId) || op.opId === aSetting.opId)}
                                updateCurrentSetting={(val)=>{
                                    updateSetting(index, val)
                                }}
								aSetting={aSetting}
								onDelete={() => {
									deleteSetting(index)
								}}
							/>

                        )
                    })}
                </div>
            }
			<button
				className="c-btn c-btn--primary"
				onClick={addSetting}
			>{t('bot.autoAssignText.autoAssignSetting.addSetting')}</button>
        </div>
    )
}

export default SettingSection