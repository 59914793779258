import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { fetchHelper } from 'tools/FetchHelper'
import Uploader from 'Components/Uploader';
import enums from 'enums'
import ValidInput from 'Components/ValidInput'

import './index.css';

const ChannelNameAndPhoto = (props) => {
	const { t } = useTranslation();
	const [channelName, setName] = useState(props.settings ? props.settings.name : null)
	const [channelPhoto, setPhoto] = useState(props.settings ? props.settings.avatarUrl : null)
	const [startUpload, setUpload] = useState(false)
	const [errorMsg, setError] = useState(null)
	const [isFetching, setFetching] = useState(false)

	const createOrUpdateChannel = async (redirect = false) => {
		setFetching(true)

		try {
			let result  = {}
			if (props.channelId) {
				result = await fetchHelper.patch(`/channel/${props.channelId}` , {
					name: channelName,
					avatarUrl: channelPhoto
				});
			} else {
				result = await fetchHelper.post('/channel' , {
					name: channelName,
					avatarUrl: channelPhoto
				});
			}
			const res = result.data
			if (res.status === "OK") {
				setFetching(false);
				if (redirect) {
					window.location = `/console/channel/${res.data}/setting`
				}
			} else {
				throw new Error(res.errorMessage);
			}

		} catch (error) {
			setFetching(false);
			console.log('create or update Channel fial', error.message);
		}
	}

	return (
		<div className="set-name-photo-block">
			<div className="channel-name-and-photo">{t('channel.channelNameAndPhoto')}</div>
			<div className="channel-photo-des">
				{t('channel.linePhotoDes')}
				<a
					className="culture-text success"
					href="https://admin-official.line.me/"
					rel="noopener noreferrer"
					target="_blank">{t('channel.lineManager')}</a>
				{t('set')}。
			</div>
			<div className="channel-info-edit">
				<div className="image-upload" onClick={() => setUpload(true)}>
					<figure>
						<img
							src={channelPhoto ? channelPhoto : 'https://lineline.s3.amazonaws.com/icons/group.png'}
							alt=""
						/>
					</figure>
					<div className="camera-icon">
						<i className="fal fa-camera" />
					</div>
					<Uploader
						startUpload={startUpload}
						onUploadEventFired={() => setUpload(false)}
						onError={(e) => setError(e)}
						onUpLoaded={(result) => {
							setPhoto(result.url)
							setError(null)
						}}
						folderType={enums.FileUploadType.channel}
					/>
				</div>
				<div className="channel-name">
					<ValidInput
						className={cx((() => {
							if (channelName === null) return

							return !!channelName ? 'success' : 'error'
						})())}
						value={channelName || ''}
						placeholder={t('channel.channelName')}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
			</div>
			<div className="save-n-error">
				<button
					className="c-btn c-btn--primary"
					onClick={() => createOrUpdateChannel(props.redirect)}
					disabled={!channelPhoto || !channelName}
				>
					{isFetching ?
						<i className="far fa-spinner-third fa-spin" /> :
						t('save')
					}
				</button>
				{!!errorMsg && <div className="error">{errorMsg}</div>}
			</div>
		</div>
	)
}

export default ChannelNameAndPhoto;