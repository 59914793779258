import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';
import cx from 'classnames';

import enums from 'enums';
import UploadModal from 'Components/Uploader/UploadModal';
import RichImageModal from 'Components/CreateMsg/RichImageModal';
import Tooltip from 'Components/Tooltip';
import { getPreviewByfileContentType, formatBytes } from 'Components/Uploader'

import './index.css'

const msgTypeOptions = ['text', 'file', 'imagemap']

const MsgCard = (props) => {
	const { t } = useTranslation();

	if (props.message.contentUrl && !props.message.extension) {
		const contentUrlArr = props.message.contentUrl.split('.')
		props.message.extension = contentUrlArr[contentUrlArr.length-1]
	}

	const [msgType, setMsgType] = useState(props.message.type || 'text') // text or image, audio, video or imagemap
	const [msgObj, setMsg] = useState(props.message || {})
	const [textInputValue, setText] = useState(props.message.text || null)
	const [showUploadModal, setUploadModal] = useState(false)
	const isFirstRun = useRef(true)
	const oldMsgObj = useRef(props.message || {})

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}

		if (!(msgType === 'text' && msgObj.text === oldMsgObj.current.text)) {
			props.onMsgUpdate(msgObj)
		}

	}, [msgObj])

	return (
		<div className="msg-card">
			<div className="top-section">
				<div className="left-section">
					<span>{t('message.msgType')}</span>
					<ThemeProvider theme={{
						...window.menuTheme,
						'menus.menu_view': `
							&& {
								max-width: 100px;
								min-width: 100px;
							}
						`,
					}}>
						<Menu
							trigger={({ ref, isOpen }) => {
								return (
									<div className={cx("c-txt__input--select filter-select", { 'is-open': isOpen })} ref={ref}>
										{['file', 'image', 'audio', 'video'].includes(msgType) ? t(`message.file`) : t(`message.${msgType}`)}
									</div>
								)
							}}
						>
							{msgTypeOptions.map((aType, i) => (
								<Item
									className="c-txt__input--select-item"
									key={aType}
									onClick={() => {
										setMsgType(aType)
										if (['file', 'imagemap'].includes(aType)) {
											setUploadModal(true)
										}
									}}>
									{t(`message.${aType}`)}
								</Item>
							))}
						</Menu>
					</ThemeProvider>
					{['file', 'image', 'audio', 'video', 'imagemap'].includes(msgType) &&
						<button
							className="c-btn c-btn--primary"
							onClick={() => setUploadModal(true)}
						>
							{['file', 'image', 'audio', 'video'].includes(msgType) && t('upload')}
							{msgType === 'imagemap' && t('edit')}
						</button>
					}
				</div>
				<div className="right-section">
					{!props.showAddBtnOnly &&
						<>
							<Tooltip>
								<i
									className={cx("fal fa-arrow-up", {disabled: props.disableGoAbove })}
									onClick={() => {
										if (!props.disableGoAbove) {
											props.swapMsg(props.index, props.index-1)
										}
									}}
									data-tip={t('tooltip.isTop')}
									data-tip-disable={!props.disableGoAbove}
								/>
							</Tooltip>
							<Tooltip>
								<i
									className={cx("fal fa-arrow-down", {disabled: props.disableGoBelow })}
									onClick={() => {
										if (!props.disableGoBelow) {
											props.swapMsg(props.index, props.index+1)
										}
									}}
									data-tip={t('tooltip.isBottom')}
									data-tip-disable={!props.disableGoBelow}
								/>
							</Tooltip>
							<i className="fal fa-comment-alt-times" onClick={() => props.onDelete()} />
						</>
					}
					<Tooltip>
						<i
							className={cx("fal fa-comment-alt-plus", {disabled: props.disabledAddMsg })}
							onClick={() => props.onMsgAdd()}
							data-tip={t('tooltip.maxAnswers')}
							data-tip-disable={!props.disabledAddMsg}
						/>
					</Tooltip>
				</div>
			</div>
			<div className="select-content">
				{msgType === 'text' &&
					<textarea
						className="c-txt__input c-txt__input--area"
						value={textInputValue || ""}
						name="msg-textarea"
						onChange={(e) => setText(e.target.value)}
						onBlur={(e) => setMsg({
							...msgObj,
							text: e.target.value
						})}
					/>
				}
				{(msgType === 'file' || ['image', 'audio', 'video'].includes(msgType)) &&
					<div className="file-upload">
						<UploadModal
							isModalOpen={showUploadModal}
							onRequestClose={() => setUploadModal(false)}
							folderType={enums.FileUploadType.channel}
							onUpLoaded={(result) => setMsg({
								...msgObj,
								contentUrl: result.url,
								...result
							})}
							channelId={props.channelId}
							fileUploadFrom={enums.FileUploadFrom.bot}
						/>
						{msgType === 'file' && msgObj.guid &&
							<div className="link-file">
								{(() => {
									const { previewUrl, isImage } = getPreviewByfileContentType(msgObj.contentType, msgObj.fileUrl)
									return  (
										<figure className={cx({ preview: !isImage })}>
											<img src={previewUrl} alt=""/>
										</figure>
									)
								})()}
								<div className="file-info">
									<div className="file-name">{msgObj.fileName}</div>
									<div className="file-ext-n-size">
										{t('message.fileExt')}： .{msgObj.extension} | {t('message.fileSize')}： {formatBytes(msgObj.size, 2, t('message.unrecognized'))}
									</div>
								</div>
							</div>
						}
						{['image', 'audio', 'video'].includes(msgType) && msgObj.contentUrl &&
							<div className="uploaded-file">
								{['jpeg', 'jpg', 'png'].includes(msgObj.extension) &&
									<figure>
										<img src={msgObj.contentUrl} alt=""/>
									</figure>
								}
								{['m4a', 'x-m4a'].includes(msgObj.extension) &&
									<div className="media-icon">
										<i className="fal fa-file-audio" />
									</div>
								}
								{msgObj.extension === 'mp4' &&
									<div className="media-icon">
										<i className="fal fa-file-video" />
									</div>
								}
								<div className="file-info">
									<div className="file-name">{msgObj.name}</div>
									{msgObj.guid &&
										<div className="file-ext-n-size">
											{t('message.fileExt')}： .{msgObj.extension} | {t('message.fileSize')}： {formatBytes(msgObj.size, 2, t('message.unrecognized'))}
										</div>
									}
								</div>
							</div>
						}
					</div>
				}
				{msgType === 'imagemap' &&
					<div className="image-n-text">
						<RichImageModal
							{...props}
							isModalOpen={showUploadModal}
							onRequestClose={() => setUploadModal(false)}
							initialMsg={msgObj}
							onSaved={(form) => setMsg({
								...msgObj,
								...form,
								type: msgType
							})}
							fileUploadFrom={enums.FileUploadFrom.bot}
						/>
						{msgObj.imageUrl &&
							<div className="uploaded-file">
								<figure>
									<img src={msgObj.imageUrl} alt=""/>
								</figure>
								<div className="file-info">
									{msgObj.title}
								</div>
							</div>
						}
					</div>
				}
			</div>
		</div>
	)
}

const Answers = (props) => {
	const [messages, setMsg] = useState(props.messages.length < 1 ? [{
		type: 'text',
	}] : props.messages)
	const isFirstRun = useRef(true);

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		props.onChange(messages.filter(aMsg => {
			if (!aMsg.type) return false;
			if (aMsg.type === 'text') return !!aMsg.text
			if (aMsg.type === 'image') return !!aMsg.contentUrl
			if (aMsg.type === 'imagemap') return !!aMsg.imageUrl
			if (aMsg.type === 'file') return !!aMsg.guid

			return false
		}))
	}, [messages])

	return (
		<div className="qabot-answers">
			{messages.map((aMsg, i) => (
				<MsgCard
					{...props}
					message={aMsg}
					key={`new-msg-${aMsg.timestamp}-${i}`}
					index={i}
					onMsgUpdate={(msg) => {
						const msgCopy = messages.slice()
						msgCopy[i] = msg
						setMsg(msgCopy)
					}}
					onDelete={() => {
						const msgCopy = messages.slice()
						msgCopy.splice(i, 1)
						setMsg(msgCopy)
					}}
					onMsgAdd={() => {
						const msgCopy = messages.slice()
						msgCopy.splice((i+1), 0, {
							type: 'text',
							timestamp: new Date().getTime()
						})
						setMsg(msgCopy)
					}}
					swapMsg={(indexA, indexB) => {
						const msgCopy = messages.slice()
						const temp = msgCopy[indexA]
						msgCopy[indexA] = msgCopy[indexB]
						msgCopy[indexB] = temp
						setMsg(msgCopy)
					}}
					disableGoAbove={i === 0}
					disableGoBelow={i === (messages.length - 1)}
					disabledAddMsg={messages.length >= 5}
					showAddBtnOnly={messages.length === 1}
				/>
			))}
		</div>
	)
}

Answers.defaultProps = {
	onChange: () => {},
	messages: []
}

export default Answers;
