import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Switch } from 'react-router-dom'
import dayjs from 'dayjs';
import cx from 'classnames'

import { fetchHelper } from 'tools/FetchHelper'
import BroadcastDetail from './Detail'
import enums from 'enums'

import './index.css'

const BroadcastList = (props) => {
	const { t } = useTranslation();
	const [isFetching, setFetching] = useState(false)
	const [isDeleting, setDelete] = useState(-1)
	const [isCopying, setCopy] = useState(-1)
	const [broadcasts, setBroadcasts] = useState([])

	const getBroadcastList = async () => {
		setFetching(true)
		try {
			const result = await fetchHelper.get(`/channel/${props.channelId}/multiCast/log`);
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				setFetching(false);
				setBroadcasts(res.data)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false);
			console.log('get broadcast list fail', error.message)
		}
	}

	const deleteCast = async (castId, i) => {
		setDelete(i)
		try {
			const result = await fetchHelper.delete(`/channel/${props.channelId}/multiCast/${castId}`);
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				setDelete(-1);
				const broadcastsCopy = broadcasts.slice()
				broadcastsCopy.splice(i, 1)
				setBroadcasts(broadcastsCopy)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setDelete(-1);
			console.log('delete broadcast fail', error.message)
		}
	}

	const copyCast = async (castId, i) => {
		setCopy(i)
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/multiCast/${castId}/copy`);
			const res = result.data
			console.log(res)
			if (res.status === "OK") {
				setCopy(-1);
				window.location.reload()
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setCopy(-1);
			console.log('copy broadcast fail', error.message)
		}
	}

	useEffect(() => { getBroadcastList() }, []);

	return isFetching ?
		<div className="icon-loading">
			<i className="fal fa-spinner-third fa-spin" />
		</div> :
		<div className="channel-broadcast">
			<div className="page-title-with-button">
				<p>
					{t('channel.market')}
					<i className="fal fa-chevron-right" />
					{t('broadcast.title')}
				</p>
				<Link
					className="c-btn c-btn--primary"
					to={`${props.match.url}/create`}
				>
					{t('broadcast.newGroupMsg')}
				</Link>
			</div>
			<div className="broadcast-list">
				{broadcasts.map((aBroadcast, i) => {
					const recievedPercent =  Math.floor((
						aBroadcast.sendTo.receviedChatRoomIds.length /
						aBroadcast.sendTo.targetChatRoomIds.length
					) * 100)
					return (
						<div className="a-broadcast" key={`a-broadcast-${i}`}>
							<div className="sending-status">
								<div className={cx(aBroadcast.status === 'sent' ? 'msg-sent' : 'msg-unsend')}>
									{aBroadcast.status === 'sent' && t('broadcast.sent')}
									{aBroadcast.status === 'not sent' && t('broadcast.unsend')}
									{aBroadcast.status === 'sending' && t('broadcast.sending')}

								</div>
							</div>
							<div className="msg-title-n-time">
								<Link
									className="msg-title"
									to={{
										pathname: `${props.match.url}/create`,
										state: { broadcastData: aBroadcast }
									}}
								>
									{aBroadcast.title}
								</Link>
								<div className="op-name">{aBroadcast.operator.name}</div>
								<div className="scheduled-sending-time">
									{t('broadcast.scheduledSendingTime')}:
									{aBroadcast.reservationDtm ? dayjs(aBroadcast.reservationDtm).format('YYYY/MM/DD HH:mm') : t('unset')}
								</div>
							</div>
							<div className="buttons-n-statics">
								<div className="button-group">
									<Link
										className="msg-title"
										to={{
											pathname: `${props.match.url}/create`,
											state: { broadcastData: aBroadcast }
										}}
									>
										<i className="far fa-pen" />
									</Link>
									{isCopying === i ?
										<i className="fal fa-spinner-third fa-spin" /> :
										<i className="far fa-copy" onClick={() => copyCast(aBroadcast._id, i)} />
									}
									{isDeleting === i ?
										<i className="fal fa-spinner-third fa-spin" /> :
										<i
											className={cx("far fa-trash",
												{ disabled: aBroadcast.status === 'sent'}
											)}
											onClick={() => {
												if (aBroadcast.status === 'sent') return
												deleteCast(aBroadcast._id, i)
											}}
										/>
									}
								</div>
								<div className="msg-statics">
									<div className="sent-people">
										<p className="big-number">
											{(aBroadcast.type && aBroadcast.type === enums.broadcastType.line) ?
												aBroadcast.sendTo.followers :
												aBroadcast.sendTo.targetChatRoomIds.length}</p>
										<p>{t('broadcast.numberOfPeopleSent')}</p>
									</div>
									<div className="deliveray-rate">
										<p className="big-number">
											{`${isNaN(recievedPercent) ? 0 : recievedPercent}%`}
										</p>
										<p>{t('broadcast.deliverayRate')}</p>
									</div>
								</div>
							</div>
						</div>
					)
				})}
			</div>
		</div>
}

const Broadcast = (props) => {

	return (
		<Switch>
			<Route path={`${props.match.path}/create`} component={(routerProps) =>
				<BroadcastDetail
					{...props}
					{...routerProps}
				/>}
			/>
			<Route path={props.match.path} render={(routerProps) => <BroadcastList
				{...props}
				{...routerProps}
			/>} />
		</Switch>


	);
}

export default Broadcast;