import React from 'react';
import cx from 'classnames'

import './index.css'

/*

  Set props.className as "success" / "error" to render a valid / not valid input

	props:
		className
		subMsg
*/

const ValidInput = (props) => {
	return (
		<div className={cx("input-wrapper valid-input", props.className)}>
			<input
				{...props}
				className="c-txt__input"
			/>
			{props.subMsg && <div className="sub-msg">{props.subMsg}</div>}
		</div>
	)
}

export default ValidInput;