import React from 'react';
import { useTranslation } from 'react-i18next';
import ChannelNameAndPhoto from './ChannelNameAndPhoto'
import './index.css';

const CreateChannel = (props) => {
	const { t } = useTranslation();

	return (
		<div className="console-create-channel">
			<div className="page-header">
				<h2>{t('channel.addChannel')}</h2>
			</div>
			<ChannelNameAndPhoto redirect {...props} />
		</div>
	)
}

export default CreateChannel;