import React from 'react';
import cx from 'classnames';

import Avatar from './Avatar';
import NameAndTime from './NameAndTime';
import './index.css';

const RichImage = (props) => {
	return (
		<div className={cx("chat-dialogue rich-image", { 'hide-avatar': props.hideAvatar })}>
			{!props.hideAvatar &&
				<Avatar {...props} />
			}
			<div className="dialogue-content">
				{!props.hideAvatar &&
					<NameAndTime {...props} />
				}
				<a href={props.linkUrl} target="_blank" rel="noreferrer noopener">
					<figure>
						<img alt="" src={props.imageUrl} />
					</figure>
				</a>
			</div>
		</div>
	);
}

export default RichImage;