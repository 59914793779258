/**
 *
 * @param {array} xs
 * @param {string} key
 */
const groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

export {groupBy}