import React from 'react';
import {
  Link
} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import './index.css'


const PlatformUnset = (props) => {
	const { t } = useTranslation();
	return (
		<div className="platform-unset-page">
			<div className="page-title">{t('setting.unSettingTitle')}</div>
			<div className="go-to-setting">
				{t('setting.unSetting')}
				<Link to={`/console/channel/${props.channelId}/initial/line`}>
					{t('set')}
				</Link>
			</div>
		</div>
	)
}
export default PlatformUnset;
