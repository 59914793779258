
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import permissionEnum from 'enums/permission'
import { fetchHelper } from 'tools/FetchHelper'
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';
import Modal from 'Components/Modal'
import Tooltip from 'Components/Tooltip';
import ClickNEditInput from 'Components/ClickNEditInput';

const DeleteMemberModal = (props) => {
	const { t } = useTranslation();
	const [step, setStep] = useState('initial') // 'initial' or 'confirm'
	const [isFetching, setFetching] = useState(false) // 'initial' or 'confirm'

	const deleteMember = async () => {
		setFetching(true);
		try {
			const result = await fetchHelper.delete(`/channel/${props.channelId}/operator/${props.operator.guid}`);
			const res = result.data
			if (res.status === "OK") {
				setStep('confirm')
				setFetching(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false);
			console.log('delete member fail', error.message)
		}
	}

	return (
		<Modal
			className="delete-member-modal"
			isOpen={props.isModalOpen}
			onRequestClose={() => {
				props.onRequestClose(false)
				props.onDeleted(props.operator.guid)
			}}
		>
			{step === 'initial' &&
				<>
					<div className="modal-title">{t('deleteConfirm')}</div>
					<div className="modal-description">{props.operator.name}</div>
					<div className="button-group">
						<button className="c-btn c-btn--danger" onClick={() => deleteMember()}>
							{isFetching ?
								<i className="fal fa-spinner-third fa-spin" /> :
								t('delete')
							}
						</button>
						<button className="c-btn" onClick={() => props.onRequestClose()}>
							{t('cancel')}
						</button>
					</div>
				</>
			}
			{step === 'confirm' &&
				<>
					<div className="big-icon">
						<i className="fal fa-thumbs-up" />
					</div>
					{/* <div className="modal-title">{t('update')}{t('success')}</div> */}
				</>
			}
		</Modal>
	)
}
DeleteMemberModal.defaultProps = {
	onDeleted: () => {}
}



const MemberRow = (props) => {
	const { t } = useTranslation();
	const loginType = props.loginType || "cowell" // cowell or google
	const [role, setRole] = useState(props.operator.permissionType || permissionEnum.type.op) // operator / admin
	const [notifyToken, setNotifyToken] = useState(props.operator.lineNotifyToken || "")
	const [subName, setSubName] = useState(props.operator.subName || "")

	const [isModalOpen, setModalState] = useState(false)
	const [isFetching, setFetching] = useState(null)

	const isFirstRun = useRef([]); // this ref record witch state have run

	const updateMember = async (updateBody) => {
		if (isFetching) {
			return
		}

		setFetching(true);
		try {
			const result = await fetchHelper.patch(`/channel/${props.channelId}/operator/${props.operator.guid}`, updateBody);
			const res = result.data
			if (res.status === "OK") {
				setFetching(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false);
			console.log('update member fail', error.message)
		}
	}

	useEffect(() => {
		if (!isFirstRun.current.includes("role")) {
			isFirstRun.current.push("role")
			return;
		}

		console.log("update role")

		let updateBody = { permissionType: role }
		updateMember(updateBody)
	}, [role])


	useEffect(() => {
		if (!isFirstRun.current.includes("notifyToken")) {
			isFirstRun.current.push("notifyToken")
			return;
		}

		let updateBody = { lineNotifyToken: notifyToken }
		updateMember(updateBody)

	}, [notifyToken])

	useEffect(() => {
		if (!isFirstRun.current.includes("subName")) {
			isFirstRun.current.push("subName")
			return;
		}

		let updateBody = { name: subName }
		updateMember(updateBody)

	}, [subName])

	return (
		<div className="a-member">

			<Tooltip>
				<figure data-tip={props.operator.email || "" }>
					<img
						alt=""
						src={props.operator.picture ? props.operator.picture : "https://lineline.s3.amazonaws.com/icons/user.png"}
					/>
				</figure>
			</Tooltip>

			{loginType === "google" ?
				<div className="name-n-sub-name-section">
					<ClickNEditInput
						className="subName-input-section"
						initValue={subName|| props.operator.name}
						updateValue={setSubName}
						isFetching={isFetching}
					/>
					{subName ?
						<div className="name-section">{props.operator.name}</div>
						:
						<div className="name-section"> </div>
					}
				</div>
			:

				<div className="member-name">{props.operator.name}</div>

			}

			<ThemeProvider theme={window.menuTheme}>
				<Menu
					trigger={({ ref, isOpen }) => {
						return (
							<div className={cx("c-txt__input--select member-role", { 'is-open': isOpen })} ref={ref}>
								{t(`channel.${permissionEnum.type[role]}`)}
							</div>
						)
					}}
				>
					<Item className="c-txt__input--select-item" key="admin" onClick={() => setRole(permissionEnum.type.admin)}>
						{t('channel.admin')}
					</Item>
					<Item className="c-txt__input--select-item" key="operator" onClick={() => setRole(permissionEnum.type.op)}>
						{t('channel.op')}
					</Item>
					<Item className="c-txt__input--select-item" key="customerService" onClick={() => setRole(permissionEnum.type.csr)}>
						{t('channel.csr')}
					</Item>
				</Menu>
			</ThemeProvider>

			{loginType === "google" &&
				<ClickNEditInput
					className="line-token-input-section"
					initValue={notifyToken}
					updateValue={setNotifyToken}
					isFetching={isFetching}
				/>
			}

			<i className="far fa-trash icon-delete" onClick={() => setModalState(true)} />
			{isFetching != null &&
				<div className="update-msg icon-with-text culture-text success">
					{isFetching ?
						<i className="far fa-spinner-third fa-spin" /> :
						<>
							<i className="far fa-check" />
							{t('update')}{t('success')}
						</>
					}
				</div>
			}


			<DeleteMemberModal
				{...props}
				isModalOpen={isModalOpen}
				onRequestClose={() => setModalState(false)}
				onDeleted={(opId) => props.onDeleted(opId)}
			/>
		</div>
	)
}

MemberRow.defaultProps = {
	onDeleted: () => {}
}

export default MemberRow;
