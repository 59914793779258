import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import qs from 'qs'
import store from 'store';

import { fetchHelper } from 'tools/FetchHelper'

import './index.css'

const CowellSSO = (props) => {
	const { t } = useTranslation();
	const [isFetching, setFetching] = useState(true)
	const [errorMsg, setErrorMsg] = useState(null)

	const loginByAccessToken = async (accessToken, account) => {
		setFetching(true)
		try {
			const result = await fetchHelper.post('/login/cowell/valid', {
				accessToken: accessToken,
				account: account
			});

			if (result.data.status === "OK") {
				const data = result.data.data
				store.set('TRIP_NINJA_USER', {
					apiToken: data.apiToken.token,
					guid: data.guid,
					name: data.name,
					avatar: data.picture
				})

				window.location.href = '/console'
			} else {
				throw new Error(result.data.errorMessage);
			}
		} catch (error) {
			setFetching(false)
			setErrorMsg(error.message)
			console.log('login fail', error.message);
		}
	}

	useEffect(() => {
		const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
		console.log(params)
		if (!!params.accessToken && !!params.account) {
			console.log('test')
			loginByAccessToken(params.accessToken, params.account)
		} else {
			setFetching(false)
		}

	}, [])
	return (
		<div className="validation-page">
			{isFetching ?
				<div className="icon-loading">
					<i className="fal fa-spinner-third fa-spin" />
				</div> :
				<div className="result-block">
					<img src="" alt="" />
					<div className="block-title">
						{t('error.loginFail')}
						<span>{errorMsg ? errorMsg : t('error.validFail')}</span>
					</div>
					<Link className="c-btn c-btn--primary" to="/" >
						{t('login.backToHome')}
					</Link>
					<div className="contact-us">
						{t('login.dontHaveAccount')}
						<a href="mailto:service@trip-ninja.io">{t('login.contactUs')}</a>
					</div>
				</div>
			}
		</div>
	)
}

export default CowellSSO;