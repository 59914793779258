import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import cx from 'classnames';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { SelectContainer, SelectView, Dropdown } from '@zendeskgarden/react-select';

import './index.css'
import './calendar.css'

const monthsShortcut = 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_')
const weekdaysSortcut = 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_')


const Calendar = (props) => {
	let startDate, endDate = dayjs();
	const selected = props.selectedDate ? dayjs(props.selectedDate) : dayjs()
	const [showDropdown, setDropdwon] = useState({
		month: false,
		year: false
	})

	const currentYear = dayjs().year();
	const [selectedMonth, setMonth] = useState(selected.month())
	const [selectedYear, setYear] = useState(selected.year())
	const [selectedDate, setDate] =  useState(selected.date())
	const [datesArr, setDates] =  useState([])
	const [outputDate, setOutput] =  useState(dayjs().set('year', selectedYear).set('month', selectedMonth).set('date', selectedDate))

	const isFirstRun = useRef(true);
	const mode = props.mode
	const calendarDropdownYearLargest = mode === 'schedule' ? currentYear+1 : currentYear
	const calendarDropdownYearNumber = mode === 'schedule' ? 3 : 105

	const checkDisabledDate = (today) => {
		let disabled =  today.isBefore(
			props.selectPeriod.periodDateStart ? dayjs(props.selectPeriod.periodDateStart) : dayjs(), 'date')

		if (!disabled && props.selectPeriod.periodDateEnd) {
			disabled = today.isAfter(dayjs(props.selectPeriod.periodDateEnd) , 'date')
		}

		return disabled
	}


	useEffect(() => {
		startDate = dayjs().set('year', selectedYear).set('month', selectedMonth).set('date', 1).startOf('week')
		endDate = dayjs().set('year', selectedYear).set('month', selectedMonth).endOf('month').endOf('week')

		setDates([...Array(endDate.add(1, 'day').diff(startDate, 'day'))].map((aDay, i) => startDate.add(i, 'day')))
	}, [selectedMonth, selectedYear]);

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		props.onRequestClose(outputDate.format('YYYY-MM-DD'))
	}, [outputDate])


	return (
		<div className="calendar">
			<div className="select-date">
				<ThemeProvider>
					<SelectContainer
						isOpen={showDropdown.month}
						selectedKey={`selectedMonth-${selectedMonth}`}
						trigger={({ getTriggerProps, triggerRef, isOpen }) => (
							<SelectView
								bare
								className="c-txt__input--select calendar-select"
								{...getTriggerProps({
									open: isOpen,
									focused: isOpen,
									inputRef: (ref) => {
										triggerRef(ref);
									}
								})}
								onClick={() => setDropdwon({
									...showDropdown,
									month: !showDropdown.month
								})}
							>
								{monthsShortcut[selectedMonth]}
	    					</SelectView>
	    				)}
	    			>
	 					{({ getSelectProps, dropdownRef }) => (
							<Dropdown
	 							{...getSelectProps({
	 								animate: true,
	 								dropdownRef
	 							})}
	 							className="calendar-dropdown"
	 						>
	 							{monthsShortcut.map((aMon, i) => (
									<div
										className="calendar-select-item"
										key={`selectedMonth-${i}`}
										onClick={() => {
											setMonth(i)
											setDropdwon({
												...showDropdown,
												month: false
											})
										}}
									>{aMon}</div>
								))}
							</Dropdown>
 						)}
	 				</SelectContainer>
				</ThemeProvider>
				<ThemeProvider>
	 				<SelectContainer
						isOpen={showDropdown.year}
						selectedKey={`selectedYear-${selectedYear}`}
						trigger={({ getTriggerProps, triggerRef, isOpen }) => (
							<SelectView
								bare
								className="c-txt__input--select calendar-select year"
								{...getTriggerProps({
									open: isOpen,
									focused: isOpen,
									inputRef: (ref) => {
										triggerRef(ref);
									}
								})}
								onClick={() => setDropdwon({
									...showDropdown,
									year: !showDropdown.year
								})}
							>
								{selectedYear}
	    					</SelectView>
	    				)}
	    			>
	 					{({ getSelectProps, dropdownRef }) => (
							<Dropdown
	 							{...getSelectProps({
	 								animate: true,
	 								dropdownRef
	 							})}
	 							className="calendar-dropdown"
	 						>
	 							{[...Array(calendarDropdownYearNumber)].map((x, i) => (
									<div
										className="calendar-select-item"
										key={`selectedYear-${calendarDropdownYearLargest-i}`}
										onClick={() => {
											setYear(calendarDropdownYearLargest-i)
											setDropdwon({
												...showDropdown,
												year: false
											})
										}}
									>{calendarDropdownYearLargest-i}</div>
								))}

							</Dropdown>
 						)}
	 				</SelectContainer>
				</ThemeProvider>
			</div>
			<div className="a-month">
				<div className="weekdays">
					{weekdaysSortcut.map((aDay, i) => (
						<div className="weekday-name" key={`weekday-name-${i}`}>{aDay}</div>
					))}
				</div>
				<div className="dates">
					{datesArr.map((today, i) => {
						const disabled = props.selectPeriod ? checkDisabledDate(today) : false;

						return (
							<div
								key={`calendar-dates-${i}`}
								className={cx(
									"a-day",
									{
										"not-this-month": today.month() !== selectedMonth,
										"is-weekend": today.day() === 0 || today.day() === 6,
										active: today.format('YYYYMMDD') === outputDate.format('YYYYMMDD'),
										disabled: disabled
									}
								)}
								onClick={() => {
									if (disabled) return;
									if (!(today.format('YYYYMMDD') === outputDate.format('YYYYMMDD'))) {
										setYear(today.year())
										setMonth(today.month())
										setDate(today.date())
										setOutput(today)
									}
								}}
							>
								{today.format('D')}
							</div>
						)
					})}
				</div>
			</div>
		</div>
	);
}

const DateInput = (props) => {
	const {disabled} = props
	const [isCalendatOpen, setCalendarOpen] = useState(false)
	const [selectedDate, setDate] = useState(props.selectedDate || '')
	const isFirstRun = useRef(true);
	const mode = props.mode || "birthday"
	// birthday mode for pick year til now, schedule mode for pick last year to next year

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		props.onSelected(selectedDate)
	}, [selectedDate])

	return (
		<div className="date-input">
			<div
				className={cx("c-txt__input c-txt__input--media", { valid: !!selectedDate })}
				onClick={() => setCalendarOpen(!isCalendatOpen)}
			>
				<input
					className="c-txt__input c-txt__input--bare c-txt__input--media__body"
					type="date"
					value={selectedDate || ""}
					onChange={() => {}}
					disabled={disabled}
				/>
				<div className="c-txt__input--media__figure">
					<i className="fal fa-calendar-alt" />
				</div>
			</div>
			{!disabled && isCalendatOpen &&
				<div className="calendar-modal">
					<Calendar
						selectedDate={selectedDate}
						onRequestClose={(date) => {
							setCalendarOpen(false)
							setDate(date)
						}}
						selectPeriod={props.selectPeriod}
						mode={mode}
					/>
				</div>
			}
		</div>
	)
}

DateInput.defaultProps = {
	onSelected: () => {}
}

export default DateInput;