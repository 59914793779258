import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchHelper } from 'tools/FetchHelper'
import dayjs from 'dayjs'
import enums from 'enums'
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import Pager from 'Components/Pager'

const AttendeesTable = (props) => {
	const { t } = useTranslation();
	const [isFetching, setFetching] = useState(true)
	const [isDownloading, setDownloading] = useState(false)
	const [page, setPage] = useState(props.page)
	const [total, setTotal] = useState(props.total)
	const [attendees, setAttendees] = useState([])
	const size = props.size;

	const downloadAttendees = async () => {
		setDownloading(true)

		try {
			const result = await fetchHelper.get(`/activity/${props.activity.guid}/attendees`, {
				params: {
					startDtm: props.startDate.format('YYYY-MM-DD'),
					endDtm: props.endDate.format('YYYY-MM-DD'),
					page: 1,
					size: total
				},
				fetchQAbot: true
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {

				const allAttendees = res.data.attendees.map(aUser => ([
					aUser.no,
					aUser.chatRoom.name,
					aUser.isCreate ? t('activity.newMember') : t('activity.oldMember'),
					dayjs(aUser.createDtm).format('YYYY/MM/DD HH:mm'),
					aUser.participateWays.filter((x, i) => aUser.participateWays.indexOf(x) === i)
						.map(x => t(`activity.${enums.activityParticipateWays[String(x)]}`)).join('/'),
					aUser.count
				]))

				const lineArray = [].concat([[
					t('activity.order'), t('activity.attendeeName'), t('activity.newOrOldMember'),
					t('activity.firstJoinTime'), t('activity.participateWays'), t('activity.participateCounts')
				]], allAttendees.map(aLine => aLine.join(",")))

				// add bom for windows user
				const blob = new Blob([String.fromCharCode(0xFEFF), lineArray.join("\n")],{ type: 'text/csv;charset=utf-8;' });
				saveAs(
					blob,
					`${props.activity.name} ${props.startDate.format('YYYY-MM-DD')} - ${props.endDate.format('YYYY-MM-DD')}.csv`
				)


				setDownloading(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setDownloading(false);
			console.log('fecth all attendees fail', error.message)
		}
	}

	const getAttendee = async () => {
		setFetching(true);
		try {
			const result = await fetchHelper.get(`/activity/${props.activity.guid}/attendees`, {
				params: {
					startDtm: props.startDate.format('YYYY-MM-DD'),
					endDtm: props.endDate.format('YYYY-MM-DD'),
					page: page,
					size: 10
				},
				fetchQAbot: true
			});
			const res = result.data
			if (res.status === "OK") {
				setTotal(res.data.total)
				setAttendees(res.data.attendees)
				setFetching(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false);
			console.log('fecth attendees', error.message)
		}
	}
	const generateAttendeeVoteResult = (voteOption) => {

	}
	useEffect(() => {
		getAttendee()
	}, [page]);

	return (
		<>
			<div className="a-overview-block last-child">
				<div className="block-head with-button">
					{t('activity.attendees')}
					{total > 0 &&
						<div className="download" onClick={() => {
							if (isDownloading) return;
							downloadAttendees()}
						}>
							{isDownloading ?
								<i className="fal fa-spinner-third fa-spin" />:
								<i className="fal fa-file-download" />
							}
							{t('activity.downloadAttendees')}
						</div>
					}
				</div>
				<div className="block-content">
					<table>
						<thead>
							<tr>
								<th className="order">{t('activity.order')}</th>
								<th className="avatar-name">{t('activity.attendeeName')}</th>
								<th className="member">{t('activity.newOrOldMember')}</th>
								<th className="date">{t('activity.firstJoinTime')}</th>
								<th className="participate-ways">{t('activity.participateWays')}</th>
								{props.activity.type === enums.ActivityType.vote ?
									<th className="participate-option">{"投票選項"}</th>
									:
									<th className="participate-counts">{t('activity.participateCounts')}</th>
								}
								<th className="conversation">{t('activity.conversation')}</th>
							</tr>
						</thead>
						{isFetching ?
							<tbody>
								<tr><td colSpan="7">
									<div className="icon-loading">
										<i className="fal fa-spinner-third fa-spin" />
									</div>
								</td></tr>
							</tbody> :
							<tbody>
								{attendees.map((aUser, i) => (
									<tr key={`a-attendee-${i}`}>
										<td className="order">{aUser.no}</td>
										<td className="avatar-name">
											<figure><img src={aUser.chatRoom.avatar} alt=""/></figure>
											<div className="attendee-name">{aUser.chatRoom.name}</div>
										</td>
										<td className="member">
											<div className="member-type">
												{aUser.isCreate ? t('activity.newMember') : t('activity.oldMember')}
											</div>
											{aUser.chatRoom.state !== 'follow' &&
												<div className="not-join">{t('activity.notJoin')}</div>
											}
										</td>
										<td className="date">{dayjs(aUser.createDtm).format('YYYY/MM/DD HH:mm')}</td>
										<td className="participate-ways">
											{aUser.participateWays.filter((x, i) => aUser.participateWays.indexOf(x) === i)
												.map(x => t(`activity.${enums.activityParticipateWays[String(x)]}`)).join('\n')}
										</td>
										{props.activity.type === enums.ActivityType.vote ?
											<td className="participate-option">
												{aUser.voteOption.map((aOption, i) => {
													return <p key={i}>{aOption.optionValue}</p>
												})}
											</td>
											:
											<td className="participate-counts">{aUser.count}</td>
										}
										<td className="conversation">
											<Link to={`/console/channel/${props.channelId}/message?search=${aUser.chatRoom.name}`}>
												<i className="fal fa-comments" />
											</Link>

										</td>
									</tr>
								))}
							</tbody>
						}

					</table>
				</div>
			</div>
			<Pager
				total={Math.ceil(total / size)}
				current={page}
				onPageChanged={(nextPageNo) => setPage(nextPageNo)}
			/>
		</>
	)
}


AttendeesTable.defaultProps = {
	size: 10,
	page: 1,
	total: 0
}

export default AttendeesTable

