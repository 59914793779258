import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';

import './index.css'

const FormInputBlock = (props) => {
	const [value, setValue] = useState(props.aForm.value || null)
	const [checkedList, setList] = useState([])
	const isFirstRun = useRef(true);

	useEffect(() => {
		if (isFirstRun.current) return;

		if (props.aForm.subType === 'tags') {
			props.onChange({
				[`${props.aForm.subType}${props.index}`]: checkedList
			})
		}

		if (['subName', 'birthday', 'phone', 'email'].includes(props.aForm.subType)) {
			props.onChange({
				[props.aForm.subType]: value
			})
		}
	}, [checkedList, value])

	return (
		<div className="form-input">
			<div className="input-title">{props.aForm.displayTitle}</div>
			{props.aForm.displayDescription &&
				<div className="input-des">{props.aForm.displayDescription}</div>
			}
			{props.aForm.subType === 'tags' &&
				<div className="checkbox-list">
					{props.aForm.tags && props.aForm.tags.map((aTag, i) => {
						return (
							<div className="input-checkbox-block" key={`input-checkbox-block-${i}`}>
								<input
									id={`a-tag-${props.index}-${i}`}
									type="checkbox"
									checked={checkedList.findIndex(x => x.text === aTag.text) > -1}
									onChange={(e) => {
										const list = checkedList.slice()
										if (e.target.checked) {
											list.push(aTag)
										} else {
											const index = list.findIndex(x => x.text === aTag.text);
											list.splice(index, 1)
										}
										isFirstRun.current = false;
										setList(list)
									}}
								/>
								<label htmlFor={`a-tag-${props.index}-${i}`}>
									{aTag.text}
								</label>
							</div>
						)
					})}
				</div>
			}
			{['subName', 'birthday', 'phone', 'email'].includes(props.aForm.subType) &&
				<input
					className="c-txt__input"
					type={(() => {
						if (props.aForm.subType === 'birthday') return "date"
						if (props.aForm.subType === 'phone') return "tel"
						if (props.aForm.subType === 'email') return "email"


						return "text"
					})()}
					value={value || ""}
					onChange={(e) => {
						isFirstRun.current = false;
						setValue(e.target.value)
					}}
				/>
			}

		</div>
	)
}

FormInputBlock.defaultProps = {
	onChange: () => {}
}


const RenderBotSheet = (props) => {
	const { t } = useTranslation();
	const [fields, setField] = useState({})

	const isFirstRun = useRef(true);
	const {preview, onChange} = props

	useEffect(() => {
		if (preview) return;
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		onChange(fields)
	}, [fields, preview, onChange])

	return (
		<div className="bot-sheet">
			{props.forms.map((aForm, i) => {
				if (aForm.type === 'input') {
					return (
						<FormInputBlock
							aForm={aForm}
							key={`a-sheet-form-${i}`}
							index={i}
							onChange={(result) => setField({...fields, ...result})}
						/>
					)
				}

				if (aForm.type === 'image') {
					return (
						<figure className="form-image" key={`a-sheet-form-${i}`}>
							<img src={aForm.imagePath} alt=""/>
						</figure>
					)
				}

				return (
					<div key={`a-sheet-form-${i}`} className={cx(
						"form-text",
						{ title: aForm.subType === 'title' },
						{ description: aForm.subType === 'description'}
					)}>
						{aForm.value}
					</div>
				)
			})}
			<div key={`a-sheet-form-policy`} className={cx(
				"form-text", 'policy'
			)}>
				{t('bot.sheetPolicy.noti-1')}
				<a
					href={"https://www.linebagel.com/?p=74"}
					target="_blank" rel="noopener noreferrer">
					{t('bot.sheetPolicy.policy')}</a>
				{t('bot.sheetPolicy.noti-2')}
				<a
					href={"https://www.linebagel.com/?p=76"}
					target="_blank" rel="noopener noreferrer">
					{t('bot.sheetPolicy.terms')}</a>
				{t('bot.sheetPolicy.noti-3')}
			</div>

			{props.preview && props.forms.length > 0 &&
				<div className="sheet-buttons">
					<button className="c-btn c-btn--primary">
						{t('submit')}
					</button>
					<button className="c-btn">
						{t('cancel')}
					</button>
				</div>
			}
		</div>
	)
}

RenderBotSheet.defaultProps = {
	forms: [],
	preview: false,
	onChange: () => {}
}


export default RenderBotSheet;