import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import enums from 'enums'
import MemberRow from './MemberRow'
import TooltipFixPosition from 'Components/TooltipFixPosition'
import InviteMemberModal from './InviteMemberModal'

import './index.css'

/**
 * operator section
 * @prop  {string} channelId channel.guid
 * @prop  {array}} operators settings.operators
 * @prop  {bool} isModalOpen
 * @prop  {func} setModalState
 * @prop  {func} fetchChannelSetting
 */
const MemberSection = (props) => {
	const { t } = useTranslation();
	const { operators, setOperators, isModalOpen, setModalState, fetchChannelSetting } = props

	const cowellOps = operators.filter((aOperator)=> aOperator.loginType === enums.LoginType.Cowell)
	const googleOps = operators.filter((aOperator)=> aOperator.loginType === enums.LoginType.Google)

	return (
		<>
		<div className="a-block member-block">
			<div className="sub-title">{t('channel.memberInChannel')} {`(${operators.length})`}</div>
			{cowellOps &&
				<>
				<div className="member-list-header">
					<div className="header type">
						{t('setting.header.cowellAccount', {"opNumber" : cowellOps.length})}
					</div>
					<div className="header permission">{t('setting.header.permission')}</div>
				</div>
				<div className="member-list">
					{cowellOps.map((aOperator, i) => (
						<MemberRow
							{...props}
							operator={aOperator}
							key={`member-a-op-${i}`}
							onDeleted={(deleteOpId) => {
								setOperators(operators.filter((op)=> {return op.guid !== deleteOpId}))
							}}
						/>
					))}
				</div>
				</>
			}
			{googleOps &&
				<>
				<div className="member-list-header">
					<div className="header type">
						{t('setting.header.googleAccount', {"opNumber" : googleOps.length})}
					</div>
					<div className="header permission">{t('setting.header.permission')}</div>
					<div className="header token">{t('setting.header.token')}
						<TooltipFixPosition>
							<div className="tooltip-link">
								<Link to={`/static/line/notify/tutorial`} target="_blank">
									{t('setting.header.toTutorial')}
								</Link>
							</div>
						</TooltipFixPosition>
					</div>
				</div>
				<div className="member-list">
					{googleOps.map((aOperator, i) => (
						<MemberRow
							{...props}
							operator={aOperator}
							key={`member-a-op-${i}`}
							loginType={"google"}
							onDeleted={(deleteOpId) => {
								setOperators(operators.filter((op)=> {return op.guid !== deleteOpId}))
							}}
						/>
					))}
				</div>
				</>
			}
			<button
				className="c-btn c-btn--primary"
				onClick={() => setModalState(true)}
			>
				{t('channel.addMember')}
			</button>
		</div>

		<InviteMemberModal
			{...props}
			isModalOpen={isModalOpen}
			onRequestClose={() => setModalState(false)}
			onInvited={() => fetchChannelSetting()}
		/>
		</>
	)
}
export default MemberSection