import React from 'react';
import cx from 'classnames';

import './index.css'

/*
 * render a icon with tooltip(handle display by css)
 */
const TooltipFixPosition = (props) => {
	const iconClass = props.icon
	return (
		<div className="fix-position-tooltip">
			<div className="tooltip-icon">
				<i
					className={iconClass}
				/>
			</div>
			<div className="tooltip-text">
				{props.children}
			</div>
		</div>
	)
}
TooltipFixPosition.defaultProps = {
	icon: "fal fa-info-circle"
}

export default TooltipFixPosition;
