import React from 'react';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu } from '@zendeskgarden/react-menus';
// import cx from 'classnames';

import './index.css'

/**
 * a modal trigger by an add-buton
 * @param {*} props
 * @property {string} placement https://garden.zendesk.com/components/menu#api
 * @property {string} placeLeft
 * @property {string} plusBtnClass default to "plus-button"
 * @property {element} children
 *
 */
const GeneralModal = (props) => {
    const { children } = props
	return (
		<ThemeProvider>
			<Menu
				arrow
				placement={props.placement ? props.placement : props.placeLeft ? "end-top" : "start-top"}
				popperModifiers={{
					preventOverflow: {
						boundariesElement: 'viewport'
					}
				}}
				id={props.id || "general-modal"}
				trigger={({ ref, isOpen }) => {
					return (
						<div className={props.plusBtnClass || "plus-button"} ref={ref} onClick={() => {}}>
							<i className="far fa-plus icon-primary" />
						</div>
					)
				}}
				zIndex={1101}
			>
			{children}
			</Menu>
		</ThemeProvider>
	)
}


export default GeneralModal;