import { getUser } from 'Login';
export default class {
	constructor(wsHost, wsHostPort, isSSL= true, args = {}) {
		this.ws = undefined;
		this.onMessageFunc = undefined;
		this.onMessageCallBackFunc = undefined;

		this.wsHost = wsHost ? wsHost : window.location.hostname;
		this.wsHostPort = wsHostPort? wsHostPort : ( window.location.port ? window.location.port: '');
		this.protocol = isSSL ? 'wss' : 'ws';
		this.wsUrl = `${this.protocol}://${this.wsHost + (this.wsHostPort ? ":" : "") + this.wsHostPort + "/ws"}`;
	}

	createWebSocket(){
		if(window.ws) {
			this.ws = window.ws;
		} else {
			this._initWebSocket();
		}

		return this;
	}

	_initWebSocket() {
		try {
			this.ws = new WebSocket(this.wsUrl);

			this.ws.onopen = () => {
				console.log('websocket has opened');

				var data = {
					event: 'REGISTER',
					data: {
						type: 'linelineConsole',
						deviceId: getUser().guid
					}
				};

				this.ws.send(JSON.stringify(data));
			}

			this.ws.onmessage = () => {}

			this.setWsOnclose()

			window.ws = this.ws;
		}
		catch(e) {
			console.log(e)
			setTimeout(() => {
				this.createWebSocket()
				this.setOnMessage()
			}, 3000)
		}
	}
	setWsOnclose(func, callback){
		this.ws.onclose = (e) => {
			console.log('websocket has closed')
			this.ws = window.ws = null;

			setTimeout(() => {
				this.createWebSocket()
				this.setOnMessage(func, callback)
			}, 3000)
		}
	}

	setOnMessage(func, callback){
		if(func){
			this.onMessageFunc = func;
		}
		if(callback){
			this.onMessageCallBackFunc = callback;
		}

		if(!this.onMessageFunc){
			return;
		}

		this.setWsOnclose(this.onMessageFunc, this.onMessageCallBackFunc)

		if(!this.ws){
			setTimeout(() => {
				this.setOnMessage(func, callback)
			}, 3000)
			return;
		}

		this.ws.onmessage = (e) => {
			this.onMessageFunc(e)
			if (this.onMessageCallBackFunc) {
				this.onMessageCallBackFunc(e)
			}
		}
	}
}
