import React from 'react';
import Tooltip from 'Components/Tooltip';
import _uniqueId from 'lodash/uniqueId';
import cx from 'classnames'

import './index.css'
const CheckboxInput = (props) => {
	const inputId = props.id || _uniqueId('radio-input-')
	const useSpanText = props.useSpanText || false
	return (
		<div className={cx("input-checkbox-block", {"disabled" : props.isDisabled}, props.className || "")} >
			<input
				id={inputId}
				name="recipient"
				type="checkbox"
				disabled={props.isDisabled}
				onChange={props.onChange}
				checked={props.isChecked}
			/>
			{useSpanText?
				<span>
					{props.labelText}
				</span>
			:
				<label htmlFor={inputId}>
					{props.labelText}
				</label>
			}
			{ props.toolTip &&
				<Tooltip>
					<i className="fal fa-info-circle" data-tip={props.toolTip} />
				</Tooltip>
			}
		</div>
		)
}

CheckboxInput.defaultProps = {
	isDisabled: false,
	onChange: () => {},
	isChecked: false,
	labelText: "",
	toolTip: ""
}

export default CheckboxInput