import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import { Link } from 'react-router-dom';

import enums from 'enums'
import { fetchHelper } from 'tools/FetchHelper'

import './index.css'
import AttendeeStatistics from './AttendeeStatistics'
import ActivityContent from './ActivityContent'

const ActivityDetail = (props) => {
	const { t } = useTranslation();
	const [tab, setTab] = useState('setting')
	const [isFetching, setFetching] = useState(false)
	const [activity, setActivity] = useState({})
	const linkState = props.location.state
	const [allTags, setAllTags] = useState()

	useEffect(()=> {
		const fetchAllTags = async () => {
			try {
				const result = await fetchHelper.get(`/channel/${props.channelId}/tag`);
				const res = result.data
				if (res.status === "OK") {
					setAllTags(res.data)
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				console.log('fecth all tags fail', error.message)
			}
		}

		if (!allTags) {
			fetchAllTags()
		}
	}, [props.channelId])



	useEffect(() => {
		const getActivityDetail = async (activityId) => {
			setFetching(true);
			try {
				const result = await fetchHelper.get(`/activity/${activityId}`, {
					fetchQAbot: true
				});
				const res = result.data
				if (res.status === "OK") {
					setActivity(res.data)
					setFetching(false);
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				setFetching(false);
				window.location.href = `/console/channel/${props.channelId}/activity`
				console.log('fecth activity detail', error.message)
			}
		}

		let hasLocationState = false
		if (props.location.state && props.location.state.activityId) {
			getActivityDetail(props.location.state.activityId)
			hasLocationState = true
		}

		if (!hasLocationState && props.match.params && props.match.params.activityId) {
			getActivityDetail(props.match.params.activityId)
		}


		if (linkState && linkState.type) {
			setActivity({type: linkState.type})
		}

	}, [linkState, props.location.state, props.match.params, props.channelId])

	return (
		<div className={cx("activity-detail", tab)}>
			<div className="page-title">
				<Link to={`/console/channel/${props.channelId}/activity`}>
					{t('activity.title')}
				</Link>
				<i className="far fa-chevron-right" />
				{(activity && activity.name) ? activity.name
				: ( (linkState && linkState.type) ? t(`activity.createActivity.${enums.ActivityType[linkState.type]}`)
				: "")}
			</div>
			<div className="page-des">
				{t('activity.description')}
			</div>
			<div className="tabs">
				<div className={cx("a-tab", { active: tab === 'setting'})}
					onClick={() => setTab('setting')}>
					{t('activity.setting')}
				</div>
				<div className={cx(
					"a-tab",
					{ active: tab === 'statistics'},
					{ disabled: !activity.guid || !activity.startDtm}
				)}
					onClick={() => {
						if (!activity.guid || !activity.startDtm) return;
						setTab('statistics')
					}}>
					{t('activity.downloadNstatistics')}
				</div>
			</div>
			{isFetching || !allTags ?
				<div className="icon-loading">
					<i className="fal fa-spinner-third fa-spin" />
				</div> :
				<>
					{tab === 'setting' &&
						<ActivityContent
							{...props}
							activity={activity}
							allTags={allTags}
						/>
					}

					{tab === 'statistics' &&
						<AttendeeStatistics
							{...props}
							activity={activity}
						/>
					}
				</>
			}
		</div>
	)
}

export default ActivityDetail;