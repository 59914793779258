import React, { useState, useEffect } from 'react';
import store from 'store';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';
import cx from 'classnames'
import qs from 'qs'

import { fetchHelper } from 'tools/FetchHelper'
import enums from 'enums'
import './index.css'

const CowellLogin = (props) => {
	const { t } = useTranslation();
	const [selectedIndex, setOTAindex] = useState(null)
	const [isFetching, setFetching] = useState(true)
	const [otaList, setOTAList] = useState([])

	const fetchAllOTA = async () => {
		setFetching(true);
		try {
			const result = await fetchHelper.get('/travel-agency');
			const res = result.data
			if (res.status === "OK") {
				// console.log(res)
				setOTAList(res.data)
				setFetching(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false);
			console.log('fecth all channels fail', error.message)
		}
	}

	useEffect(() => {
		fetchAllOTA()
	}, [])

	return (
		<>
			<div className="block-title">
				{t('login.chooseTA')}
				<span className="orUseGoogle">
					{t('login.or')}
					<span onClick={() => props.setLoginType('initial')}>{t('login.googleLogin')}</span>
				</span>
			</div>
			{isFetching ?
				<div className="icon-loading">
					<i className="fal fa-spinner-third fa-spin" />
				</div> :
				<form className="cowell-form">
					<ThemeProvider theme={{
						...window.menuTheme,
						'menus.menu_view': `
							&& {
								max-width: 300px;
								min-width: 300px;
							}
						`,
					}}>
						<Menu
							trigger={({ ref, isOpen }) => {
								return (
									<div className={cx("c-txt__input--select filter-select", { 'is-open': isOpen })} ref={ref}>
										{selectedIndex !== null ?
											otaList[selectedIndex].name :
											t('plzSelect')
										}
									</div>
								)
							}}
						>
							{otaList.map((agent ,i) => (
								<Item
									key={`a-agency-${i}`}
									className="c-txt__input--select-item"
									onClick={() => setOTAindex(i)}
								>
									{agent.name}
								</Item>
							))}
						</Menu>
					</ThemeProvider>

					<button className="c-btn c-btn--primary" onClick={(e) => {
						e.preventDefault()
						if (selectedIndex !== null) {
							console.log(selectedIndex)
							window.location.href = otaList[selectedIndex].domain
						}
					}}>
						{t('login.next')}
					</button>
				</form>
			}

		</>
	)
}


const LoginPage = (props) => {
	const { t } = useTranslation();
	const [isFetching, setFetching] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [renderTopBlock, setTopBlock] = useState('logo') // privacy or term or logo
	const [loginType, setLoginType] = useState('initial') // initial or cowell

	const [shouldRedirct, setRedirect] = useState(false)

	// use query string as alternate for state now
	const urlParams = qs.parse(props.location.search, { ignoreQueryPrefix: true })
	const defaultReferer = { referer: { pathname: '/' } }
	const { referer } = urlParams.redirectTo ? {referer: { pathname: urlParams.redirectTo}}
		: props.location.state ? props.location.state : defaultReferer

	const googleSignInCallBack = async (id_token) => {
		try {
			const result = await fetchHelper.post('/login/social', {
				loginType: enums.SocialType.Google,
				token: id_token
			});
			// console.log(result)
			if (result.data.status === "OK") {
				const data = result.data.data
				const profile = window.auth2.currentUser.get().getBasicProfile();
				store.set('TRIP_NINJA_USER', {
					apiToken: data.apiToken,
					guid: data.guid,
					name: profile.getName(),
					avatar: profile.getImageUrl()
				})

				setErrorMsg(null);
				setRedirect(true)
			} else if (result.data.errorCode > 0) {
				setErrorMsg(`${result.data.errorCode}: ${result.data.errorMessage}`);
			} else {
				setErrorMsg('Unknown response');
			}
			setFetching(false);
		} catch (error) {
			setErrorMsg(error.message);
		}
	}

	const handleGoogleLogin = () => {
		setFetching(true);
		setErrorMsg(null);

		window.auth2.signIn().then(() => {
			googleSignInCallBack(window.auth2.currentUser.get().getAuthResponse().id_token)
		});
	};

	if (shouldRedirct) {
		window.location.href = referer.pathname
	}

	return (
		<div className="login-page">
			<div className="login-block">
				{renderTopBlock === 'logo' &&
					<div className="logo-part">
						<figure>
							<img alt="" src="https://lineline.s3.amazonaws.com/icons/line-bagel-logo.svg" />
						</figure>
					</div>
				}
				{renderTopBlock === 'privacy' &&
					<div className="show-privacy">
						<div className="content-title">{t('login.privatePolicy')}</div>
						<div className="privacy-content">
							{t('login.privatePolicyContent')}
						</div>
					</div>
				}
				{renderTopBlock === 'term' &&
					<div className="show-privacy">
						<div className="content-title">{t('login.termsOfService')}</div>
						<div className="privacy-content">
							{t('login.termsOfServiceContent')}
						</div>
					</div>
				}
				{loginType === 'initial' &&
					<>
						<div className="block-title">{t('login.login')}</div>
						<div className="choose-login-type">
							<button className="c-btn c-btn--primary" onClick={() => setLoginType('cowell')}>
								<>
									<figure>
										<img src="https://lineline.s3.amazonaws.com/icons/cowell.png" alt="CW"/>
									</figure>
									{t('login.useCowellAccount')}
								</>
							</button>
							<button className="c-btn c-btn--secondary" onClick={() => handleGoogleLogin()}>
								{isFetching ?
									<i className="far fa-spinner-third fa-spin" /> :
									<>
										<figure>
											<img src="https://lineline.s3.amazonaws.com/icons/google.jpg" alt="G"/>
										</figure>
										{t('login.useGoogleAccount')}
									</>
								}
							</button>
						</div>
					</>
				}
				{loginType === 'cowell' &&
					<CowellLogin errorMsg={errorMsg} setLoginType={setLoginType} {...props} />
				}
				<div className="service-privacy">
					{t('login.loginConfirm')}
					<span onClick={() => setTopBlock('privacy')}>{t('login.privatePolicy')}</span>
					{t('login.and')}
					<span onClick={() => setTopBlock('term')}>{t('login.termsOfService')}</span>
					{t('login.withCookie')}
				</div>
			</div>
		</div>
	);
}

const checkLogined = () => store.get('TRIP_NINJA_USER') ? store.get('TRIP_NINJA_USER').apiToken : null;

const logoutUser = (logoutLocal=false) => {

	window.auth2.signOut().then(function () {
		console.log('User signed out.');
	});

	const logoutFromServer = async () => {
		try {
			const result = await fetchHelper.post('logout', {});
			console.log(result)
			if (result.data.status === "OK") {
				logoutFromLocal()
			}
		} catch (error) {
			console.log('logout error', error.message)
			logoutFromLocal()
		}
	}

	const logoutFromLocal = () => {
		store.remove('TRIP_NINJA_USER')
		window.location.href = '/'
	}

	if(logoutLocal){
		logoutFromLocal()
	}
	else {
		logoutFromServer()
	}
}


const getUser = () => {
	const userData = {
		deviceId: store.get('TRIP_NINJA_DEVICE_ID')
	}
	if (store.get('TRIP_NINJA_USER')) {
		const user = store.get('TRIP_NINJA_USER')
		Object.assign(userData, {
			apiToken: user.apiToken,
			name: user.name,
			avatar: user.avatar,
			guid: user.guid,
		})
	}
	return userData
}

export {checkLogined, logoutUser, getUser, LoginPage}