import React from 'react';

import './index.css';

const Avatar = (props) => {
	return (
		<div className="user-avatar">
			<figure>
				<img alt="" src={props.avatar} />
			</figure>
		</div>
	);
}

export default Avatar;