import React, { useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { ComposedChart, AreaChart, Area, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip } from 'recharts';
import dayjs from 'dayjs'
import cx from 'classnames';
import numeral from 'numeral'

import { fetchHelper } from 'tools/FetchHelper'
import Tooltip from 'Components/Tooltip';
import ActiveSection from './ActiveSection';
import LineUsageSection from './LineUsageSection'

import './index.css';

const CustomTooltip = ({ active, payload, label, ...rest }) => {
	const { t } = useTranslation();

	if (active) {
		return (
			<div className="custom-tooltip">
				<div className="tooltip-title">{label}</div>
				{payload.length > 0 && payload.map((aRow, i) => (
					<div className="a-row" style={{ color: aRow.color}} key={`custom-tooltip-data-row-${i}`}>
						{aRow.value}
						<span>{t(`dashboard.${aRow.dataKey}`)}</span>
					</div>
				))
				}
			</div>
		);
	}

	return null
}

const DataSection = ({ section, hideChart = false}) => {

	return (
		<div className="a-overview-section">
				<div className="head-part">
					<div className="title-part">
						<div className="overview-title">{section.title}</div>
						{section.tooltip &&
							<Tooltip>
								<i
									className="fal fa-info-circle"
									data-tip={section.tooltip}
								/>
							</Tooltip>
						}
					</div>
				</div>
				<div className="content-part">
					{section.blocks.map((aBlock, i) => (
						<div className="a-overview-block" key={`a-block-${section.key}-${i}`}>
							<div className="block-title">{aBlock.title}</div>
							<div className={cx("block-content", { 'hide-chart': hideChart })}>
								{!hideChart &&
									<RenderAreaChart
										data={aBlock.chartData}
										areaStroke={aBlock.areaStroke}
										areaFill={aBlock.areaFill}
										width={80}
										height={30}
									/>
								}
								<div className="data-number-unit"><span>{aBlock.data}</span> {aBlock.unit}</div>
							</div>
						</div>
					))}

				</div>
			</div>
	)
}

const RenderAreaChart = ({ width = 175, height = 51, data, areaStroke, areaFill}) => {
	return <div className="area-chart">
		<AreaChart
			height={height}
			width={width}
			data={data}
			margin={{ top: 5, left: 0, right: 0, bottom: 5}}
		>
			<XAxis dataKey="date" hide />
			<YAxis hide />
			<ChartTooltip content={({ active, payload, label, ...rest }) => {
				if (active) {
					return (
						<div className="area-tooltip">
							{payload.length > 0 &&
								<div className="a-row" >
									{payload[0].payload.date}: {payload[0].payload.tooltipText || payload[0].payload.count} {payload[0].payload.unit}
								</div>
							}
						</div>
					);
				}

				return null
			}} />
			<Area type="monotone" dataKey="count" stroke={areaStroke} fill={areaFill} />
		</AreaChart>
	</div>
}

const Dashboard = (props) => {
	const { t } = useTranslation();

	const [dashboardData, setDashboard] = useState({})
	const [fetchDashboard, setFetchDashboard] = useState(true)

	const [sessionData, setSession] = useState({})
	const [fetchSession, setFetchSession] = useState(true)

	const [graphData, setGraph] = useState({})
	const [fetchGraph, setFetchGraph] = useState(true)

	const [activeTagData, setActiveTag] = useState([])
	const [fetchTag, setFetchTag] = useState(true)

	const secondsToHHMMSS = (sec) => {
		const hours = Math.floor(sec / 3600);
    	const minutes = Math.floor((sec - (hours * 3600)) / 60);
    	const seconds = sec - (hours * 3600) - (minutes * 60);

    	return `${String('0'+ hours).slice(-2)}:${String('0'+ minutes).slice(-2)}:${String('0'+ seconds).slice(-2)}`;
	}

	useEffect(() => {
		const getDashboard = async () => {
			setFetchDashboard(true);
			try {
				const result = await fetchHelper.get(`/${props.channelId}/dashboard`);
				const res = result.data
				// console.log(res)
				if (res.status === "OK") {
					setDashboard(res.data.dashboardData)
					setFetchDashboard(false);
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				setFetchDashboard(false);
				console.log('fecth dashboard', error.message)
			}
		}

		const getDashboardSession = async () => {
			setFetchSession(true);
			try {
				const result = await fetchHelper.get(`/${props.channelId}/dashboard/session`);
				const res = result.data
				// console.log(res)
				if (res.status === "OK") {
					setSession(res.data.sessionData)
					setFetchSession(false);
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				setFetchSession(false);
				console.log('fecth channel session', error.message)
			}
		}
		const getDashboardGraph = async () => {
			setFetchGraph(true);
			try {
				const result = await fetchHelper.get(`/${props.channelId}/dashboard/graph`, {
					params: {
						dayNum: 30
					}
				});
				const res = result.data
				// console.log(res)
				if (res.status === "OK") {
					setGraph(res.data.graphData)
					setFetchGraph(false);
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				setFetchGraph(false);
				console.log('fecth channel graph', error.message)
			}
		}
		const getDashboardActiveTag = async () => {
			setFetchTag(true);
			try {
				const result = await fetchHelper.get(`/${props.channelId}/dashboard/activeTag`);
				const res = result.data
				// console.log(res)
				if (res.status === "OK") {
					setActiveTag(res.data.activeTagData)

					setFetchTag(false);
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				setFetchTag(false);
				console.log('fecth channel active tag', error.message)
			}
		}

		getDashboard()
		getDashboardSession()
		getDashboardGraph()
		getDashboardActiveTag()
	}, [props.channelId]);

	return  [fetchDashboard, fetchSession, fetchGraph, fetchTag].includes(true) ?
		<div className="icon-loading">
			<i className="fal fa-spinner-third fa-spin" />
		</div> :
		<div className="channel-dashboard">
			<div className="page-title">{t('dashboard.overview')}</div>
			<div className="overview">
				<div className="a-overview-block">
					<div className="block-title">{t('dashboard.alreadyFriend')}</div>
					<div className="block-content">
						<div className="data-number-unit">
							<span>{numeral(dashboardData.totalLineFriendCount).format('0,0')}</span>
							{t('unit.people')}
						</div>
						<RenderAreaChart
							data={graphData.dailyNewFriendCount
								.sort((a,b) => dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1)
								.filter((x, i) => (i <= 6))
								.reverse()
								.map(aDay => ({
									date: dayjs(aDay.date).format('M/D'),
									count: aDay.count,
									unit: t('unit.people')
								}))
							}
							areaStroke="#f7d767"
							areaFill="#ffedab"
						/>
					</div>
				</div>
				<div className="a-overview-block">
					<div className="block-title">{t('dashboard.messagesCount')}</div>
					<div className="block-content">
						<div className="data-number-unit">
							<span>{numeral(dashboardData.totalMsgCount).format('0,0')}</span>
							{t('unit.message')}
						</div>
						<RenderAreaChart
							data={graphData.dailyMsgCount
								.sort((a, b) => dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1)
								.filter((x, i) => (i <= 6))
								.reverse()
								.map(aDay => ({
									date: dayjs(aDay.date).format('M/D'),
									count: aDay.count,
									unit: t('unit.message'),
								}))
							}
							areaStroke="#3dcca2"
							areaFill="#d7f4ec"
						/>
					</div>
				</div>
				<div className="a-overview-block">
					<div className="block-title">{t('dashboard.tagsCount')}</div>
					<div className="block-content">
						<div className="data-number-unit">
							<span>{numeral(dashboardData.todayTagCount).format('0,0')}
							</span> / <span>
							{numeral(dashboardData.totalTagCount).format('0,0')}</span> {t('unit.tag')}
						</div>
						<RenderAreaChart
							data={graphData.recentTagCountList.map((aDay, i) => (({
								date: dayjs().subtract(graphData.recentTagCountList.length-1-i, 'day').format('M/D'),
								count: aDay,
								unit: t('unit.tag'),
							})))}
							areaStroke="#359ced"
							areaFill="#e6f4ff"
						/>
					</div>
				</div>
				<div className="a-overview-block">
					<div className="block-title">{t('dashboard.callOpCount')}</div>
					<div className="block-content">
						<div className="data-number-unit">
							<span>{numeral(dashboardData.todayCallOpCount).format('0,0')}</span>
							{t('unit.times')}
						</div>
						<RenderAreaChart
							data={graphData.recentCallOpCountList.map((aDay, i) => (({
								date: dayjs().subtract(graphData.recentCallOpCountList.length-1-i, 'day').format('M/D'),
								count: aDay,
								unit: t('unit.times'),
							})))}
							areaStroke="#f6545f"
							areaFill="#feedef"
						/>
					</div>
				</div>
			</div>
			<LineUsageSection dashboardData={dashboardData} />
			<div className="record-block">
				<div className="sub-title">{t('dashboard.fourteenDaysRecord')}</div>
				<div className="axis-title">
					<span>{t('dashboard.people')}</span>
					<span>{t('dashboard.conversations')}</span>
				</div>
				<ComposedChart
					width={850}
					height={400}
					data={graphData.dailyMsgCount.map((aDayMsg, i) => ({
						date: dayjs(aDayMsg.date).format('M/D'),
						friends: graphData.dailyNewFriendCount[i].count,
						messages: aDayMsg.count,
					}))}
					margin={{ top: 20, right: -15, bottom: 0, left: -25 }}
				>
					<XAxis
						dataKey="date"
						tickLine={false}
						reversed={true}
					/>
					<YAxis
						className="axis-left"
						yAxisId="left"
						axisLine={false}
						tickLine={false}
						allowDecimals={false}
					/>
					<YAxis
						yAxisId="right"
						orientation="right"
						stroke="#aeb1b8"
						tickLine={false}
						allowDecimals={false}
					/>
					<ChartTooltip content={<CustomTooltip />} />
					<CartesianGrid vertical={false} />
					<Bar yAxisId="right" dataKey="messages" barSize={22} fill="#f7d767" />
					<Line yAxisId="left" type="monotone" dataKey="friends" stroke="#359ced" />
				</ComposedChart>
				<div className="axis-illustration">
					<div className="friends">
						<div className="dot" />
						{t('dashboard.illustrationPeople')}
					</div>
					<div className="messages">
						<div className="dot" />
						{t('dashboard.illustrationConversations')}
					</div>
				</div>
			</div>
			<DataSection
				section={{
					key: 'todaySession',
					title: t('dashboard.todaySession'),
					tooltip: t('dashboard.sessionTooltip'),
					blocks: [{
						title: t('dashboard.sessionCount'),
						unit: "",
						data: sessionData.todaySessionCount,
						chartData: sessionData.recentSessionCountList.map((aDay, i) => (({
							date: dayjs().subtract(sessionData.recentSessionCountList.length-1-i, 'day').format('M/D'),
							count: aDay,
							unit: "",
						}))),
						areaStroke: "#3dcca2",
						areaFill: "#d7f4ec"
					},{
						title: t('dashboard.sessionPeriod'),
						unit: '',
						data: sessionData.todaySessionAvgTime,
						chartData: sessionData.recentSessionAvgTimeList.map((aDay, i) => (({
							date: dayjs().subtract(sessionData.recentSessionAvgTimeList.length-1-i, 'day').format('M/D'),
							count: aDay,
							tooltipText: secondsToHHMMSS(aDay),
							unit: "",
						}))),
						areaStroke: "#359ced",
						areaFill: "#e6f4ff"
					},{
						title: t('dashboard.sessionMsgCount'),
						unit: t('unit.message'),
						data: numeral(sessionData.todaySessionAvgMsgCount).format('0,0'),
						chartData: sessionData.recentSessionAvgMsgCountList.map((aDay, i) => (({
							date: dayjs().subtract(sessionData.recentSessionAvgMsgCountList.length-1-i, 'day').format('M/D'),
							count: aDay,
							unit: t('unit.message'),
						}))),
						areaStroke: "#f6545f",
						areaFill: "#feedef"
					}]
				}}
			/>
			<DataSection
				hideChart
				section={{
					key: 'matchCowell',
					title: t('dashboard.matchCowell'),
					tooltip: t('dashboard.matchCowellTooltip'),
					blocks: [{
						title: t('dashboard.matchPercent'),
						unit: "%",
						data: numeral(dashboardData.cowellMatchPercent*100).format('0.0[0]')
					},{
						title: t('dashboard.matchCount'),
						unit: t('unit.friend'),
						data: numeral(dashboardData.cowellMatchCount).format('0,0'),
					},{
						title: t('dashboard.travelercount'),
						unit: t('unit.friend'),
						data: numeral(dashboardData.cowellPassengerCount).format('0,0'),
					}]
				}}
			/>
			<DataSection
				hideChart
				section={{
					key: 'multiCast',
					title: t('dashboard.multiCast'),
					blocks: [{
						title: t('dashboard.multiCastCount'),
						unit: t('unit.times'),
						data: numeral(dashboardData.multiCastCount).format('0,0')
					},{
						title: t('dashboard.friendsTouchCount'),
						unit: t('unit.people'),
						data: numeral(dashboardData.multiCastReachedCount).format('0,0'),
					},{
						title: t('dashboard.multiCastTotalMsgCount'),
						unit: t('unit.message'),
						data: numeral(dashboardData.multiCastReachedMsgCount).format('0,0'),
					}]
				}}
			/>
			<div className="active-statics">
				{dashboardData.recentActiveChatRoom &&
					<ActiveSection
						{...{
							sectionKey: 'activeFriend',
							title: t('dashboard.activeFriend'),
							rendreAll: true,
							tooltip: t('dashboard.activeFriendTooltip'),
							Today: () => (
								<div className="today-content">
									{new Array(3).fill(undefined).map((_, i) => {
										const todayData = dashboardData.recentActiveChatRoom[0]

										const renderData = todayData.length > i ? todayData[i] : {}

										return (
											<div className="a-row" key={`a-active-friend-${i}`}>
												<div className="no-n-others">
													<div className="no">{i+1}.</div>
													<div className="img-n-name">
														<figure>
															<img
																src={renderData.userData ? renderData.userData.avatar : "https://lineline.s3.amazonaws.com/icons/user.png"}
																alt=""
															/>
														</figure>

														<div className="name">{renderData.userData ? renderData.userData.name : '-'}</div>
													</div>
												</div>
												<div className="count">{renderData.count || 0}</div>
											</div>
										)
									})}
								</div>
							),
							renderYesterday: dashboardData.recentActiveChatRoom.length >= 2 && dashboardData.recentActiveChatRoom[1].length > 0,
							Yesterday: dashboardData.recentActiveChatRoom.length < 2 ? null : () => (
								<div className="not-today-row active-friend">
									{dashboardData.recentActiveChatRoom[1].map((aActiveFriend, i) => (
										<div className="a-friend" key={`yesterday-active-friend-${i}`}>
											<Tooltip>
												<figure>
													<img
														src={aActiveFriend.userData.avatar || "https://lineline.s3.amazonaws.com/icons/user.png"}
														alt=""
														data-tip={aActiveFriend.userData.name}
													/>
												</figure>
											</Tooltip>
										</div>
									))}
								</div>
							),
							renderBeforeYesterday: dashboardData.recentActiveChatRoom.length >= 3 && dashboardData.recentActiveChatRoom[2].length > 0,
							BeforeYesterday: dashboardData.recentActiveChatRoom.length < 3 ? null : () => (
								<div className="not-today-row active-friend">
									{dashboardData.recentActiveChatRoom[2].map((aActiveFriend, i) => (
										<div className="a-friend" key={`beforeYesterday-active-friend-${i}`}>
											<Tooltip>
												<figure>
													<img
														src={aActiveFriend.userData.avatar || "https://lineline.s3.amazonaws.com/icons/user.png"}
														alt=""
														data-tip={aActiveFriend.userData.name}
													/>
												</figure>
											</Tooltip>
										</div>
									))}
								</div>
							),
						}}
					/>
				}
				<ActiveSection
					{...{
						sectionKey: 'activeTag',
						title: t('dashboard.activeTag'),
						rendreAll: true,
						tooltip: t('dashboard.activeTagTooltip'),
						Today: () => (
							<div className="today-content">
								{new Array(3).fill(undefined).map((_, i) => {
									const todayData = activeTagData[0]
									const renderData = todayData.length > i ? todayData[i] : {}

									return (
										<div className="a-row tag-row" key={`a-active-tag-${i}`}>
											<div className="no-n-others">
												<div className="no">{i+1}.</div>
												<div className="a-tag">
													<div className="a-tag-text">{renderData.tagName || '-'}</div>
												</div>
											</div>
											<div className="count">{renderData.count || 0}</div>
										</div>
									)
								})}
							</div>
						),
						renderYesterday: activeTagData.length >= 2 && activeTagData[1].length > 0,
						Yesterday: activeTagData.length < 2 ? null : () => (
							<div className="not-today-row">
								{activeTagData[1].map((aTag, i) => (
									<div className="active-tag" key={`yesterday-active-tag-${i}`}>
										<div className="a-tag">
											<div className="a-tag-text">{aTag.tagName || '-'}</div>
										</div>
										<div className="count">{aTag.count || ""}</div>
									</div>
								))}
							</div>
						),
						renderBeforeYesterday: activeTagData.length >= 3 && activeTagData[2].length > 0,
						BeforeYesterday: activeTagData.length < 3 ? null : () => (
							<div className="not-today-row">
								{activeTagData[2].map((aTag, i) => (
									<div className="active-tag" key={`beforeYesterday-active-tag-${i}`}>
										<div className="a-tag">
											<div className="a-tag-text">{aTag.tagName || '-'}</div>
										</div>
										<div className="count">{aTag.count || ""}</div>
									</div>
								))}
							</div>
						),
					}}
				/>
				<ActiveSection
					{...{
						sectionKey: 'activeQuestion',
						title: t('dashboard.activeQuestion'),
						tooltip: t('dashboard.activeQuestionTooltip'),
						rendreAll: true,
						Today: () => (
							<div className="today-content">
								{new Array(3).fill(undefined).map((_, i) => {
									const todayData = dashboardData.recentActiveQABotQList[0]

									const renderData = todayData.length > i ? todayData[i] : {}

									return (
										<div className="a-row question-row" key={`a-active-q-${i}`}>
											<div className="no-n-others">
												<div className="no">{i+1}.</div>
												<div className="question">{renderData.matchQas || '-'}</div>
											</div>
											<div className="count">{renderData.count || 0}</div>
										</div>
									)
								})}
							</div>
						),
						renderYesterday: dashboardData.recentActiveQABotQList.length >= 2 && dashboardData.recentActiveQABotQList[1].length > 0,
						Yesterday: dashboardData.recentActiveQABotQList.length < 2 ? null : () => (
							<div className="not-today-row">
								{dashboardData.recentActiveQABotQList[1].map((aQuestion, i) => (
									<div className="a-question" key={`yesterday-active-q-${i}`}>
										<div className="question">{aQuestion.matchQas}</div>
										<div className="count">{aQuestion.count}</div>
									</div>
								))}
							</div>
						),
						renderBeforeYesterday: dashboardData.recentActiveQABotQList.length >= 3 && dashboardData.recentActiveQABotQList[2].length > 0,
						BeforeYesterday: dashboardData.recentActiveQABotQList.length < 3 ? null : () => (
							<div className="not-today-row">
								{dashboardData.recentActiveQABotQList[2].map((aQuestion, i) => (
									<div className="a-question" key={`beforeYesterday-active-q-${i}`}>
										<div className="question">{aQuestion.matchQas}</div>
										<div className="count">{aQuestion.count}</div>
									</div>
								))}
							</div>
						),
					}}
				/>

			</div>
			<div className="a-overview-section unrecognized-q">
				<div className="head-part">
					<div className="title-part">
						<div className="overview-title">{`${t('dashboard.unrecognizedQuestion')}（${dashboardData.recentNoAnsQABotQList.length}）`}</div>
						<Tooltip>
							<i
								className="fal fa-info-circle"
								data-tip={t('dashboard.unrecognizedTooltip')}
							/>
						</Tooltip>
					</div>
				</div>
				<div className="content-part">
					{dashboardData.recentNoAnsQABotQList.map((aQuestion, i) => (
						<div className="a-question" key={`a-unrecognized-question-${i}`}>
							<span className="no">{i+1}.</span>
							{`${aQuestion.question}（${aQuestion.count}）`}
						</div>
					))}
				</div>
			</div>
			<DataSection
				section={{
					key: 'todayUserStatics',
					title: t('dashboard.todayUserStatics'),
					blocks: [{
						title: t('dashboard.finishFormCount'),
						unit: t('unit.people'),
						data: numeral(dashboardData.todayFillFormCount).format('0,0'),
						chartData: graphData.recentFillFormCountList.map((aDay, i) => (({
							date: dayjs().subtract(graphData.recentFillFormCountList.length-1-i, 'day').format('M/D'),
							count: aDay,
							unit: t('unit.people'),
						}))),
						areaStroke: "#3dcca2",
						areaFill: "#d7f4ec"
					},{
						title: t('dashboard.matchCowellCount'),
						unit: t('unit.people'),
						data: numeral(dashboardData.todayCowellMatchCount).format('0,0'),
						chartData: graphData.recentCowellMatchCountList.map((aDay, i) => (({
							date: dayjs().subtract(graphData.recentCowellMatchCountList.length-1-i, 'day').format('M/D'),
							count: aDay,
							unit: t('unit.people'),
						}))),
						areaStroke: "#359ced",
						areaFill: "#e6f4ff"
					},{
						title: t('dashboard.openFormCount'),
						unit: t('unit.people'),
						data: numeral(dashboardData.todayOpenFormCount).format('0,0'),
						chartData: graphData.recentOpenFormCountList.map((aDay, i) => (({
							date: dayjs().subtract(graphData.recentOpenFormCountList.length-1-i, 'day').format('M/D'),
							count: aDay,
							unit: t('unit.people'),
						}))),
						areaStroke: "#f6545f",
						areaFill: "#feedef"
					}]
				}}
			/>
		</div>
}

export default Dashboard;
