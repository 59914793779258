import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { fetchHelper } from 'tools/FetchHelper'
import dayjs from 'dayjs'
import enums from 'enums'

const NormalActivityContent = (props) => {
	const { t } = useTranslation();
	const {userData, chatRoomData, activityId} = props
	const [isFetching, setFetching] = useState(false)

	const attendActivity = async (data, profile) => {
		try {
			setFetching(true)
			const result = await fetchHelper.post(`/activity/${activityId}/attend`, {
				lineUserId: profile.userId,
				isCreate: data.isCreate
			}, {
				fetchQAbot: true
			});
			const res = result.data

			if (res.status === "OK") {
				window.location.href = `line://ti/p/${data.channel.lineAt}`
				setFetching(false)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('attend activity fail', error.message)
		}
	}

	return (
		<div className="normal-activity">
			<div className="activity-name">{chatRoomData.activity.name}</div>
			<div className="activity-date">
				{t('activity.activityDate')}
				{`${dayjs(chatRoomData.activity.startDtm).format('YYYY-MM-DD')} - `}
				{chatRoomData.activity.isDelete ?
					dayjs(chatRoomData.activity.deleteDtm).format('YYYY-MM-DD') :
					chatRoomData.activity.expireDateType === enums.activityExpiredDateType.specificDay ?
						dayjs(chatRoomData.activity.endDtm).format('YYYY-MM-DD') :
						t('activity.notAssign')
				}
			</div>

			{isFetching ?
				<div className="icon-loading">
					<i className="fal fa-spinner-third fa-spin" />
				</div> :
				<button className="line-button" onClick={() => {
					attendActivity(chatRoomData, userData)
				}}>
					{t('goNow')}
				</button>
			}
		</div>
	)

}

export default NormalActivityContent;
