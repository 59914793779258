import axios from 'axios';

import { checkLogined, getUser , logoutUser} from 'Login'

// global confs
window.CONFS = {
	// apiServer: 'http://beta.line-line.trip-ninja.io:23386/v1.0',

	// apiServer: 'http://192.168.97.107:23386/v1.0',

	apiServer: 'http://localhost:23386/v1.0',

	//wsUrl: 'beta.qabot-websocket-server.trip-ninja.io',

	qabotServer: 'http://localhost:23363/v1.0',
	wsUrl: 'localhost',
	wsPort: '9001',
	wsSSL: false,
	lineWebhookUrl: "local-hook.trip-ninja.io/line/", // for line connect guiding
	shortenUrl: "https://7e8523da.ngrok.io" // for liff redirect
}

if (process.env.REACT_APP_ENV === "beta") {
	window.CONFS = {
		apiServer: 'https://beta.line-line.trip-ninja.io/v1.0',
		qabotServer: 'https://beta.qabot-console-api.trip-ninja.io/v1.0',
		wsUrl: 'beta.qabot-websocket-server.trip-ninja.io',
		wsPort: '443',
		wsSSL: true,
		lineWebhookUrl: "beta.qabot-console-api.trip-ninja.io/line/",
		shortenUrl: "https://beta.shorten-url.trip-ninja.io"
	}
}

if (process.env.REACT_APP_ENV === "production") {
	let hostname = window.location.hostname;
	let domain = hostname.substring(hostname.indexOf('.') + 1);
	// 預設正式區的 domain 是 'treavel.net.tw'
	// 測試區則為 'travelindex.com.tw'
	// 一般考慮到跨域存取的問題，這個不會跳來跳去，所以就直接抓當下網址的 domain。
	// 這樣就不用因為 stage 環境與 production 環境的不同而另設參數。
	// 另外有些從 line-liff url 轉過來的網址，還是會帶 www.trip-ninja.io
	// 所以遇到這種網址必須改成固定的網址
	if (hostname.indexOf('trip-ninja.io')) {
		domain = 'travel.net.tw';
	}

	window.CONFS = {
		apiServer: `https://linebagel-api.${domain}/v1.0`,
		qabotServer: `https://linebagel-hook.${domain}/v1.0`,
		wsUrl: `linebagel-ws.${domain}`,
		wsPort: '443',
		wsSSL: true,
		lineWebhookUrl: `linebagel-hook.${domain}/line/`,
		shortenUrl: `https://linebagel-shorten-url.${domain}`
	}
}

// console.log('Use confs', window.CONFS);

class FetchHelper {
	constructor() {
		let self = this
		this.axios = axios.create({
			baseURL: window.CONFS.apiServer
		});
		this.axios.defaults.headers.post['Content-Type'] = 'application/json'
		this.tokenNotValidCode = [1000, 1011]
		this.qabotTokenNotValidCode = [1000, 1011]

		this.axios.interceptors.request.use((config) => {
			config = self._fetchWithToken(config)
			return config
		})

		this.axios.interceptors.response.use((response) => {
			response = self._checkTokenValid(response)
			return response
		})
	}

	get (url, option) {
		option = this._checkServer(option)
		return this.axios.get(url, option)
	}
	delete (url, body={}, option={}) {
		option = this._checkServer(option)
		if (body) {
			option.data = body
		}
		return this.axios.delete(url, option)
	}
	patch (url, body={}, option) {
		option = this._checkServer(option)
		return this.axios.patch(url, body, option)
	}
	post (url, body={}, option) {
		option = this._checkServer(option)
		return this.axios.post(url, body, option)
	}
	put (url, body={}, option) {
		option = this._checkServer(option)
		return this.axios.put(url, body, option)
	}

	_checkServer(option) {
		if (option && option.fetchQAbot) { // set baseURL if option.fetchQAbot
			option.baseURL = window.CONFS.qabotServer
		}
		return option
	}

	_fetchWithToken (config) {
		if (checkLogined()) {
			config.headers.common['X-Authorize'] = getUser().apiToken
		}
		return config
	}

	_checkTokenValid (response) {
		if(response.data.errorCode === 0){
			return response
		}

		// console.warn(response.data)

		let tokenNotValidCode = this.tokenNotValidCode
		if(response.config.baseURL === window.CONFS.qabotServer) {
			tokenNotValidCode = this.qabotTokenNotValidCode
		}

		if(tokenNotValidCode.includes(response.data.errorCode)){
			let logoutLocal = true
			logoutUser(logoutLocal)
		}
		return response
	}
}

export const fetchHelper = new FetchHelper()
