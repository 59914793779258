import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Link } from 'react-router-dom'

import ChatPreviewBlock from 'Components/ChatPreviewBlock'
import FormCard from './FormCard'
import AddQuestionBlock from 'Components/AddQuestionBlock'
import { fetchHelper } from 'tools/FetchHelper'

import './index.css'

const Form = (props) => {
	const { t } = useTranslation();
	const [tab, setTab] = useState('form') // from, setting
	const [copySuccess, setCopySuccess] = useState(false)
	const [forms, setForms] = useState(props.forms.length < 1 ? [{
		type: 'text',
		subType: 'title',
		timestamp: new Date().getTime()
	}] : props.forms);
	const [questions, setQuestions] = useState([])
	const [text, setText] = useState(null)
	const [isFetching, setFetching] = useState(false)
	const [isUpdating, setUpdating] = useState(false)

	const liffUrlRef = useRef()
	const liffValue = useRef()

	const copyToClipboard = (e) => {
		liffUrlRef.current.focus()
		liffUrlRef.current.select();
		document.execCommand('copy')
		setCopySuccess(true)
	}

	const filterForms = () => {
		return forms.filter(aForm => {
			if (aForm.type === 'text') return !!aForm.value;
			if (aForm.type === 'image') return !!aForm.imagePath
			if (aForm.type === 'input') {
				if (aForm.subType === 'tags') return !!aForm.tags && !!aForm.displayTitle

				return !!aForm.displayTitle
			}

			return false
		})
	}

	const saveForms = async () => {
		setUpdating(true)
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/bot/form`, {
				"keywords" : questions.map(aQues => aQues.value),
				"replyText" : text, // 回覆的時候會把 %URL% 換成Liff的表單網址
				"formColumns" : filterForms()
			}, {
				fetchQAbot: true
			});
			const res = result.data

			if (res.status === "OK") {
				setUpdating(false)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setUpdating(false)
			console.log('update bot form fail', error.message)
		}
	}

	useEffect(() => {
		const fetchBotForms = async () => {
			setFetching(true)
			try {
				const result = await fetchHelper.get(`/channel/${props.channelId}/bot/form`, {
					fetchQAbot: true
				});
				const res = result.data
				// console.log(res)
				if (res.status === "OK") {
					const data = res.data
					liffValue.current = data.liffUrl
					setQuestions(data.keywords.map(str => ({ value: str })))
					setForms(data.formColumns)
					setText(data.replyText)
					setFetching(false);
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				setFetching(false);
				console.log('get bot form fail', error.message)
			}
		}


		fetchBotForms()
	}, [props.channelId])

	return (
		<div className="form-page">
			<div className="page-title">
				<Link to={`/console/channel/${props.channelId}/bot`}>{t('bot.title')}</Link>
				<i className="far fa-chevron-right" />
				{t('bot.form')}
			</div>
			<div className="page-des">{t('bot.formDes')}</div>
			{isFetching ?
				<div className="icon-loading">
					<i className="fal fa-spinner-third fa-spin" />
				</div> :
				<>
					<div className="tabs">
						<div className={cx("a-tab", { active: tab === 'form'})}
							onClick={() => setTab('form')}>
							{t('bot.sheetDesign')}
						</div>
						<div className={cx("a-tab", { active: tab === 'setting'})}
							onClick={() => setTab('setting')}>
							{t('bot.sheetSetting')}
						</div>
					</div>
					<div className="bot-setting-content">
						<div className="setting-part">

							{tab === 'form' &&
								<div className="custom-forms">
									{forms.map((aForm, i) => (
										<FormCard
											{...props}
											form={aForm}
											key={`a-form-${aForm.timestamp}-${i}`}
											index={i}
											onFormUpdate={(msg) => {
												const formCopy = forms.slice()
												formCopy[i] = msg
												setForms(formCopy)
											}}
											onDelete={() => {
												const formCopy = forms.slice()
												formCopy.splice(i, 1)
												setForms(formCopy)
											}}
											swapFormCard={(indexA, indexB) => {
												const formCopy = forms.slice()
												const temp = formCopy[indexA]
												formCopy[indexA] = formCopy[indexB]
												formCopy[indexB] = temp
												setForms(formCopy)
											}}
											disableGoAbove={i === 0}
											disableGoBelow={i === (forms.length - 1)}
										/>
									))}

									<button className="c-btn c-btn--primary add-more-button"
										onClick={() => {
											const formCopy = forms.slice()
											formCopy.push({
												type: 'text',
												subType: 'name',
												timestamp: new Date().getTime()
											})
											setForms(formCopy)
										}}>
										{t('bot.addMore')}
									</button>
								</div>
							}
							{tab === 'setting' &&
								<>
									<div className="sub-title">{t('bot.copySheetUrl')}</div>
									<div className="sub-des">{t('bot.sheetUrlDes')}</div>
									<div className="c-txt__input c-txt__input--media liff-url">
										<input
											ref={liffUrlRef}
											className="c-txt__input c-txt__input--bare c-txt__input--media__body"
											type="text"
											readOnly
											value={liffValue.current}
										/>
										<div className="c-txt__input--media__figure" onClick={copyToClipboard}>
											{copySuccess ?
												<i className="fal fa-check" /> :
												<i className="fal fa-copy" />
											}
										</div>
									</div>
									<div className="sub-title">{t('bot.setTriggerQuestion')}</div>
									<div className="sub-des">{t('bot.triggerDes')}</div>
									<div className="third-title">{t('bot.triggerQuestion')}</div>
									<div className="trigger-question">
										<AddQuestionBlock
											{...props}
											questions={questions}
											onChange={(result) => setQuestions(result)}
										/>
									</div>
									<div className="third-title">{t('bot.reply')}</div>
									<textarea
										className="c-txt__input c-txt__input--area"
										value={text || ""}
										onChange={(e) => setText(e.target.value)}
									/>
								</>
							}
							<div className="button-group">
								<button className="c-btn">{t('cancel')}</button>
								<button className="c-btn c-btn--primary" onClick={() => saveForms()}>
									{isUpdating ?
										<i className="fal fa-spinner-third fa-spin" /> :
										t('save')
									}
								</button>
							</div>
						</div>
						<div className="preview-part">
							<div className="sub-title">{t('preview')}</div>
							<ChatPreviewBlock
								{...props}
								{...(tab === 'form' ? {
									renderBotSheet: true,
									forms
								} : {
									messages: [{
										text: questions.length > 0 ? questions[0].value : "加入會員",
								        type: "text"
								    },{
										text: text.replace('%URL%', liffValue.current),
										type: 'text'
								    }]
								})}
								sender={{
									type: 'bot'
								}}
							/>
						</div>
					</div>
					<div className="button-group">
						<button className="c-btn">{t('cancel')}</button>
						<button className="c-btn c-btn--primary" onClick={() => saveForms()}>
							{isUpdating ?
								<i className="fal fa-spinner-third fa-spin" /> :
								t('save')
							}
						</button>
					</div>
				</>
			}
		</div>
	)
}

Form.defaultProps = {
	forms: []
}

export default Form;