import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Switch } from 'react-router-dom'

import enums from 'enums'
import OneToOne from './OneToOne'
import Form from './Form'
import QnA from './QnA'
import Greeting from './Greeting'
import Default from './Default'
import AutoAssign from './AutoAssign'
import StickerReply from './StickerReply'
import { fetchHelper } from 'tools/FetchHelper'

import './index.css'

const BotList = (props) => {
	const { t } = useTranslation();
	const [isFetching, setFetching] = useState(true)
	const [botStatus, setBotStatus] = useState([])
	const botType = enums.BotType

	const updateBotAvailble = async (body) => {
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/bot/available`,body, {
				fetchQAbot: true
			});
			const res = result.data

			if (res.status === "OK") {
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('update bot available fail', error.message)
		}
	}

	useEffect(() => {
		const fetchBotAvailble = async () => {
			setFetching(true)
			try {
				const result = await fetchHelper.get(`/channel/${props.channelId}/bot`, {
					fetchQAbot: true
				});
				const res = result.data

				if (res.status === "OK") {
					setFetching(false);
					setBotStatus(res.data)
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				setFetching(false);
				console.log('get bot list fail', error.message)
			}
		}


		fetchBotAvailble()
	}, [props.channelId])

	return (
		<div className="qabot-list">
			<div className="page-title">{t('bot.title')}</div>
			<div className="page-des">{t('bot.funcDes')}</div>
			{isFetching ?
				<div className="icon-loading">
					<i className="fal fa-spinner-third fa-spin" />
				</div> :
				Object.keys(botType).map((aType, i) => (
				<div className="a-bot" key={`a-bot-type-${i}`}>
					<figure><img src={`https://lineline.s3.amazonaws.com/icons/${aType}.svg`} alt=""/></figure>
						<div className="bot-info">
							<div className="switch-n-pen">
								<div className="switch-button">
									<input
										id={`func-active-switch-${i}`}
										type="checkbox"
										name={`func-active-switch-${i}`}
										className="switch"
										checked={botStatus[botType[aType]] || false}
										onChange={(e) => {
											setBotStatus({
												...botStatus,
												[botType[aType]]: e.target.checked
											})
											updateBotAvailble({[botType[aType]]: e.target.checked})
										}}
									/>
									{/* bulma-switch must have a label right after input.switch */}
									<label htmlFor={`func-active-switch-${i}`} className="label">
										{botStatus[botType[aType]] ? t('bot.isActive') : t('bot.isClose')}
									</label>
								</div>
								<Link to={`/console/channel/${props.channelId}/bot/${aType}`}><i className="fal fa-pen" /></Link>
							</div>
							<Link to={`/console/channel/${props.channelId}/bot/${aType}`}>
								<div className="bot-title">{t(`bot.${aType}`)}</div>
							</Link>
							<div className="bot-des">{t(`bot.${aType}Des`)}</div>
						</div>
					</div>
				))
			}


		</div>
	)
}

const components = {
	greeting: Greeting,
	form: Form,
	oneToOne: OneToOne,
	qna: QnA,
	default: Default,
	stickerReply: StickerReply,
	autoAssign: AutoAssign
}

const Bot = (props) => {
	const botType = enums.BotType
	const match = props.match

	return (
		<div className="bot-page">
			<Switch>
				{Object.keys(botType).map((aType, i) => {
					const Component = components[aType]

					return (
						<Route
							key={`channel-func-${i}`}
							path={`${match.path}/${aType}`}
							component={(routerProps) => <Component
								channelId={match.params.channelId}
								{...props}
								{...routerProps}
							/>}
						/>
					)
				})}

				<Route path={props.match.path} render={(routerProps) => <BotList
					channelId={match.params.channelId}
					{...props}
					{...routerProps}
				/>} />
			</Switch>
		</div>
	)
}

export default Bot;