import React, { useState} from 'react';
import { useTranslation } from 'react-i18next';
import { AreaChart } from 'recharts';
import store from 'store';

import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';

import Tooltip from 'Components/Tooltip';
import numeral from 'numeral'
import cx from 'classnames';
import './index.css';

const lineUsageOptions = ['low', 'mid', 'high']

const lineFreeMsg = {
	low: {
		cost: 0,
		amount: 500
	},
	mid: {
		cost: 800,
		amount: 4000
	},
	high: {
		cost: 4000,
		amount: 25000
	}
};

// 	{ amount: 10000, cost: 0.2}
// TODO: 2.0 yet, need update
const lineChargePerMsg = [
	{ amount: 10000, cost: 0.15},
	{ amount: 10000, cost: 0.14},
	{ amount: 20000, cost: 0.13},
	{ amount: 40000, cost: 0.12},
	{ amount: 80000, cost: 0.11},
	{ amount: 160000, cost: 0.095},
	{ amount: 320000, cost: 0.094},
	{ amount: 160000, cost: 0.090},
	{ amount: 480000, cost: 0.086},
	{ amount: 1280000, cost: 0.078},
	{ amount: 940000, cost: 0.070},
	{ amount: 1620000, cost: 0.06},
	{ amount: 2880000, cost: 0.05},
	{ amount: 2240000, cost: 0.035},
	{ amount: 10240000, cost: 0.017},
	{ amount: 99999999, cost: 0.01},
]

const LineUsageSection = ({ dashboardData }) => {
	const { t } = useTranslation();
	const [usageType ,setUsageType] = useState(store.get('LINE_USAGE_TYPE') ? store.get('LINE_USAGE_TYPE') : lineUsageOptions[0])

	const caculateCost = () => {
		if (usageType === 'low') return lineFreeMsg[usageType].cost;

		const msgUsage = parseInt(dashboardData.monthlyLineMsgCount, 10)
		let msgCost = lineFreeMsg[usageType].cost;
		let chargeMsgCount = msgUsage - lineFreeMsg[usageType].amount


		if (chargeMsgCount < 0) return msgCost;

		if (usageType === 'mid') {
			if (chargeMsgCount - 21000 < 0) {
				msgCost = chargeMsgCount * 0.2
				return msgCost
			}

			msgCost += 21000 * 0.2
			chargeMsgCount = chargeMsgCount - 21000
		}

		lineChargePerMsg.map((aScope, i) => {
			if (chargeMsgCount === 0) return null;

			if (chargeMsgCount - aScope.amount > 0) {
				msgCost += aScope.amount * aScope.cost
				chargeMsgCount = chargeMsgCount - aScope.amount
			} else {
				msgCost += chargeMsgCount * aScope.cost
				chargeMsgCount = 0
			}
			return aScope
		})

		return msgCost
	}

	return (
		<div className="a-overview-section">
				<div className="head-part">
					<div className="title-part">
						<div className="overview-title">{t('dashboard.usagePerMonth')}</div>
						<Tooltip>
							<i
								className="fal fa-info-circle"
								data-tip={t('dashboard.usageTooltip')}
							/>
						</Tooltip>
					</div>
					<div className="select-part">
						<span>{t('dashboard.chooseLineProgram')}</span>
						<ThemeProvider theme={window.menuTheme}>
							<Menu
								trigger={({ ref, isOpen }) => {
									return (
										<div className={cx("c-txt__input--select usage-select", { 'is-open': isOpen })} ref={ref}>
											{t(`lineProgram.${usageType}`)}
										</div>
									)
								}}
							>
								{lineUsageOptions.map((aType, i) => (
									<Item
										className="c-txt__input--select-item"
										key={aType}
										onClick={() => {
											setUsageType(aType)
											store.set("LINE_USAGE_TYPE", aType)
										}}>
										{t(`lineProgram.${aType}`)}
									</Item>
								))}
							</Menu>
						</ThemeProvider>
					</div>
				</div>
				<div className="content-part">
					<div className="a-overview-block">
						<div className="block-title">{t('dashboard.messageSent')}</div>
						<div className="block-content">
							<AreaChart />
							<div className="data-number-unit">
								<span>{numeral(dashboardData.monthlyLineMsgCount).format('0,0')}</span>
								{t('unit.message')}
							</div>
						</div>
					</div>
					<div className="a-overview-block">
						<div className="block-title">
							{t('dashboard.freeMessage')}
							{usageType === 'low' &&
								<div className="alert">{t('dashboard.lowProgramLimit')}</div>
							}
						</div>

						<div className="block-content">
							{/* <AreaChart /> */}
							<div className="data-number-unit">
								<span>
									{usageType === 'low' && 500}
									{usageType === 'mid' && numeral(4000).format('0,0')}
									{usageType === 'high' && numeral(25000).format('0,0')}
								</span>
								{t('unit.message')}</div>
						</div>
					</div>
					<div className="a-overview-block">
						<div className="block-title">{t('dashboard.lineChargeEstimate')}</div>
						<div className="block-content">
							{/* <AreaChart /> */}
							<div className="data-number-unit">
								<span>{numeral(caculateCost()).format('0,0.00')}</span>
								{t('unit.NTD')}
							</div>
						</div>
					</div>
				</div>
			</div>
	)
}
export default LineUsageSection;
