import React from 'react';
import ReactTooltip from 'react-tooltip'

import './index.css'

const Tooltip = (props) => {
	return (
		<div className="tooltip">
			{props.children}
			<ReactTooltip place="right" type="light" effect='float' offset={{ top: -30 }} />
		</div>
	)
}

export default Tooltip;