import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'

import { fetchHelper } from 'tools/FetchHelper'
import './index.css'
import Step from 'Components/Step'

const LineConnectSetting = (props) => {
	const { t } = useTranslation();
	const [isFetching, setFetching] = useState(false)
	const [copySuccess, setCopySuccess] = useState(false)

	const defaultForm = !!props.settings && !!props.settings.lineData ? {
		lineChannelId: props.settings.lineData.channelId,
		lineChannelSecret: props.settings.lineData.secret,
		lineAccessToken: props.settings.lineData.accessToken,
		lineAtId: props.settings.lineData.lineAtId
	} : {
		lineChannelId: "",
		lineChannelSecret: "",
		lineAccessToken: "",
		lineAtId: ""
	}


	const [form, setForm] = useState(defaultForm)
	const webhookRef = useRef(true)

	const copyToClipboard = (e) => {
		webhookRef.current.focus()
		webhookRef.current.select();
		document.execCommand('copy')
		setCopySuccess(true)
	}

	const connectToLine = async () => {
		setFetching(true)
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/line/connect`, {
				...form
			});
			const res = result.data
			console.log(res)
			if (res.status === "OK") {
				window.location = `/console/channel/${props.channelId}/dashboard`
				setFetching(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false);
			console.log('udpate channel greeting', error.message)
		}
	}

	const updateField = e => {
		setForm({
			...form,
			[e.target.name]: e.target.value
		});
	};

	return (
		<div className="line-connect-setting">
			<div className="page-title">{t('setting.title')}</div>
			<div className="sub-title">{t('line@setting.line@Subtitle')}</div>
			<div className="content-description">{t('line@setting.line@Des')}</div>
			<div className="step-list">
				<Step
					seqNum="1"
					titleKey="line@setting.step1Title"
					DesComp={() => (
						<>
							<div className="des-paragraph">
								{t('line@setting.step1DesPart1')}
								<a href="https://developers.line.biz/" target="_blank" rel="noopener noreferrer">
									{t('line@setting.step1DesPart2')}
								</a>
								{t('line@setting.step1DesPart3')}
								<img alt="" src="https://s3.ap-northeast-2.amazonaws.com/lineline/line-tutorial-images/line.png" />
								<span>{t('line@setting.step1DesPart4')}</span>
								{t('line@setting.step1DesPart5')}
								<b>{t('line@setting.step1DesPart6')}</b>
								{t('line@setting.step1DesPart7')}
							</div>
							<div className="des-paragraph">
								{t('line@setting.step1DesPart8')}
							</div>
						</>
					)}
					imgUrl="https://s3.ap-northeast-2.amazonaws.com/lineline/line-tutorial-images/step1.png"
				/>
				<Step
					seqNum="2"
					titleKey="line@setting.step2Title"
					DesComp={() => (
						<>
							<div className="des-paragraph">
								{t('line@setting.step2DesPart1')}
								<b>{t('line@setting.step2DesPart2')}</b>
								{t('line@setting.step2DesPart3')}
							</div>
						</>
					)}
					renderInput={() => (
						<input
							className="c-txt__input"
							value={form.lineChannelId}
							name="lineChannelId"
							onChange={updateField}
							placeholder={t('line@setting.channelIdPlaceholder')}
							disabled={isFetching}
						/>
					)}
					imgUrl="https://s3.ap-northeast-2.amazonaws.com/lineline/line-tutorial-images/step2.png"
				/>
				<Step
					seqNum="3"
					titleKey="line@setting.step3Title"
					DesComp={() => (
						<>
							<div className="des-paragraph">
								{t('line@setting.step3DesPart1')}
								<b>{t('line@setting.step3DesPart2')}</b>
								{t('line@setting.step3DesPart3')}
							</div>
						</>
					)}
					renderInput={() => (
						<input
							className="c-txt__input"
							value={form.lineChannelSecret}
							name="lineChannelSecret"
							onChange={updateField}
							placeholder={t('line@setting.channelSecretPlaceholder')}
							disabled={isFetching}
						/>
					)}
					imgUrl="https://s3.ap-northeast-2.amazonaws.com/lineline/line-tutorial-images/step3.png"
				/>
				<Step
					seqNum="4"
					titleKey="line@setting.step4Title"
					DesComp={() => (
						<>
							<div className="des-paragraph">
								{t('line@setting.step4DesPart1')}
								<img alt="" src="https://s3.ap-northeast-2.amazonaws.com/lineline/line-tutorial-images/line.png" />
								<span>{t('line@setting.step4DesPart2')}</span>
								{t('line@setting.step4DesPart3')}
								<b>{t('line@setting.step4DesPart4')}</b>
								{t('line@setting.step4DesPart5')}
								<img alt="" src="https://s3.ap-northeast-2.amazonaws.com/lineline/line-tutorial-images/issue.png" />
								{t('line@setting.step4DesPart6')}
								<img alt="" src="https://s3.ap-northeast-2.amazonaws.com/lineline/line-tutorial-images/issue.png" />
								{t('line@setting.step4DesPart7')}
							</div>
						</>
					)}
					renderInput={() => (
						<input
							className="c-txt__input"
							value={form.lineAccessToken}
							name="lineAccessToken"
							onChange={updateField}
							placeholder={t('line@setting.channelAccessTokenPlaceholder')}
							disabled={isFetching}
						/>
					)}
					imgUrl="https://s3.ap-northeast-2.amazonaws.com/lineline/line-tutorial-images/step4.png"
				/>
				<Step
					seqNum="5"
					titleKey="line@setting.step5Title"
					DesComp={() => (
						<>
							<div className="des-paragraph">
								{t('line@setting.step5DesPart1')}
								<div className="input-radio-block">
									<input
										type="radio"
										defaultChecked
										readOnly
									/>
								</div>
								{t('line@setting.step5DesPart2')}
							</div>
						</>
					)}
					imgUrl="https://s3.ap-northeast-2.amazonaws.com/lineline/line-tutorial-images/step5.png"
				/>
				<Step
					seqNum="6"
					titleKey="line@setting.step6Title"
					DesComp={() => (
						<div className="des-paragraph">
							{t('line@setting.step6DesPart1')}
							<i className="fal fa-copy" />
							{t('line@setting.step6DesPart2')}
							<b>{t('line@setting.step6DesPart3')}</b>
							{t('line@setting.step6DesPart4')}
							<img alt="" src="https://s3.ap-northeast-2.amazonaws.com/lineline/line-tutorial-images/update.png" />
							{t('line@setting.step6DesPart5')}
						</div>
					)}
					renderInput={() => (
						<div className="c-txt__input c-txt__input--media">
							<input
								ref={webhookRef}
								className="c-txt__input c-txt__input--bare c-txt__input--media__body"
								type="text"
								readOnly
								defaultValue={`https://${window.CONFS.lineWebhookUrl}${props.channelId}`}
							/>
							<div className="c-txt__input--media__figure" onClick={copyToClipboard}>
								{copySuccess ?
									<i className="fal fa-check" /> :
									<i className="fal fa-copy" />
								}
							</div>
						</div>
					)}
					imgUrl="https://s3.ap-northeast-2.amazonaws.com/lineline/line-tutorial-images/step6.png"
				/>
				<Step
					className="step-7"
					seqNum="7"
					titleKey="line@setting.step7Title"
					DesComp={() => (
						<>
							<div className="des-paragraph">
								{t('line@setting.step7DesPart1')}
								<a href="https://admin-official.line.me/" target="_blank" rel="noopener noreferrer">
									{t('line@setting.step7DesPart2')}
								</a>
								{t('line@setting.step7DesPart3')}
							</div>
							<div className="des-paragraph">
								{t('line@setting.step7DesPart4')}
							</div>
						</>
					)}
					renderInput={() => (
						<input
							className="c-txt__input"
							value={form.lineAtId}
							name="lineAtId"
							onChange={updateField}
							placeholder={t('line@setting.lineAtIdPlaceholder')}
							disabled={isFetching}
						/>
					)}
					imgUrl="https://s3.ap-northeast-2.amazonaws.com/lineline/line-tutorial-images/step7.jpg"
				/>
			</div>
			<button
				className="c-btn c-btn--primary connect-button"
				onClick={() => connectToLine()}
			>
				{isFetching ?
					<i className="fal fa-spinner-third fa-spin" /> :
					t('line@setting.connect')
				}
			</button>
		</div>
	)
}

LineConnectSetting.defaultProps = {
	lineData: {}
}

export default LineConnectSetting;
