import React, { useGlobal } from 'reactn';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import permission from 'enums/permission'

import './index.css'

const Channels = (props) => {
	const { t } = useTranslation();
	const [channels] = useGlobal('channels')
	return (
		<div className="console-channels">
			<div className="page-header">
				<h2>{t('channel.allChannels')}</h2>
				<Link className="c-btn c-btn--primary" to="/console/channel/create">{t('channel.addChannel')}</Link>
			</div>
			<div className="channel-list">
				{channels.map((aChannel, i) => {
					let channelUrl = `/console/channel/${aChannel.guid}/`
					const permissionName = permission.type[aChannel.permissionType]
					channelUrl += permission[permissionName].goToChannelPath

					return (
						<div className="a-channel" key={`a-channel-${i}`}>
							<div className="channel-avatar">
								<figure>
									<img src={aChannel.avatarUrl} alt=""/>
								</figure>
							</div>
							<div className="channel-brief">
								<div className="channel-name">{aChannel.name}</div>
								<div className="icon-with-text">
									<i className="fal fa-users" />
									<span>{t('channel.friends')}：{aChannel.lineFriendCount} {t('unit.people')}</span>
								</div>
								<div className="icon-with-text">
									<i className="fal fa-comments" />
									<span>{t('channel.messages')}：{aChannel.messageCount} {t('unit.message')}</span>
								</div>
								<div className={cx("icon-with-text", { danger: aChannel.callOpCount > 0})}>
									<i className="fal fa-user-headset" />
									<span>{t('channel.callOp')}： <span className="number">{aChannel.callOpCount}</span> {t('unit.friend')}</span>
								</div>
							</div>
							<div className="channel-control">
								<Link className="c-btn" to={channelUrl}>{t('channel.goToChannel')}</Link>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	);
}

Channels.defaultProps = {
	channels: []
}

export default Channels;