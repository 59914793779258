import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

import { fetchHelper } from 'tools/FetchHelper'
import { saveAs } from 'file-saver';

import './index.css'

const FileDownload = (props) => {
	const { t } = useTranslation();
	const [file, setFile] = useState(null)
	const [isFetching, setFetching] = useState(true)
	const [isError, setError] = useState(false)
	const ua = window.navigator.userAgent

	const isLineWebViewOnIphone = ua.indexOf("Line") > -1 && ua.indexOf("iPhone") > -1
	const isLineWebViewOnAndroid = ua.indexOf("Line") > -1 && ua.indexOf("Android") > -1


	const triggerDownloadEvent = (data) => {
		if (!data.fileUrl) {
			setError(true)
			return null
		}
		// console.log(data)

		const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
		const webkit = !!ua.match(/WebKit/i);
		const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

		return fetch(data.fileUrl, {
				method: 'GET',
				headers: {
					Origin: window.location.origin
				}
			})
			.then((resp) => {
				return resp.blob()
			})
			.then((blob) => {
				if (iOSSafari) {
					// see https://github.com/eligrey/FileSaver.js/issues/12, for ios safari optimize
					setTimeout(() => {
						saveAs(blob, data.fileName)
				    }, 950); // Any longer then 1sec will make the window.open blocked again
				} else {
					saveAs(blob, data.fileName, {
						type: data.contentType
					})
				}
		});
	}

	const fetchFileLog = async (fileGUID) => {
		setFetching(true);
		try {
			const result = await fetchHelper.get(`/file/${fileGUID}`);
			const res = result.data
			if (res.status === "OK") {
				setFile(res.data)
				triggerDownloadEvent(res.data)
				setFetching(false);
			} else {
				setError(true)
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false);
			setError(true)
			console.log('fecth file log fail', error.message)
		}
	}

	useEffect(() => {
		if (props.match.params && props.match.params.fileGUID) {
			fetchFileLog(props.match.params.fileGUID)
		}
	}, [])

	const renderDownloadStatus = () => {
		if (isLineWebViewOnIphone || isLineWebViewOnAndroid) {
			return (
				<div className="download-tutorial">
					<img src={isLineWebViewOnIphone ?
						"https://lineline.s3.amazonaws.com/systemFiles/3.0_iOS.svg" :
						"https://lineline.s3.amazonaws.com/systemFiles/3.0_Android.svg"} alt=""/>
				</div>
			)
		}

		if (isError) {
			return (
				<div className="download-status">
					<figure>
						<img src="https://lineline.s3.amazonaws.com/icons/nofiles.svg" alt=""/>
					</figure>
					<h3>{t('file.noFile')}</h3>
					<div className="description">
						{t('file.overExpireDate')}
					</div>
					<div className="button-group">
						<Link to="/landing" className="c-btn c-btn--primary know-more-button">{t('file.knowLineBagelMore')}</Link>
					</div>
				</div>
			)
		}


		return (
			<div className="download-status">
				<figure>
					<img src="https://lineline.s3.amazonaws.com/icons/download.svg" alt=""/>
				</figure>
				<h3>{t('file.fileDownload')}</h3>
				<div className="description">
					{t('file.downloadSuccess')}
				</div>
				<div className="button-group">
					<button className="c-btn" onClick={() => window.close()}>{t('file.closeWindow')}</button>
					<button className="c-btn c-btn--primary" onClick={() => triggerDownloadEvent(file, true)}>{t('file.downloadAgain')}</button>
				</div>
			</div>
		)
	}

	return (
		<div className="file-download">
			{isFetching ?
				<>
					<div className="icon-loading">
						<i className="fal fa-spinner-third fa-spin" />
					</div>
					<div className="loading">{t('file.loading')}</div>
				</> : renderDownloadStatus()
			}
		</div>
	)
}

export default FileDownload;