import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchHelper } from 'tools/FetchHelper'

import './index.css';

const LINE_VALID_TYPE = ["image/jpeg", "image/jpg", "image/png", "audio/m4a", "video/mp4"]
const MIME_TYPE_MAPING = {
	'pdf': ['application/pdf'],
	'doc': ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
	'ppt': ['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
	'xls': ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
	'zip': ['application/gzip', 'application/zip', 'application/x-7z-compressed', 'application/x-bzip', 'application/x-bzip2', 'application/epub+zip'],
	'm4a': ['audio/aac', 'audio/mpeg', 'audio/midi', 'audio/x-midi', 'audio/ogg', 'audio/wav', 'audio/webm', 'audio/3gpp', 'audio/x-m4a', 'audio/m4a'],
	'mp4': ['video/x-msvideo', 'video/x-msvideo', 'video/ogg', 'video/mp4', 'video/mp2t', 'video/webm', 'audio/3gpp'],
	'image': ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml']
}
const LINE_FILE_SIZE = 10485760; //10 mb
const S3_FILE_SIZE = 134217728; // 128 mb

const dataURLtoFile = (dataurl, filename) => {
	var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
	bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
	while(n--){
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, {type:mime});
}

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const getSnapshotFromVideo = async (videoUrl, filename) => {
	const video = document.createElement('video')
	video.src = videoUrl
	document.body.append(video)
	video.currentTime = 5;
	video.muted = true;
	video.play()

	const canvas = document.createElement('canvas');
	document.body.append(canvas)

	// delay for video tag initial on document
	await sleep(2000);

	canvas.width = video.videoWidth ;
	canvas.height = video.videoHeight;
	canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
	const dataURI = canvas.toDataURL('image/jpeg');
	const file = await dataURLtoFile(dataURI, `${filename.split('.')[0]}-thumbnail.jpg`)

	video.parentElement.removeChild(video)
	canvas.parentElement.removeChild(canvas)

	return file
}

const transferPNGtoJPG = async (file) => {
	const pngUrl = window.URL.createObjectURL(file)
	const img = document.createElement('img')
	img.src = pngUrl
	document.body.append(img)

	const canvas = document.createElement('canvas');
	document.body.append(canvas)

	// delay for video tag initial on document
	await sleep(1000);

	canvas.width = img.width;
	canvas.height = img.height;
	const ctx = canvas.getContext('2d');
	ctx.fillStyle = '#ffffff';
	ctx.fillRect(0, 0, canvas.width, canvas.height);
	ctx.drawImage(img, 0, 0);

	const dataURI = canvas.toDataURL('image/jpeg');

	const outputFile = await dataURLtoFile(dataURI, `${file.name.split('.')[0]}.jpg`);

	img.parentElement.removeChild(img)
	canvas.parentElement.removeChild(canvas)

	return outputFile
}

const uploadFile = async ({
	file, folderType, fileUploadFrom,
	channelId = null, validImageTypes = null, onUploaded = () => {}, onProgress=()=> {}, onError=() => {},
	lineSizeLimit = false, chatRoomId=null
}) => {
	if (file.size > S3_FILE_SIZE) {
		onError()
		return {}
	}

	if (validImageTypes && validImageTypes.indexOf(file.type) < 0) {
		onError()
		return {}
	}

	if (lineSizeLimit && file.size > LINE_FILE_SIZE) {
		onError()
		return{}
	}

	const formData = new FormData()
	formData.append('file', file)
	formData.append('userType', 1)
	formData.append('fileUploadFrom', fileUploadFrom)
	formData.append('channelId', channelId)
	formData.append('folderType', folderType)
	formData.append('contentType', file.type)
	formData.append('fileName', file.name)
	formData.append('extension', file.name.split('.')[1])
	formData.append('preview', (LINE_VALID_TYPE.indexOf(file.type) < 0 || file.size > LINE_FILE_SIZE))

	if (chatRoomId) {
		formData.append('chatRoomId', chatRoomId)
	}

	if (file.type === 'video/mp4') {
		const videoUrl = window.URL.createObjectURL(file)
		const snapshotImgFile = await getSnapshotFromVideo(videoUrl, file.name)
		formData.append('thumbnail', snapshotImgFile)
	}

 	if (file.type === 'image/png') {
		const jpgFile = await transferPNGtoJPG(file)
		formData.set('file', jpgFile)
		formData.set('fileName', jpgFile.name)
		formData.set('contentType', jpgFile.type)
 	}


	const result = await fetchHelper.post('/upload', formData, {
		onUploadProgress: (e) => onProgress(Math.floor(e.loaded / e.total * 100))
	});
	const res = result.data
	if (res.status !== "OK") {
		onError()
		return {}
	}

	const fileNameArray = file.name.split('.')
	const output = {
		guid: res.data.guid,
		url: res.data.fileUrl,
		fileUrl: res.data.fileUrl,
		shortUrl: res.data.shortUrl,
		expireDtm: res.data.expireDtm,
		name: file.name,
		fileName: file.name,
		type: LINE_VALID_TYPE.indexOf(file.type) > -1 ? file.type.split('/')[0] : 'file',
		contentType: file.type,
		size: file.size,
		extension: fileNameArray[fileNameArray.length - 1]
	}

	onUploaded(output)
	return output
}

const getPreviewByfileContentType = (contentType, url) => {
	const base = 'https://lineline.s3.amazonaws.com/systemFiles/file-preview-image/'
	let previewUrl = `${base}others.svg`
	let isImage = false
	Object.keys(MIME_TYPE_MAPING).map(aKey => {
		if (MIME_TYPE_MAPING[aKey].indexOf(contentType) > -1) {
			previewUrl = `${base}${aKey}.svg`

			if (aKey === 'image') {
				previewUrl = url
				isImage = true
			}
		}

		return aKey
	})

	return { previewUrl, isImage }
}

const formatBytes = (bytes, decimals, defaultStr) => {
	if (!bytes) return defaultStr;
	if(bytes === 0) return '0 Bytes';
	const k = 1024,
		dm = decimals <= 0 ? 0 : decimals || 2,
		sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const Uploader = (props) => {
	const { t } = useTranslation();
	const inputRef = useRef(null);

	const uploadHandler = (event) => {
		const file = event.target.files[0];

		if (file) {
			const reader = new FileReader();
			reader.onerror = (e) => {
				// console.log('file reader error', e)
				props.onError(e)
				reader.abort();
			};

			reader.onloadstart = (e) => {
				props.onFileLoadStart()
			}

			reader.onload = () => {
				uploadFile({
					file: file,
					folderType: props.folderType,
					fileUploadFrom: props.fileUploadFrom,
					channelId: props.channelId,
					validImageTypes: props.validImageTypes,
					onUploaded: (result) => {
						props.onUpLoaded(result)
					},
					onProgress: (percent) => {},
					onError: () => props.onError(t('error.fileTypeAndSize')),
					lineSizeLimit: props.lineSizeLimit || false
				})
			};
			reader.readAsDataURL(file);
		}
	}

	if (inputRef && props.startUpload) {
		inputRef.current.click()
		props.onUploadEventFired()
	}

	return (
		<input
			ref={inputRef}
			className="upload-input"
			type="file"
			onChange={(e)=> uploadHandler(e)}
			tilte="upload"
		/>
	)
}

Uploader.defaultProps = {
	onError: () => {},
	onFileLoaded: () => {},
	startUpload: false,
	onUploadEventFired: () => {},
	onFileLoadStart: () => {}
}

export default Uploader;
export { uploadFile, getPreviewByfileContentType, formatBytes }