import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';
import cx from 'classnames';
import numeral from 'numeral'
import qs from 'qs'

import TagsInputer from 'Components/TagsInputer'
import { fetchHelper } from 'tools/FetchHelper'

import './index.css'

const ENTER_KEY = 13;

const conditionOption = ['include', 'notInclude']

const CheckboxList = (props) => {
	const { t } = useTranslation();

	const [checkedList, setList] = useState(props.value || [])
	const isFirstRun = useRef(true);

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		props.onChange({
			name: props.name,
			value: checkedList
		})
	}, [checkedList])

	const renderLabel = (name, i, pattern) => {
		if (name === 'chatRoomFollowTime') {
			if (i === 0) {
				return t('filter.comparePattern', {number: pattern[1], name: 'travelDay', compare: pattern[0]})
			}
			if (i === 1) {
				return `${pattern[1]}${t('filter.unit.travelDay')}-${pattern[2]}${t('filter.unit.chatRoomFollowTime')}`
			}
		}

		return pattern[0] === 'between' ?
			t('filter.betweenPattern', {number1: pattern[1], number2: pattern[2], name: props.name}) :
			t('filter.comparePattern', {number: pattern[1], name: props.name, compare: pattern[0]})
	}

	return (
		<div className="checkbox-list">
			{props.options.map((aOption, i) => {
				const pattern = aOption.value.pattern.split('_')
				return (
					<div className="input-checkbox-block" key={`input-checkbox-block-${i}`}>
						<input
							id={`${props.name}-${i}`}
							name={props.name}
							type="checkbox"
							checked={checkedList.filter(x => x.pattern === aOption.value.pattern).length > 0}
							onChange={(e) => {
								const list = checkedList.slice()
								if (e.target.checked) {
									pattern.push(aOption.pattern)
									list.push({
										pattern: aOption.pattern,
										...aOption.value
									})
								} else {
									const index = list.findIndex(x => x.pattern === aOption.value.pattern);
									list.splice(index, 1)
								}
								setList(list)
							}}
						/>
						<label htmlFor={`${props.name}-${i}`}>
							{renderLabel(props.name, i, pattern)}

						</label>
					</div>
				)
			})}
		</div>
	)
}

CheckboxList.defaultProps = {
	onChange: () => {},
	options: []
}

const TravelAreaChooser = (props) => {
	const { t } = useTranslation();
	const [travelArea, setArea] = useState(Object.keys(props.area.option).reduce((pre, aArea) => {
		if (!props.area.value || props.area.value.length === 0) return pre

		Object.keys(pre[aArea]).map(aRegion => {
			if (props.area.value.includes(aArea)) {
				pre[aArea][aRegion].checked = true
				return aRegion
			}
			if (props.area.value.includes(`${aArea}.${aRegion}`)) {
				pre[aArea][aRegion].checked = true
			}
			return aRegion
		})

		return pre
	} , props.area.option))

	const isFirstRun = useRef(true);

	const getCheckStatus = (aArea) => {
		const statusList = Object.keys(aArea).map((aRegion, i) => !!aArea[aRegion]['checked'])

		if (statusList.includes(false)) {
			if (statusList.includes(true)) {
				return 'partial_checked'
			}

			return 'non_checked'
		}

		return 'all_checked'
	}

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}

		props.onChange({
			name: 'area',
			value: Object.keys(travelArea).reduce((pre, aArea) => {
				if (getCheckStatus(travelArea[aArea]) === 'all_checked') {
					pre.push(aArea)
				}
				Object.keys(travelArea[aArea]).map(aRegion => {
					if (travelArea[aArea][aRegion].checked) {
						pre.push(`${aArea}.${aRegion}`)
					}

					return aRegion
				})

				return pre
			}, [])
		})

	}, [travelArea])

	return (
		<div className="travel-area">
			{Object.keys(travelArea).map((aArea, i) => {
				if (aArea === '_id') return null;

 				return (
					<div className="a-area" key={`travel-area-${i}`}>
						<div className="input-checkbox-block select-area">
							<input
								id={`travelArea-${i}`}
								name="travelArea"
								type="checkbox"
								checked={getCheckStatus(travelArea[aArea]) === 'all_checked'}
								className={cx(getCheckStatus(travelArea[aArea]))}
								onChange={(e) => setArea({
									...travelArea,
									[aArea]: Object.keys(travelArea[aArea]).reduce((pre, aRegion) => {
										pre[aRegion] = {
											checked: !(getCheckStatus(travelArea[aArea]) === 'all_checked')
										}
										return pre
									}, {})
								})}
							/>
							<label htmlFor={`travelArea-${i}`}>
								{t(`travelArea.${aArea}.title`)}
							</label>
						</div>
						<div className="sub-list">
							{Object.keys(travelArea[aArea]).map((aRegion, j) => (
								<div className="a-region input-checkbox-block" key={`a-region-${i}-${j}`}>
									<input
										id={`travelArea-${i}-${j}`}
										name="travelArea"
										type="checkbox"
										checked={travelArea[aArea][aRegion]['checked'] || false}
										onChange={(e) => setArea({
											...travelArea,
											[aArea]: {
												...travelArea[aArea],
												[aRegion]: {
													checked: !travelArea[aArea][aRegion]['checked']
												}
											}
										})}
									/>
									<label htmlFor={`travelArea-${i}-${j}`}>
										{t(`travelArea.${aArea}.${aRegion}`)}
									</label>
								</div>
							))}
						</div>
					</div>
				)
			})}
		</div>
	)
}

TravelAreaChooser.defaultProps = {
	onChange: () => {}
}

const SearchCowell = (props) => {
	const { t } = useTranslation();
	const [keyword, setKeyword] = useState(props.value)
	const [isFetching, setFetching] = useState(null)
	const [total, setTotal] = useState(null)

	const fetchCowellList = async () => {
		setFetching(true);
		try {
			const result = await fetchHelper.get(`/channel/${props.channelId}/cowell/passenger`, {
				params: {
					keyword: keyword,
					matchedPassengerOnly: true
				}
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				setTotal(res.data.total)
				setFetching(false);

				props.onChange({
					name: props.name,
					value: keyword
				})
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false);
			console.log('fecth cowell members', error.message)
		}
	}

	useEffect(() => {
		if (props.value) {
			fetchCowellList()
		}
	}, [])

	return (
		<div className="cowell-search">
			<div className={cx("c-txt__input c-txt__input--media", { valid: !!keyword })}>
				<input
					className="c-txt__input c-txt__input--bare c-txt__input--media__body"
					placeholder={t('filter.cowellSearchPlaceholder')}
					type="text"
					value={keyword || ""}
					onChange={(e) => setKeyword(e.target.value)}
					onKeyDown={(e) => {
						if (e.keyCode === ENTER_KEY && !isFetching) {
							e.preventDefault()
							if (keyword) {
								fetchCowellList()
							}

						}
					}}
				/>
				<div className="c-txt__input--media__figure" onClick={() => {
					if (!isFetching) fetchCowellList()
				}}>
					{isFetching ?
						<i className="fal fa-spinner-third fa-spin" /> :
						<i className="far fa-search" />
					}
				</div>
			</div>
			<div className="search-result">
				{keyword && total !== null &&
					<>
						{`${t('search')} "${keyword}", `}
						{t('friends.searchResult', { searchNumbers: numeral(total).format('0,0')})}
					</>
				}
			</div>
		</div>
	)
}

const LineFirends = (props) => {
	const { t } = useTranslation();
	const [friends, setFriends] = useState(props.friends)
	const [searchResultList, setSearchResult] = useState([])
	const [searchValue, setSearchValue] = useState(null)
	const [isFetching, setFetching] = useState(false)
	const [total, setTotal] = useState(null)

	const updateFriendsUrlParams = (newFriends) => {
		const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
		urlParams.friends = newFriends.map(x => x.guid).join(',')
		window.history.pushState('', '', `?${qs.stringify(urlParams)}`);
		props.onFriendsChange(newFriends)
	}


	const addUserToSendlist = (user, index) => {
		const friendsCopy = friends.slice()

		const matchIndex = friendsCopy.findIndex(x => x.guid === user.guid)

		if (matchIndex < 0) {
			friendsCopy.push(user)

			updateFriendsUrlParams(friendsCopy)

			setFriends(friendsCopy)

			const searchResultCopy = searchResultList.slice()
			searchResultCopy.splice(index, 1)
			setSearchResult(searchResultCopy)
		}
	}

	const deletUserFromSendList = (index) => {
		const friendsCopy = friends.slice()

		friendsCopy.splice(index, 1)

		updateFriendsUrlParams(friendsCopy)

		setFriends(friendsCopy)
	}

	const searchFriends = async () => {
		setFetching(true)
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/multiCast/filter/chatroom/search`, {
				keyword: searchValue,
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				setSearchResult(res.data)
				setTotal(res.data.length)
				setFetching(false)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('search chatroom fail', error.message)
		}
	}


	return (
		<div className="line-friends">
			<p>{t('broadcast.sendFriendList')}：</p>
			<div className="send-list">
				{friends.map((aFriend, i) => (
					<div className="a-friend" key={`a-send-friend-${aFriend.guid}`}>
						<figure>
							<img src={aFriend.avatar} alt=""/>
						</figure>
						<div className="real-name">{aFriend.name}</div>
						<div className="nick-name">（{aFriend.cowelName || '-'}）</div>
						<div className="control-button" onClick={() => deletUserFromSendList(i)}>
							<i className="fal fa-times icon-normal" />
						</div>
					</div>
				))}
			</div>
			<div className={cx("c-txt__input c-txt__input--media", { valid: !!searchValue })}>
				<input
					className="c-txt__input c-txt__input--bare c-txt__input--media__body"
					placeholder={t('broadcast.searchPlaceholder')}
					type="text"
					value={searchValue || ""}
					onChange={(e) => setSearchValue(e.target.value)}
					onKeyDown={(e) => {
						if (e.keyCode === ENTER_KEY && !isFetching) {
							e.preventDefault()
							if (searchValue) {
								searchFriends()
							}

						}
					}}
				/>
				<div className="c-txt__input--media__figure" onClick={() => {
					if (!isFetching) searchFriends()
				}}>
					<i className="far fa-search" />
				</div>
			</div>
			{searchValue && total !== null &&
				<div className="search-result">
					{`${t('search')} "${searchValue}", `}
					{t('friends.searchResult', { searchNumbers: numeral(total).format('0,0')})}
				</div>
			}
			<div className="search-friends">
				{isFetching ?
					<div className="icon-loading">
						<i className="fal fa-spinner-third fa-spin" />
					</div>	:
					searchResultList.map((aFriend, i) => (
						<div className="a-friend" key={`a-search-friend-${aFriend.guid}`}>
							<figure>
								<img src={aFriend.avatar} alt=""/>
							</figure>
							<div className="real-name">{aFriend.name}</div>
							<div className="nick-name">（{aFriend.cowelName || '-'}）</div>
							<div className="control-button" onClick={() => addUserToSendlist(aFriend, i)}>
								<i className="fal fa-plus icon-normal" />
							</div>
						</div>
					))
				}
			</div>

		</div>
	)
}

LineFirends.defaultProps = {
	onFriendsChange: () => {}
}

const FilterBlock = (props) => {
	const { t } = useTranslation();
	const [currentFilter, setFilter] = useState(props.filter.name ? props.filter : { name: 'tag' })
	const [tagFilters, setTagFilters] = useState(currentFilter.name === 'tag' ? currentFilter.value || [] : [])
	const [isFetchingFilters, setFetchingFilters] = useState(true)
	const [selectedFilters, setSelected] = useState({})
	const [includeType, setInclude] = useState('include')
	const [friends, setFriends] = useState([])
	const [isFetchingFriends, setFetchingFriends] = useState(currentFilter.name === 'chatRoomGuid')
	const filters = useRef([])

	const isFirstRun = useRef(true);

	const getUsersByGUIDs = async (guids) => {
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/multiCast/filter/chatroom/search`, {
				guid: guids,
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				if (res.data.length > 0) {
					setFriends(res.data)
				}
				setFetchingFriends(false)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetchingFriends(false)
			console.log('search chatroom fail', error.message)
		}
	}

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}

		props.onUpdate({
			...selectedFilters,
			isReverse: includeType === 'include' ? false : true,
			...(currentFilter.name === 'tag' && {
				name: 'tag',
				value: tagFilters
			}),
			...(currentFilter.name === 'chatRoomGuid' && {
				name: 'chatRoomGuid',
				value: friends.map(x => x.guid)
			})

		})

	}, [selectedFilters, tagFilters, includeType, friends])


	const getFilters = async () => {
		setFetchingFilters(true)
		try {
			const result = await fetchHelper.get(`/channel/${props.channelId}/multiCast/filter`);
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				filters.current = res.data
				filters.current.unshift({ name: "chatRoomGuid" })
				filters.current.unshift({ name: "tag" })
				filters.current.push({ name: "cowellSearch" })
				if (!['tag', 'chatRoomGuid'].includes(currentFilter.name)) {
					setFilter({
						...currentFilter,
						option: filters.current.filter(x => x.name === currentFilter.name)[0].option
					})
				}
				setFetchingFilters(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetchingFilters(false);
			console.log('get filter list fail', error.message)
		}
	}

	useEffect(() => {
		if (filters.current.length < 1) {
			getFilters()
		}

		if (currentFilter.name === 'chatRoomGuid') {
			if (currentFilter.value.length > 0) {
				getUsersByGUIDs(currentFilter.value)
			} else {
				setFetchingFriends(false)
			}
		}
	}, []);

	return isFetchingFilters ?
		<div className="icon-loading">
			<i className="fal fa-spinner-third fa-spin" />
		</div> :
		<div className="filter-block">
			<div className="top-section">
				<div className="left">
					<ThemeProvider theme={window.menuTheme}>
						<Menu
							trigger={({ ref, isOpen }) => {
								return (
									<div
										className={cx("c-txt__input--select filter-select", { 'is-open': isOpen })}
										ref={ref}
									>
										{t(`filter.${currentFilter.name}`)}
									</div>
								)
							}}
						>
							{filters.current.map((aType, i) => (
								<Item
									className="c-txt__input--select-item"
									key={aType.name}
									onClick={() => setFilter(filters.current[i])}>
										{t(`filter.${aType.name}`)}
								</Item>
							))}
						</Menu>
					</ThemeProvider>
					<ThemeProvider theme={{
						...window.menuTheme,
						'menus.menu_view': `
							&& {
								max-width: 100px;
								min-width: 100px;
							}
						`,
					}}>
						<Menu
							trigger={({ ref, isOpen }) => {
								return (
									<div
										className={cx("c-txt__input--select include-select", { 'is-open': isOpen })}
										ref={ref}
									>
										{t(`broadcast.${includeType}`)}
									</div>
								)
							}}
						>
							{conditionOption.map((aCond, i) => (
								<Item
									className="c-txt__input--select-item"
									key={`filer-condition-${aCond}`}
									onClick={() => setInclude(aCond)}>
										{t(`broadcast.${aCond}`)}
								</Item>
							))}
						</Menu>
					</ThemeProvider>
				</div>
				{ !props.hideDeleteIcon  &&
					<i className="far fa-trash" onClick={() => props.onDelete()} />
				}
			</div>
			{currentFilter &&
				<div className="select-content">
					{currentFilter.name === 'tag' &&
						<TagsInputer
							tagFilters={tagFilters}
							onFilterChange={(filters) => setTagFilters(filters)}
							channelId={props.channelId}
							boroadcastMode
						/>
					}
					{currentFilter.name === 'area' &&
						<TravelAreaChooser
							{...props}
							area={currentFilter}
							onChange={(option) => setSelected(option)}
						/>
					}
					{currentFilter.name === 'cowellSearch' &&
						<SearchCowell
							{...props}
							name={currentFilter.name}
							value={currentFilter.value}
							onChange={(option) => setSelected(option)}
						/>
					}

					{ currentFilter.name === 'chatRoomGuid' && isFetchingFriends &&
						<div className="icon-loading">
							<i className="fal fa-spinner-third fa-spin" />
						</div>
					}

					{currentFilter.name === 'chatRoomGuid' && !isFetchingFriends &&
						<LineFirends
							friends={friends}
							onFriendsChange={(updatedFriendList) => {
								setFriends(updatedFriendList)
							}}
							channelId={props.channelId}
						/>
					}


					{!['tag', 'area', 'cowellSearch', 'chatRoomGuid'].includes(currentFilter.name) &&
						<CheckboxList
							name={currentFilter.name}
							options={currentFilter.option}
							value={currentFilter.value}
							checkList={[]}
							onChange={(option) => setSelected(option)}
						/>
					}
				</div>
			}
		</div>
}

FilterBlock.defaultProps = {
	onDelete: () => {},
	hideDeleteIcon: false,
	onUpdate: () => {},
}

export default FilterBlock;