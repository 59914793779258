import React, { useState, useEffect, useRef } from 'reactn';
import qs from 'qs'
import { getUser } from 'Login'
import cx from 'classnames'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import zhTW from 'dayjs/locale/zh-tw'
import enums from 'enums'

import { fetchHelper } from 'tools/FetchHelper'
import SearchBar from './SearchBar'
import FriendList from './FriendList'
import './index.css'

dayjs.locale('zh-tw', zhTW)
dayjs.extend(relativeTime)

const MessageCenterLeft = (props) => {
	// var general
	const { currentFriend, setCurrent, isCustomerServiePermission } = props
	const { changedFriendDetail, toggleChatRoomStatus } = props
	const [searchBarExtend, setSearchBarExtend] = useState(false)
	const [initFetch, setInitFetch] = useState(false)

	// var fetch friend
	const [friends, setFriends] = useState([])
	const [moreFriendsFetching, setFetching] = useState(false)
	const [noMoreFriend, setNoMoreFriend] = useState(false)
	const [currentOwnerFilter, setCurrentOwnerFilter] = useState(isCustomerServiePermission ? {
		type: enums.ChatRoomAssignOwnerType.op,
		GUID: getUser().guid,
		name: getUser().name
	} : undefined)
	const [currentChatRoomTypeFilter, setCurrentChatRoomTypeFilter] = useState(enums.ChatRoomStatusType.all)

	// var searching
	const searchResultField = ["history", "tag", "name", "subName", "phone", "email"]
	const initSearchResult = {
		history: [],
		tag: [],
		name: [],
		subName: [],
		phone: [],
		email: []
	}
	const searchValueTypeFieldList = {
		'history': ['history'],
		'tag': ['tag'],
		'userInfo': ['name', 'subName', 'phone', 'email']
	}
	const [searchResult, setSearchResult] = useState(initSearchResult)
	const [searching, setSearching] = useState(false)
	const [noMoreFriendSearchResult, setNoMoreFriendSearchResult] = useState(false)
	const searchResultPage = useRef(1)
	const [currentSearchValueType, setCurrentSearchValueType] = useState(enums.SearchValueType.userInfo)
	const [searchValue, setSearchValue] = useState(undefined)
	const [hasSearch, setHasSearch] = useState(false)

	const { isDisplayChat, setDisplayChat } = props

	const fetchGivenChatroom = async (chatRoomGuid) => {
		setFetching(true)
		try {
			const result = await fetchHelper.get(`/channel/${props.channelId}/chatRoom/${chatRoomGuid}/listcard`)
			const res = result.data

			if (res.status === "OK") {
				setNoMoreFriend(true)
				setDisplayChat(true)
				setCurrent(res.data)
				setFriends([res.data])
				setFetching(false)
			}
			else {
				setFetching(false)
			}

		} catch (error) {
			setFetching(false);
			console.log('fetch friend by url param error', error)
		}
	}

	const fetchCallOp = async () => {
		// 重新抓聊天室列表
		setFetching(true)
		try {

			let postbody = {}
			if (!!currentOwnerFilter) {
				postbody.assignOp = currentOwnerFilter
			}
			if (!!currentChatRoomTypeFilter) {
				postbody.filterStatus = currentChatRoomTypeFilter
			}

			const result = await fetchHelper.post(`/channel/${props.channelId}/chatRoom/list/callOp`, postbody);

			const res = result.data
			if (res.status === "OK") {
				setFriends(res.data.map(x => {
					x.isBotActive = true
					return x
				}))
				fetchMoreFriends(res.data, [])
			} else {
				throw new Error(res.errorMessage)
			}
		} catch (error) {
			setFetching(false);
			console.log('fetch call op friends error', error)
		}
	}

	const fetchMoreFriends = async (callOps = [], currentFriends = null) => {
		if (moreFriendsFetching) return
		setFetching(true)
		try {
			let excludeGuid = callOps.map(x => x.guid)
			const fetchSize = 20
			if (currentFriends) {
				excludeGuid = excludeGuid.concat(currentFriends.map(x => x.guid))
			}
			let postbody = {
				size: fetchSize,
				page: 1,
				excludeGuid: excludeGuid
			}

			if (!!currentOwnerFilter) {
				postbody.assignOp = currentOwnerFilter
			}

			if (!!currentChatRoomTypeFilter) {
				postbody.filterStatus = currentChatRoomTypeFilter
			}

			const result = await fetchHelper.post(`/channel/${props.channelId}/chatRoom/list`, postbody);
			const res = result.data
			if (res.status === "OK") {
				const originFriends = currentFriends ? currentFriends : friends

				if (res.data.length < fetchSize) {
					setNoMoreFriend(true)
				}

				let allFriends = originFriends.concat(callOps).concat(res.data.map(x => {
					x.isBotActive = true
					return x
				}))

				if (!currentFriend.guid && allFriends.length > 0 && !props.location.search) {
					setCurrent(allFriends[0])
				}
				setFriends(allFriends)
				setFetching(false)

			} else {
				throw new Error(res.errorMessage)
			}
		} catch (error) {
			setFetching(false);
			console.log('fetch more friends error', error)
		}
	}

	const searchChat = async (initSearchValue) => {
		let keyword = searchValue
		if (!!initSearchValue) {
			keyword = initSearchValue
		}

		setSearching(true)
		try {
			const fetchSize = 20
			const result = await fetchHelper.post(`/channel/${props.channelId}/chatRoom/search`, {
				"page": searchResultPage.current,
				"size": fetchSize,
				"type": searchValueTypeFieldList[enums.SearchValueType[currentSearchValueType]],
				"keyword": keyword
			});
			const res = result.data
			if (res.status === "OK") {
				if (res.data.history.length < fetchSize && currentSearchValueType === enums.SearchValueType.history) {
					setNoMoreFriendSearchResult(true)
				}
				if (currentSearchValueType === enums.SearchValueType.history && searchResultPage.current !== 1) {
					setSearchResult({
						...res.data,
						"history": searchResult.history.concat(res.data.history)
					})
				} else {
					setSearchResult({ ...res.data })
				}
				setSearching(false)

				if (!!initSearchValue) {
					if (!!res.data.name) {
						setCurrent(res.data.name[0].chatRoom)
					}
					else if (!!res.data.subName) {
						setCurrent(res.data.subName[0].chatRoom)
					}
				}

			} else {
				throw new Error(res.errorMessage)
			}
		} catch (error) {
			setSearching(false);
			console.log('search chat result failed', error)
		}
	}

	const updateFriend = (friendsCopy) => {
		return friendsCopy.map((friend) => {
			if (friend.guid === changedFriendDetail.guid) {
				friend = {
					...friend,
					...changedFriendDetail
				}
			}
			return friend
		})
	}

	const updateSearchResult = () => {
		const searchResultContentCopy = {}
		searchResultField.map((field) => {
			searchResultContentCopy[field] = searchResult[field].slice().map((fieldContent) => {
				if (fieldContent.guid === changedFriendDetail.guid) {
					fieldContent = {
						...fieldContent,
						...changedFriendDetail
					}
				}
				return fieldContent
			})
			return field
		})

		setSearchResult({
			...searchResult,
			...searchResultContentCopy
		})
	}

	const updateCallOp = (friendsCopy) => {
		const { updateCallOpDetail } = props
		const isCallOpStatus = enums.ChatRoomActivateStatusType.isCallOp
		const matchFriendIdx = friends.findIndex(x => x.guid === updateCallOpDetail.guid)

		if (matchFriendIdx >= 0) {
			const matchFriendCopy = friendsCopy.slice()[matchFriendIdx]
			if (matchFriendIdx > 0) {
				friendsCopy.splice(matchFriendIdx, 1)
				friendsCopy.unshift(matchFriendCopy)
			}
			if (updateCallOpDetail.callOpAlert && !friendsCopy[0].activateStatus.includes(isCallOpStatus)) {
				friendsCopy[0] = {
					...friendsCopy[0],
					activateStatus: friendsCopy[0].activateStatus.concat([isCallOpStatus])
				}
			} else if (!updateCallOpDetail.callOpAlert && friendsCopy[0].activateStatus.includes(isCallOpStatus)) {
				friendsCopy[0] = {
					...friendsCopy[0],
					activateStatus: friendsCopy[0].activateStatus.filter(x => x !== isCallOpStatus)
				}
			}
		}

		return friendsCopy
	}

	const updateUnRead = (friendsCopy) => {
		const { updateUnReadDetail } = props

		return friendsCopy.map((friend) => {
			if (friend.guid === updateUnReadDetail.guid) {
				if (updateUnReadDetail.unReadAlert) {
					friend = {
						...friend,
						hasUnreadMsg: updateUnReadDetail.unReadAlert,
						unreadCount: friend.unreadCount ? friend.unreadCount + 1 : 1
					}
				} else {
					friend = {
						...friend,
						hasUnreadMsg: updateUnReadDetail.unReadAlert,
						unreadCount: 0
					}
				}
			}
			return friend
		})
	}

	const searchByKeyword = (initSearchValue) => {
		searchResultPage.current = 1
		setNoMoreFriendSearchResult(false)
		setHasSearch(true)
		searchChat(initSearchValue)
	}

	useEffect(() => {
		const { updateCallOpDetail, updateUnReadDetail, changedFriendDetail } = props
		let friendsCopy = friends.slice()

		if (updateCallOpDetail) {
			friendsCopy = updateCallOp(friendsCopy)
			props.setUpdateCallOpDetail(null)
		}

		if (updateUnReadDetail) {
			friendsCopy = updateUnRead(friendsCopy)
			props.setUpdateUnReadDetail(null)
		}

		if (changedFriendDetail) {
			friendsCopy = updateFriend(friendsCopy)
			if (!!searchValue) {
				updateSearchResult()
			}
			props.setChangedFriendDetail(null)
		}

		setFriends(friendsCopy)
	}, [props.updateCallOpDetail, props.updateUnReadDetail, changedFriendDetail])

	useEffect(() => {
		if (searchValue !== undefined) {
			searchByKeyword()
		}
	}, [currentSearchValueType])

	useEffect(() => {
		if (initFetch) {
			setNoMoreFriend(false)
			fetchCallOp()
		}
	}, [currentOwnerFilter, currentChatRoomTypeFilter])

	useEffect(() => {
		if (!searchBarExtend) {
			searchResultPage.current = 1
			setNoMoreFriendSearchResult(false)
			setHasSearch(false)
			setSearchResult(initSearchResult)
		}
	}, [searchBarExtend])

	useEffect(() => {
		if (props.location.search) {
			const urlParams = qs.parse(props.location.search, { ignoreQueryPrefix: true })
			if (urlParams.search) {
				setSearchValue(urlParams.search)
				setSearchBarExtend(true)
				searchByKeyword(urlParams.search)
			}

			if (urlParams.chatroomId) {
				fetchGivenChatroom(urlParams.chatroomId)
				return
			}

		}

		fetchCallOp()
		setInitFetch(true)
	}, [])

	return (
		<div className={cx("message-center-left", { "mobile-show": !isDisplayChat })}>
			<SearchBar
				{...props}
				searchBarExtend={searchBarExtend}
				setSearchBarExtend={setSearchBarExtend}
				currentOwnerFilter={currentOwnerFilter}
				setCurrentOwnerFilter={setCurrentOwnerFilter}
				currentChatRoomTypeFilter={currentChatRoomTypeFilter}
				setCurrentChatRoomTypeFilter={setCurrentChatRoomTypeFilter}
				currentSearchValueType={currentSearchValueType}
				setCurrentSearchValueType={setCurrentSearchValueType}
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				searchByKeyword={searchByKeyword}
				disableOwnerFilter={isCustomerServiePermission}
			/>
			<FriendList
				searchValue={searchValue}
				hasSearch={hasSearch}
				searching={searching}
				searchResult={searchResult}
				noMoreFriendSearchResult={noMoreFriendSearchResult}
				currentSearchValueType={currentSearchValueType}
				friends={friends}
				moreFriendsFetching={moreFriendsFetching}
				noMoreFriend={noMoreFriend}
				fetchMoreFriends={fetchMoreFriends}
				currentFriend={currentFriend}
				setCurrent={setCurrent}
				toggleChatRoomStatus={toggleChatRoomStatus}
				searchResultPage={searchResultPage}
				searchChat={searchChat}
				msgRead={props.msgRead}
				setDisplayChat={setDisplayChat}
			/>
		</div>
	)
}

export default MessageCenterLeft;