import React from 'react';
import cx from 'classnames'

import RadioInput from 'Components/RadioInput'
import CheckboxInput from 'Components/CheckboxInput'

const VoteActivityVoteOption = (props) => {
	const {inputId, optionValue, count} = props
	const {isSingleSelect, isSelect, isDisabled, showCount} = props
	const {checkChanged} = props
	return (
		<div
			className={cx('a-vote-option',{
				'single-select': isSingleSelect,
				'multi-select': !isSingleSelect,
				"is-select" : isSelect,
				"disabled" : isDisabled})}
			onClick={() => {
				if (isDisabled) return
				checkChanged()
			}}
		>
			<div className="input-section">
			{isSingleSelect?
				<RadioInput
					id={inputId}
					isDisabled={isDisabled}
					isChecked={isSelect}
					labelText={optionValue}
					useSpanText={true}
				/>
			:
				<CheckboxInput
					id={inputId}
					isDisabled={isDisabled}
					isChecked={isSelect}
					labelText={optionValue}
					useSpanText={true}
				/>
			}
			</div>
			{showCount &&
				<div className="count-section">
					{count || 0}
				</div>
			}

		</div>

	)
}

VoteActivityVoteOption.defaultProps = {
	checkChanged: ()=>{},
}
export default VoteActivityVoteOption;
