/**
 * Render a step-block with step-icon and seq on left-top
 * @param seqNum - sequence on icon
 * @param DesComp - a react component which will render at middle
 * @param imgUrl - image put on the right
 * @param className - add to step-block
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import './index.css'
const Step = ({ seqNum, seqText, titleKey, title, DesComp, imgUrl, renderInput, className}) => {
	const { t } = useTranslation();

	return (
		<div className={cx("step-block", className)}>
			<div className="seq-icon">
				{seqNum ?
					<>
					<div className="icon-title">{t('line@setting.step')}</div>
					<div className="icon-seq">{seqNum}</div>
					</>
					:
					<div className="icon-title">{seqText}</div>
				}
				<div className="icon-decor-line" />
			</div>
			<div className="description">
				{titleKey?
					<div className="des-title">{t(titleKey)}</div>
					:
					<div className="des-title">{title}</div>
				}
				<DesComp />
				{!!renderInput && renderInput()}
			</div>
			{ imgUrl &&

				<div className="demo-image">
					<img alt="" src={imgUrl} />
				</div>
			}
		</div>

	)
}

Step.defaultProps = {
	renderInput: null
}

export default Step;
