import React, { useGlobal } from 'reactn';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import permission from 'enums/permission'

import './index.css'

const SideMenu = (props) => {
	const { t } = useTranslation();
	const pathname = props.location.pathname
	const [channelsReplica] = useGlobal('channelsReplica')

	return (
		<div className="console-menu">
			<Link to="/console/channels">
				<div
					className={cx("a-section", { active: pathname === '/console/channels' })}
					title={t('channel.allChannels')}
				>
			 		<img src="https://lineline.s3.amazonaws.com/icons/overview.svg" alt=""/>
				</div>
			</Link>
			{channelsReplica.map((aChannel, i) => {
				// link rule : callOpCount -> 'message', than decides by permission
				let channelUrl = `/console/channel/${aChannel.guid}/`
				if (aChannel.callOpCount > 0) {
					channelUrl += 'message'
				}
				else {
					const permissionName = permission.type[aChannel.permissionType]
					channelUrl += permission[permissionName].goToChannelPath
				}
				return (
					<Link to={channelUrl} key={`side-a-channel-${i}`}>
						<div
							className={cx(
								"a-section",
								{
									active: pathname.match(aChannel.guid),
									'yellow-dot': aChannel.unreadCount > 0,
									'red-dot': aChannel.callOpCount > 0
								}
							)}
							title={aChannel.name}
						>
							<figure>
								<img
									alt=""
									src={aChannel.avatarUrl ? aChannel.avatarUrl : 'https://lineline.s3.amazonaws.com/icons/group.png'}
								/>
								<div className="dot" />
							</figure>
						</div>
					</Link>
			)})}
		 	<Link to="/console/channel/create">
		 		<div
		 			className={cx("a-section", { active: pathname === '/console/channel/create' })}
		 			title={t('channel.addChannel')}
		 		>
		 			<i className="fal fa-plus-circle" />
		 		</div>
		 	</Link>
		</div>
	);
}

SideMenu.defaultProps = {
	channels: []
}

export default SideMenu;