import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import Uploader from 'Components/Uploader'
import './index.css'

const ImageUploader = (props) => {
	const { t } = useTranslation();
	const disabled = props.disabled || false
	const {defaultImg} = props
	const {imageUrl, filename, startUpload, errorMsg} = props
	const {onClick, onUploadEventFired, onFileLoadStart, onUpLoaded, onError} = props
	const {folderType, channelId, fileUploadFrom} = props
	const imgRestriction = props.imgRestriction || t('message.imgRestriction')

	return (
		<div className={cx("upload-image", { error: !!errorMsg })}>
			{!!imageUrl ?
				<figure>
					<img src={imageUrl} alt=""/>
				</figure> :
				<div className="default-img" onClick={() => onClick()}>
					{!!defaultImg ?
						<figure>
							<img src={defaultImg} alt=""/>
						</figure>

					: !!errorMsg ?
						<i className="fal fa-exclamation-triangle" />
					:
						<i className="fal fa-image" />
					}
				</div>
			}
			{!disabled &&
				<>
				<div className="image-info">
					<div className="restriction">{imgRestriction.split('\n').map((newline, i) => {return <p key={i}>{newline}</p>})}</div>
					{!!imageUrl ?
						<div className="image-name">{filename}</div> :
						<div className="error-msg">{errorMsg}</div>
					}
					<button className="c-btn c-btn--primary" onClick={() => onClick()}>
						{t('upload')}
					</button>
				</div>
				<Uploader
					validImageTypes={["image/jpeg", "image/jpg", "image/png"]}
					startUpload={startUpload}
					onUploadEventFired={() => {
						onUploadEventFired()
					}}
					onFileLoadStart={() => {
						onFileLoadStart()
					}}
					onError={(e) => {onError(e)}}
					onUpLoaded={(result) => {onUpLoaded(result)}}
					lineSizeLimit
					folderType={folderType}
					channelId={channelId}
					fileUploadFrom={fileUploadFrom}
				/>
				</>
			}
		</div>
	)

}
export default ImageUploader
