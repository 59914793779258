import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import zhTW from 'dayjs/locale/zh-tw'

import TagsInputer from 'Components/TagsInputer'
import DateInput from 'Components/DateInput'
import ValidInput from 'Components/ValidInput'
import { checkEmailValid, checkIsPhoneNumber } from 'tools/validations'
import { fetchHelper } from 'tools/FetchHelper'

import './index.css'

dayjs.locale('zh-tw', zhTW)
dayjs.extend(relativeTime)


const ENTER_KEY = 13;

const Profile = (props) => {
	const { t } = useTranslation();
	const [form, setValues] = useState({
		phone: undefined,
		email: undefined,
		subName: undefined,
		birthday: '',
		remark: '',
		tag: [],
		addedTags: [],
		deletedTags: [],
		...props.userData
	});
	const [shouldUpdateProfile, setShouldUpdateProfile] = useState(false)
	const validation = (key) => {
		if (form[key] === undefined) return;

		if (key === 'email') return checkEmailValid(form[key]) ? 'success' : 'error'

		if (key === 'phone') return checkIsPhoneNumber(form[key]) ?  'success' : 'error'
	}

	const updateField = e => {
		setValues({
			...form,
			[e.target.name]: e.target.value
		});
	};

	const updateProfile = async () => {

		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/chatRoom/${props.user.guid}`, {
				...form,
				phone: form.phone && form.phone.trim(),
				email: form.email && form.email.trim(),
				subName: form.subName && form.subName.trim(),
				tag: {
					add: form.addedTags,
					remove: form.deletedTags
				}
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				setValues({
					...form,
					'addedTags': [],
					'deletedTags': []
				})
			} else {
				throw new Error(res.errorMessage)
			}
		} catch (error) {
			console.log('update user profile failed', error)
		}
	}

	useEffect(() => {
		if (form.addedTags.length > 0 || form.deletedTags.length > 0) {
			updateProfile()
		}
	}, [form.tag])

	useEffect(() => {
		if (shouldUpdateProfile) {
			setShouldUpdateProfile(false)
			updateProfile()
		}
	}, [shouldUpdateProfile])

	return (
		<div className="user-profile">
			<div className="label-input-inline">
				<span className="label">{t('user.subName')}</span>
				<div className="inputer">
					<ValidInput
						className={cx(validation('subName'))}
						value={form.subName || ""}
						name="subName"
						onChange={updateField}
						onBlur={()=> updateProfile()}
						onKeyDown={(e) => {
							if (e.keyCode === ENTER_KEY) {
								updateProfile()
							}
						}}
					/>
				</div>
			</div>
			<div className="label-input-inline">
				<span className="label">{t('user.phone')}</span>
				<div className="inputer">
					<ValidInput
						className={cx(validation('phone'))}
						value={form.phone || ""}
						name="phone"
						onChange={updateField}
						onBlur={()=> updateProfile()}
						onKeyDown={(e) => {
							if (e.keyCode === ENTER_KEY) {
								updateProfile()
							}
						}}
					/>
				</div>
			</div>
			<div className="label-input-inline">
				<span className="label">{t('user.email')}</span>
				<div className="inputer">
					<ValidInput
						className={cx(validation('email'))}
						value={form.email || ""}
						name="email"
						onChange={updateField}
						onBlur={()=> updateProfile()}
						onKeyDown={(e) => {
							if (e.keyCode === ENTER_KEY) {
								updateProfile()
							}
						}}
					/>
				</div>
			</div>
			<div className="label-input-inline">
				<span className="label">{t('user.birthday')}</span>
				<div className="inputer">
					<DateInput
						name="birthday"
						selectedDate={form.birthday}
						onSelected={(date) => {
							if (date !== form.birthday) {
								setValues({
									...form,
									'birthday': date
								})
								setShouldUpdateProfile(true)
							}

						}}
					/>
				</div>
			</div>
			<div className="remark-section">
				<div className="remark-label">{t('user.remark')}</div>
				<textarea
					className="c-txt__input c-txt__input--area"
					name="remark"
					value={form.remark || ''}
					onChange={updateField}
					onBlur={()=> updateProfile()}
					onKeyDown={(e) => {
						if (e.keyCode === ENTER_KEY) {
							updateProfile()
						}
					}}
				/>
			</div>
			<div className="tags-section">
				<div className="tags-label">{t('user.userTags')}</div>
				<TagsInputer
					tags={form.tag}
					onChange={(tags, addedTags, deletedTags) => {
						setValues({
							...form,
							'tag': tags,
							'addedTags': addedTags,
							'deletedTags': deletedTags
						})
					}}
					channelId={props.channelId}
				/>

			</div>
			{props.cowellData && props.cowellData.travelGroups &&
				<>
					{props.cowellData.travelGroups.length > 0 &&
						<div className="user-actions">
							<div className="action-labels">{t('user.userActions')}</div>
							{props.cowellData.travelGroups.map((aTravel, i) => {
								if (i > 2) return null;

								return (
									<p key={`a-user-travel-${i}`}>{dayjs(aTravel.leaveDtm).fromNow()} {aTravel.name}</p>
								)
							})}

						</div>
					}

					<Link
						className="c-btn c-btn--primary see-user-detail"
						target="_blank"
						to={`/console/channel/${props.channelId}/friends/detail/${props.cowellData.PAX_CD}`}
					>{t('user.seeUserActionDetail')}</Link>
				</>
			}
		</div>
	)
}

Profile.defaultProps = {
	user: {},
	cowellData: null
}

export default Profile;
