import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom'
import cx from 'classnames'

import { checkLogined, LoginPage } from 'Login'
import Landing from './Landing'
import Console from './Console'
import CowellSSO from './CowellSSO'
import LineForm from './LineForm'
import FileDownload from './FileDownload'
import LineActivity from './LineActivity'
import LineRedirect from './LineRedirect'
import LineNotifyTutorial from './StaticPages/LineNotifyTutorial'

import 'App.css'
import 'bulma-switch/dist/css/bulma-switch.min.css'

window.menuTheme = {
	'menus.menu_view': `
		&& {
			max-width: 160px;
			min-width: 160px
		}
	`,
	'menus.item': `
		&&& {
			padding: 10px 15px;
			background-color: #ffffff;

			&:hover,&.is-hovered, &:focus, &.is-focused, &.is-active {
				background-color: #ffffff;
			    color: #359ced;
			}
		}
	`
}


window.searchFiltermenuTheme = {
	'menus.menu_view': `
		&& {
			max-width: 102px;
			min-width: 102px
		}
	`,
	'menus.item': `
		&&& {
			padding: 10px 15px;
			background-color: #ffffff;

			&:hover,&.is-hovered, &:focus, &.is-focused, &.is-active {
				background-color: #ffffff;
			    color: #359ced;
			}
		}
	`
}


function App (props) {
	const [isLogined, setLogined] = useState(!!checkLogined())

	return (
		<Router>
			<div className={cx("service-root", { 'not-mac': !(window.navigator.platform.indexOf('Mac') > -1) })}>
				<Switch>
					<Route path="/login" component={(routerProps) =>
						<LoginPage {...props} {...routerProps}  setLogined={setLogined} />} />
					<Route path="/validation" component={(routerProps) =>
						<CowellSSO {...props} {...routerProps}  setLogined={setLogined} />} />
					<Route path="/line/form/:channelId" component={(routerProps) =>
						<LineForm {...props} {...routerProps}  setLogined={setLogined} />} />
					<Route path="/line/activity" component={(routerProps) =>
						<LineActivity {...props} {...routerProps}  setLogined={setLogined} />} />
					<Route path="/line/channel/:channelId/redirect" component={(routerProps) =>
						<LineRedirect {...props} {...routerProps}  setLogined={setLogined} />} />
					<Route path="/file/:fileGUID" component={(routerProps) =>
						<FileDownload {...props} {...routerProps} />} />

					<Route path="/static/line/notify/tutorial" component={(routerProps) =>
						<LineNotifyTutorial {...props} {...routerProps} setLogined={setLogined} />} />

					<PrivateRoute
						path="/console"
						component={(routerProps) => <Console {...props} {...routerProps}  />}
						isLogined={isLogined}
					/>
					<Route path="/landing" component={(routerProps) => <Landing {...props} {...routerProps}  />} />
					<Route render={() => <Redirect to={isLogined ? '/console' : '/landing'} />} />
				</Switch>
			</div>
		</Router>
	);
}

const PrivateRoute = ({ component: Component, isLogined, ...rest }) => (
	<Route {...rest}
		render={props => {
			return isLogined ? (
				<Component {...rest} {...props} />
			) : (
				<Redirect to={{
					pathname: `/login`,
					search: `?redirectTo=${encodeURI(props.location.pathname)}${encodeURI(props.location.search)}`
					// state: { referer: props.location }
				}}/>
			)
	}}/>
)

export default App;
