import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import dayjs from 'dayjs'

const VoteActivityNotStartPage = (props) => {
	const { t } = useTranslation();
	const {activity} = props
	const startDtm = dayjs(activity.startDtm)
	const monthToDisplay = ['01','02','03','04','05','06','07','08','09','10','11','12']

	return (
		<div className="vote-not-start">
			<div className="vote-not-start-figure">
				<figure>
					<img src="https://lineline.s3.ap-northeast-2.amazonaws.com/systemFiles/vote-not-start.svg" alt=""/>
				</figure>
			</div>
			<div className="vote-not-start-text">
				<div className="vote-not-start-date">
					{t('activity.votePage.activityNotStart-1')}
					<div className="date-text">
						{monthToDisplay[startDtm.month()]}
					</div>
					{t('activity.votePage.activityNotStart-2')}
					<div className="date-text">
						{startDtm.date()}
					</div>
					{t('activity.votePage.activityNotStart-3')}
				</div>
				<div>
					{t('activity.votePage.activityNotStart-4')}
				</div>
			</div>
			<div className="button-section">
				<button className={cx("line-button close-button")}
					onClick={() => {props.onClose()}}>
					{t('activity.votePage.close')}
				</button>
			</div>
		</div>
	)
}
export default VoteActivityNotStartPage;
