import React, { useState, useEffect, useCallback } from 'react';
import qs from 'qs';

import { fetchHelper } from 'tools/FetchHelper'
import './index.css';

/**
	{redirectLiffUrl}?channelId={channelGuid}&shortenCode={shortenUrl.urlCode}
	redirect to {shortenUrl}/{shortenUrl.urlCode}?chatroomid={chatRoomGuid}
**/

const LineRedirect = (props) => {
	const match =  props.match;
	const channelId = match.params ? match.params.channelId : null
	const currentUri = window.location.href.split('#')[0]

	let urlParams = qs.parse(props.location.search, { ignoreQueryPrefix: true })
	if (urlParams["liff.state"]) {
		urlParams = qs.parse(urlParams["liff.state"], { ignoreQueryPrefix: true })
	}
	const urlCode = urlParams ? urlParams.urlCode : null

	const shortenUrl = window.CONFS.shortenUrl
	const [isFetching, setFetching] = useState(true)
	const [chatRoomData, setChatRoom] = useState(null)
	const [liffId, setLiffId] = useState(null)

	const getChatRoom = useCallback(async (profile) => {
		setFetching(true)
		try {
			const result = await fetchHelper.post(`/channel/${channelId}/liff/chatRoom`, {
				lineUserId: profile.userId,
				userName: profile.displayName,
				userAvatar: profile.pictureUrl
			});
			const res = result.data

			if (res.status === "OK") {
				setChatRoom(res.data)
				setFetching(false)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false)
			console.log('create chatroom fail', error.message)
			throw new Error('create chatroom fail' + error.message);

		}
	}, [channelId])

	const redirect = useCallback((chatRoomGuid) => {
		const url = chatRoomGuid ? `${shortenUrl}/${urlCode}?chatroomid=${chatRoomGuid}`
			: `${shortenUrl}/${urlCode}`
		// console.log(`redirect to ${url}`)
		window.location.replace(url)
	}, [shortenUrl, urlCode])

	useEffect(() => {
		if (!chatRoomData) return
		redirect(chatRoomData.chatRoomGuid)
	}, [chatRoomData, redirect])

	useEffect(() => {
		if (!liffId) return

		const getProfile = () => {
			window.liff.getProfile()
			.then(profile => {
				return getChatRoom(profile)
			})
			.catch((err) => {
			  	console.log(err.code, err.message);
				redirect()
			});
		}

		window.liff
			.init({liffId: liffId})
			.then(()=>{
				console.log(window.liff.isLoggedIn())
				if (!window.liff.isLoggedIn()) {
					window.liff.login({
						redirectUri:currentUri
					})
				}
				getProfile()
			})

	}, [liffId, getChatRoom, redirect])


	useEffect(() => {
		const getLiffId = async () => {
			try {
				const result = await fetchHelper.get(`/channel/${channelId}/liff/id?type=redirect`);
				const res = result.data
				setLiffId(res.data)
			} catch (error) {
				setLiffId("1653935656-5gPjNjzB")
				console.log('get liffId Error', error.message)
			}
		}
		getLiffId()
	}, [channelId])



	return (
		<div className="line-redirect">
			{isFetching ?
			<div className="icon-loading">
				<i className="fal fa-spinner-third fa-spin" />
			</div>
				:
			<div className="icon-redirect">
				<i className="fal fa-rocket  faa-float animated" />
			</div>
			}
		</div>
	)
}

export default LineRedirect;