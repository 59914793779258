
import Dashboard from './Dashboard'
import MessageCenter from './MessageCenter'
import Broadcast from './Broadcast'
import Activity from './Activity'
import Setting from './Setting'
import Cowell from './Cowell'
import Bot from './Bot'
import Unavailable from './Unavailable'
import TagManagement from './TagManagement'
import AutoAssignKeywordManagement from './AutoAssignKeywordManagement'
import PlatformUnset from './PlatformUnset'

// for render tab row on top of console content
const channelFuncList = {
	dashboard: {
		component: Dashboard
	},
	message: {
		component: MessageCenter
	},
	market: {
		sub: {
			broadcast: {
				component: Broadcast
			},
			activity: {
				component: Activity
			}
		}
	},
	bot: {
		component: Bot
	},
	setting: {
		component: Setting
	},
	members: {
		sub: {
			friends: {
				component: Cowell
			},
			tags: {
				component: TagManagement
			},
			autoAssignKeyword: {
				component: AutoAssignKeywordManagement
			}
		}
	},
	unSet: {
		component: PlatformUnset
	}, 
	unavailable: {
		component: Unavailable
	}
}

export default channelFuncList;
