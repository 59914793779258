import WsFactory from  './WsFactory'
import { getUser } from 'Login'

export default class {
	constructor(opts = {}) {
		this.wsUrl = window.CONFS.wsUrl
		this.wsPort = window.CONFS.wsPort
		this.isSSL = window.CONFS.wsSSL

		this.wsApiUrl = `${this.isSSL? 'https' : 'http'}://${this.wsUrl}:${this.wsPort}/v1.0`
		this.deviceId = getUser().deviceId
	}
	initWebSocket(onMessageFunc, callback) {
		let factory = this.wsFactory = new WsFactory(
			this.wsUrl,
			this.wsPort,
			this.isSSL
		);

		factory.createWebSocket();
		factory.setOnMessage(onMessageFunc, callback);
	}

	send (message, channelGuid, chatRoomGuid, event = 'MESSAGE') {
		let ws = this.wsFactory.ws;

		var data = Object.assign({
			event: event, // MESSAGE or File
			recipient: {
				channelGuid,
				chatRoomGuid
			},
			sender: {
				op: {
					picture: getUser().avatar,
					name: getUser().name
				},
				opGuid: getUser().guid
			},
			botType: 'linelineConsole',
			token: getUser().apiToken,
			data : message
		});

		if(!ws || ws.readyState !== 1){
			setTimeout(() => {
				this.send(message, channelGuid, chatRoomGuid, event)
			}, 3000)
			return data;
		}

		ws.send(JSON.stringify(data));

		return data
	}
}
