import React from 'react';

const LoadingIcon = (props) => {
    return (
        <div className="icon-loading">
            <i className="fal fa-spinner-third fa-spin" />
        </div>
    )
}

export default LoadingIcon
