import React, { useState } from 'react';
import cx from 'classnames';

import './index.css'

/**
 * display dir.name at front and dir.tag in one line with "..." or all tags
 * @property {array} dir
 * 	- name : tag的分類名稱
 * 	- guid : use in  map key
 * 	- tags : array of tags
 * 		- text
 * @property {number} indent
 * 	from 0 - 2
 * @property {function} onTagClick
 * 	excute onTagClick(tag) on tag pressed
 */
const TagDirView = ({ dir, indent = 0, onTagClick, defaultOpen = false }) => {
	const [showExtendButton, setExtendButton] = useState(!defaultOpen)
	const tagTextLength = dir.tags.reduce((pre, aTag) => pre += aTag.text.length, 0)
	return (
		<div className={cx("a-dir-row", `indent-${indent}` , { 'is-open': !showExtendButton })}>
			<div className="title-part">
				<div className="indent">
					{indent === 0 &&
						<figure>
							<img src="https://lineline.s3.amazonaws.com/systemFiles/tag-management-1dot.svg" alt=""/>
						</figure>
					}
					{indent === 1 &&
						<figure>
							<img src="https://lineline.s3.amazonaws.com/systemFiles/tag-management-2dot.svg" alt=""/>
						</figure>
					}
					{indent === 2 &&
						<i className="far fa-ellipsis-v"  />
					}
				</div>
				<div className="dir-name">{dir.name}：</div>
			</div>
			{dir.tags.map((aTag, i) => (
				<div key={`a-dir-${dir.guid || "default" }-tag-${i}`} className="a-tag" onClick={() => onTagClick(aTag)}>
					{aTag.text}
				</div>
			))}
			{!showExtendButton &&
				<div className="close-button" onClick={() => setExtendButton(true)}>
					<i className="far fa-angle-up" />
				</div>
			}

			{tagTextLength > 8 && showExtendButton &&
				<div className="button-n-blank">
					<div className="open-button" onClick={() => setExtendButton(false)}>
						<i className="far fa-ellipsis-h" />
					</div>
				</div>
			}
		</div>
	)
}

export default TagDirView