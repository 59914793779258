import React from 'react';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import zhTW from 'dayjs/locale/zh-tw'

import './index.css';

dayjs.locale('zh-tw', zhTW)
dayjs.extend(relativeTime)

const NameAndTime = (props) => {
	return (
		<div className="name-n-time">
			<div className="speaker">{props.name}</div>
			<div className="message-time">
				{dayjs(props.time).fromNow()}, {dayjs(props.time).format('YYYY/MM/DD HH:mm:ss')}
			</div>
		</div>
	);
}

export default NameAndTime;