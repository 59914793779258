import React from 'react';
import Tooltip from 'Components/Tooltip';
import dayjs from 'dayjs'
import cx from 'classnames';

import './index.css';

const ActiveSection = ({ Today, Yesterday, BeforeYesterday, ...section }) => {
	return (
		<div className="a-active-section">
			<div className="top">
				<div className="section-title">{section.title}{`（ ${dayjs().format('MM/DD')} ）`}</div>
				<Tooltip>
					<i
						className="fal fa-info-circle"
						data-tip={section.tooltip}

					/>
				</Tooltip>
			</div>

			<div className="today">
				<Today />
			</div>
			{(section.renderYesterday || section.rendreAll) &&
				<>
					<hr/>
					<div className={cx("not-today", section.sectionKey)}>
						<div className="date">{dayjs().subtract(1, 'day').format('MM/DD')}</div>
						<Yesterday />
					</div>
				</>

			}

			{(section.renderBeforeYesterday || section.rendreAll) &&
				<div className={cx("not-today", section.sectionKey)}>
					<div className="date">{dayjs().subtract(2, 'day').format('MM/DD')}</div>
					<BeforeYesterday />
				</div>
			}
		</div>
	)
}


export default ActiveSection;