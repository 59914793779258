import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import enums from 'enums'
import Modal from 'Components/Modal'
import ImageUploader from 'Components/ImageUploader'
import ValidInput from 'Components/ValidInput'
import TagsInputer from 'Components/TagsInputer'
import { checkLinkValid } from 'tools/validations'

const RichImageModal = (props) => {
	const { t } = useTranslation();
	const [errorMsg, setError] = useState('')
	const [filename, setFilename] = useState(null)
	const [startUpload, setUpload] = useState(false)
	const [form, setValues] = useState({
		title: null,
		imageUrl: null,
		altText: null,
		linkUrl: null,
		tags: [],
		...props.initialMsg
	});
	const formRef = useRef(form)

	const validation = (key, value) => {
		if (value === null) return null

		if (key === 'linkUrl') {
			return checkLinkValid(value) ? 'success' : 'error'
		}

		return !!value ? 'success' : 'error'
	}

	const isSubmitable = () => {
		const checkValueValid = Object.keys(form).map(aKey => {
			if (!['title', 'imageUrl', 'altText', 'linkUrl'].includes(aKey)) return null
			return validation(aKey, form[aKey])
		})

		return checkValueValid.filter(x => x === 'success').length === 4
	}

	const updateField = e => {
		setValues({
			...form,
			[e.target.name]: e.target.value
		});
	};

	const initialState = e => {
		setValues({
			title: null,
			imageUrl: null,
			altText: null,
			linkUrl: null,
			tags: [],
			...props.initialMsg
		})
	}

 	useEffect(() => {
		formRef.current = form
	}, [form])

	useEffect(() => { initialState() }, [props.initialMsg])

	return (
		<Modal
			className="rich-image-modal"
			isOpen={props.isModalOpen}
			cancelBGClick
			onRequestClose={() => {
				initialState()
				props.onRequestClose(false)
			}}
		>
			<div className="modal-title">{t('message.createRichImage')}</div>
			<div className="rich-img-des">{t('message.richImgDes')}</div>
			<div className="label-input-inline">
				<span className="label">{t('message.msgTitle')}<span className="must-filled">*</span></span>
				<div className="inputer">
					<ValidInput
						className={cx(validation('title', form.title))}
						value={form.title || ''}
						name="title"
						onChange={updateField}
					/>
				</div>
			</div>
			<div className="label-input-inline">
				<span className="label">{t('message.bgImg')}</span>
				<ImageUploader
					imageUrl={form.imageUrl}
					filename={filename}
					startUpload={startUpload}
					errorMsg={errorMsg}
					onClick={() => setUpload(true)}
					onUploadEventFired={() => {
						setUpload(false)
					}}
					onFileLoadStart={() => {
						setValues({
							...form,
							imageUrl: null
						})
						setError("")
					}}
					onUpLoaded={(result) => {
						setFilename(result.name)
						setValues({
							...formRef.current,
							imageUrl: result.url
						});
						setError("")
					}}
					onError={(e) => setError(e)}
					folderType={enums.FileUploadType.chatroom}
					channelId={props.channelId}
					fileUploadFrom={props.fileUploadFrom}
				/>

			</div>
			<div className="label-input-inline">
				<span className="label">{t('message.altText')}<span className="must-filled">*</span></span>
				<div className="inputer">
					<ValidInput
						className={cx(validation('altText', form.altText))}
						value={form.altText || ''}
						name="altText"
						onChange={updateField}
					/>
				</div>
			</div>
			<div className="label-input-inline">
				<span className="label">{t('message.linkUrl')}<span className="must-filled">*</span></span>
				<div className="inputer">
					<ValidInput
						className={cx(validation('linkUrl', form.linkUrl))}
						value={form.linkUrl || ''}
						name="linkUrl"
						onChange={updateField}
					/>
				</div>
			</div>
			<div className="label-input-inline">
				<span className="label">{t('message.addTags')}</span>
				<TagsInputer
					tags={form.tags}
					onChange={(tags) => setValues({
						...form,
						tags: tags
					})}
					channelId={props.channelId}
				/>
			</div>
			<hr />
			<div className="label-input-inline">
				<span className="label">{t('preview')}</span>
				<div className="preview-content">
					<div className="a-version">
						<div className="version-number">{t('message.after441')}</div>
						<figure>
							<img src="https://lineline.s3.amazonaws.com/line-tutorial-images/img.png" alt=""/>
						</figure>
					</div>
					<div className="a-version">
						<div className="version-number">{t('message.before441')}</div>
						<figure className="text-image">
							<img src="https://lineline.s3.amazonaws.com/line-tutorial-images/text.png" alt=""/>
						</figure>
					</div>
				</div>
			</div>
			<div className="save-button">
				<button
					className="c-btn c-btn--primary"
					onClick={() => {
						props.onSaved(form)
						initialState()
						props.onRequestClose()
					}}
					disabled={!isSubmitable()}
				>
					{t('save')}
				</button>
			</div>

		</Modal>
	)
}

RichImageModal.defaultProps = {
	isModalOpen: false,
	onRequestClose: () => {},
	onSaved: () => {},
	initialMsg: {}
}

export default RichImageModal