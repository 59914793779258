import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs'
import numeral from 'numeral'
import { saveAs } from 'file-saver';
import { ComposedChart, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip } from 'recharts';

import enums from 'enums'
import { fetchHelper } from 'tools/FetchHelper'
import AttendeesTable from './AttendeesTable'
import DateInput from 'Components/DateInput'
import ClickToCopyInput from 'Components/ClickToCopyInput'

import './index.css'


const CustomTooltip = ({ active, payload, label, unit, ...rest }) => {
	const { t } = useTranslation();

	if (active) {
		return (
			<div className="custom-tooltip">
				<div className="tooltip-title">{label}</div>
				{payload && payload.length > 0 && payload.map((aRow, i) => (
					<div className="a-row" style={{ color: aRow.color}} key={`custom-tooltip-data-row-${i}`}>
						{aRow.value}
						<span>{unit}</span>
					</div>
				))
				}
			</div>
		);
	}

	return null
}



const AttendeeStatistics = (props) => {
	const { t } = useTranslation();
	const {activity} = props
	const [startDate, setStartDate] = useState(dayjs(activity.startDtm))
	const [endDate, setEndDate] = useState(
		activity.expireDateType === enums.activityExpiredDateType.specificDay ?
			dayjs(activity.endDtm) :
			dayjs().isAfter(startDate) ? dayjs() : startDate
	)
	const [isFetching, setFetching] = useState(true)
	const [data, setData] = useState({
		attendeePerDay: {},
		totalAttendees: 0,
		oldMembers: 0,
		newMembers: 0,
		scanQRCode: 0,
		fireByQA: 0,
		failToJoin: 0,
		voteResult: {}
	})

	const getStatistics = async () => {
		setFetching(true);
		try {
			const result = await fetchHelper.get(`/activity/${activity.guid}/statistics`, {
				params: {
					startDtm: startDate.format('YYYY-MM-DD'),
					endDtm: endDate.format('YYYY-MM-DD')
				},
				fetchQAbot: true
			});
			const res = result.data
			if (res.status === "OK") {
				setData(res.data)
				setFetching(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false);
			console.log('fecth statistics fail', error.message)
		}
	}

	useEffect(() => {
		getStatistics()
	}, [startDate, endDate])

	const getVoteResultYaxisWidth = (data) => {
		// accept data: { key: count }
		const optionValueMaxLength = Math.max(...Object.keys(data).map((aOption) => {return aOption.length })) * 15
		return Math.max(Math.min(optionValueMaxLength, 100), 60)
	}

	const generateVoteResult = () => {
		if (!data.voteResult || !data.voteResult.result) return {}

		let voteResult = {}
		for (var i = 0; i < activity.voteSetting.options.length; ++i)
			voteResult[activity.voteSetting.options[i].optionValue] = 0
		return {
			...voteResult,
			...data.voteResult.result
		}
	}

	const voteResult = generateVoteResult()
	const voteBarChartData = Object.keys(voteResult).map(aOption => ({
			optionValue: aOption,
			count: voteResult[aOption]
		}))

	return (
		<div className="activity-statistics">
			<div className="sub-title">{t('activity.downloadQRcode')}</div>
			<div className="qrcode-download">
				<figure>
					<img src={activity.imageUrl} alt=""/>
				</figure>
				<div className="qrcode-info-n-button">
					<div className="qrcode-info-url">
						<div className="qrcode-info-url-input-title">{t('activity.linkUrl')}</div>
						<div className="qrcode-info-url-input">
							<ClickToCopyInput
								value={activity.url}
							/>
						</div>
					</div>
					<div className="qrcode-info">
						{t('activity.qrcodeInfo')}
					</div>
					<button className="c-btn c-btn--primary" onClick={() => saveAs(activity.imageUrl, `${activity.name}.png`)}
					>
						{t('download')}
					</button>
				</div>
			</div>
			<div className="title-n-date">
				<div className="sub-title">{t('activity.statistic')}</div>
				<div className="select-statistic-date">
					<span>{t('activity.betweenActivity')}</span>
					<DateInput
						name="startDate"
						selectedDate={startDate.format('YYYY-MM-DD') || null}
						onSelected={(date) => setStartDate(dayjs(date))}
					/>
					<span>-</span>
					<DateInput
						name="endDate"
						selectedDate={endDate.format('YYYY-MM-DD')  || null}
						selectPeriod={startDate ? {
							periodDateStart: startDate
						} : null}
						onSelected={(date) => setEndDate(dayjs(date))}
					/>
				</div>
			</div>
			<div className="statistics-part">
				{isFetching ?
					<div className="icon-loading">
						<i className="fal fa-spinner-third fa-spin" />
					</div> :
					<>
						{activity.type === enums.ActivityType.vote  &&
						<div className="a-overview-block">
							<div className="block-head hide-border">
								{t('activity.voteResult')}
							</div>
							<div className="block-content record-block">
								<BarChart
									width={850}
									height={400}
									data={voteBarChartData}
									layout={'vertical'}
								>
									<CartesianGrid horizontal={false}/>
									<YAxis dataKey="optionValue" type="category"
										width={getVoteResultYaxisWidth(voteResult)}/>
									<XAxis type="number"/>
									<ChartTooltip content={<CustomTooltip unit={t('activity.voteUnit')}/>} />
									<Bar dataKey="count" barSize={22} fill="#f7d767"/>
								</BarChart>
								<div className="axis-illustration">
									<div className="attendees">
										<div className="dot" />
										{t('activity.voteResultIllustration')}
									</div>
								</div>
							</div>
						</div>
						}
						<div className="a-overview-block">
							<div className="block-head hide-border">
								{t('activity.peoplePerDay')}
								<span>（{t('activity.peoplePerDayChartDes')}）</span>
							</div>
							<div className="block-content record-block">
								<div className="axis-title">
									<span> </span>
									<span>{t('dashboard.conversations')}</span>
								</div>
								<ComposedChart
									width={850}
									height={400}
									data={Object.keys(data.attendeePerDay).map(aDay => ({
										date: dayjs(aDay).format('M/D'),
										peoplePerDay: data.attendeePerDay[aDay]
									})).reverse()}
									margin={{ top: 20, right: -15, bottom: 0, left: -25 }}
								>
									<XAxis
										dataKey="date"
										tickLine={false}
										reversed={true}
									/>
									<YAxis
										yAxisId="right"
										orientation="right"
										stroke="#aeb1b8"
										tickLine={false}
										allowDecimals={false}
									/>
									<ChartTooltip content={<CustomTooltip unit={t(`unit.people`)}/>} />
									<CartesianGrid vertical={false} />
									<Bar yAxisId="right" dataKey="peoplePerDay" barSize={22} fill="#f7d767" />
								</ComposedChart>
								<div className="axis-illustration">
									<div className="attendees">
										<div className="dot" />
										{t('activity.peoplePerDay')}
									</div>
								</div>
							</div>
						</div>
						<div className="a-overview-block">
							<div className="block-head">
								{t('activity.numberOfAttendees')}
							</div>
							<div className="block-content">
								<div className="a-section">
									<div className="section-title">{t('activity.total')}</div>
									<div className="section-content">
										<div className="data-number-unit">
											<span>{numeral(data.totalAttendees).format('0,0')}</span>
											{t('unit.people')}
										</div>
									</div>
								</div>
								<div className="a-section">
									<div className="section-title">{t('activity.oldMember')}</div>
									<div className="section-content">
										<div className="data-number-unit">
											<span>{numeral(data.oldMembers).format('0,0')}</span>
											{t('unit.people')}
										</div>
									</div>
								</div>
								<div className="a-section">
									<div className="section-title">{t('activity.newMember')}</div>
									<div className="section-content">
										<div className="data-number-unit">
											<span>{numeral(data.newMembers).format('0,0')}</span>
											{t('unit.people')}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="a-overview-block">
							<div className="block-head">
								{t('activity.participateWays')}
							</div>
							<div className="block-content">
								<div className="a-section">
									<div className="section-title">{t('activity.scanQRCode')}</div>
									<div className="section-content">
										<div className="data-number-unit">
											<span>{numeral(data.scanQRCode).format('0,0')}</span>
											{t('unit.people')}
										</div>
									</div>
								</div>
								<div className="a-section">
									<div className="section-title">{t('activity.keywords')}</div>
									<div className="section-content">
										<div className="data-number-unit">
											<span>{numeral(data.fireByQA).format('0,0')}</span>
											{t('unit.people')}
										</div>
									</div>
								</div>

								{activity.type !== enums.ActivityType.vote  &&
								<div className="a-section">
									<div className="section-title">{t('activity.scanButNotJoin')}</div>
									<div className="section-content">
										<div className="data-number-unit">
											<span>{numeral(data.failToJoin).format('0,0')}</span>
											{t('unit.people')}
										</div>
									</div>
								</div>
								}
							</div>
						</div>
						<AttendeesTable
							startDate={startDate}
							endDate={endDate}
							{...props}
						/>
					</>
				}
			</div>
		</div>
	)
}

AttendeeStatistics.defaultProps = {
	activity: {}
}

export default AttendeeStatistics
