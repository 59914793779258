import React, { useState, useEffect } from 'react';
import AutosizeInput from 'react-input-autosize';
import cx from 'classnames'
import MessageModal from 'Components/MessageModal';

import './index.css'
const ENTER_KEY = 13;


/**
 * 一個可以編輯的Tag(包含tooltip與刪除的icon)
 * 以及在刪除/變更名稱前可以去呼叫的function
 * 還有以上兩隻function
 * @property {function} checkUpdate an async function return valid and error message
 *      #### return: use for show modifyModal
 *          - title: stiring,
 *          - des: stiring,
 *          - showCancelBtn: bool
 *          - cancelBtnText: string
 *          - showActionBtn: bool
 *          - actionBtnText: string
 *          - directlyExcute: bool, excute onUpdate() immediately if got true
 * @property {function} onUpdate
 *      - excute onUpdate(newVal) on
 *      1. call checkUpdate() and click confirm btn in modal after blur from inputer
 *      2. call after blur from inputer, if checkUpdate == null
 * @property {function} checkDelete an async function return valid and error message
 *      - same as checkUpdate
 * @property {function} onDelete
 *      - same as onUpdate, but excute onDelete()
 * @property {function} onClick
 * @property {function} pressEditIcon excute on press edit icon
 * @property {string} text
 * @property {string} placeholder
 * @property {string} className
 * @property {bool} displayIcon display edit/confirm/delete icon
 * @property {string} mode "view": display tag text, "edit": display input
 * @property {string} iconType "edit" "confirm" "delete"
 */
const EditableTag = (props) =>{
    const { text, placeholder, className, displayIcon, mode, iconType } = props
    const {checkUpdate, onUpdate, checkDelete, onDelete, onClick, pressEditIcon} = props

    const [tagText , setTagText] = useState(text)
	const [deleteModal, setDeleteModal] = useState()
    const [modifyModal, setModifyModal] = useState()

    const setModifyModalOpen = (newVal) => {
        setModifyModal({
            ...modifyModal,
            "isModalOpen": newVal
        })
    }
    const setDeleteModalOpen = (newVal) => {
        setDeleteModal({
            ...deleteModal,
            "isModalOpen": newVal
        })
    }

    const onTagInputChange = (e) => {
        setTagText(e.target.value)
    }

    const pressDeleteIcon = async (e) => {
        e.stopPropagation()

        if (!!checkDelete) {
            const deleteModalData = await checkDelete()
            if (deleteModalData.directlyExcute) {
                onDelete()
            }
            else {
                setDeleteModal({
                    ...deleteModalData,
                    "isModalOpen" : true
                })
            }
        }

    }
    const pressConfirmIcon = (e) => {
        e.stopPropagation()
        onConfirm()
    }
    const onConfirm = async () => {
        if (tagText === text) return

        if (!!checkUpdate) {
            const updateModalData = await checkUpdate(tagText)
            if (updateModalData.directlyExcute) {
                onUpdate(tagText)
            }
            else {
                setModifyModal({
                    ...updateModalData,
                    "isModalOpen" : true
                })
            }
        }
        else {
            onUpdate(tagText)
        }
    }


    const pressCancelInModifyModal = () => {
        setModifyModalOpen(false)
        setTagText(text)
    }


    const pressCancelInDeleteModal = () => {
        setDeleteModalOpen(false)
    }
    const pressDeleteInDeleteModal = () => {
        onDelete()
        setDeleteModalOpen(false)
    }

    useEffect(()=>{
        setTagText(text)
    }, [props.mode, props.text])

    return (
        <div className={cx("tag-n-tooltip", className)} onClick={onClick}>
            { mode === "edit" ?
                <AutosizeInput
                    type="text"
                    placeholder={placeholder}
                    value={tagText}
                    onChange={(e) => {onTagInputChange(e)}}
                    onClick={(e)=>{e.stopPropagation()}}
                    onKeyDown={(e) => {
                        if (e.keyCode === ENTER_KEY && tagText !== text) onConfirm()
                    }}
                /> :
                <span>{tagText}</span>
            }
            {displayIcon && iconType === "edit" &&
				<i className="fal fa-pen" onClick={(e) => {
                    e.stopPropagation()
                    pressEditIcon()}} />
            }
            {displayIcon && iconType === "confirm" &&
                <i
                    className={cx("fal fa-check", {'is-disabled': tagText === text})}
                    onClick={(e)=>{pressConfirmIcon(e)}}
                />
            }
            {displayIcon && iconType === "delete" &&
				<i className="fal fa-times" onClick={(e) => {pressDeleteIcon(e)}} />
            }
            <MessageModal // only appeare when not allow
                {...modifyModal}
                className={"update-modal"}
                onCancel={pressCancelInModifyModal}
            />
            <MessageModal
                {...deleteModal}
                className={"delete-modal"}
                onCancel={pressCancelInDeleteModal}
                onExcute={pressDeleteInDeleteModal}
            />
        </div>
    )

}
EditableTag.defaultPropd = {
    "text" : "",
    "placeholder" : ""
}

export default EditableTag