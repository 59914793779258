import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import cx from 'classnames'

import ChatPreviewBlock from 'Components/ChatPreviewBlock'
import { fetchHelper } from 'tools/FetchHelper'

import './index.css'

const Default = (props) => {
	const { t } = useTranslation();
	const [text, setText] = useState(null)
	const [isFetching, setFetching] = useState(false)
	const [isUpdating, setUpdating] = useState(false)

	const updateBotDefault = async () => {
		if (!text) return;
		setUpdating(true)
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/bot/default`, {
				replyText: text
			}, {
				fetchQAbot: true
			});
			const res = result.data

			if (res.status === "OK") {
				setUpdating(false)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setUpdating(false)
			console.log('update bot default fail', error.message)
		}
	}

	useEffect(() => {
		const fetchBotDefault = async () => {
			setFetching(true)
			try {
				const result = await fetchHelper.get(`/channel/${props.channelId}/bot/default`, {
					fetchQAbot: true
				});
				const res = result.data

				if (res.status === "OK") {
					setText(res.data.replyText)
					setFetching(false);
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				setFetching(false);
				console.log('get bot default fail', error.message)
			}
		}


		fetchBotDefault()
	}, [props.channelId])

	return (
		<div className="default-page">
			<div className="page-title">
				<Link to={`/console/channel/${props.channelId}/bot`}>{t('bot.title')}</Link>
				<i className="far fa-chevron-right" />
				{t('bot.default')}
			</div>
			<div className="page-des">{t('bot.defaultDes')}</div>
			<div className="bot-setting-content">
				<div className="setting-part">
					{isFetching ?
						<div className="icon-loading">
							<i className="fal fa-spinner-third fa-spin" />
						</div> :
						<>
							<textarea
								className={cx("c-txt__input c-txt__input--area", { 'is-error': !text })}
								value={text || ""}
								onChange={(e) => setText(e.target.value)}
								{...(!text && {
									placeholder: t('bot.noDefault')
								})}
							/>
							<div className="go-to-one-to-one">
								{t('bot.setOneToOne1')}
								<Link to={`/console/channel/${props.channelId}/bot/oneToOne`}>{t('bot.oneToOne')}</Link>
								{t('bot.setOneToOne2')}
							</div>
							<div className="button-group">
								<button className="c-btn">{t('cancel')}</button>
								<button className={cx("c-btn c-btn--primary", { 'is-disabled': !text })}
									onClick={() => updateBotDefault()}
								>
									{isUpdating ?
										<i className="fal fa-spinner-third fa-spin" /> :
										t('save')
									}
								</button>
							</div>
						</>
					}
				</div>
				<div className="preview-part">
					<div className="sub-title">{t('preview')}</div>
					<ChatPreviewBlock
						{...props}
						messages={text ? [{
							type: 'template',
							altText: text,
							templateType: "buttons",
							title: text,
							text: text,
							"actions" : [{
								"type": "message",
								"label": t('bot.openOneToOneConversation'),
								"text": t('bot.openOneToOneConversation')
							}]
						}] : []}
						sender={{
							type: 'bot'
						}}
					/>
				</div>
			</div>
			<div className="button-group">
				<button className="c-btn">{t('cancel')}</button>
				<button className={cx("c-btn c-btn--primary", { 'is-disabled': !text })}
					onClick={() => updateBotDefault()}>
					{isUpdating ?
						<i className="fal fa-spinner-third fa-spin" /> :
						t('save')
					}
				</button>
			</div>
		</div>
	)
}

export default Default;