import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import cx from 'classnames';

import Counter from 'Components/Counter'
import CheckboxInput from 'Components/CheckboxInput'
import UpdateMessage from 'Components/UpdateMessage'
import ValidInput from 'Components/ValidInput'
import TooltipFixPosition from 'Components/TooltipFixPosition'
import BulmaSwitch from 'react-bulma-switch'

/**
 * 未回覆訊息通知設定區塊
 * @property {bool} isUpdating
 * @property {obj} settings response of get(`/channel/${props.channelId}`).notifySetting.unreplyMessage
 * @property {func} updateSetting
 */
const UnreplyMessageNotifySetting = (props) => {
	const { t } = useTranslation();
	const { isUpdating, settings, updateSetting } = props
	const defaultSetting = {
		"active" : settings.active || false,
		"token" : settings.token || "",
		"sendToInChargeOp" : settings.sendToInChargeOp || false,
		"fromHour" : (settings.activeTime && settings.activeTime.timeOfDay && settings.activeTime.timeOfDay.from)
			? Math.floor(settings.activeTime.timeOfDay.from / 3600) : 0,
		"fromMin" : (settings.activeTime && settings.activeTime.timeOfDay)
			? Math.floor((settings.activeTime.timeOfDay.from % 3600) / 60) : 0,
		"toHour" : (settings.activeTime && settings.activeTime.timeOfDay && settings.activeTime.timeOfDay.to)
			? Math.floor(settings.activeTime.timeOfDay.to / 3600) : 0,
		"toMin" : (settings.activeTime && settings.activeTime.timeOfDay)
			? Math.floor((settings.activeTime.timeOfDay.to % 3600) / 60) : 0,
		"activeWeekday" : settings.activeTime ? settings.activeTime.dayOfWeek || [] : []
	}
	const [ formVal, setFormVal ] = useState(defaultSetting)

	const updateFormVal = ({key, val}) => {
		setFormVal({
			...formVal,
			[key]: val
		})
		setFormChange(true)
	}

	/**
	 * transform formVal.fromHour, formVal.fromMin to postbody.activeTime.timeOfDay.from
	 * transform formVal.toMin, to postbody.activeTime.timeOfDay.to
	 * transform formVal.activeWeekday, to postbody.activeTime.dayOfWeek
	 */
	const getPostBody = () => {
		return {
			active: formVal.active,
			token: formVal.token,
			sendToInChargeOp: formVal.sendToInChargeOp,
			activeTime: {
				timeOfDay: {
					from: formVal.fromHour * 3600 + formVal.fromMin * 60,
					to: formVal.toHour * 3600 + formVal.toMin * 60,
				},
				dayOfWeek: formVal.activeWeekday
			}
		}

	}

	const [ isFormChange, setFormChange ] = useState(false)

	const onPressSave = () => {
		updateSetting({
			postBody: getPostBody(),
			callback: () => {
				setFormChange(false)
			}
		})
	}
	const onPressCancel = () => {
		setFormChange(false)
		setFormVal(defaultSetting)
	}
	return (
		<div className="a-block unreply-message-notify">
			<div className="title">
				<div className="sub-title">{t('setting.notify.unreplyMessageNotify')}</div>

				<BulmaSwitch
					value={formVal.active === true}
					onChange={(e)=>{
						updateFormVal({
							key: "active",
							val: !formVal.active
						})
					}}>
					{formVal.active ? t('bot.isActive') : t('bot.isClose')}
				</BulmaSwitch>
			</div>
			<div className="sub-des">{t('setting.notify.unreplyMessageNotifyDes')}</div>

			<div className="form-fields" >
				<div className="inputer-title-token sub-sub-title">{t('setting.notify.lineToken')}
					<TooltipFixPosition>
						<div className="tooltip-link">
							<Link to={`/static/line/notify/tutorial`} target="_blank">
								{t('setting.header.toTutorial')}
							</Link>
						</div>
					</TooltipFixPosition>
				</div>
				<div className="inputer-token">
					<ValidInput
						value={formVal.token}
						placeholder={t('setting.notify.lineTokenPlaceHolder')}
						onChange={(e) => {
							updateFormVal({key: "token", val: e.target.value})
						}}
					/>
				</div>
				<div className="checkbox-send-to-op">
					<CheckboxInput
						onChange={(e) => {
							updateFormVal({
								key: "sendToInChargeOp",
								val: !formVal.sendToInChargeOp
							})
						}}
						isChecked={formVal.sendToInChargeOp === true}
						isDisabled={isUpdating}
						labelText={t('setting.notify.sendToInChargeOp')}
					/>
				</div>
				<div className="active-time">
					<div className="sub-sub-title">{t('setting.notify.activeTime')}</div>
					<div className="sub-sub-des">{t('setting.notify.activeTimeDes')}</div>

					<div className="inputer-time">
						<Counter
							max={23}
							value={formVal.fromHour}
							onChange={(hour) => {
								updateFormVal({
									key: "fromHour",
									val: hour
								})
							}}
						/>
						<div className="seperater">:</div>
						<Counter
							max={59}
							value={formVal.fromMin}
							onChange={(minute) => {
								updateFormVal({
									key: "fromMin",
									val: minute
								})
							}}
						/>
						<div className="range-text">{t('setting.notify.to')}</div>

						<Counter
							max={23}
							value={formVal.toHour}
							onChange={(hour) => {
								updateFormVal({
									key: "toHour",
									val: hour
								})
							}}
						/>
						<div className="seperater">:</div>
						<Counter
							max={59}
							value={formVal.toMin}
							onChange={(minute) => {
								updateFormVal({
									key: "toMin",
									val: minute
								})
							}}
						/>
					</div>
					<UnreplyMessageNotifySettingActiveWeekDays
						isUpdating={isUpdating}
						activeWeekday={formVal.activeWeekday}
						updateActiveWeekdays={(newVal) => {
							updateFormVal({
								key: "activeWeekday",
								val: newVal
							})
						}}/>
				</div>
			</div>

			<div className="btn-group">
				<button
					className="c-btn c-btn--primary"
					onClick={onPressSave}>
					{t('save')}
				</button>
				<button
					className="c-btn "
					onClick={onPressCancel}
					disabled={!isFormChange} >
					{t('cancel')}
				</button>
				<UpdateMessage isUpdating={isUpdating}/>
			</div>

		</div>
	)
}

/**
 * active weekday part
 * @property {bool} isUpdating
 * @property {array} activeWeekday array of int (0 : monday, 6: sunday)
 * @property {func} updateActiveWeekdays
 */
const UnreplyMessageNotifySettingActiveWeekDays = (props) => {
	const { t } = useTranslation();
	const weekdayList = {
		"monday": 0,
		"tuesday": 1,
		"wednesday": 2,
		"thursday": 3,
		"friday": 4,
		"saturday": 5,
		"sunda": 6,
	}
	const { activeWeekday, updateActiveWeekdays, isUpdating } = props
	return (
		<div className="checkbox-weekdays">
		{Object.keys(weekdayList).map((key, i)=> {
			return (
				<CheckboxInput
					key={`checkbox-weekdays-${i}`}
					className={"checkbox"}
					onChange={(e) => {
						var newVal = activeWeekday.slice()
						var currentValueIndex = newVal.indexOf(weekdayList[key])
						if (currentValueIndex > -1) {
							newVal.splice(currentValueIndex, 1)
						}
						else {
							newVal.push(weekdayList[key])
						}
						updateActiveWeekdays(newVal)
					}}
					isChecked={activeWeekday.includes(weekdayList[key])}
					isDisabled={isUpdating}
					labelText={t(`weekday.${key}`)}
				/>
			)
		})}
	</div>
	)
}

UnreplyMessageNotifySetting.propTypes = {
    isUpdating: PropTypes.bool,
    settings: PropTypes.shape({
        active: PropTypes.bool,
        token: PropTypes.string,
        sendToInChargeOp: PropTypes.bool,
        activeTime: PropTypes.shape({
            dayOfWeek: PropTypes.arrayOf(PropTypes.number),
            timeOfDay: PropTypes.shape({
                from: PropTypes.number,
                to: PropTypes.number
            })
        }),
    }),
    updateSetting: PropTypes.func
}
export default UnreplyMessageNotifySetting
