import React, { useState } from 'react';
import cx from 'classnames';

import './index.css';

/*
 * render a input with icons
 * a pen icon for trigger edit mode, a check icon for confirm and times icon for cancel
 * @param initValue: input value restore to init value on cancel
 * @param updateValue: function for update initValue
 * @param isFetching: block confirm or cancel onClick while isFetching
 */

const ClickNEditInput = (props) => {
	const {initValue, updateValue, isFetching} = props
	const [isEditing, setEditing] = useState(false)
	const [value, setValue] = useState(initValue)

	return (
		<div className={cx("input-with-icons", props.className, {"is-disabled": !isEditing})}>
			<input
				className={cx("c-txt__input", {"is-editing" : isEditing})}
				value={value}
				onChange={(e) => setValue(e.target.value)}
				disabled={!isEditing}
			/>
			{isEditing?
				<>
					<i className="confirm-icon fa fa-check" onClick={() => {
						if (isFetching) return
						updateValue(value)
						setEditing(false)}} />
					<i className="confirm-icon fal fa-times" onClick={() => {
						if (isFetching) return
						setValue(initValue)
						setEditing(false)
					}} />
				</>
				:
				<i className="fa fa-pen display-on-hover" onClick={() => {setEditing(true)}} />
			}
		</div>
	)
}
export default ClickNEditInput;

