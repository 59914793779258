import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import { Link } from 'react-router-dom'
import MessageModal from 'Components/MessageModal';
import LoadingIcon from 'Components/LoadingIcon';
import { fetchHelper } from 'tools/FetchHelper'
import { groupBy } from 'tools/dataHelper'

import EditableTag from './EditableTag'

import './index.css'
const ENTER_KEY = 13;

/**
 *  @property {int} level 0, 1, 2
 *  @property {element} children
 *  @property {object} selectTag current selected tag
 *  @property {function} addKeyword func(level, parent, text)
 *  @property {bool} isAddingKeyword is fetching api now
 *  @property {function} checkValid
 *      params: tagText
 *      return: use for show modifyModal
 *          - title: stiring,
 *          - des: stiring,
 *          - showCancelBtn: bool
 *          - cancelBtnText: string
 *          - showActionBtn: bool
 *          - actionBtnText: string
 *          - directlyExcute: bool, excute onUpdate() immediately if got true
 */
const AKeywordSection = (props) => {
    const { t } = useTranslation();
    const { level, children} = props
    const { selectTag, addKeyword, isAddingKeyword, checkValid } = props
    const [ newKeywordText, setNewKeywordText ] = useState("")
    const [ notifyModal, setNotifyModal] = useState()

    const allowAddKeyword = level === 0 ? true :
        !!selectTag ? selectTag.level >= level - 1 : false

    let addKeywordParent = null
    if (allowAddKeyword && level >= 1) { // parent.level = level - 1
        let findParent = selectTag
        while (findParent.level > level -1) {
            findParent = findParent.parent
        }
        addKeywordParent = findParent
    }

    const onAddKeyword = async () => {
        if (isAddingKeyword) return
        if (!newKeywordText) return

        // check addKeywordInputer valid before update
        const notifyModalData = await checkValid(newKeywordText)
        if (notifyModalData.directlyExcute) {
            addKeyword(level, !!addKeywordParent ? addKeywordParent.guid : "", newKeywordText)
            setNewKeywordText("")
        }
        else {
            setNotifyModal({
                ...notifyModalData,
                "isModalOpen" : true
            })
        }
    }

    const pressCancelInModifyModal = () => {
        setNewKeywordText("")
        setNotifyModal({"isModalOpen" : false})
    }

	return (
		<div
			className={cx(
				"a-keyword-section",
				`level-${level}`
			)}
		>
			<div className="section-top">
				<div className="title-part">
					<div className="indent">
						{level === 0 &&
							<figure>
								<img src="https://lineline.s3.amazonaws.com/systemFiles/tag-management-1dot.svg" alt=""/>
							</figure>
						}
						{level === 1 &&
							<figure>
								<img src="https://lineline.s3.amazonaws.com/systemFiles/tag-management-2dot.svg" alt=""/>
							</figure>
						}
						{level === 2 &&
							<i className="far fa-ellipsis-v"  />
						}
					</div>
                    {t(`autoAssignKeyword.level.${level}`)}
				</div>
                {allowAddKeyword &&
                    <div className="add-keyword-part">
                        <input
                            className="c-txt__input"
                            placeholder={!!addKeywordParent
                                ? t("autoAssignKeyword.addKeywordUnder", {parent: addKeywordParent.text})
                                : t("autoAssignKeyword.addKeyword")}
                            type="text"
                            value={newKeywordText || ""}
                            onChange={(e) => setNewKeywordText(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.keyCode === ENTER_KEY) onAddKeyword()
                            }}
                        />
                        {!isAddingKeyword ?
                            <div className="add-button" onClick={() => {
                                onAddKeyword()
                            }}>
                                <i className="far fa-plus" />
                            </div>
                            :
                            <LoadingIcon/>
                        }

                        <MessageModal // only appeare when not allow
                            {...notifyModal}
                            className={"update-modal"}
                            onCancel={pressCancelInModifyModal}
                        />
                    </div>
                }
			</div>
			<div className="section-bottom">
				{children}
			</div>
		</div>
	)
}

/**
 * keyword part of AKeywordSection
 *
 */
const AKeywordSectionKeywordList = (props) => {
    const { t } = useTranslation();
    const { keywords, selectTag, setSelectTag, selectTagMode, setSelectTagMode } = props
    const { onClickTag, checkUpdate, updateTag, checkDelete, deleteTag} = props

    return (
        <div className="keyword-list">
            {keywords && keywords.length > 0 ? keywords.map((aTag, i) => {
                const isSelect = !!selectTag ? selectTag.guid === aTag.guid : false
                const iconType = isSelect ? selectTagMode === "view"
                        ? "edit" : "confirm"
                    : "delete"
                return (
                    <EditableTag
                        key={`recent-keyword-${i}`}
                        text={aTag.text}
                        className={cx({"is-select" : isSelect, "is-parent": !!selectTag && aTag.isParent})}
                        displayIcon={true}
                        mode={isSelect? selectTagMode: "view"}
                        iconType={iconType}
                        onClick={()=>{ onClickTag(aTag)}}
                        pressEditIcon={()=>{setSelectTagMode("edit")}}
                        onUpdate={(newVal) => {
                            updateTag(newVal, aTag)
                            setSelectTag(null)
                        }}
                        checkUpdate={(tagText)=>{ return checkUpdate(tagText)}}
                        onDelete={()=>deleteTag(aTag)}
                        checkDelete={()=>{return checkDelete(aTag.guid)}}
                    />
                )
            })
                :
                <div className="no-keyword-text">{t("autoAssignKeyword.noMatchKeyword")}</div>
            }
        </div>
    )
}


const KeywordManagement = (props) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState("")
    const [isFetching, setFetching] = useState(false)
    const [isFetchingRecent, setFetchingRecent] = useState(false)
    const [selectTag, setSelectTag] = useState(null)
    const [selectTagMode, setSelectTagMode] = useState("view")

    const [recentTagList, setRecentTagList] = useState([])
    const [levelTagList, setLevelTagList] = useState({})

    const [isAddingKeyword, setAddingKeyword] = useState(false)

    const searchKeyword = async ({keyword, parent}) => {
        setFetching(true)
        try {
            const res = await fetchHelper.get(`/channel/${props.channelId}/bot/autoAssign/keywords`, {
                params: {
                    type: "search",
                    keyword: keyword || "",
                    parent: parent || "",
                    size: 100
                },
                fetchQAbot: true
            })
            const response = res.data

            if (response.status === "OK") {
                const keywordDir = groupBy(response.data.keywords, 'level')
                setLevelTagList({
                    0: keywordDir[0],
                    1: keywordDir[1],
                    2: keywordDir[2]
                })
                setSelectTag(null)
            }
        } catch (e) {
            console.log('search keywords fail', e.message)
        } finally {
            setFetching(false)
        }
    }

    // check tagText is valid to use
    const checkValid = async (tagText) => {
        const res = await fetchHelper.post(`/channel/${props.channelId}/bot/autoAssign/keywords/valid`, {
            "text" : tagText
        }, {
            fetchQAbot: true
        })

        const response = res.data
        if (response.data.isValid) {
            return {
                "directlyExcute" : true
            }
        }
        return {
            "title" : t('autoAssignKeyword.noDuplicate', {tagText:tagText}),
            "showCancelBtn" : true,
            "cancelBtnText" : t('understand')
        }
    }

    const updateTag = async (tagText, originTag) => {
        // fetchApi
        try {
            const res = await fetchHelper.post(`/channel/${props.channelId}/bot/autoAssign/keywords/${originTag.guid}`, {
                "text" : tagText
            }, {
                fetchQAbot: true
            })
            const response = res.data
			if (response.status !== "OK") {
                throw new Error(response.errorMessage)
            }
        } catch (e) {
            console.log('search keywords fail', e.message)
            return
        }

        // update data in local
        const existInRecent = recentTagList.some(x=> x.guid === originTag.guid)
        if (existInRecent) {
            let newRecentTagList = recentTagList.slice()
            let updateTag = newRecentTagList.find(x=> x.guid === originTag.guid)
            updateTag.text = tagText
            setRecentTagList(newRecentTagList)
        }

        let newLevelTagList = levelTagList[originTag.level].slice()
        let updateTag = newLevelTagList.find(x=> x.guid === originTag.guid)
        updateTag.text = tagText
        setLevelTagList({
            ...levelTagList,
            [originTag.level] : newLevelTagList
        })
    }

    const checkDelete = async (guid) => {
        const res = await fetchHelper.get(`/channel/${props.channelId}/bot/autoAssign/keywords/${guid}`, {
            fetchQAbot: true
        })
        const response = res.data
        return {
            "title" : t("autoAssignKeyword.confirmDelete", {
                useOperatorCount: response.data.useOperatorCount,
                childCount: response.data.childCount
            }),
            "showCancelBtn" : true,
            "cancelBtnText" : t(`cancel`),
            "showActionBtn" : true,
            "actionBtnText" : t(`delete`)
        }
    }
    const deleteTag = async (aTag) => {
        // fetch api
        try {
            const res = await fetchHelper.delete(`/channel/${props.channelId}/bot/autoAssign/keywords/${aTag.guid}`, {}, {
                fetchQAbot: true
            })
            const response = res.data
            if (response.status !== "OK") {
                throw new Error(response.errorMessage)
            }
        } catch (e) {
            console.log('fetch recent tag fail', e.message)
            return
        }

        // update comppnent state
        let copyCurrentLevel = (levelTagList[aTag.level] || []).filter((item) => item.guid !== aTag.guid)
        setLevelTagList({
            ...levelTagList,
            [aTag.level]: copyCurrentLevel
        })

        setRecentTagList(recentTagList.filter((item) => item.guid !== aTag.guid))
    }

    const onClickTag = async (tag) => {
        if (!!selectTag && selectTag.guid === tag.guid) {
            setSelectTag(null)
            return
        }
        // TODO 直接點擊搜尋結果和在已經有選擇的Tag的情況下的點擊應該動作是不同的（不用重新搜尋上層之類的
        // 點擊關鍵字之後的規則：
        // 上層：包含目前關鍵字的上層及20個“最近使用”的關鍵字
        // 同層：目前關鍵字的上層及20個“最近使用”的關鍵字
        // 下層：目前關鍵字的子層所有關鍵字
        // 再往下：空陣列
        setFetching(true)
        try {
            const res = await fetchHelper.get(`/channel/${props.channelId}/bot/autoAssign/keywords/${tag.guid}/detail`, {
                fetchQAbot: true
            })
            const response = res.data

            if (response.status === "OK") {
                setLevelTagList(response.data)

                setSelectTag(response.data[tag.level][0])
                setSelectTagMode("view")
            }
        } catch (e) {
            console.log('fetch recent tag fail', e.message)
        } finally {
            setFetching(false)
        }
        // clear searchValue
        setSearchValue("")
    }

    const addKeyword = async (level, parent, text) => {
        setAddingKeyword(true)
        // check text valid first

        let newKeywordGuid = null
        // fetch api
        try {
            const res = await fetchHelper.post(`/channel/${props.channelId}/bot/autoAssign/keywords`, {
                text: text,
                parent: parent
            }, {
                fetchQAbot: true
            })
            const response = res.data
			if (response.status === "OK") {
                newKeywordGuid = response.data.guid
            } else {
                throw new Error(response.errorMessage)
            }
        } catch (e) {
            console.log("create keyword fail, ", e.message)
            return
        } finally {
            setAddingKeyword(false)
        }

        // add to list
        try {
            const res = await fetchHelper.get(`/channel/${props.channelId}/bot/autoAssign/keywords/${newKeywordGuid}`, {
                fetchQAbot: true
            })
            const response = res.data
			if (response.status === "OK") {
                let copyCurrentLevel = (levelTagList[level] || []).slice()
                copyCurrentLevel.unshift(response.data)
                setLevelTagList({
                    ...levelTagList,
                    [level]: copyCurrentLevel
                })
                let copyCurrentRecent = (recentTagList || []).slice()
                copyCurrentRecent.unshift(response.data)
                setRecentTagList(copyCurrentRecent)
            } else {
                throw new Error(response.errorMessage)
            }
        } catch (e) {
            console.log("create keyword fail, ", e.message)
        }
    }

    useEffect(() => {
        const getRecentTagList = async () => {
            setFetchingRecent(true)
            try {
                const res = await fetchHelper.get(`/channel/${props.channelId}/bot/autoAssign/keywords`, {
                    params: {
                        page: 1,
                        size: 20
                    },
                    fetchQAbot: true
                })
                const response = res.data

                if (response.status === "OK") {
                    setRecentTagList(response.data.keywords)
                }
            } catch (e) {
                console.log('fetch recent tag fail', e.message)
            } finally {
                setFetchingRecent(false)
            }
        }
        getRecentTagList()
        // search for level 0
        searchKeyword({parent: "null"})
    }, [])

    return (
		<div className="keyword-management">
            <div className="page-title">
                {t('channel.members')}
                <i className="far fa-chevron-right" />
                {t('autoAssignKeyword.title')}
            </div>
            <div className="page-des">
                {t('autoAssignKeyword.des')}
            </div>

            <div className="search-section">
				<div className={cx("search-input c-txt__input c-txt__input--media", { valid: !!searchValue })}>
					<input
						className="c-txt__input c-txt__input--bare c-txt__input--media__body"
						placeholder={t('autoAssignKeyword.search')}
						type="text"
						value={searchValue || ""}
                        onChange={(e) => setSearchValue(e.target.value)}
						onKeyDown={(e) => {
							if (e.keyCode === ENTER_KEY && !isFetching && !!searchValue) {
                                searchKeyword({keyword: searchValue})
							}
						}}
                        />
					<div className="c-txt__input--media__figure">
						<i className="far fa-search" onClick={() => {
                            if (!isFetching && !!searchValue) {
                                searchKeyword({keyword: searchValue})
							}
						}}/>
					</div>
				</div>
				<Link
					className="view-all-link"
                    to={`${props.match.url}/all`}
                    target="_blank"
				>
					{t('autoAssignKeyword.viewAllKeyword')}
				</Link>
            </div>
            <div className="keyword-section">
                <div className="recent-keyword">
                    <div className="recent-keyword-title">{t('autoAssignKeyword.recent20')}</div>
                    { isFetchingRecent && <LoadingIcon/> }
                    { !isFetchingRecent && recentTagList &&
                        <div className="recent-keyword-list">
                            {recentTagList.map((aTag, i) => {
                                const isSelect = !!selectTag ? selectTag.guid === aTag.guid : false
                                return (
                                    <EditableTag
                                        key={`recent-keyword-${i}`}
                                        text={aTag.text}
                                        className={cx({"is-select" : isSelect})}
                                        displayIcon={false}
                                        onClick={()=>{ onClickTag(aTag)}}
                                    />
                                )
                            })}
                        </div>
                    }


                </div>
                { isFetching && <LoadingIcon/> }
                { !isFetching && !!levelTagList &&
                    <div className="level-keyword">
                        <AKeywordSection
                            level={0} selectTag={selectTag}
                            addKeyword={addKeyword} isAddingKeyword={isAddingKeyword} checkValid={checkValid}
                        >
                            <AKeywordSectionKeywordList
                                keywords={levelTagList[0]}
                                {...{selectTag, setSelectTag, selectTagMode, setSelectTagMode}}
                                {...{onClickTag, checkUpdate: checkValid, updateTag, checkDelete, deleteTag}}
                            />
                        </AKeywordSection>
                        <AKeywordSection
                            level={1} selectTag={selectTag}
                            addKeyword={addKeyword} isAddingKeyword={isAddingKeyword} checkValid={checkValid}
                        >
                            <AKeywordSectionKeywordList
                                keywords={levelTagList[1]}
                                {...{selectTag, setSelectTag, selectTagMode, setSelectTagMode}}
                                {...{onClickTag, checkUpdate: checkValid, updateTag, checkDelete, deleteTag}}
                            />
                        </AKeywordSection>
                        <AKeywordSection
                            level={2} selectTag={selectTag}
                            addKeyword={addKeyword} isAddingKeyword={isAddingKeyword} checkValid={checkValid}
                        >
                            <AKeywordSectionKeywordList
                                keywords={levelTagList[2]}
                                {...{selectTag, setSelectTag, selectTagMode, setSelectTagMode}}
                                {...{onClickTag, checkUpdate: checkValid, updateTag, checkDelete, deleteTag}}
                            />
                        </AKeywordSection>
                    </div>
                }
            </div>

        </div>
    )
}
export default KeywordManagement