import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchHelper } from 'tools/FetchHelper'
import LoadingIcon from 'Components/LoadingIcon';

import './index.css'

const Level1KeywordSection = (props) => {
    const { keywords } = props // array

    return (
        <div className="a-keyword-section level-1">
            <div className="title">
                <div className="indent">
                    <figure>
                        <img src="https://lineline.s3.amazonaws.com/systemFiles/tag-management-2dot.svg" alt=""/>
                    </figure>
                </div>
                <div className="tag-section">
                    { keywords.map((keyword, i) =>{
                        return (
                            <div key={`keyword-${keyword.guid}`} className='tag'>
                                {keyword.text}
                            </div>
                        )
                    })
                    }
                </div>
            </div>
            { !!keywords[0].child &&
                <div className="sub-keyword-section level-2">
                    <div className="indent">
                        <i className="far fa-ellipsis-v"  />
                    </div>
                    <div className="tag-section">
                        {keywords[0].child.map((keyword, i)=> {
                            return (
                                <div key={`keyword-${keyword.guid}`} className='tag'>
                                    {keyword.text}
                                </div>
                            )
                        })}
                    </div>
                </div>
            }

        </div>
    )

}

const Level0KeywordSection = (props) => {
    const { keyword } = props
    // find all level 1 without child

    const isAnyChildWithoutChild = (keyword.child || []).some((childKey)=>!childKey.child)


    return (
        <div className="a-keyword">
            <div className="a-keyword-section level-0">
                <div className="title">
                    <div className="indent">
                        <figure>
                            <img src="https://lineline.s3.amazonaws.com/systemFiles/tag-management-1dot.svg" alt=""/>
                        </figure>
                    </div>
                    <div className="tag-section">
                        <div className='tag'>{keyword.text}</div>
                    </div>
                </div>
            </div>
            { isAnyChildWithoutChild &&
               <Level1KeywordSection keywords={(keyword.child || []).filter((childKey)=> !childKey.child)}/>
            }
            { (keyword.child || [])
                .filter((childKey)=> !!childKey.child)
                .map((aKeyword, i)=>{
                    return(
                        <Level1KeywordSection key={`key-${keyword.guid}-child-${i}`} keywords={[aKeyword]}/>
                    )
            })

            }

        </div>
    )
}


const KeywordList = (props) => {
    const { t } = useTranslation();
    const [keywordList, setKeywordList] = useState([])
    const [isFetching, setFetching] = useState(false)

    console.log(keywordList)
    useEffect(()=>{
        console.log("fetch all keyword")
		let isCanceled = false

        const fetchKeywordList = async () => {
            setFetching(true)
            try {
                const res = await fetchHelper.get(`/channel/${props.channelId}/bot/autoAssign/keywords/all`, {
                    fetchQAbot: true
                })
                const response = res.data
				if (isCanceled) return
                if (response.status === "OK") {
                    setKeywordList(response.data)
                }
            } catch (e) {
                console.log('fetch recent tag fail', e.message)
            } finally {
                setFetching(false)
            }
        }

        fetchKeywordList()
        return () => {
            isCanceled = true
        }
    }, [])
    return (
		<div className="all-keyword">
            <div className="page-title">
                {t('channel.members')}
                <i className="far fa-chevron-right" />
                {t('autoAssignKeyword.all.title')}
            </div>
            <div className="page-des">
                {t('autoAssignKeyword.all.des')}
            </div>
            { isFetching && <LoadingIcon/> }
            { !isFetching && keywordList.map((aKeyword, i)=>{
                return (
                    <Level0KeywordSection key={`keyword-level-0-${i}`} keyword={aKeyword}/>
                )
            })}
        </div>
    )
}
export default KeywordList