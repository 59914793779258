import React, { Fragment } from 'react';
import cx from 'classnames';
import './index.css'

const Modal = ({ className, isOpen, onRequestClose, children, cancelBGClick }) => {

	const closeModal = () => {
		onRequestClose()
	}

	return isOpen ?
		<div
			className={cx(
				"react-modal l-backdrop l-backdrop--center",
				className,
				{ 'is-visible': isOpen }
			)}
			onClick={() => {
				if (!cancelBGClick) {
					closeModal()
				}
			}}
		>
			<section
				className="c-dialog c-dialog--center is-open"
				onClick={(e) => {
					e.stopPropagation()
				}}
			>
				<button className="c-dialog__close" onClick={() => closeModal()} />
				{children}
			</section>
		</div> :
		<Fragment />
}

Modal.defaultProps = {
	isOpen: false,
	onRequestClose: () => {},
	cancelBGClick: false
}

export default Modal;