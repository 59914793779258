import React from 'react';

import './index.css';

const Loading = (props) => {
	return (
		<div className="chat-dialogue loading">
			<div className="icon-loading">
				<i className="fal fa-spinner-third fa-spin" />
			</div>
		</div>
	);
}

export default Loading;