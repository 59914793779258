import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames'
import { useTranslation } from 'react-i18next';

import { uploadFile, getPreviewByfileContentType } from 'Components/Uploader';
import enums from 'enums'

import './index.css';

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const UploadCard = (props) => {
	const { t } = useTranslation();
	const [uploadPercent, setPercent]  = useState(0)
	const [imagePreview, setImagePreview] = useState(null)
	const [isFinished, setFinish] = useState(null)
	const [isError, setError] = useState(false)
	const [isCancelClick, setCancel] = useState(false)
	const isCanceled = useRef(false)

	const finishedProcess = async (result) => {
		setFinish('fade-out')

		// delay for animate
		await sleep(1000)

		setFinish('hidden')

		if (!isCanceled.current) {
			props.onUpLoaded(result)
		}
	}

	const errorProcess = async () => {
		setError(true)

		await sleep(1000)

		setFinish('fade-out')

		// delay for animate
		await sleep(1000)

		setFinish('hidden')
	}

	const startUpload = async () => {
		const reader = new FileReader();
		reader.onerror = (e) => {
			console.log('file reader error', e)
			// props.onError(e)
			reader.abort();
		};

		reader.onload = (e) => {
			setImagePreview(e.target.result)
		};

		uploadFile({
			file: props.file,
			folderType: enums.FileUploadType.channel,
			fileUploadFrom: enums.FileUploadFrom.directMessage,
			channelId: props.channelId,
			chatRoomId: props.chatRoomId,
			onUploaded: (result) => {
				finishedProcess(result)
			},
			onProgress: (percent) => setPercent(percent),
			onError: () => {
				errorProcess()
			}
		})

		reader.readAsDataURL(props.file);
	}

	useEffect(() => {
		if (props.file) {
			startUpload()
		}
	}, []);

	return (
		<div className={cx("a-upload-card", isFinished, { hidden: isCancelClick }, { 'is-error': isError })}>
			{(() => {
				if (isError) {
					return (
						<div className="file-icon">
							<i className="fal fa-exclamation-triangle" />
						</div>
					)
				}

				const { previewUrl, isImage } = getPreviewByfileContentType(props.file.type, '')
				return  (
					<figure className={cx({ preview: !isImage })}>
						<img src={isImage ? imagePreview : previewUrl} alt=""/>
					</figure>
				)
			})()}

			<div className="file-name-n-progress-bar">
				<div className="file-name u-truncate">{props.file.name}</div>
				{isError ?
					<div className="error-msg">{t('error.fileTypeAndSize')}</div> :
					<div
						className={cx("progress-bar", { complete: uploadPercent === 100 })}
					>
						<span style={{ width: `${uploadPercent}%`}} />
					</div>
				}

			</div>
			<div className="close-button" onClick={() => {
				isCanceled.current = true
				setCancel(true)
			}}>
				<i className="fal fa-times" />
			</div>
		</div>
	)
}

export default UploadCard;

