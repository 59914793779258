import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CheckboxInput from 'Components/CheckboxInput'
import UpdateMessage from 'Components/UpdateMessage'
import ValidInput from 'Components/ValidInput'
import TooltipFixPosition from 'Components/TooltipFixPosition'
import BulmaSwitch from 'react-bulma-switch'

/**
 * 新訊息通知設定區塊
 * @property {bool} isUpdating
 * @property {obj} settings response of get(`/channel/${props.channelId}`).notifySetting.newMessage
 * @property {func} updateSetting
 */
const NewMessageNotifySetting = (props) => {
	const { t } = useTranslation()
	const { isUpdating, settings, updateSetting } = props

	const [ formVal, setFormVal ] = useState({
		"active" : settings.active || false,
		"token" : settings.token || "",
		"sendToInChargeOp" : settings.sendToInChargeOp || false
	})
	const updateFormVal = ({key, val}) => {
		setFormVal({
			...formVal,
			[key]: val
		})
		setFormChange(true)
	}
	const [ isFormChange, setFormChange ] = useState(false)

	const onPressSave = () => {
		updateSetting({
			postBody: formVal,
			callback: () => {
				setFormChange(false)
			}
		})
	}
	const onPressCancel = () => {
		setFormChange(false)
		setFormVal({
			"active" : settings.active,
			"token" : settings.token,
			"sendToInChargeOp" : settings.sendToInChargeOp
		})
	}

	return (
		<div className="a-block new-message-notify">

			<div className="title">
				<div className="sub-title">{t('setting.notify.newMessageNotify')}</div>
				<BulmaSwitch
					value={formVal.active === true}
					onChange={(e)=>{
						updateFormVal({
							key: "active",
							val: !formVal.active
						})
					}}>
					{formVal.active ? t('bot.isActive') : t('bot.isClose')}
				</BulmaSwitch>
			</div>

			<div className="sub-des">{t('setting.notify.newMessageNotifyDes')}</div>
			<div className="form-fields" >
				<div className="inputer-title-token sub-sub-title">{t('setting.notify.lineToken')}
					<TooltipFixPosition>
						<div className="tooltip-link">
							<Link to={`/static/line/notify/tutorial`} target="_blank">
								{t('setting.header.toTutorial')}
							</Link>
						</div>
					</TooltipFixPosition>
				</div>
				<div className="inputer-token">
					<ValidInput
						value={formVal.token}
						placeholder={t('setting.notify.lineTokenPlaceHolder')}
						onChange={(e) => {
							updateFormVal({key: "token", val: e.target.value})
						}}
					/>
				</div>
				<div className="checkbox-send-to-op">
					<CheckboxInput
						onChange={(e) => {
							updateFormVal({
								key: "sendToInChargeOp",
								val: !formVal.sendToInChargeOp
							})
						}}
						isChecked={formVal.sendToInChargeOp === true}
						isDisabled={isUpdating}
						labelText={t('setting.notify.sendToInChargeOp')}
					/>
				</div>
			</div>

			<div className="btn-group">
				<button
					className="c-btn c-btn--primary"
					onClick={onPressSave}>
					{t('save')}
				</button>
				<button
					className="c-btn "
					onClick={onPressCancel}
					disabled={!isFormChange} >
					{t('cancel')}
				</button>
				<UpdateMessage isUpdating={isUpdating}/>
			</div>

		</div>
	)
}

NewMessageNotifySetting.propTypes = {
    isUpdating: PropTypes.bool,
    settings: PropTypes.shape({
        active: PropTypes.bool,
        token: PropTypes.string,
        sendToInChargeOp: PropTypes.bool
    }),
    updateSetting: PropTypes.func
}

export default NewMessageNotifySetting;