import React, { useState, useEffect, useRef, getGlobal } from 'reactn';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import cx from 'classnames'

import Text from './Dialogue/Text';
// import Join from './Dialogue/Join';
import Loading from './Dialogue/Loading';
import DateLine from './Dialogue/DateLine';
import Files from './Dialogue/Files';
import RichImage from './Dialogue/RichImage';
import Tamplate from './Dialogue/Tamplate';
import Sticker from './Dialogue/Sticker';
import ActivityCard from './Dialogue/ActivityCard';

import autosize from "autosize";

import RichImageModal from 'Components/CreateMsg/RichImageModal'
import ActivityModal from 'Components/CreateMsg/ActivityModal'
import UploadModal from 'Components/Uploader/UploadModal'
import { fetchHelper } from 'tools/FetchHelper'
import { getUser } from 'Login'
import enums from 'enums'

import UploadCard from './UploadCard'

import './index.css';

const ENTER_KEY = 13;

const PreviewDialogue = () => <div className="preview"/>

const dialogues = {
	text: Text,
	image: Files,
	audio: Files,
	video: Files,
	file: Files,
	sticker: Sticker,
	imagemap: RichImage,
	preview: PreviewDialogue,
	template: Tamplate,
	activityCard: ActivityCard
}

const Inputer = (props) => {
	const { t } = useTranslation();
	const [text, setText] = useState(null)
	const [showMenu, setMenu] = useState(false)
	const [showModal, setModal] = useState({
		richImage: false,
		file: false,
		activity: false
	})
	const textareaRef = useRef(null);
	useEffect(() =>{
		autosize(textareaRef.current)
	})

	const sendMsg = (message, event="MESSAGE") => {
		const wsData = props.bot.send(
			message,
			props.channelId,
			props.user.guid,
			event
		)
		props.onMessageSent({
			...wsData,
			message: wsData.data,
		})
		props.updateCallOpAlert(props.user.guid, false)
		props.deactivateBot()
	}

	const setModalOnAndCloseMenu = (openModal) => {
		setModal({
			...showModal,
			[openModal]: true
		})
		setMenu(false)
	}

	return (
		<div className="message-input">
			<div className="add-media-msg">
				<div className={cx("add-button", { 'show-menu': showMenu})} onClick={() => setMenu(!showMenu)}>
					<i className="fal fa-plus" />
				</div>
				{showMenu &&
					<div className="media-menu">
						<div className="a-row" onClick={() => {setModalOnAndCloseMenu("file")}}>
							{t('message.file')}
						</div>
						<div className="a-row" onClick={() => {setModalOnAndCloseMenu("richImage")}}>
							{t('message.richImage')}
						</div>
						<div className="a-row" onClick={() => {setModalOnAndCloseMenu("activity")}}>
							{"活動卡片"}
						</div>
					</div>
				}
			</div>
			<div
				className={cx("c-txt__input c-txt__input--media", { valid: !!text })}
				onClick={() => {
					if (props.msgRead) {
						props.msgRead()
					}
				}}
			>
				<textarea
					ref={textareaRef}
					className="c-txt__input c-txt__input--bare c-txt__input--media__body"
					type="text"
					placeholder={t('message.msgIputPlaceHolder')}
					value={text || ""}
					onChange={(e) => {
						if (e.keyCode === ENTER_KEY) {
							if (e.shiftKey) {
								setText(e.target.value)
							}
						}  else {
							setText(e.target.value)
						}
					}}
					onKeyDown={(e) => {
						if (e.keyCode === ENTER_KEY && !e.shiftKey) {
							e.preventDefault()
							if (text) {
								sendMsg({
									type: 'text',
									text: text
								})
								setText(null)
							}

						}
					}}
					onPaste={(e) =>{
					    const paste = (e.clipboardData || window.clipboardData)
						const pasteText = paste.getData('Text')

						if (paste.files && paste.files.length > 0) {
							e.preventDefault();
							const files = []

							for (let i = 0;i<paste.files.length;i+=1) {
						    	files.push(new File(
						    		paste.files,
						    		`${pasteText.split('.')[0]}.${paste.files[i].name.split('.')[1]}`,
						    		{type: paste.files[i].type}
						    	));
						    }

						    props.dropOrPasteFiles(files)
						}
					}}
				/>
				<div
					className="c-txt__input--media__figure"
					onClick={() => {
						if (text) {
							sendMsg({
								type: 'text',
								text: text
							})
							setText(null)
						}
					}}
				>
					<i className="fal fa-paper-plane" />
				</div>
			</div>
			<RichImageModal
				isModalOpen={showModal.richImage}
				onRequestClose={() => setModal({
					...showModal,
					richImage: false
				})}
				onSaved={(form) => sendMsg({
					type: 'imagemap',
					...form
				})}
				channelId={props.channelId}
				fileUploadFrom={enums.FileUploadFrom.directMessage}
				chatRoomId={props.chatRoomId}
			/>
			<UploadModal
				isModalOpen={showModal.file}
				onRequestClose={() => setModal({
					...showModal,
					file: false
				})}
				folderType={enums.FileUploadType.channel}
				onUpLoaded={(result) => sendMsg({
					...result,
					type: result.type,
					contentUrl: result.url
				})}
				channelId={props.channelId}
				chatRoomId={props.chatRoomId}
				fileUploadFrom={enums.FileUploadFrom.directMessage}
			/>
			<ActivityModal
				isModalOpen={showModal.activity}
				onRequestClose={() => setModal({
					...showModal,
					activity: false
				})}
				channelId={props.channelId}
				onSaved={(selectActivity)=>{
					sendMsg({
						type: "activityCard",
						activityGuid: selectActivity.guid,
						title: selectActivity.name,
						image: selectActivity.coverImagePath,
						text: `${dayjs(selectActivity.startDtm).format('YYYY/MM/DD')} -
							${selectActivity.endDtm? dayjs(selectActivity.endDtm).format('YYYY/MM/DD'): ""}`
					})

				}}
			/>
		</div>
	)
}

Inputer.defaultProps = {
	onMessageSent: () => {},
	msgRead: () => {}
}

const ChatHistory = (props) => {
	const { t } = useTranslation();

	// states
	const [isFetching, setFetching] = useState({
		message: !props.preview,
		history: false
	})
	const [messages, setMessages] = useState(props.messages)
	const [searchValue, setSearchValue] = useState(null)
	const [showDropZone, setDropZone] = useState(false)
	const [dropedFiles, setDropedFiles] = useState([])

	// refs
	const historyPage = useRef(1);
	const noMoreMessage = useRef(false);
	const noMoreNewMessage = useRef(false);
	const scrollToBottom = useRef(false);
	const msgIndex = useRef(0);
	const { bot, handleWebSocketMessage } = getGlobal()

	const historiesRef = useRef(null);
	const messagesRef = useRef(messages)

	const handleWebSocketMessageEvent = (res) => {
		try{
			let replyMsg = JSON.parse(res.data);
			let isCurrentOp = false
			// console.log('replyMsg', replyMsg)

			if (replyMsg.event === "MESSAGE") {
				if (replyMsg.recipient.chatRoomGuid !== props.guid) {
					return
				}
				if (typeof replyMsg.recipient.id === 'string') {
					isCurrentOp = replyMsg.recipient.id === getUser().guid
				} else if (Array.isArray(replyMsg.recipient.id)) {
					isCurrentOp = replyMsg.recipient.id.includes(getUser().guid)
				}

				if (isCurrentOp) {
					if (props.user.guid === replyMsg.recipient.chatRoomGuid) {
						updateMessage({
							createDtm: dayjs().format(),
							message: replyMsg.data,
							sender: replyMsg.sender || {
								"lineUserId": props.user.lineUserId || props.user.guid
							}
						}, messagesRef.current.length) // 因為只取得到一開始initialWebSocket時的messages, 這邊用messagesRef
						props.updateChatRoomMessage(replyMsg)
					} else {
						props.updateChatRoomMessage(replyMsg)
					}
				}
			}

			if (replyMsg.event === 'ALERT') {
				props.updateUnReadAlert(replyMsg.recipient.chatRoomGuid, true)
			}

			if (replyMsg.event === "CALLOP") {
				props.updateCallOpAlert(replyMsg.recipient.chatRoomGuid, true)
			}

		}
		catch(e){
			console.log(res)
		}
	}

	const initialWebSocket = () => {
		bot.initWebSocket(handleWebSocketMessage, handleWebSocketMessageEvent)
	}

	const getSearchHistory = async ({ fetchMore=false, scrollCount=0 } = {}) => {
		setFetching({
			message: true,
			history: fetchMore
		})

		try {
			const messageId = props.user.currentSearchMsg._id
			const result = await fetchHelper.get(`/channel/${props.channelId}/chatRoom/${props.user.guid}/search`, {
				params: {
					fromId: messageId
				}
			});
			const res = result.data
			if (res.status === "OK") {
				initialRef()

				setMessages([].concat(res.data))

				let scrollNewHeight = 0;
				const searchResultIdx = res.data.findIndex(aMsg => aMsg._id === messageId)

				for (let i=0; i < searchResultIdx; i++) {
					scrollNewHeight = scrollNewHeight + historiesRef.current.children[i].scrollHeight
				}
				historiesRef.current.scrollTop = scrollNewHeight;

				setFetching({
					message: false,
					history: false
				});
			}
		} catch (error) {
			setFetching({
				message: false,
				history: false
			});
			console.log('get message histories fail', error.message)
		}
	}

	const getHistory = async ({ fetchMore=false, scrollCount=0, messageId=undefined, sort=undefined } = {}) => {
		if (fetchMore && ((sort === -1 && noMoreMessage.current) || (sort === 1 && noMoreNewMessage.current)))  {
			return
		}
		if (!props.user) {
			return
		}

		setFetching({
			message: true,
			history: fetchMore,
		})

		historyPage.current = fetchMore ? historyPage.current + 1 : 1;

		try {
			let searchParam = {}
			if(!!messageId) {
				searchParam = {
					fromId: messageId,
					sort: sort,
					size: 50
				}
			}
			else {
				searchParam = {
					page: historyPage.current,
					size: 50
				}
			}

			const result = await fetchHelper.get(`/channel/${props.channelId}/chatRoom/${props.user.guid}/history`, {
				params: searchParam
			});
			const res = result.data

			if (res.status === "OK") {
				initialRef()

				if (fetchMore && res.data.length === 0) {
					if (!!messageId && sort === 1) {
						noMoreNewMessage.current = true
					} else {
						noMoreMessage.current = true
					}
				} else {
					if (!!messageId && sort === 1) {
						setMessages(fetchMore ?
							[].concat(messages, res.data) :
							res.data
						)
					} else {
						setMessages(fetchMore ?
							[].concat(res.data, messages) :
							res.data
						)
					}
				}

				if (sort === 1) {
					historiesRef.current.scrollTop = fetchMore ?
						scrollCount :
						historiesRef.current.scrollHeight
				} else {
					historiesRef.current.scrollTop = fetchMore ?
						historiesRef.current.scrollHeight - scrollCount :
						historiesRef.current.scrollHeight
				}

				setFetching({
					message: false,
					history: false
				});
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching({
				message: false,
				history: false
			});
			console.log('get message histories fail', error.message)
		}
	}

	const updateMessage = (aMsg, i) => {
		const msgCopy = messagesRef.current.slice()
		msgCopy[i] = aMsg
		scrollToBottom.current = true
		setMessages(msgCopy)
	}

	const onHistoriesScroll = () => {
		if (historiesRef.current.scrollTop === 0 && messages.length > 0 && !isFetching.history && !noMoreMessage.current) {
			getHistory({
				fetchMore: true,
				scrollCount: historiesRef.current.scrollHeight,
				messageId: !noMoreMessage.current ? messages[0]._id : undefined,
				sort: -1
			})
		}

		if ((historiesRef.current.scrollTop === historiesRef.current.scrollHeight - historiesRef.current.clientHeight) && messages.length > 0 && !isFetching.history && !noMoreNewMessage.current) {
			getHistory({
				fetchMore: true,
				scrollCount: historiesRef.current.scrollHeight,
				messageId: !noMoreNewMessage.current ? messages[messages.length-1]._id : undefined,
				sort: 1
			})
		}
	}

	const initialRef = () => {
		msgIndex.current = 0
	}

	const dropOrPasteFiles = (addFiles) => {
		const files = []
		const msgCopy = messages.slice()
		for (let i = 0;i<addFiles.length;i+=1) {
			msgCopy[messages.length + i] = {
				dropedFileEmptyMessage: true
			}
			files.push({
				msgIndex: messages.length + i,
				file: addFiles[i],
				timestamp: new Date().getTime()
			})
		}
		setMessages(msgCopy)
		const dropedFilesCopy = dropedFiles.slice()
		setDropedFiles(dropedFilesCopy.concat(files))
	}

	useEffect(() => {
		initialWebSocket() // for refresh porps in webSocket func
		if (!props.preview && props.guid) {
			historyPage.current = 1
			noMoreMessage.current = false

			noMoreNewMessage.current = false
			setSearchValue(!!props.user.currentSearchMsg ? props.user.currentSearchMsg.message.text : null)

			setMessages([])
			if (!!props.user.currentSearchMsg) {
				getSearchHistory()
			} else {
				getHistory()
			}

		}
	}, [props.guid]);

	useEffect(() => {
		setMessages(props.messages)
	}, [props.messages]);

	useEffect(() => {
		messagesRef.current = messages

		if (scrollToBottom.current) {

			// use scrolltop instead of scrollto for old broswers versions
			if (historiesRef.current.scrollTo) {
				historiesRef.current.scrollTo(0, historiesRef.current.scrollHeight)
			} else {
				historiesRef.current.scrollTop = historiesRef.current.scrollHeight
			}

			scrollToBottom.current = false
		}
	}, [messages]);

	useEffect(() => {
		if (!props.preview) {
			initialWebSocket()
			getHistory()
		}
	}, []);

	let lastMsgSender = null
	let lastMsgTimestamp = null
	return (
		<div
			className={cx("chat-history", { 'show-drop-zone': showDropZone && !props.noInputer })}
			onDragEnter={(e) => {
				e.preventDefault()
				if (props.noInputer) return;
				setDropZone(true)
			}}
			onDragLeave={(e) => {
				e.preventDefault()
				if (props.noInputer) return;
				setDropZone(false)
			}}
			onDragOver={(e) => {
				e.preventDefault();
			}}
			onDrop={(e) => {
				e.preventDefault()
				e.stopPropagation()
				if (props.noInputer) return;
				setDropZone(false)
				if (e.dataTransfer.files && e.dataTransfer.files[0]) {
					dropOrPasteFiles(e.dataTransfer.files)
			    }
			}}
		>
			<div className="histories" ref={historiesRef} onScroll={onHistoriesScroll}>
				{isFetching.history && <Loading />}
				{messages.reduce((pre, aMsg, i) => {
					const msg = aMsg.message
					let avatar, name, currentId;
					let hideAvatar = false;

					if (aMsg.dropedFileEmptyMessage) {
						return pre
					}

					if (aMsg.sender.lineUserId) {
						avatar = props.user.userData.avatar
						name = props.user.userData.name
						currentId = aMsg.sender.lineUserId
					}
					if (aMsg.sender.opGuid) {
						avatar = aMsg.sender.op.picture
						name = aMsg.sender.op.name
						currentId = aMsg.sender.opGuid
					}
					if (aMsg.sender.type && aMsg.sender.type === 'bot') {
						avatar = "https://lineline.s3.amazonaws.com/icons/robot.svg"
						name = "訊息機器人"
						currentId = "robot"
					}

					if (lastMsgSender) {
						hideAvatar =  props.hideAvatar !== undefined ?
							props.hideAvatar :
							lastMsgSender === currentId
					}

					if (lastMsgTimestamp) {
						if (dayjs(aMsg.createDtm).diff(lastMsgTimestamp , 'day') > 0) {
							pre.push(<DateLine
								key={`a-history-message-${msgIndex.current}`}
								showyear={dayjs(aMsg.createDtm).diff(lastMsgTimestamp, 'year') > 0}
								date={aMsg.createDtm}
								{...props}
							/>)
							msgIndex.current += 1
						}
					}

					if (Object.keys(dialogues).includes(msg.type)) {
						const Comp = dialogues[msg.type]
						pre.push(
							<Comp
								key={`a-history-message-${msgIndex.current}`}
								hideAvatar={hideAvatar}
								avatar={avatar}
								name={name}
								searchValue={searchValue}
								// required
								time={aMsg.createDtm}
								type={msg.type}
								text={msg.text}
								msg={msg}
								// optional
								contentUrl={msg.contentUrl}
								filename={msg.fileName}
								size={msg.size} // bytes
								imageUrl={msg.imageUrl}
								linkUrl={msg.linkUrl}
								preview={props.preview}
							/>
						)
					}
					else {
						console.log(msg)
					}

					lastMsgTimestamp = dayjs(aMsg.createDtm)
					lastMsgSender = currentId
					msgIndex.current += 1
					return pre
				}, [])}
				{/* <Join {...props} /> */}

				{isFetching.message && <Loading /> }
			</div>
			{!props.noInputer &&
				<Inputer
					{...props}
					bot={bot}
					onMessageSent={(aMsg) => {
						const msgCopy = messages.slice()
						msgCopy.push(aMsg)
						scrollToBottom.current = true
						initialRef()
						props.updateChatRoomMessage(aMsg)
						setMessages(msgCopy)
					}}
					msgRead={props.msgRead}
					updateCallOpAlert={props.updateCallOpAlert}
					dropOrPasteFiles={dropOrPasteFiles}
					deactivateBot={props.deactivateBot}
					chatRoomId={props.user.guid}
				/>
			}
			{showDropZone && !props.noInputer &&
				<div className="drop-zone">
					<i className="fal fa-cloud-upload" />
					<span className="">{t('message.uploadFile')}</span>
				</div>
			}
			{!props.noInputer &&
				<div className="upload-block">
					{dropedFiles.length > 0 && dropedFiles.map((aFile, i) => {
						return (
							<UploadCard
								{...props}
								file={aFile.file}
								onUpLoaded={(result) => {
									const wsData = bot.send({
											...result,
											type: result.type,
											contentUrl: result.url
										},
										props.channelId,
										props.user.guid,
										"MESSAGE"
									)
									const msgCopy = messagesRef.current.slice()
									msgCopy[aFile.msgIndex] = {
										...wsData,
										message: wsData.data,
									}
									scrollToBottom.current = true
									initialRef()
									setMessages(msgCopy)
								}}
								key={`upload-card-${i}-${aFile.file.name}`}
								chatRoomId={props.user.guid}
							/>
						)
					})}
				</div>
			}
		</div>
	);
}

ChatHistory.defaultProps = {
	messages: [],
	preview: false,
	noInputer: false,
	updateChatRoomMessage: () => {},
	updateUserAlert: () => {},
	updateCallOpAlert: () => {},
	deactivateBot: () => {}
}

export default ChatHistory;