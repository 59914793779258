import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import dayjs from 'dayjs'

import { fetchHelper } from 'tools/FetchHelper'

import enums from 'enums'
import Modal from 'Components/Modal'

const ActivityModal = (props) => {
	const { t } = useTranslation();
	const originSelectedActivityGuid = props.selectedActivityGuid
	const [selectableActivity, setSelectableActivity] = useState(props.allActivitys)
	const [selectedActivity, setSelectActivity] = useState()
	const [isFetching, setFetching] = useState(false)
	const activityListType = props.activityType || "ongoing"

	const isSubmitable = () => {
		if (!selectedActivity) return false
		return true
	}

	const initialState = () => {
		if (!!selectableActivity && !!originSelectedActivityGuid) {
			if (!!selectedActivity && selectedActivity.guid === originSelectedActivityGuid) return
			const matchActivity = selectableActivity.find((activity)=>{
				return activity.guid === originSelectedActivityGuid
			})
			if (!!matchActivity) {

				setSelectActivity(matchActivity)
				return
			}
		}
		setSelectActivity(null)
	}

	const submit = () =>{
		if (selectedActivity.guid !== originSelectedActivityGuid) {
			props.onSaved(selectedActivity)
		}
		initialState()
		props.onRequestClose()
	}


	useEffect(() => {
		initialState()
	}, [selectableActivity, props.selectedActivityGuid])

	useEffect(() => {
		let isCanceled = false
		const getActivities = async () => {
			setFetching(true)
			try {
				const result = await fetchHelper.get(`/channel/${props.channelId}/activity`, {
					params: {
						size: 100,
						type: activityListType
					},
					fetchQAbot: true
				});
				const res = result.data
				if (isCanceled) return
				if (res.status === "OK") {
					setSelectableActivity(res.data.activity)
					setFetching(false);
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				setFetching(false);
				console.log('get all activities fail', error.message)
			}
		}


		if (!isFetching && !selectableActivity) {
			getActivities()
			return () => {
				isCanceled = true
			}
		}

	}, [props.channelId])

	return (
		<Modal
			className="activity-modal"
			isOpen={props.isModalOpen}
			cancelBGClick
			onRequestClose={() => {
				props.onRequestClose()
			}}
		>
			<div className="modal-title">{t('activity.chooseActivity')}</div>
			<div className="modal-content">
				{isFetching || !selectableActivity ?
					<div className="icon-loading">
						<i className="fal fa-spinner-third fa-spin" />
					</div>
				:
					<div className="activit-list">
					{selectableActivity.map((aActivity, i)=>{
						return (
							<div
								className={cx("a-activity", {"selected" : aActivity === selectedActivity})} key={`a-activity-${i}`}
								onClick={()=>{
									setSelectActivity(aActivity)
								}}
							>
								<div className="activity-cover">
									<figure>
										<img src={aActivity.coverImagePath} alt=""/>
									</figure>
								</div>

								<div className="activity-name-n-date">
									<div className="activity-name">
										{aActivity.name}
									</div>
									<div className="activity-date">
										{`${dayjs(aActivity.startDtm).format('YYYY/MM/DD')} -
										${aActivity.endDtm? dayjs(aActivity.endDtm).format('YYYY/MM/DD'): ""}`}
									</div>
								</div>

							</div>
						)

					})}
					</div>
				}
			</div>

			<div className="button-section">
				<button
					className="c-btn c-btn--primary"
					onClick={() => {
						submit()
					}}
					disabled={!isSubmitable()}
				>
					{t('confirm')}
				</button>
			</div>

		</Modal>
	)

}

ActivityModal.defaultProps = {
	isModalOpen: false,
	onRequestClose: () => {},
	onSaved: () => {},
}



export default ActivityModal;
