import React, { useRef, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'

import { fetchHelper } from 'tools/FetchHelper'
import { getPreviewByfileContentType } from 'Components/Uploader'

import './index.css'

const Files = (props) => {
	const { t } = useTranslation();
	const [isFetching, setFetching] = useState(true)
	const filesPage = useRef(0)
	const [files, setFiles] = useState([])

	const getFileList = async () => {
		setFetching(true)
		filesPage.current += 1;
		try {
			const result = await fetchHelper.get(`/channel/${props.channelId}/chatRoom/${props.user.guid}/files`, {
				params: {
					page: filesPage.current,
					size: 10
				}
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				setFiles([].concat(files, res.data))
				setFetching(false)
			} else {
				throw new Error(res.errorMessage)
			}
		} catch (error) {
			setFetching(false)
			console.log('get user files failed', error)
		}
	}

	const renderSender = (aFileMsg) => {
		if (aFileMsg.sender.type && aFileMsg.sender.type === 'bot') {
			return t('file.bot')
		}

		if (aFileMsg.sender.op) {
			return aFileMsg.sender.op.name
		}
	}

	useEffect(() => {
		getFileList()
	}, [])

	return (
		<div className="file-record">
			<div className="files">
				{files.map((aFileMsg, i) => (
					<a
						href={`/file/${aFileMsg.message.guid}`}
						target="_blank"
						rel="noreferrer noopener"
						className="a-file"
						key={`a-chatroom-file-${i}`}
					>
						{(() => {
							const { previewUrl, isImage } = getPreviewByfileContentType(aFileMsg.message.contentType, aFileMsg.message.fileUrl)
							return  (
								<figure className={cx({ preview: !isImage })}>
									<img src={previewUrl} alt=""/>
								</figure>
							)
						})()}
						<div className="file-info">
							<div className="file-name u-truncate">{aFileMsg.message.fileName}</div>
							<div className="sender">{t('message.fileSender')}： {renderSender(aFileMsg)}</div>
							<div className="date">{t('message.fileSendTime')}：{dayjs(aFileMsg.createDtm).format('YYYY/MM/DD')}</div>
						</div>
					</a>
				))}
			</div>


			{isFetching ?
				<div className="icon-loading">
					<i className="fal fa-spinner-third fa-spin" />
				</div> :
				<div className="load-more" onClick={() => getFileList()}>
					{t('loadmore')}
				</div>
			}


		</div>
	)
}

export default Files;