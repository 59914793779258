import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'

const VoteActivityDeleteOrExpiredPage = (props) => {
	const {activity} = props
	const { t } = useTranslation()
	return (
		<div className="vote-expired">
			<div className="vote-expired-figure">
				<figure>
					<img src="https://lineline.s3.ap-northeast-2.amazonaws.com/systemFiles/vote-delete-or-expired-v2.svg" alt=""/>
				</figure>
			</div>
			<div className="vote-expired-reply">
				{activity.expiredReplyText}
			</div>

			<div className="button-section">
				<button className={cx("line-button close-button")}
					onClick={() => {props.onClose()}}>
					{t('activity.votePage.close')}
				</button>
			</div>
		</div>
	)
}
export default VoteActivityDeleteOrExpiredPage;
