import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';
import enums from 'enums'
import DateInput from 'Components/DateInput'
import './index.css'

const DateSetting = (props) => {
	const { t } = useTranslation();

	const {title, description, activityType, expiredType, disabled} = props
	const {startDate, startDateSelectPeriod, onStartSelect} = props
	const {setExpiredType} = props
	const {endDate, endDateSelectPeriod, onEndSelect} = props

	return (
		<>
		<div className="sub-title">{title}</div>
		{activityType === enums.ActivityType.vote &&
			<div className="sub-des">{description}</div>
		}

		<div className="activity-date">
			<DateInput
				mode="schedule"
				name="startDate"
				selectedDate={startDate}
				selectPeriod={startDateSelectPeriod}
				onSelected={(e) => onStartSelect(e)}
				disabled={disabled}
			/>
			<span>-</span>
			{disabled ?
				<div className={cx("c-txt__input expired-select disabled")} >
					{t(`activity.${expiredType}`)}
				</div>
				:
				<ThemeProvider theme={window.menuTheme}>
					<Menu
						trigger={({ ref, isOpen }) => {
							return (
								<div className={cx("c-txt__input--select expired-select", { 'is-open': isOpen })} ref={ref}>
									{t(`activity.${expiredType}`)}
								</div>
							)
						}}
					>
						{Object.keys(enums.activityExpiredDateType).map((aType, i) => (
							<Item
								className="c-txt__input--select-item"
								key={`expired-type-${aType}`}
								onClick={() => {setExpiredType(aType)}}
							>
								{t(`activity.${aType}`)}
							</Item>
						))}
					</Menu>
				</ThemeProvider>
			}
			{expiredType === 'specificDay' &&
				<DateInput
					mode="schedule"
					name="endDate"
					selectedDate={endDate}
					selectPeriod={endDateSelectPeriod}
					onSelected={(e) => onEndSelect(e)}
					disabled={disabled}
				/>
			}
		</div>
		</>
	)
}
export default DateSetting;
