import React from 'react';
import ChatHistory from 'Components/ChatHistory'
import RenderBotSheet from 'Components/RenderBotSheet'

import { getUser } from 'Login'

import './index.css'

const ChatPreviewBlock = (props) => {
	return (
		<div className="chat-preview">
			{props.renderBotSheet ?
				<RenderBotSheet
					preview
					forms={props.forms}
					onChange={(result) => { console.log() }}
				/> :
				<ChatHistory
					noInputer
					preview
					hideAvatar={false}
					messages={props.messages.map(aMsg => ({
						sender: props.sender ||  {
							op: {
								picture: getUser().avatar,
								name: getUser().name
							},
							"opGuid": getUser().guid
						},
						"event" : "MESSAGE",
						"botType" : "linelineConsole",
						"message": aMsg
					}))}
				 />
			}
		</div>
	)
}

ChatPreviewBlock.defaultProps = {
	messages: []
}

export default ChatPreviewBlock;