// seperate from TagControlModal

import React from 'react';
import Modal from 'Components/Modal'
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import './index.css'

/**
 * show a modal with title, des, and at max two button
 * @property {string} title
 * @property {string} des
 * @property {bool} isModalOpen
 * @property {string} className
 * @property {bool} showCancelBtn
 * @property {string} cancelBtnText default to "取消"
 * @property {function} onCancel press cancel or x
 * @property {bool} showActionBtn
 * @property {string} actionBtnText default to "確認"
 * @property {function} onExcute this function will excute on click action btn
 *
 */
const MessageModal = (props) => {
	const { t } = useTranslation();
    const {showCancelBtn, showActionBtn} = props
    const buttonCount = [showActionBtn, showCancelBtn].filter(x=>x).length
	return (
		<Modal
			className={cx("message-control-modal", props.className || "")}
			isOpen={props.isModalOpen}
			onRequestClose={() => {
				props.onCancel()
			}}
		>
			{props.title &&
                <div className="modal-title">
                    { props.title.split(/\n/).map((newline, i)=>(
                        <div key={`line-${i}`}className="a-line">{newline}</div>
                    ))}
                </div>
            }
			{props.des && <div className="modal-description">{props.des}</div>}
            { buttonCount > 0 &&
                <div className={buttonCount > 1 ? "button-group": "button-section"}>
                    {showActionBtn &&
                        <button className="c-btn action-btn" onClick={() => props.onExcute()}>
                            {props.actionBtnText || t('confirm')}
                        </button>
                    }
                    {showCancelBtn &&
                        <button className="c-btn cancel-btn" onClick={() => props.onCancel()}>
                            {props.cancelBtnText || t('cancel')}
                        </button>
                    }

                </div>
            }

		</Modal>
	)
}

MessageModal.defaultProps = {
	shouldConfirm: null,
	onDelete: () => {},
	onConfirm: () => {}
}

export default MessageModal;