import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import dayjs from 'dayjs'

import { getPreviewByfileContentType, formatBytes } from 'Components/Uploader'
import Avatar from './Avatar';
import NameAndTime from './NameAndTime';

import './index.css';

const Files = (props) => {
	const { t } = useTranslation();

	return (
		<div className={cx("chat-dialogue files", { 'hide-avatar': props.hideAvatar })}>
			{!props.hideAvatar &&
				<Avatar {...props} />
			}
			<div className="dialogue-content">
				{!props.hideAvatar &&
					<NameAndTime {...props} />
				}
				{props.msg && props.msg.guid ?
					<div className="file-thumbnail">
						<a href={props.msg.shortUrl} target="_blank" rel="noopener noreferrer">{props.msg.shortUrl}</a>
						<div className="preview-block">
							<div className="file-info">
								<div className="file-name u-truncate">{props.msg.name}</div>
								{props.preview ?
									`${formatBytes(props.size, 2, '-')},
									${t('message.ExpiredDateFromNow', { expiredDate: dayjs(props.msg.expireDtm).format('YYYY/MM/DD') })}` :
									<>
										<div className="file-ext">{t('message.fileExt')}： .{props.msg.extension}</div>
										<div className="file-size">{t('message.fileSize')}： {formatBytes(props.size, 2, t('message.unrecognized'))}</div>
										<div className="expire-date">{t('message.fileDownloadExpiredDate')}： {props.msg ?
											t('message.ExpiredDateFromNow', { expiredDate: dayjs(props.msg.expireDtm).format('YYYY/MM/DD') }) :
											'-'
										}</div>
									</>
								}
							</div>
							<figure>
								{(() => {
									const { previewUrl, isImage } = getPreviewByfileContentType(props.msg.contentType, props.msg.fileUrl)
									return  <img className={cx({ 'is-image': isImage})}  src={previewUrl} alt=""/>
								})()}
							</figure>
						</div>
					</div>
					: // compatible to old file type
					<>
						{['audio', 'video'].includes(props.type) &&
							<div className="multi-files">
								<a
									className="a-file"
									href={props.contentUrl}
									download={props.filename}
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="file-icon">
										{props.type === 'audio' &&
											<i className="fal fa-file-audio" />
										}
										{props.type === 'video' &&
											<i className="fal fa-file-video" />
										}
									</div>
									<div className="file-info">
										<div className="file-name">{props.filename}</div>
										<div className="file-size">{t('message.fileSize')}：{formatBytes(props.size, 2)}</div>
									</div>
								</a>
							</div>
						}
						{props.type === 'image' &&
							<div className="display-image">
								<a href={props.contentUrl} target="_blank" rel="noreferrer noopener">
									<figure>
										<img alt="" src={props.contentUrl} />
									</figure>
								</a>
							</div>
						}
					</>
				}

			</div>
		</div>
	);
}

export default Files;