import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import cx from 'classnames';
import { fetchHelper } from 'tools/FetchHelper'

import './index.css'

dayjs.extend(isSameOrBefore)

const CowellDetail = (props) => {
	const { t } = useTranslation();
	const [isFetching, setFetching] = useState(true)
	const [passenger, setPassenger] = useState(null)
	const [upcomingGroups, setUpcoming] = useState([])
	const [passedGroups, setPassed] = useState([])

	const fetchCowellPassengerDetail = useCallback(async (passengerId) => {
		setFetching(true);
		try {
			const result = await fetchHelper.get(`/channel/${props.channelId}/cowell/passenger/${passengerId}`);
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				setPassenger(res.data)

				if (res.data.travelGroups && res.data.travelGroups.length > 0) {
					const now = dayjs()
					const before = [], after = [];

					res.data.travelGroups.map(aGroup => {
						dayjs(aGroup.leaveDtm).isSameOrBefore(now, 'hour') ?
							before.push(aGroup) : after.push(aGroup)

						return aGroup
					})

					setUpcoming(after)
					setPassed(before)
				}

				setFetching(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false);
			console.log('fecth cowell members', error.message)
		}
	}, [props.channelId])

	const renderTagList = (travelGroup) => {
		const tagList = []

		if (travelGroup.nation.length > 0) {
			tagList.push({
				class: 'fa-globe',
				value: travelGroup.nation[0].name
			})
		}

		if (travelGroup.leaveDtm && travelGroup.returnDtm) {
			const leaveDay = dayjs(travelGroup.leaveDtm)
			const returnDay = dayjs(travelGroup.returnDtm)

			tagList.push({
				class: 'fa-calendar-alt',
				value: `${leaveDay.format('YYYY/MM/DD')}-
					${returnDay.format('YYYY/MM/DD')}
					${t('friends.travlePeriod',  { travelDays: returnDay.diff(leaveDay, 'day')})}
				`
			})
		}

		tagList.push({
			class: 'fa-user-friends',
			value: `${travelGroup.adultNumber}${t('friends.adult')}
				${travelGroup.childrenNumber}${t('friends.child')}
				${travelGroup.babyNumber}${t('friends.baby')}
				${t('friends.travelFee', { travelFee: travelGroup.groupFee})}
			`
		})

		return <div className="tag-list sub-des">
			{tagList.map((aTag, i) => (
				<div className="a-tag" key={`a-tag-${i}`}>
					<i className={cx("fal", aTag.class)} />
					<span>{aTag.value}</span>
				</div>
			))}
		</div>
	}


	useEffect(() => {
		if (props.match.params && props.match.params.passengerId) {
			fetchCowellPassengerDetail(props.match.params.passengerId)
		}
	}, [props.match.params, fetchCowellPassengerDetail])

	if (isFetching) {
		return (
			<div className="cowell-detail">
				<div className="icon-loading">
					<i className="fal fa-spinner-third fa-spin" />
				</div>
			</div>
		)
	}

	return (
		<div className="cowell-detail">
			<div className="page-title">{`${passenger.nameCh} ${t('friends.detail')}`}</div>
			<div className="sub-title">{t('friends.travelerInfo')}</div>
			<div className="travel-info">
				<div className="a-info">{t('friends.mandarinName')}: {passenger.nameCh}</div>
				<div className="a-info">{t('friends.englishName')}: {passenger.name}</div>
				{passenger.birthday && <div className="a-info">{t('friends.birthday')}: {dayjs(passenger.birthday).format('YYYY/MM/DD')}</div>}
				{passenger.phone && <div className="a-info">{t('friends.contactNumber')}: {passenger.phone}</div>}
				{passenger.email && <div className="a-info">{t('friends.email')}: {passenger.email}</div>}
				{passenger.lineId && <div className="a-info">{t('friends.lineId')}: {passenger.lineId}</div>}
			</div>
			<div className="sub-title">{t('friends.matchResult')}</div>

			<div className="match-block">
				<div className="top-section">
					{passenger.chatRoomId ?
						<>
							{passenger.chatRoom.lineUserId && <div className="matched-platform"></div>}

							<div className="friend-info" onClick={() => {}}>
								<figure className={cx({ 'show-noti': passenger.chatRoom.hasUnreadMsg })}>
									<img alt="" src={passenger.chatRoom.userData.avatar} />
									<div className="noti-dot" />
								</figure>
								<div className="friend-info">
									<div className="name-n-time">
										<div className="friend-name">
											{passenger.chatRoom.userData.name}
										</div>
										<div className="time-from-now">{dayjs(passenger.chatRoom.lastMessageDtm).fromNow()}</div>
									</div>
									<div className="lastest-msg u-truncate">
										{passenger.chatRoom.latestMessage.message.text}
									</div>
								</div>
							</div>
						</> :
						<>
							<div className="no-match">{t('friends.noMatch')}</div>
							<div className="no-match-des">{t('friends.noMatchDes')}</div>
						</>
					}
				</div>
				<div className="bottom-section">
					{passenger.chatRoomId ?
						<Link to={`/console/channel/${props.channelId}/message?search=${passenger.userData.name}`}>
							<i className="fal fa-comments" />
						</Link> :
						<a href="mailto:service@trip-ninja.io"><i className="fal fa-envelope" /></a>
					}
				</div>
			</div>
			{upcomingGroups.sort((a,b) => dayjs(a.leaveDtm).isBefore(dayjs(b.leaveDtm)) ? -1 : 1).length > 0 &&
				<div className="recent-travel">
					<div className="sub-title no-margin">{t('friends.recentTravelRecord')}</div>
					{upcomingGroups.map((aTravelGroup, i) => (
						<div className="a-travel-record" key={`a-travel-record-${i}`}>
							<div className="record-name">{aTravelGroup.name}</div>
							{renderTagList(aTravelGroup)}
						</div>
					))}
				</div>
			}
			{passedGroups.sort((a,b) => dayjs(a.leaveDtm).isBefore(dayjs(b.leaveDtm)) ? 1 : -1).length > 0 &&
				<div className="travel-record">
					<div className="sub-title no-margin">{t('friends.travelRecord')}</div>
					{passedGroups.map((aTravelGroup, i) => (
						<div className="a-travel-record" key={`a-travel-record-${i}`}>
							<div className="record-name">{aTravelGroup.name}</div>
							{renderTagList(aTravelGroup)}
						</div>
					))}
				</div>
			}

			<Link className="c-btn c-btn--primary" to={`/console/channel/${props.channelId}/cowell`} >
				{t('friends.backToMemberList')}
			</Link>
		</div>
	)
}
export default CowellDetail;