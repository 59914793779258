import React from 'react';
import './index.css'
import Tooltip from 'Components/Tooltip';
import cx from 'classnames'
import _uniqueId from 'lodash/uniqueId';

const RadioInput = (props) => {
	const inputId = props.id || _uniqueId('radio-input-')
	const useSpanText = props.useSpanText || false
	return (
		<div className={cx("input-radio-block", {"disabled" : props.isDisabled})}>
			<input
				id={inputId}
				name={props.name}
				type="radio"
				disabled={props.isDisabled}
				onChange={props.onChange}
				checked={props.isChecked}
			/>
			{useSpanText?
				<span>
					{props.labelText}
				</span>
			:
				<label htmlFor={inputId}>
					{props.labelText}
				</label>
			}
			{ props.radioToolTip &&
				<Tooltip>
					<i className="fal fa-info-circle" data-tip={props.radioToolTip} />
				</Tooltip>
			}
		</div>
		)
}

RadioInput.defaultProps = {
	name: "radio-btn",
	isDisabled: false,
	onChange: () => {},
	isChecked: false,
	labelText: "",
	radioToolTip: ""
}

export default RadioInput