import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import Modal from 'Components/Modal'
import ValidInput from 'Components/ValidInput'
import { checkEmailValid } from 'tools/validations'
import { fetchHelper } from 'tools/FetchHelper'

/**
 * modal of invite op
 * @prop  {string} channelId channel.guid
 * @prop  {bool} isModalOpen
 * @prop  {func} onRequestClose
 * @prop  {func} onInvited
 */
const InviteMemberModal = (props) => {
	const { t } = useTranslation();
	const [email, setEmail] = useState(null)
	const [step, setStep] = useState('initial') // 'initial' or 'processing' or 'success'
	const passEmailValidation = useRef(true);

	const inviteMember = async () => {
		setStep('processing');
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/invite`, {
				emails: email
			});
			const res = result.data
			if (res.status === "OK") {
				setStep('finish');
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setStep('initial');
			console.log('invite member fail', error.message)
		}
	}

	const validation = () => {
		if (!props.isModalOpen) return null
		if (passEmailValidation.current) {
			passEmailValidation.current = false;
			return null;
		}

    	return checkEmailValid(email) ? 'success' : 'error';
	}

	return (
		<Modal
			className="add-member-modal"
			isOpen={props.isModalOpen}
			onRequestClose={() => {
				if (step === 'finish') {
					props.onInvited()
				}
				passEmailValidation.current = true
				setStep('initial');
				props.onRequestClose(false)
			}}
		>
			{step === 'initial' &&
				<>
					<div className="modal-title">{t('channel.addMemberTitle')}</div>
					<div className="modal-description">{t('channel.inviteMemberByEmail')}</div>
					<ValidInput
						className={cx(validation())}
						placeholder={t('channel.typeEmail')}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<button className="c-btn c-btn--primary" onClick={() => inviteMember()}>
						{t('invite')}
					</button>
				</>
			}
			{step === 'processing' &&
				<>
					<div className="modal-title">{t('channel.addMemberTitle')}</div>
					<div className="big-icon">
						<i className="far fa-spinner-third fa-spin" />
					</div>
					<div className="modal-description">{t('channel.inviting')}</div>
				</>
			}
			{step === 'finish' &&
				<>
					<div className="big-icon">
						<i className="fal fa-thumbs-up" />
					</div>
					<div className="modal-title">{t('save')}{t('success')}</div>
				</>
			}
		</Modal>
	)
}

InviteMemberModal.defaultProps = {
	onInvited: () => {}
}

export default InviteMemberModal;