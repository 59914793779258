import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './index.css'

/*
	questions = [{
		value: str,
		isEditable: boolean
	}]
*/
const ENTER_KEY = 13;

const SingleQuestion = (props) => {
	const {value, onDelete, disabled} = props

	return (
		<div
			className="a-tag"
		>
			<span>{value}</span>
			{!disabled &&
				<i className="fal fa-times" onClick={() => {onDelete()}} />
			}
		</div>
	);

}


const EditableQuestion = (props) => {
	const {value, placeholder, disabled} = props
	const {onChange, onKeyDown, onBlur, onDelete} = props

	return (
		<div className="editable-tag" >
			<input
				type="text"
				placeholder={placeholder}
				value={value || ""}
				onChange={(e) => {onChange(e)}}
				onKeyDown={(e) => {onKeyDown(e)}}
				onBlur={(e) => {onBlur(e)}}
			/>
			{!disabled &&
				<i className="fal fa-times" onClick={() => {onDelete()}} />
			}
		</div>
	);
}

const AddQuestionBlock = (props) => {
	const disabled = props.disabled || false
	const { t } = useTranslation();
	const [questions, setQuestions] = useState(props.questions || []);
	const [newTagText, setText]	= useState(null);
	const isFirstRun = useRef(true);

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		props.onChange(questions.filter(x => !!x.value))
	}, [questions])

	const removeTag = (i) => {
		const tagCopy = questions.slice()

		tagCopy.splice(i, 1)
		setQuestions(tagCopy)
	}

	const updateTag = (i, value, isEditable) => {
		const tagCopy = questions.slice()

		tagCopy[i] = {
			value: value,
			isEditable: false
		}
		setQuestions(tagCopy)
	}

	return (
		<div className="add-question-block">
			{!disabled &&
				<div className="add-button" onClick={() => {
					const tagCopy = questions.slice()
					tagCopy.unshift({
						value: "",
						isEditable: true
					})
					setQuestions(tagCopy)
				}}>
					<i className="far fa-plus" />
				</div>
			}

			{props.placeholder && questions.length === 0 &&
				<span className="placeholder">{props.placeholder}</span>
			}
			{questions.map((aQuestion, i) => {
				if (aQuestion.isEditable) {
					return (
						<EditableQuestion
							key={`a-question-tag-${i}`}
							value={newTagText || ""}
							placeholder={t('bot.possibleQuestion')}
							disabled={disabled}
							onChange={(e) => setText(e.target.value)}
							onKeyDown={(e) => {
								if (e.keyCode === ENTER_KEY && !!e.target.value) {
									updateTag(i, e.target.value, false)
									setText("")
								}
							}}
							onBlur={(e) => {
								updateTag(i, e.target.value, false)
								setText("")
							}}
							onDelete={() => removeTag(i)}
						/>
					);
				}

				return (
					<SingleQuestion

						key={`a-question-tag-${i}`}
						value={aQuestion.value}
						disabled={disabled}
						onDelete={() => removeTag(i)}
					/>
				);
			})}

		</div>
	)
}

AddQuestionBlock.defaultProps = {
	onChange: () => {}
}

export default AddQuestionBlock;