import { logoutUser} from 'Login'
import enums from 'enums'


class ValidationError extends Error {
	constructor(message, errorType) {
		super(message)
		this.name = "ValidationError"
		this.errorType = errorType
	}
}

class ErrorCatcher {
	constructor() {
		window.onerror = (message, source, lineno, colno, error) => {
			if (!error instanceof ValidationError) {
				return false
			}

			if (error.errorType === enums.errorType.channelPermissionMissing) {
				let logoutLocal = true
				logoutUser(logoutLocal)
				return true
			}
			return false
		}
	}
}

const errorCatcher = new ErrorCatcher()

export { errorCatcher, ValidationError}
