const enums = {
	SocialType: {
		Google: 1,
		Facebook: 2
	},
	LoginType: {
		Google: 1,
		Cowell: 2
	},
	ChannelFuncList: {
		dashboard: {},
		message: {},
		market: {
			broadcast: {},
			activity: {}
		},
		bot: {},
		setting: {},
		members: {
			friends: {},
			tags: {}
		}
	},
	BotType: {
		greeting: 1,
		autoAssign: 16,
		form: 2,
		oneToOne: 3,
		stickerReply: 15,
		qna: 4,
		default: 5
	},
	FileUploadType: {
		channel: 1,
		chatroom: 2
	},
	FileUploadFrom: {
		directMessage: 1,
		bot: 2,
		broadcast: 3
	},
	fieldType: {
		text: ['title', 'description'],
		image: ['image'],
		input: ['subName', 'birthday', 'phone', 'email', 'tags']
	},
	broadcastType: {
		line: 1,
		chatRooms: 2,
		filter: 3,
		1: 'line',
		2: 'chatRooms',
		3: 'filter'
	},
	ChatRoomStatusType: {
		all: 0,
		pending: 1,
		star: 2,
		done:3,
		0: 'all',
		1: 'pending',
		2: 'star',
		3: 'done'
	},
	ChatRoomAssignOwnerType: {
		all: 0,
		op: 1,
		none: 2
	},
	ChatRoomActivateStatusType: {
		isCallOp: 1,
		isStar: 2,
		isHandled: 3
	},
	SearchValueType: {
		userInfo: 1,
		tag: 2,
		history: 3,
		cowell: 4,
		1: 'userInfo',
		2: 'tag',
		3: 'history',
		4: 'cowell'
	},
	activityExpiredDateType: {
		specificDay: 1,
		notAssign: 2
	},
	activityParticipateWays: {
		scanQRCode: 1,
		keywords: 2,
		"1": "scanQRCode",
		"2": "keywords"
	},
	ActivityType: {
		normal: 1,
		vote: 2,
		1: "normal",
		2: "vote"
	},
	ActivityVoteOptionType: {
		1: "text",
		2: "date",
		text: 1,
		date: 2
	},
	ActivityVoteSelectionType: {
		single: 1,
		multiple: 2
	},
	errorType: {
		channelPermissionMissing: 1
	}
}
export default enums;