import React, { useState, useEffect } from 'react';
import './index.css'
import cx from 'classnames'

const PagerButton = ({ pageNo, onClick, isCurrent}) => (
	<button
		className={cx("pagination-link", {
			'is-current': isCurrent
		})}
		onClick={onClick}>
		{pageNo}
	</button>
)

const PageControlButton =  ({ DisplayComp, onClick,  disabled }) => (
	<button className={cx("pagination-link", { 'is-disabled': disabled})}
		disabled={disabled}
		onClick={() => {
			if (!disabled) {
				onClick()
			}
		}}>
		<DisplayComp />
	</button>
)

const PageJumper = (props) => {
	// currentPage
	// totalPage
	// onPageChanged: page change function

	const [currentPage, setCurrentPage] = useState(props.currentPage)

	useEffect(() => {
		setCurrentPage(props.currentPage)
	}, [props.currentPage]);

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {

			if (currentPage <= 0 || currentPage > props.totalPage) {
				setCurrentPage(props.currentPage)
				return
			}
			setCurrentPage(currentPage)
		}
	}

	return	(
		<div className="field is-grouped pageJumper">
			<div className="control pageJumperText">
				跳至
			</div>
			<p className="control is-expanded">
				<input
					type="number"
					value={this.state.currentPage || ''}
					className="pageJumperInput input"
					onChange={(e) => setCurrentPage(parseInt(e.target.value, 10))}
					onKeyPress={handleKeyPress}
		 		/>
			</p>
			<div className="control pageJumperText">
				/ {props.totalPage || ''}
			</div>
		</div>
	)
}

const PagerElement = ({ total, current, onPageChanged, numVisiablePage, hideJumper }) => {
	let [fromPageNo, toPageNo]
		= getPageFromAndTo(total, current, numVisiablePage)
	// buttons
	let buttons = []

	// previous page button
	buttons.push(<span key="ai-button-prev">
		<PageControlButton
			DisplayComp={() => <i className="far fa-angle-left" />}
			// disable the button if the current page is equal to 1
			disabled={current === 1}
			onClick={() => onPageChanged(current - 1)} />
		</span>)

	// add first page btn if fromPageNo > 1
	if (fromPageNo > 1 ) {
		buttons.push(<span key={'page-1'}>
			<PagerButton
				pageNo={1}
				onClick={() => onPageChanged(1)}
				isCurrent={1===current}
				key={'ai-button-1'} />
		</span>)
	}

	if (fromPageNo > 2) {
		//ellipsis after prev button for small screen
		buttons.push(
			<span key="pagination-ellipsis-0">
				<span className="pagination-ellipsis">&hellip;</span>
			</span>
		)
	}


	// add page buttons
	for (let i = fromPageNo; i<=toPageNo; i++) {
		buttons.push(<span key={'page-'+i}>
			<PagerButton
				pageNo={i}
				onClick={() => onPageChanged(i)}
				isCurrent={i===current}
				key={'ai-button-'+i} />
			</span>)
	}

	if (toPageNo < total - 1) {
		//ellipsis before next button
		buttons.push(
			<span key="pagination-ellipsis-1">
				<span className="pagination-ellipsis">&hellip;</span>
			</span>
		)
	}

	// add last page button
	if (toPageNo < total) {
		buttons.push(<span key={'page-'+total}>
			<PagerButton
				pageNo={total}
				onClick={() => onPageChanged(total)}
				isCurrent={total===current}
				key={'ai-button-'+total} />
		</span>)
	}

	// next page button
	buttons.push(<span key="ai-button-next">
		<PageControlButton
			DisplayComp={() => <i className="far fa-angle-right" />}
			// disable the button if the current page is equal to 1
			disabled={current === total}
			onClick={() => {
				onPageChanged(current + 1)
			}} />
		</span>)

	return (
		<nav className="al-pager pagination">
			<div className="pagination-list">
				{buttons}
				{!hideJumper &&
					<PageJumper
						totalPage={total}
						currentPage={current}
						onPageChanged={onPageChanged}
					/>
				}
			</div>
	</nav>
	)
}

const Pager = ({ total, current, onPageChanged, hideJumper }) => {

	return (
		<div className="pager">
			<div className="largeScreen">
				<PagerElement
					total={total}
					current={current}
					onPageChanged={onPageChanged}
					numVisiablePage={5}
					hideJumper={hideJumper}
				/>
			</div>
			<div className="smallScreen">
				<PagerElement
					total={total}
					current={current}
					onPageChanged={onPageChanged}
					numVisiablePage={3}
					hideJumper={hideJumper}
				/>
			</div>
		</div>
	)
}

Pager.defaultProps = {
	onPageChanged: () => {},
	hideJumper: true
}

export default Pager;

/**
 * returns the pageFrom and pageTo
 *
 * @param {number} total
 *   total pages of the pagination
 * @param {number} current
 *   the current page
 * @param {number} numVisiablePage
 *   the number of visible pages
 *
 * @return {array[from, to]}
 *   pageFrom and pageTo
 */
export function getPageFromAndTo(total, current, numVisiablePage) {
	let fromPageNo, toPageNo

	if (total <= numVisiablePage + 2) {
		fromPageNo = 1
		toPageNo = total
	} else {
		const pagesBeforeCurrent = Math.ceil((numVisiablePage / 2) - 1)
		const pagesAfterCurrent = Math.floor(numVisiablePage / 2 )

		fromPageNo = current - pagesBeforeCurrent
		toPageNo = current + pagesAfterCurrent

		if (fromPageNo <= 1) {
			fromPageNo = 1
			toPageNo = 1 + numVisiablePage
		}
		if (toPageNo >= total) {
			fromPageNo = total - numVisiablePage
			toPageNo = total
		}
	}

	return [fromPageNo, toPageNo]
}
