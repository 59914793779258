import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs'

import cx from 'classnames'
import { fetchHelper } from 'tools/FetchHelper'

import VoteActivityDeleteOrExpiredPage from './VoteActivityDeleteOrExpiredPage'
import VoteActivityNotStartPage from './VoteActivityNotStartPage'
import VoteActivityResultPage from './VoteActivityResultPage'
import VoteActivityVotePage from './VoteActivityVotePage'

const VoteActivityContent = (props) => {
	const { t } = useTranslation();

	const {userData, chatRoomData, activityId, isScan} = props
	const activity = chatRoomData.activity
	const channel = chatRoomData.channel
	const isUnfollow = chatRoomData.isUnfollow

	const [voteOption, setVoteOption] = useState(
		chatRoomData.voteRecord ? chatRoomData.voteRecord.voteOption || [] : [])
	const [isVote, setIsVote] = useState(false)
	const [voteResult, setVoteResult] = useState({"count":0})

	const isActivityStart = activity.startDtm ? dayjs(activity.startDtm) < dayjs() : false
	const isActivityEnd = activity.endDtm ? dayjs(activity.endDtm) < dayjs() : false
	const dateLeft = dayjs(activity.endDtm).diff(dayjs(),'day') + 1

	const vote = async () => {
		try {
			const result = await fetchHelper.post(`/activity/${activityId}/attend`, {
				lineUserId: userData.userId,
				isCreate: chatRoomData.isCreate,
				voteOption: voteOption,
				isQrcode: isScan
			}, {
				fetchQAbot: true
			});
			const res = result.data
			if (res.status === "OK") {
				if (!chatRoomData.activity.voteSetting.publicResult) {
					onCloseAfterVoteDone()
				}
				setIsVote(true)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('attend activity fail', error.message)
		}
	}
	const onClose = () => {
		window.location.href = `https://line.me/R/ti/p/${chatRoomData.channel.lineAt}`
	}

	const onCloseAfterVoteDone = () => {
		if (!!activity.voteDoneReplyKeyword) {
			window.location.href = `https://line.me/R/oaMessage/${chatRoomData.channel.lineAt}/?` + encodeURI(activity.voteDoneReplyKeyword)
		}
		else {
			onClose()
		}
	}

	return (
		<div className="vote-activity">

			<div className={cx("vote-state", {"ongoing" : isActivityStart && !isActivityEnd && !isVote})}>
				{ activity.isDelete?
					t('activity.voteActivityState.isDelete')
				: !isActivityStart ?
					t('activity.voteActivityState.isNotStart')
				: isActivityStart && !isActivityEnd && isVote?
					t('activity.voteActivityState.hasVote', {"voteCount" : voteResult.count})
				: isActivityStart && !isActivityEnd && !!activity.endDtm?
					t('activity.voteActivityState.ongoingWithEndDtm', {"dateLeft" : dateLeft})
				: isActivityStart && !isActivityEnd && !activity.endDtm?
					t('activity.voteActivityState.ongoing')
				: t('activity.voteActivityState.isEnd')
				}
			</div>

			<div className="vote-name">{activity.name}</div>
			<div className="channel-avatar-and-name">
				<div className="channel-avatar">
					<figure>
						<img src={channel.avatar} alt=""/>
					</figure>
				</div>
				<div className="channel-name">
					{channel.name}
				</div>
			</div>

			{activity.isDelete ?
				<VoteActivityDeleteOrExpiredPage
					activity={activity}
					onClose={onClose}
				/>

			: !isActivityStart ?
				<VoteActivityNotStartPage
					activity={activity}
					onClose={onClose}
				/>
			: isActivityEnd && activity.voteSetting.publicResult ?
				<VoteActivityResultPage
					activityId={activityId}
					activity={activity}
					voteOption={voteOption}
					onClose={onClose}
					voteResult={voteResult}
					setVoteResult={setVoteResult}
				/>
			: isActivityEnd ?
				<VoteActivityDeleteOrExpiredPage
					activity={activity}
					onClose={onClose}
				/>
			: !isVote ?
				<VoteActivityVotePage
					activity={activity}
					vote={vote}
					voteOption={voteOption}
					setVoteOption={setVoteOption}
					onClose={onClose}
				/>
			: isVote && activity.voteSetting.publicResult ?
				<VoteActivityResultPage
					activityId={activityId}
					activity={activity}
					voteOption={voteOption}
					onClose={onClose}
					onCloseAfterVoteDone={onCloseAfterVoteDone}
					voteResult={voteResult}
					setVoteResult={setVoteResult}
					isVote={isVote}
					isUnfollow={isUnfollow}
				/>
			:	<div>{"關閉頁面"}</div>

			}

		</div>
	)
}

export default VoteActivityContent;
