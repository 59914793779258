import React, { useState, useEffect, useRef } from 'react';
import AutosizeInput from 'react-input-autosize';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';
import shortid from 'shortid';

import TagAddModal from './TagAddModal'

import './index.css';
const ENTER_KEY = 13;

const parseToValidInt = (value) => {
	if (!isNaN(value) && !isNaN(parseInt(Number(value))) && !isNaN(parseInt(value, 10))) {
		return parseInt(Number(value))
	}
	return 0
}

const TagWithCount = (props) => {
	const { tagValue, tagCount } = props
	const { onUpdateTagCount, onRemoveTag, isDisplayMode } = props

	const [ tagMode, setTagMode ] = useState("init") // init, edit, delete
	const [ isDeleteClicked, setDeleteClicked ] = useState(false)
	const [ countValue, setCountValue ] = useState(tagCount)

	const countPartRef = useRef(null)
	const inCountInput = useRef(false)
	const inCountEdit = useRef(false)


	const displayCountValue = (value) => {
		if (tagMode !== "edit") {
			if (value > 1000) return "1000+"
			if (value > 100) return "100+"
		}
		return value
	}

	return (
		<div className="tag-with-count">
			<div className={cx(
				"tag-value",
				{ "level-0": countValue < 3 },
				{ "level-1": countValue >= 3 && countValue < 6 },
				{ "level-2": countValue >= 6 && countValue < 11 },
				{ "level-3": countValue >= 11 && countValue < 21 },
				{ "level-4": countValue >= 21 && countValue < 51 },
				{ "level-5": countValue >= 51 && countValue <= 100 },
				{ "level-6": countValue > 100 }
			)}>{tagValue}</div>

			{isDisplayMode ?
				<div className="count-part">
					<div className="count-value">{displayCountValue(countValue)}</div>
				</div>
				:
				<div
					ref={countPartRef}
					className="count-part"
					tabIndex="1"
					onBlur={(e) => {
						const timer = setTimeout(() => {
							if (inCountEdit.current || inCountInput.current) {
								inCountEdit.current = false
								inCountInput.current = false
							} else {
								onUpdateTagCount(countValue)
								setTagMode("init")
								setDeleteClicked(false)
							}
						}, 300);
						return () => clearTimeout(timer);
					}}
				>
					<AutosizeInput
						className="count-value-input"
						value={displayCountValue(countValue)}
						onFocus={() => {
							setTagMode("edit")
							inCountInput.current = true
						}}
						onBlur={() => {
							inCountInput.current = false
						}}
						onChange={(e) => {
							setCountValue(parseToValidInt(e.target.value))
						}}
						onKeyDown={(e) => {
							if (e.keyCode === ENTER_KEY && !!e.target.value) {
								countPartRef.current.focus()
							}
						}}
					/>

					<div
						className={cx(
							"tag-delete-icon-list",
							{"is-hide": !(tagMode === "init" || tagMode === "delete")}
						)}
					>
						<i 	className={cx("fal fa-times", {"is-hide": isDeleteClicked})}
							onClick={() => setDeleteClicked(true)} />
						<i 	className={cx("fal fa-trash", {"is-hide": !isDeleteClicked})}
							onClick={() => onRemoveTag()} />
					</div>
					<div
						tabIndex="2"
						onFocus={() => {
							inCountEdit.current = true
						}}
						onBlur={() => {
							inCountEdit.current = false
						}}
						className={cx("count-edit-icon-list", {"is-hide": !(tagMode === "edit")})}
					>
						<div
							className="icon-sort-up"
							onClick={(e) => {
								setCountValue(countValue+1)
							}}
						>
							<i 	className="fas fa-sort-up" />
						</div>
						<div className="icon-sort-down"
							onClick={(e) => {
								setCountValue(countValue-1)
							}}
						>
							<i 	className="fas fa-sort-down" />
						</div>
					</div>
				</div>
			}


		</div>
	)
}

TagWithCount.propTypes = {
	tagValue: PropTypes.string.isRequired,
	tagCount: PropTypes.number,
	onUpdateTagCount: PropTypes.func
}

TagWithCount.defaultProps = {
	tagCount: 1,
	isDisplayMode: false,
	onUpdateTagCount: () => {},
	onRemoveTag: () => {}
}

const TagFilterCompare = ['greaterThanOrEqual', 'lessThanOrEqual']

const TagFilter = (props) => {
	const { t } = useTranslation();
	const { isFirst, tagFilter, deleteFilter, onFilterChange } = props
	const initial = Object.keys(tagFilter.count).filter(x => TagFilterCompare.includes(x))

	const [compare, setCompare] = useState(initial.length > 0 ? initial[0] :
		'greaterThanOrEqual') // greaterThanOrEqual, lessThanOrEqual

	const [count, setCount] = useState(initial.length > 0 ? tagFilter.count[initial.pop()] : 1 )
	const isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		onFilterChange({
			hash: tagFilter.hash,
			text: tagFilter.text,
			count: {
				[compare]: count
			}
		})
	}, [compare, count, tagFilter.hash, tagFilter.text]);
	// include onFilterChange to dependency cause recursive render, solve later
	return (
		<div className="tag-filter">
			<span>{isFirst ? t('filter.find') :  t('filter.or')}</span>
			<div className="a-tag">{tagFilter.text}</div>
			<ThemeProvider theme={{
				...window.menuTheme,
				'menus.menu_view': `
					&& {
						max-width: 100px;
						min-width: 100px;
					}
				`,
			}}>
				<Menu
					trigger={({ ref, isOpen }) => {
						return (
							<div
								className={cx("c-txt__input--select compare-select", { 'is-open': isOpen })}
								ref={ref}
							>
								{t(`filter.tagCountCompare.${compare}`)}
							</div>
						)
					}}
				>
					{TagFilterCompare.map((aType, i) => (
						<Item
							className="c-txt__input--select-item"
							key={`tag-filer-condition-${aType}`}
							onClick={() => setCompare(aType)}>
								{t(`filter.tagCountCompare.${aType}`)}
						</Item>
					))}
				</Menu>
			</ThemeProvider>
			<AutosizeInput
				className="count-inputer"
				inputClassName="c-txt__input"
				value={count || 1}
				onChange={(e) => setCount(parseToValidInt(e.target.value))}
			/>
			<i className="fal fa-times" onClick={() => deleteFilter()} />
		</div>
	)
}
TagFilter.defaultProps = {
	deleteFilter : ()=>{},
	onFilterChange : ()=>{},
}

const TagsInputer = (props) => {
	const [tags, setTags] = useState(props.tags)
	const [deletedTags, setDeletedTags] = useState([])
	const [addedTags, setAddedTags] = useState([])

	const [tagFilters, setTagFilters] = useState(props.tagFilters ? props.tagFilters.map(aFilter => ({
		...aFilter,
		hash: shortid.generate()
	}))
		: [])
	const isFirstRun = useRef(true);

	const removeTag = (i) => {
		if (props.boroadcastMode) {
			const tagCopy = tagFilters.slice()
			tagCopy.splice(i, 1)
			setTagFilters(tagCopy)
		} else {
			const tagCopy = tags.slice()
			setDeletedTags([
				...deletedTags,
				tagCopy[i]
			])
			tagCopy.splice(i, 1)
			setTags(tagCopy)
		}

	}

	const handleTagAdd = (values) => {
		if (props.boroadcastMode) {
			setTagFilters([
				...tagFilters,
				...values.map(value => ({
					hash: shortid.generate(),
					text: value.text,
					count: {
						greaterThanOrEqual: 1
					}
				}))
			])
		} else {
			addTags(values)
		}
	}

	const addTags = (values) => {
		const existTags = values.map((aTag) => tags.findIndex((x) => x.text === aTag.text))
		const tagsCopy = tags.slice()
		const addTags = []

		existTags.map((findIndex, i) => {
			if (findIndex > -1) {
				tagsCopy[findIndex].sort = tagsCopy.length + (tagsCopy.length - i)
			} else {
				tagsCopy.unshift(values[i])
				addTags.push(values[i])
			}
			return findIndex
		})
		if (addTags) {
			setAddedTags([
				...addTags,
				...addedTags
			])
		}

		setTags(tagsCopy.sort((a, b) => {
			if (!a.sort) a.sort = 0
			if (!b.sort) b.sort = 0

			return b.sort - a.sort
		}).map(x => {
			const { sort, ...aTag } = x

			return aTag
		}))
	}

	const updateTagCount = (countValue, i) => {
		const tagCopy = tags.slice()
		if (tagCopy[i].count !== countValue) {
			tagCopy[i].count = countValue
			const updateTag = tags[i]
			tagCopy.splice(i, 1)
			tagCopy.splice(0, 0, updateTag)
			setAddedTags([tagCopy[0]])
			setTags(tagCopy)
		}
	}

	const updateFilterTags = (result, i) => {
		const tagCopy = tagFilters.slice()
		tagCopy[i] = result
		setTagFilters(tagCopy)
	}

	useEffect(() => {
		if ((addedTags.length > 0 || deletedTags.length > 0) && !props.isDisplayMode) {
			props.onChange(tags, addedTags, deletedTags)
			setAddedTags([])
			setDeletedTags([])
		}
	}, [tags]);

	useEffect(() => {
		if (isFirstRun.current && !props.isDisplayMode) {
			isFirstRun.current = false;
			return;
		}
		props.onFilterChange(tagFilters)
	}, [tagFilters]);


	useEffect(() => {
		setTags(props.tags);
	}, [props.tags])
	const modalPlacement = props.modalPlacement ? {placement: props.modalPlacement} : {placeLeft: ""}

	return (
		<div className="tags-inputer">
			{!props.boroadcastMode &&
				<div className="tags-section">
					{!props.hideAddbutton &&
						<TagAddModal
							{...props}
							allTags={props.allTags}
							onTagAdd={(addTags) => handleTagAdd(addTags)}
							{...modalPlacement}
						/>
					}

					{tags.map((aTag, i) => (
						<TagWithCount
							key={`user-tag-${aTag.text}-${aTag.count}`}
							tagValue={aTag.text}
							tagCount={aTag.count}
							isDisplayMode={props.isDisplayMode}
							onUpdateTagCount={(countValue) => updateTagCount(countValue, i)}
							onRemoveTag={() => removeTag(i)}
						/>
					))}
				</div>
			}
			{props.boroadcastMode &&
				<>
					{!props.hideAddbutton &&
						<TagAddModal
							{...props}
							allTags={props.allTags}
							onTagAdd={(addTags) => handleTagAdd(addTags)}
							placeLeft
						/>
					}
					<div className="filter-tag-n-count">
						{tagFilters.map((aFilter, i) => (
							<TagFilter
								key={`a-tag-filter-${aFilter.hash}`}
								isFirst={i===0}
								tagFilter={aFilter}
								deleteFilter={() => removeTag(i)}
								onFilterChange={(result) => updateFilterTags(result, i)}
							/>
						))}
					</div>
				</>
			}
		</div>
	)
}

TagsInputer.defaultProps = {
	tags: [],
	boroadcastMode: false,
	isDisplayMode: false,
	onChange: () => {},
	onFilterChange: () => {},
	allTags: null
}

export default TagsInputer;
export { TagFilter };
