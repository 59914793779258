import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import store from 'store';
import * as serviceWorker from './serviceWorker';
import App from './App';
import '@zendeskgarden/react-menus/dist/styles.css';
import '@zendeskgarden/react-select/dist/styles.css';
import './index.css';

// resolve the deviceId
function _uuid() {
	var d = Date.now();
	if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
		d += performance.now(); //use high-precision timer if available
	}
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
			return (c === 'x' ? r : (r & (0x3 | 0x8))).toString(16);
	});
}

/**
 * Retrieve the deviceId. If there's no deviceId, it will create one for it.
 */
function resolveDeviceId () {
	let deviceId = store.get('TRIP_NINJA_DEVICE_ID');
	if (deviceId) {
		return deviceId;
	} else {
		deviceId = _uuid(); // generate a new one
		store.set('TRIP_NINJA_DEVICE_ID', deviceId)
		return deviceId;
	}
}

ReactDOM.render(<App deviceId={resolveDeviceId()} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
