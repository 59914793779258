import React from 'react';
import Linkify from 'react-linkify';
import cx from 'classnames'

import Avatar from './Avatar';
import NameAndTime from './NameAndTime';
import Highlight from 'react-highlighter'

import './index.css';

const Text = (props) => {
	return (
		<div className={cx("chat-dialogue", { 'hide-avatar': props.hideAvatar })}>
			{!props.hideAvatar &&
				<Avatar {...props} />
			}
			<div className="dialogue-content">
				{!props.hideAvatar &&
					<NameAndTime {...props} />
				}
				<Linkify properties={{ target: '_blank', rel: "noopener noreferrer" }}>
					{!!props.searchValue ?
						<Highlight search={props.searchValue}>{props.text}</Highlight> :
						props.text
					}

				</Linkify>
			</div>
		</div>
	);
}

export default Text;