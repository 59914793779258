import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import numeral from 'numeral'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import zhTW from 'dayjs/locale/zh-tw'
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';
import shortid from 'shortid';
import { saveAs } from 'file-saver';
import qs from 'qs'
import { withRouter } from 'react-router-dom';

import CowellDetail from './Detail'
import Pager from 'Components/Pager'
import { fetchHelper } from 'tools/FetchHelper'
import TagAddModal from 'Components/TagsInputer/TagAddModal'
import TagsInputer, { TagFilter } from 'Components/TagsInputer'
import enums from 'enums'

import './index.css'

dayjs.locale('zh-tw', zhTW)
dayjs.extend(relativeTime)

const FriendRow = ({ chatroom, channelId }) => {
	let linkUrl = `/console/channel/${channelId}/message`
	if(chatroom.userData && chatroom.userData.name) {
		linkUrl = linkUrl + `?search=${chatroom.userData.name}`
	}
	return (
		<Link className="a-friend" to={linkUrl}>
			<figure className={cx({ 'show-noti': chatroom.hasUnreadMsg })}>
				<img alt="" src={chatroom.userData.avatar} />
				<div className="noti-dot" />
			</figure>
			<div className="friend-info">
				<div className="name-n-time">
					<div className="friend-name">
						{chatroom.userData.name}
					</div>
					<div className="time-from-now">{dayjs(chatroom.lastMessageDtm).fromNow()}</div>
				</div>
				<div className="lastest-msg u-truncate">
					{!!chatroom.matchLastMsg ? chatroom.matchLastMsg.message.text : ""}
				</div>
			</div>
		</Link>
	)
}

const TagRow = (props) => {
	const [tags, setTags] = useState(props.tags || [])

	const updateTagToFriend = async (addedTags, deletedTags) => {

		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/chatRoom/${props.user.guid}`, {
				tag: {
					add: addedTags,
					remove: deletedTags
				}
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {

			} else {
				throw new Error(res.errorMessage)
			}
		} catch (error) {
			console.log('update user profile failed', error)
		}
	}

	return (
		<TagsInputer
			tags={tags}
			allTags={props.allTags}
			onChange={(updatedTags, addedTags, deletedTags) => {
				setTags(updatedTags)
				updateTagToFriend(addedTags, deletedTags)
			}}
			channelId={props.channelId}
			hideAddbutton={props.hideAddbutton}
			isDisplayMode={props.isDisplayMode}
		/>
	)
}

const searchValueTypeOptions = ['userInfo', 'tag', 'cowell', 'history']
const conditionOption = ['include', 'notInclude']

const CowellMembers = (props) => {
	const { t } = useTranslation();
	const [isFetching, setFetching] = useState(true)
	const [friends, setFriends] = useState([])
	const [searchValue, setSearchValue] = useState(null)
	const [searchType, setSearchType] = useState(searchValueTypeOptions[0])
	const [tagFilters, setTagFilters] = useState([])
	const [tagIncludeType, setTagInclude] = useState(conditionOption[0])
	const [isDownloading, setDownloading] = useState(false)

	const [currentPage, setPage] = useState(1)
	const totalRef = useRef()
	const lineFirendsCountRef = useRef(0)
	const lineFirendsRef = useRef([])
	const allTags = useRef()
	const isFirstRun = useRef(true)
	const size = 15

	 const fetchAllTags = useCallback(async () => {
		try {
			const result = await fetchHelper.get(`/channel/${props.channelId}/tag`);
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				allTags.current = res.data
				setFetching(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('fecth all tags fail', error.message)
		}
	}, [props.channelId])

	// use useCallback hers to solve useEffect missing dependency issue, however, cant get newest state
	// pass all state param if you want to use useCallback here
	const fetchFriendList = async (urlSearchValue = null, urlSearchType = null) => {

		setFetching(true);
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/friends`, {
				page: currentPage,
				size: size,
				search: {
					keyword: urlSearchValue || searchValue,
					mode: enums.SearchValueType[urlSearchType || searchType],
					filter: tagFilters.length > 0 ? [{
						name: 'tag',
						value: tagFilters,
						isReverse: tagIncludeType === 'include' ? false : true
					}] : []
				}
			});
			const res = result.data
			if (res.status === "OK") {
				totalRef.current = res.data.chatRoomMatchCowellCount + res.data.chatRoomNotMatchCowellCount + res.data.cowellPassengerNotMatchChatRoomCount
				lineFirendsCountRef.current = res.data.chatRoomMatchCowellCount + res.data.chatRoomNotMatchCowellCount
				lineFirendsRef.current = res.data.friends.filter(x => !!x.chatroom)
				setFriends(res.data.friends)
				if (!allTags.current) {
					fetchAllTags()
				} else {
					setFetching(false);
				}
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false);
			console.log('fecth cowell members', error.message)
		}
	}

	const downloadFriends = async () => {
		setDownloading(true)
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/friends`, {
				page: currentPage,
				size: totalRef.current,
				search: {
					keyword: searchValue,
					mode: enums.SearchValueType[searchType],
					filters:  tagFilters.length > 0 ? [{
						name: 'tag',
						value: tagFilters
					}] : []
				}
			});
			const res = result.data
			if (res.status === "OK") {

				const allFriends = res.data.friends.map(aFriend => {
					let tags = []

					if (aFriend.cowellPassenger && aFriend.cowellPassenger.tags) tags = aFriend.cowellPassenger.tags.map(x => ({ text: x, count: 1}))
					if (aFriend.chatroom && aFriend.chatroom.userData.tag) tags = aFriend.chatroom.userData.tag
					const isLineFriendDataExists = aFriend.chatroom && (aFriend.chatroom.userData.subName || aFriend.chatroom.userData.phone || aFriend.chatroom.userData.email)

					let cowellPassengerInfo = null
					if(aFriend.cowellPassenger) {
						cowellPassengerInfo =  `${aFriend.cowellPassenger.name} / ${aFriend.cowellPassenger.latestTravelGroup.name} `
						cowellPassengerInfo += `/ 團費：${aFriend.cowellPassenger.latestTravelGroup.groupFee} `

						if (aFriend.cowellPassenger.latestTravelGroup?.order?.orderDtm) {
							const orderDtm = new Date(aFriend.cowellPassenger.latestTravelGroup.order.orderDtm)
							cowellPassengerInfo += `/ 訂購日期：${orderDtm.getFullYear()}-${orderDtm.getMonth()+1}-${orderDtm.getDate()}`
						}
					}

					return ([
						aFriend.chatroom ? aFriend.chatroom.userData.name : t('friends.noRecord'),
						isLineFriendDataExists ? `${aFriend.chatroom.userData.subName || '-'}` : t('friends.noRecord'),
						isLineFriendDataExists ? `${aFriend.chatroom.userData.phone || '-'}` : t('friends.noRecord'),
						isLineFriendDataExists ? `${aFriend.chatroom.userData.email || '-'}` : t('friends.noRecord'),
						tags.map(x => `${x.text} ${x.count}`).join('/ '),
						cowellPassengerInfo || t('friends.noRecord')
					])
				})

				const lineArray = [].concat([[
					t('friends.lastMessageFromLine'),
					t('friends.dataFromLine.name'),
					t('friends.dataFromLine.phone'),
					t('friends.dataFromLine.email'),
					t('friends.friendTags'),
					t('friends.cowellMemberDetial')
				].join(", ")], allFriends.map(aLine => aLine.join(", ")))

				// add bom for windows user
				const blob = new Blob([String.fromCharCode(0xFEFF), lineArray.join("\n")],{ type: 'text/csv;charset=utf-8;' });
				saveAs(
					blob,
					`${t('friends.title')}.csv`
				)


				setDownloading(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setDownloading(false);
			console.log('fecth all friends fail', error.message)
		}
	}

	const sendBroadCast = async () => {
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/friends/guid`, {
				search: {
					keyword: searchValue,
					mode: enums.SearchValueType[searchType],
					filter: tagFilters.length > 0 ? [{
						name: 'tag',
						value: tagFilters
					}] : []
				}
			});
			const res = result.data
			if (res.status === "OK") {
				const pathParams = {
					pathname: `/console/channel/${props.channelId}/broadcast/create`,
					state: {
						guids: res.data
					}
				}
				//console.log("pathParams", pathParams)
				props.history.push(pathParams);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('fecth all guids fail', error.message)
		}


	}

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}

		fetchFriendList()
	}, [currentPage, tagFilters])


	useEffect(() => {
		if (tagFilters.length > 0) {
			fetchFriendList()
		}
	}, [tagIncludeType])


	useEffect(() => {
		const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })

		if (urlParams.search) {
			console.log("set",urlParams.search)
			setSearchValue(urlParams.search)
		}
		if (urlParams.searchType && searchValueTypeOptions.includes(urlParams.searchType)) setSearchType(urlParams.searchType)


		fetchFriendList(urlParams.search, urlParams.searchType)
	}, [])

	const totalPage = totalRef.current ? Math.ceil(totalRef.current / size) : 1

	return (
		<div className="cowell-members">
			<div className="page-title">{t('friends.title')}</div>
			<div className="cowell-match-des">{t('friends.cowellMemberMatchDes')}</div>

			<div className="search-bar-input">
				<ThemeProvider theme={{
					...window.menuTheme,
					'menus.menu_view': `
						&& {
							max-width: 90px;
							min-width: 90px;
						}
					`,
				}}>
					<Menu trigger={({ ref, isOpen }) => {
						return (
							<div className={cx("c-txt__input--select filter-select", { 'is-open': isOpen })} ref={ref}>
								{t(`message.searchValueFilter.type.${searchType}`)}
							</div>
						)
					}}>
						{searchValueTypeOptions.map((aType, i) => (
							<Item
								className="c-txt__input--select-item"
								key={aType}
								onClick={() => {
									setSearchType(aType)
								}}>
								{t(`message.searchValueFilter.type.${aType}`)}
							</Item>
						))}
					</Menu>
				</ThemeProvider>
				<div className={cx("c-txt__input c-txt__input--media", { valid: !!searchValue })}>
					<input
						className="c-txt__input c-txt__input--bare c-txt__input--media__body"
						placeholder={t('search')}
						type="text"
						value={searchValue || ""}
						onChange={(e) => setSearchValue(e.target.value)}
					/>
					<div className="c-txt__input--media__figure">
						<i className="far fa-search" onClick={() => {
							if (!isFetching) {
								fetchFriendList()
							}
						}}/>
					</div>
				</div>
			</div>

			<div className="advanced-filter">
				<span>{t('friends.advancedFilter')}：</span>
				<ThemeProvider theme={{
					...window.menuTheme,
					'menus.menu_view': `
						&& {
							max-width: 100px;
							min-width: 100px;
						}
					`,
				}}>
					<Menu
						trigger={({ ref, isOpen }) => {
							return (
								<div
									className={cx("c-txt__input--select include-select", { 'is-open': isOpen })}
									ref={ref}
								>
									{t(`broadcast.${tagIncludeType}`)}
								</div>
							)
						}}
					>
						{conditionOption.map((aCond, i) => (
							<Item
								className="c-txt__input--select-item"
								key={`filer-condition-${aCond}`}
								onClick={() => setTagInclude(aCond)}>
									{t(`broadcast.${aCond}`)}
							</Item>
						))}
					</Menu>
				</ThemeProvider>
				<TagAddModal
					allTags={allTags.current}
					channelId={props.channelId}
					onTagAdd={(addTags) => {
						setTagFilters([
							...tagFilters,
							...addTags.map(value => ({
								hash: shortid.generate(),
								text: value.text,
								count: {
									greaterThanOrEqual: 1
								}
							}))
						])
					}}
					placeLeft
				/>
			</div>

			{tagFilters && tagFilters.length > 0 &&
				<div className="filter-tag-n-count">
					{tagFilters.map((aFilter, i) => (
						<TagFilter
							key={`a-tag-filter-${aFilter.hash}`}
							isFirst={i===0}
							tagFilter={aFilter}
							deleteFilter={() => {
								const tagCopy = tagFilters.slice()
								tagCopy.splice(i, 1)
								setTagFilters(tagCopy)
							}}
							onFilterChange={(result) => {
								const tagCopy = tagFilters.slice()
								tagCopy[i] = result
								setTagFilters(tagCopy)
							}}
						/>
					))}
				</div>
			}

			<div className="search-result-n-controls">
				<div className="search-result">
					{t('friends.searchResult', { searchNumbers: numeral(totalRef.current).format(0,0)})}
				</div>
				<div className="controls">
					<div
						className="a-control-button"
						onClick={() => sendBroadCast()}
					>
						<i className="fal fa-envelope-open-text" />
						{t('friends.broadcastToFriends', { friendsNumber: lineFirendsCountRef.current })}
					</div>
					<div className="a-control-button" onClick={() => {
						if (isDownloading) return;

						downloadFriends()
					}}>
						{isDownloading ?
							<i className="fal fa-spinner-third fa-spin" />:
							<i className="fal fa-file-download" />
						}
						{t('friends.downloadCSV')}
					</div>
				</div>
			</div>

			{isFetching ?
				<div className="icon-loading">
					<i className="fal fa-spinner-third fa-spin" />
				</div> :
				<table className="match-table">
					<thead>
						<tr>
							<th className="matched-line">{t('friends.lastMessageFromLine')}</th>
							<th className="member-name">{t('friends.dataFromLine')}</th>
							<th className="recent-travel">{t('friends.friendTags')}</th>
							<th className="see-detail">{t('friends.cowellMemberDetial')}</th>
							<th className="see-detail">{t('friends.controls')}</th>
						</tr>
					</thead>
					<tbody>
						{friends.map((aFriend, i) => {
							let tags = []

							if (aFriend.cowellPassenger && aFriend.cowellPassenger.tags) tags = aFriend.cowellPassenger.tags.map(x => ({ text: x, count: 1}))
							if (aFriend.chatroom && aFriend.chatroom.userData.tag) tags = aFriend.chatroom.userData.tag

							return (
								<tr key={`a-passenger-${i}`}>
									<td className="matched-line">
										{aFriend.chatroom ?
											<FriendRow chatroom={aFriend.chatroom} channelId={props.channelId} /> :
											<div className="not-match">
												<figure><img src="https://lineline.s3.amazonaws.com/icons/user.png" alt=""/></figure>
												<div className="no-record">{t('friends.noMatch')}</div>
											</div>
										}
									</td>
									<td className="line-data">
										{aFriend.chatroom && (aFriend.chatroom.userData.subName || aFriend.chatroom.userData.phone || aFriend.chatroom.userData.email) ?
											<>
												<div className="user-data-real-name">{aFriend.chatroom.userData.subName || '-'}</div>
												<div className="user-data-phone">{aFriend.chatroom.userData.phone || '-'}</div>
												<div className="user-data-email">{aFriend.chatroom.userData.email || '-'}</div>
											</> :
											<div className="no-record">{t('friends.noRecord')}</div>
										}
									</td>
									<td className="user-tag">
										<div className="tag-list">
											<TagRow
												{...props}
												allTags={allTags.current}
												tags={tags}
												user={aFriend.chatroom}
												isDisplayMode={!aFriend.chatroom}
												hideAddbutton={!aFriend.chatroom}
											/>
										</div>
									</td>
									<td className="recent-travel">
										{aFriend.cowellPassenger ?
											<>
												<p className="main-des">{aFriend.cowellPassenger.name}</p>
												<p className="sub-des">
													{aFriend.cowellPassenger.latestTravelGroup.name}
												</p>
											</> :
											<div className="no-record">{t('friends.noRecord')}</div>
										}

									</td>
									<td className="see-detail">
										<div className="control-buttons">
											<div className="a-control-button">
												{aFriend.cowellPassenger ?
													<Link to={`/console/channel/${props.channelId}/friends/detail/${aFriend.cowellPassenger.PAX_CD}`}>
														<i className="fal fa-eye" />
													</Link> :
													<i className="fal fa-eye disabled" />
												}

											</div>
											<div className="a-control-button">
												{aFriend.chatroom ?
													<Link to={`/console/channel/${props.channelId}/message?search=${aFriend.chatroom.userData.name}`}>
														<i className="fal fa-comments" />
													</Link> :
													<i className="fal fa-comments disabled" />
												}
											</div>
										</div>
									</td>
								</tr>
							)
						})}

					</tbody>
				</table>
			}
			<Pager
				total={totalPage}
				current={currentPage}
				onPageChanged={(nextPageNo) => setPage(nextPageNo)}
			/>
		</div>
	);
}

const CowellMembersWR = withRouter(CowellMembers);

const Cowell = (props) => {
	return (
		<Switch>
			<Route
				path={`${props.match.path}/detail/:passengerId`}
				component={(routerProps) => <CowellDetail {...props} {...routerProps} />}
			/>
			<Route component={(routerProps) => <CowellMembersWR {...props} {...routerProps} />} />
		</Switch>
	)
}
export default Cowell;
