import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import _uniqueId from 'lodash/uniqueId';
import dayjs from 'dayjs'

import enums from 'enums'
import TagsInputer from 'Components/TagsInputer'
import RadioInput from 'Components/RadioInput'
import CheckboxInput from 'Components/CheckboxInput'
import DateInput from 'Components/DateInput'
import './index.css'

const VoteOption = (props) => {
	const {index, disabled, optionType} = props
	const [ isDeleteClicked, setDeleteClicked ] = useState(false)

	const setValue = (value) => {
		props.onOptionUpdate({
			...props.option,
			"optionValue" : value
		})
	}

	const setTags = (tags) => {
		props.onOptionUpdate({
			...props.option,
			"tags" : tags
		})
	}
	return (
		<div className={`vote-option`}>
			<div className="vote-option-index">{`${index+1}. `}</div>
			<div className="vote-option-value-section">
				<div className="vote-option-edit-section">
					<div className="vote-option-input">
					{optionType === enums.ActivityVoteOptionType.text &&
						<input
							type="text"
							className="c-txt__input"
							value={props.option.optionValue}
							onChange={(e) => setValue(e.target.value)}
							disabled={disabled}
						/>
					}

					{optionType === enums.ActivityVoteOptionType.date &&
						<DateInput
							mode="schedule"
							selectedDate={props.option.optionValue}
							selectPeriod={{periodDateStart: dayjs()}}
							onSelected={(e) => setValue(e)}
							disabled={disabled}
						/>
					}
					</div>
					<div className="vote-option-icon-list">
					{!isDeleteClicked ?
						<i 	className={cx("fal fa-times")}
							onClick={() => setDeleteClicked(true)} />
						:
						<i 	className={cx("fal fa-trash")}
							onClick={() => {props.onDelete()}}
							onMouseOut={()=>{
								setTimeout(() => {
									setDeleteClicked(false)
								}, 300);
							}}
						/>
					}
					</div>


				</div>
				<TagsInputer
					allTags={props.allTags}
					isDisplayMode={disabled}
					hideAddbutton={disabled}
					tags={props.option.tags}
					onChange={(result) => setTags(result)}
					channelId={props.channelId}
					modalPlacement={"bottom-start"}
				/>
			</div>

		</div>
	)
}
VoteOption.defaultProps = {
	option: {
		optionValue: "",
		tags: []
	},
	disabled: false
}

const VoteSetting = (props) => {
	const { t } = useTranslation();
	const {disabled, allTags} = props
	const {optionType, setOptionType, selectionType, setSelectionType, publicResult, setPublicResult} = props
	const {voteOptions, setVoteOptions} = props

	useEffect(() => {
		let hasInvalid = false
		if (optionType === enums.ActivityVoteOptionType.date) {
			const validOptions = voteOptions.map((aOption, i) => {
				if (!aOption.optionValue) return aOption
				const isValid = dayjs(aOption.optionValue, 'YYYY-MM-DD').isValid()
				if (!isValid) {
					hasInvalid = true
					aOption.optionValue = ""
					aOption.key = _uniqueId()
					return aOption
				}
				return aOption
			})

			if (hasInvalid) {
				setVoteOptions(validOptions)
			}

		}
	}, [optionType, voteOptions, setVoteOptions])

	return (
		<div className="activity-vote-setting">
			<div className="sub-title">{t('activity.activityVoteSetting')}</div>
			<div className="sub-des">{t('activity.activityVoteSettingDes')}</div>
			<div className="radio-group activity-votte-setting-option-type">
				<div className="inline-sub-title">{t('activity.voteSetting.optionType')}</div>
				<form>
					<RadioInput
						id={"radio-key-vote-option-text"}
						onChange={(e) => {setOptionType(enums.ActivityVoteOptionType.text)}}
						isChecked={optionType === enums.ActivityVoteOptionType.text}
						labelText={t('activity.voteSetting.text')}
						isDisabled={disabled}
					/>
					<RadioInput
						id={"radio-key-vote-option-date"}
						onChange={(e) => {setOptionType(enums.ActivityVoteOptionType.date)}}
						isChecked={optionType === enums.ActivityVoteOptionType.date}
						labelText={t('activity.voteSetting.date')}
						isDisabled={disabled}
					/>
				</form>
			</div>

			<div className="radio-group activity-votte-setting-selection-type">
				<div className="inline-sub-title">{t('activity.voteSetting.selectionType')}</div>
				<form>
					<RadioInput
						id={"radio-key-vote-select-single"}
						onChange={(e) => {setSelectionType(enums.ActivityVoteSelectionType.single)}}
						isChecked={selectionType === enums.ActivityVoteSelectionType.single}
						isDisabled={disabled}
						labelText={t('activity.voteSetting.single')}
					/>
					<RadioInput
						id={"radio-key-vote-select-multi"}
						onChange={(e) => {setSelectionType(enums.ActivityVoteSelectionType.multiple)}}
						isChecked={selectionType === enums.ActivityVoteSelectionType.multiple}
						isDisabled={disabled}
						labelText={t('activity.voteSetting.multiple')}
					/>
				</form>
			</div>
			<div className="activity-votte-setting-public">
				<CheckboxInput
					id={"radio-key-vote-public"}
					onChange={(e) => {setPublicResult(!publicResult)}}
					isChecked={publicResult === true}
					isDisabled={disabled}
					labelText={t('activity.voteSetting.publishResult')}
				/>
			</div>

			<div className="sub-title">{t('activity.voteSetting.voteOption')}</div>
			<div className="sub-des">{t('activity.voteSetting.voteOptionDes')}</div>

			{voteOptions.map((aOption, i) => {
				return (
				<VoteOption
					allTags={allTags}
					index={i}
					key={`vote-option-key-${i}-${aOption.key}`}
					optionType={optionType}
					disabled={disabled}
					option={aOption}
					onOptionUpdate={(option) => {
						const optionCopy = voteOptions.slice()
						optionCopy[i] = option
						setVoteOptions(optionCopy)
					}}
					onDelete={() => {
						const optionCopy = voteOptions.slice()
						optionCopy.splice(i, 1)
						setVoteOptions(optionCopy)
					}}
					channelId={props.channelId}
				/>
				)
			})}

			<button
				className={cx("c-btn c-btn--primary", { 'is-disabled': disabled})}
				onClick={() => {
					const optionCopy = voteOptions.slice()
					optionCopy.push({
						"optionValue" : "",
						"tags" : []
					})
					setVoteOptions(optionCopy)
				}}>{t('activity.voteSetting.newVoteOption')}
			</button>
		</div>
	)
}

export default VoteSetting;