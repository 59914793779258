import React, { useState, useEffect } from 'react';
import { Link, Route, Switch  } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import permissionEnum from 'enums/permission'
import ChannelNameAndPhoto from 'Console/CreateChannel/ChannelNameAndPhoto'
import { fetchHelper } from 'tools/FetchHelper'
import LineConnectSetting from '../LineConnectSetting'

import MemberSection from './MemberSection'
import DefaultOpSetting from './DefaultOpSetting'
import NewMessageNotifySetting from './NewMessageNotifySetting'
import UnreplyMessageNotifySetting from './UnreplyMessageNotifySetting'

import './index.css'

const SettingDetail = (props) => {
	const { t } = useTranslation();
	const {isAdmin, settings, setSettings, fetchChannelSetting, isFetching} = props

	const [isModalOpen, setModalState] = useState(false)
	const [isInitOpUpdating, setInitOpUpdating] = useState(null)

	// TODO 目前會因為執行setSettings()讓上層的props改變所以重新ｒｅｎｄｅｒ 這兩個值會被變更回初始的null 之後再解決
	const [isNewMessageNotifyUpdating, setNewMessageNotifyUpdating] = useState(null)
	const [isUnreplyMessageNotifyUpdating, setUnreplyMessageNotifyUpdating] = useState(null)

	const updateInitAssignOp = async () => {
		setInitOpUpdating(true);
		try {
			const result = await fetchHelper.patch(`/channel/${props.channelId}` , {
				initAssignOp: settings.initAssignOp,
			});
			const res = result.data
			if (res.status === "OK") {
				setInitOpUpdating(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setInitOpUpdating(false);
			console.log('update init assign op fail', error.message)
		}
	}

	const updateNewMessageNotifySetting = async ({postBody, callback}) => {
		setNewMessageNotifyUpdating(true);
		try {
			const result = await fetchHelper.patch(`/channel/${props.channelId}/notify/newMessage` , postBody)
			const res = result.data
			if (res.status === "OK") {
				setNewMessageNotifyUpdating(false)
				callback()

				// TODO 不是很好的做法 應該把目前頻道的設定放在SettingDetail這一層
				setTimeout(() => {
					setSettings({
						...settings,
						notifySetting: {
							...settings.notifySetting,
							newMessage: postBody
						}
					})
				}, 500)

				// setSettings({
				// 	...settings,
				// 	notifySetting: {
				// 		...settings.notifySetting,
				// 		newMessage: postBody
				// 	}
				// })
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setNewMessageNotifyUpdating(false);
			console.log('update fail', error.message)
		}
	}

	const updateUnreplyMessageNotifySetting = async ({postBody, callback}) => {
		setUnreplyMessageNotifyUpdating(true);
		try {
			const result = await fetchHelper.patch(`/channel/${props.channelId}/notify/unreplyMessage` , postBody)
			const res = result.data
			if (res.status === "OK") {
				setUnreplyMessageNotifyUpdating(false);
				callback()
				setTimeout(() => {
					setSettings({
						...settings,
						notifySetting: {
							...settings.notifySetting,
							unreplyMessage: postBody
						}
					})
				}, 500)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setUnreplyMessageNotifyUpdating(false);
			console.log('update init assign op fail', error.message)
		}
	}

	if (isFetching) {
		return (
			<div className="icon-loading">
				<i className="fal fa-spinner-third fa-spin" />
			</div>
		)
	}

	return (
		<div className="channel-setting">
			<div className="page-title">{t('setting.title')}</div>
			{!isAdmin &&
				<div className="not-admin">{t('setting.notAdmin')}</div>
			}

			{isAdmin &&
				<>
					<div className="platfrom-connect-status">
						<div className="connect-to-line">
							<div className="sub-title">{t('setting.line@')}</div>
							<div className={cx("c-tag", { success: !!settings.lineData})}>
								{!!settings.lineData ?
									t('setting.connected') :
									t('setting.unset')
								}
							</div>
						</div>
						{!!settings.lineData &&
							<div className="connect-account">
								{t('setting.connectedAccount')}:
								{settings.lineData.lineAtId}
							</div>
						}
						<Link className="c-btn c-btn--primary" to={`${props.match.url}/line`}>
							{t('setting.connectSetting')}
						</Link>
					</div>
					<ChannelNameAndPhoto
						{...props}
						redirect={false}
						settings={settings}
					/>
				</>
			}

			{isAdmin &&
				<MemberSection
					channelId={props.channelId}
					operators={settings.operators}
					setOperators={(newVal) => {
						setSettings({
							...settings,
							operators : newVal
						})
					}}
					isModalOpen={isModalOpen}
					setModalState={setModalState}
					fetchChannelSetting={fetchChannelSetting}
				/>

			}

			{isAdmin &&
			<>
				<DefaultOpSetting
					settings={settings}
					setSettings={(op)=>{
						setSettings({
							...settings,
							initAssignOp: op
						})
					}}
					isInitOpUpdating={isInitOpUpdating}
					updateInitAssignOp={()=>{
						updateInitAssignOp()
					}}
				/>
				<NewMessageNotifySetting
					settings={settings.notifySetting ? settings.notifySetting.newMessage || {} : {}}
					updateSetting={updateNewMessageNotifySetting}
					isUpdating={isNewMessageNotifyUpdating}
				/>
				<UnreplyMessageNotifySetting
					settings={settings.notifySetting ? settings.notifySetting.unreplyMessage || {} : {}}
					updateSetting={updateUnreplyMessageNotifySetting}
					isUpdating={isUnreplyMessageNotifyUpdating}
				/>
			</>
			}
			<div className="page-tail"></div>
		</div>
	)
}

const Setting = (props) => {
	const isAdmin = props.currentChannel.permissionType === permissionEnum.type.admin

	const [settings, setSettings] = useState({
		operators: [],
		lineData: {}
	})
	const [isFetching, setFetching] = useState(true) // let this page render after fetch

	const fetchChannelSetting = async () => {
		setFetching(true);
		try {
			const result = await fetchHelper.get(`/channel/${props.channelId}`);
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				setSettings(res.data)
				setFetching(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false);
			console.log('fecth channel setting', error.message)
		}
	}

	useEffect(() => {
		if (isAdmin) {
			fetchChannelSetting()
		}
	}, []);

	if (isFetching) {
		return (
			<div className="icon-loading">
				<i className="fal fa-spinner-third fa-spin" />
			</div>
		)
	}

	return (
		<Switch>
			<Route
				path={`${props.match.path}/line`}
				component={() => <LineConnectSetting
					{...props}
					settings={settings}
				/>}
			/>
			<Route component={() => <SettingDetail
				{...props}
				isAdmin={isAdmin}
				settings={settings}
				setSettings={setSettings}
				fetchChannelSetting={fetchChannelSetting}
				isFetching={isFetching}
			/>} />
		</Switch>
	)
}

export default Setting;
