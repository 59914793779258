import React, { useState, useEffect, setGlobal, useCallback } from 'reactn';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import numeral from 'numeral'
import { Link } from 'react-router-dom'

import Modal from 'Components/Modal'
import AddQuestionBlock from 'Components/AddQuestionBlock'
import Answers from './Answers'
import UploadModal from 'Components/Uploader/UploadModal';
import { fetchHelper } from 'tools/FetchHelper'
import enums from 'enums'

import './index.css'

const ENTER_KEY = 13;

const QnARow = (props) => {
	const { t } = useTranslation();
	const [qna, setQA] = useState(props.qna)
	const [showDeleteModal, setDeleteModal] = useState(false)

	const upsertQA = async (newQA) => {
		setGlobal({ showUpdating: 'saving' })
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/bot/qas`, {
				"qas" : [newQA]
			}, {
				fetchQAbot: true
			});
			const res = result.data

			if (res.status === "OK") {
				setQA(res.data.qas[0])
				props.onUpdated(res.data.qas[0])
				setGlobal({ showUpdating: 'saved' })
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setGlobal({ showUpdating: 'error' })
			console.log('update bot qas fail', error.message)
		}
	}

	const deleteQA = async (qasId, callback) => {
		setGlobal({ showUpdating: 'saving' })
		try {
			const result = await fetchHelper.delete(`/channel/${props.channelId}/bot/qas/${qasId}`, {}, {
				fetchQAbot: true
			});
			const res = result.data

			if (res.status === "OK") {
				setDeleteModal(false)
				setGlobal({ showUpdating: 'saved' })
				callback()
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setGlobal({ showUpdating: 'error' })
			console.log('update bot qas fail', error.message)
		}
	}

	return (
		<tr className="a-qa-row">
			<td className="no">{props.no}</td>
			<td className="question">
				<AddQuestionBlock
					{...props}
					placeholder={t('bot.enterQuestions')}
					questions={qna.keywords.map(str => ({value: str}))}
					onChange={(result) => {
						const keywords = result.map(aQues => aQues.value)
						const newQA = {
							...qna,
							keywords
						}
						setQA(newQA)
						upsertQA(newQA)
					}}
				/>
			</td>
			<td className="answer">
				<Answers
					messages={qna.reply}
					onChange={(result) => {
						const newQA = {
							...qna,
							reply: result
						}
						setQA(newQA)
						upsertQA(newQA)
					}}
					{...props}
				/>
			</td>
			<td className="delete-icon">
				{qna._id &&
					<i className="fal fa-trash" onClick={() => setDeleteModal(true)} />
				}
				<Modal
					className="delete-member-modal"
					isOpen={showDeleteModal}
					cancelBGClick
					onRequestClose={() => {
						setDeleteModal(false)
					}}
				>
					<div className="modal-title">{t('deleteConfirm')}</div>
					<div className="modal-description">{`NO ${props.no} QA`}</div>
					<div className="button-group">
						<button className="c-btn c-btn--danger" onClick={() => {
							deleteQA(qna._id, props.onDelete)
						}}>{t('delete')}</button>
						<button className="c-btn" onClick={() => setDeleteModal(false)}>{t('cancel')}</button>
					</div>
				</Modal>
			</td>
		</tr>
	)
}

const QnA = (props) => {
	const { t } = useTranslation();
	const [isFetching, setFetching] = useState(false)
	const [keyword, setKeyword] = useState(null)
	const [qas, setQAs] = useState([])
	const [showUploadMoadl, setUploadModal] = useState(false)
	const [showSearchResult, setShowSearchResult] = useState(false)

	const updateQAfromImport = async (newQA) => {
		setGlobal({ showUpdating: 'saving' })
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/bot/qas`, {
				"qas" : newQA
			}, {
				fetchQAbot: true
			});
			const res = result.data
			if (res.status === "OK") {
				const qasCopy = qas.slice()
				setQAs([].concat(res.data.qas, qasCopy))
				setGlobal({ showUpdating: 'saved' })
				setFetching(false)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setGlobal({ showUpdating: 'error' })
			console.log('import bot qas fail', error.message)
		}
	}

	const fetchBotQas = useCallback(async () => {
		setFetching(true)
		try {
			const result = await fetchHelper.get(`/channel/${props.channelId}/bot/qas`, {
				fetchQAbot: true,
				params: {
					keyword: keyword
				}
			});
			const res = result.data
			if (res.status === "OK") {
				setQAs(res.data.qas)
				setShowSearchResult(!!keyword)
				setFetching(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false);
			console.log('get bot qas fail', error.message)
		}
	}, [props.channelId, keyword])


	const displayAns = (ansOrigin) => {
		let ansDisplay = ansOrigin
		if (ansDisplay.includes("\\n")) {
			if (ansDisplay[0] === "\"" && ansDisplay[ansDisplay.length-1] === "\"") {
				ansDisplay = ansDisplay.slice(1, ansDisplay.length-1);
			}
			ansDisplay = ansDisplay.replace(/\\n/g, "\n")
		}
		return ansDisplay
	}

	useEffect(() => {
		fetchBotQas()
	}, [fetchBotQas])

	return (
		<div className="qabot-page">
			<div className="page-title">
				<Link to={`/console/channel/${props.channelId}/bot`}>{t('bot.title')}</Link>
				<i className="far fa-chevron-right" />
				{t('bot.qna')}
			</div>
			<div className="page-des">{t('bot.qnaDes')}</div>
			<div className={cx("c-txt__input c-txt__input--media search-input", { active: !!keyword })}>
				<input
					className="c-txt__input c-txt__input--bare c-txt__input--media__body"
					type="text"
					placeholder={t('bot.enterKeyword')}
					value={keyword || ""}
					onChange={(e) => setKeyword(e.target.value)}
					onKeyDown={(e) => {
						if (e.keyCode === ENTER_KEY) {
							fetchBotQas()
						}
					}}
				/>
				<div className="c-txt__input--media__figure">
					<i className="fal fa-search" onClick={() => fetchBotQas()} />
				</div>
			</div>
			<div className="search-result">
				{showSearchResult && `${t('search')} "${keyword}", `}
				{t('friends.searchResult', { searchNumbers: numeral(qas.length).format('0,0')})}
			</div>
			<div className="qna-entries">
				<div className="top-buttons">
					<a
						className="a-button"
						href="https://lineline.s3.amazonaws.com/systemFiles/import-csv-example.csv"
						download="import-csv-example.csv"
					>
						<i className="fal fa-file-download" />
						{t('bot.downloadTemplate')}
					</a>
					<div className="a-button" onClick={() => setUploadModal(true)}>
						<i className="fal fa-file-download" />
						{t('bot.importFile')}
					</div>
				</div>
				<table className="table-header">
					<tbody>
						<tr className="a-qa-row">
							<td className="no">NO</td>
							<td className="question">{t('bot.question')}</td>
							<td className="answer">{t('bot.answer')}</td>
							<td className="empty"></td>
						</tr>
					</tbody>
				</table>
				<table className="add-new-row">
					<tbody>
						<tr className="a-qa-row" onClick={() => {
								const qasCopy = qas.slice()
								qasCopy.unshift({
									reply: [],
									keywords: [],
									timestamp: new Date().getTime()
								})
								setQAs(qasCopy)
							}}>
							<td className="no"><i className="fal fa-plus" /></td>
							<td className="question">  </td>
							<td className="answer">   </td>
							<td className="empty"></td>
						</tr>
					</tbody>
				</table>
				<table>
					<tbody>
						{isFetching ?
							<tr className="a-qa-row">
								<td className="is-loading">
									<div className="icon-loading">
										<i className="fal fa-spinner-third fa-spin" />
									</div>
								</td>
								<td className="empty"></td>
							</tr> :
							qas.map((aQA, i) => (
								<QnARow
									{...props}
									qna={aQA}
									key={`a-qa-row-${aQA._id || aQA.timestamp}-${i}`}
									no={i+1}
									onUpdated={(qa) => {
										const qasCopy = qas.slice()
										qasCopy[i] = qa
										setQAs(qasCopy)
									}}
									onDelete={() => {
										const qasCopy = qas.slice()
										qasCopy.splice(i, 1)
										setQAs(qasCopy)
									}}
								/>
							))
						}
					</tbody>
				</table>
			</div>
			<UploadModal
				isModalOpen={showUploadMoadl}
				onRequestClose={() => setUploadModal(false)}
				folderType={enums.FileUploadType.channel}
				validImageTypes={"text/csv,application/vnd.ms-excel"}
				uploadCSVFile
				onUpLoaded={(result) => {
					setFetching(true)
					const newQA = []
					result.map((qna, i) => {
						if (qna.length < 3) return null
						if (['欄位', '範例'].includes(qna[0])) return null;
						if (!qna[0] || !qna[1] || !qna[2]) return null;

						newQA.push({
							reply: qna[2].split(';').map(aReply => ({
								text: displayAns(aReply),
								type: 'text'
							})),
							keywords: qna[1].split(';'),
							timestamp: new Date().getTime()
						})

						return qna
					})

					if (newQA.length > 0) {
						updateQAfromImport(newQA)
					} else {
						setFetching(false)
					}
				}}
				channelId={props.channelId}
			/>
		</div>
	)
}

export default QnA;