import React from 'react';
import cx from 'classnames';

import Avatar from './Avatar';
import NameAndTime from './NameAndTime';

import './index.css';


const ActivityCard = (props) => {
	return (
		<div className={cx("chat-dialogue activity-card",
			{ 'hide-avatar': props.hideAvatar }
		)}>
			{!props.hideAvatar &&
				<Avatar {...props} />
			}
			<div className="dialogue-content">
				{!props.hideAvatar &&
					<NameAndTime {...props} />
				}
				<div className="content-section">
					<div className="cover-image">
						<figure>
							<img alt="" src={props.msg.image} />
						</figure>
					</div>
					<div className="title-and-text">
						<div className="title">{props.msg.title}</div>
						<div className="text">{props.msg.text}</div>
					</div>
					<div className="actions">
						<div className="a-action" >
							{"立即投票"}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ActivityCard;

/*
data : {
    "type" : "voteActivityCard",
    "activityGuid" : "ce3e599e-943c-43b8-a143-dc662870dd0e",
    "title" : "測試投票活動001",
    "image" : "https://lineline.s3.ap-northeast-2.amazonaws.com/systemFiles/vote_activity.jpg",
    "activityUrl" : "https://beta.shorten-url.trip-ninja.io/v1.0/y5qff",
    "text" : "2020/04/16 - "
}
*/