import React, { useState, useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  Link,
  matchPath
} from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';
import { fetchHelper } from 'tools/FetchHelper'

import enums from 'enums'
import permissionEnum from 'enums/permission'
import channelFuncList from './channelFuncList'
import ShowUpdating from './ShowUpdating';
import MobileMenu from './MobileMenu'

import LineConnectSetting from './LineConnectSetting'
import { ValidationError } from 'tools/errorHandler'

import './index.css'

const getChnannelStatus = async (channelId) => {
	let result = await fetchHelper.get(`/channel/${channelId}/status`);
	return result
}

const TabDropdown = ({ index, funcName, curranTab, match, currentChannel}) => {
	const { t } = useTranslation();
	const aFunc = channelFuncList[funcName]
	const currentPermission = permissionEnum[permissionEnum.type[currentChannel.permissionType]]

	return (
		<ThemeProvider
			theme={{
				'menus.menu_view': `
					&& {
						padding: 0;
						top: 60px;
						max-width: 150px;
						min-width: 150px;
					}
				`,
				'menus.item': `
					&&& {
						height: 50px;
						background-color: #ffffff;
						display: flex;
						align-items: center;
						justify-content: center;

						&:hover,&.is-hovered, &:focus, &.is-focused, &.is-active {
							background-color: #ffffff;
						    color: var(--dark-sky-blue);
						}
					}
				`
			}}
		>
			<Menu
				id="tab-menu-dropdown"
				trigger={({ ref, isOpen }) => {
					return (
						<div
							ref={ref}
							className={cx(
								"a-tab",
								{ active: Object.keys(aFunc.sub).includes(curranTab)}
							)}
						>
							{t(`channel.${funcName}`)}
							<i className="far fa-chevron-down" />
						</div>
					)
				}}
			>
				{Object.keys(aFunc.sub).map((subMenu, j) => {
					if (!currentPermission.allowFunction.includes(subMenu)) return null

					return (
						<Link
							key={`a-sub-tab-${index}-${j}`}
							to={`${match.url}/${subMenu}`}
							className={cx("a-tab", { active: curranTab === subMenu })}
						>
							<Item className="c-txt__input--select-item" >
							{t(`${subMenu}.title`)}
							</Item>
						</Link>
					)
				})}
			</Menu>
		</ThemeProvider>
	)
}



const Channel =  (props) => {
	const { t } = useTranslation();
	const match = props.match;

	const getFuncNameMatch = matchPath(props.history.location.pathname, {
		path: `${match.path}/:channelFunc`
	})
	
	// const channelStatus = getChnannelStatus(match.params.channelId)

	const curranTab = getFuncNameMatch.params.channelFunc || 'dashboard'
	const currentChannel = props.channels.filter(x => x.guid === match.params.channelId).pop()
	
	const [channelAvailable, setChannelAvailable] = useState(true)

	useEffect(() => {
		const fetchChannelStatus = async () => {
			if (!isUnmounted) {
				let result = await getChnannelStatus(match.params.channelId)
				setChannelAvailable(result.data.data.available)
			}				
		}
		let isUnmounted = false;
		fetchChannelStatus()
		return () => { 
			isUnmounted = true 
		}
	}, [currentChannel])

	if (!currentChannel) {
		throw new ValidationError("", enums.errorType.channelPermissionMissing)
	}

	const currentPermission = permissionEnum[permissionEnum.type[currentChannel.permissionType]]
	const isChannelNeedLineConnect = !currentChannel.connectToLine
	const mobileMenuData = {
		items: Object.keys(channelFuncList)
			.map((aFuncName, i) => {
				if (!currentPermission.allowFunction.includes(aFuncName)) return null
					const aFunc = channelFuncList[aFuncName]
					let item = {
						class: cx({'active' : curranTab === aFuncName})
					}

					if (aFunc.sub) {
						item = {
							...item,
							labelKey: `channel.${aFuncName}`,
							childs: Object.keys(aFunc.sub)
								.map((subMenu, j) => {
									if (!currentPermission.allowFunction.includes(subMenu)) return null

									return {
										labelKey: `${subMenu}.title`,
										url: `${match.url}/${subMenu}`,
										class: cx({'active' : curranTab === subMenu})
									}
								})
								.filter(x => !!x)
						}
					}
					else {
						item = {
							...item,
							labelKey : `${aFuncName}.title`,
							url : `${match.url}/${aFuncName}`
						}
					}
					return item
				})
			.filter(x => !!x)
	}

	return (
		<div className="console-channel">
			<div className="sub-header">
				<div className="channel-tabs">
					{Object.keys(channelFuncList).map((aFuncName, i) => {
						const aFunc = channelFuncList[aFuncName]

						if (!currentPermission.allowFunction.includes(aFuncName)) return null

						if (aFunc.sub) {
							return (
								<TabDropdown
									key={`a-tab-${i}`}
									{...props}
									index={i}
									funcName={aFuncName}
									curranTab={curranTab}
									currentChannel={currentChannel}
								/>
							)
						}

						return (
							<Link
								to={`${match.url}/${aFuncName}`}
								className={cx("a-tab", { active: curranTab === aFuncName })}
								key={`a-tab-${i}`}
							>
								{t(`${aFuncName}.title`)}
							</Link>
						)
					})}
				</div>
				<ShowUpdating />
			</div>
			<MobileSubHeader menuData={mobileMenuData}/>

			<div className="tab-content">
				<Switch>
					<Route
						path={`${match.path}/initial/line`}
						component={() => <LineConnectSetting
							channelId={match.params.channelId}
							currentChannel={currentChannel}
						/>}
					/>

					{Object.keys(enums.ChannelFuncList).reduce((pre, aFuncName, i) => {
						
						if (!currentPermission.allowFunction.includes(aFuncName)) return pre
						const aFunc = channelFuncList[channelAvailable ? aFuncName : 'unavailable']
						if (aFunc.sub) {
							let subFuncNames = Object.keys(aFunc.sub).filter((subFunc) => {
								return currentPermission.allowFunction.includes(subFunc)
							})
							pre.push(subFuncNames.map((subFuncName, j) => {
								const SubComp = isChannelNeedLineConnect ? channelFuncList.unSet.component : aFunc.sub[subFuncName].component
								return (
									<Route
										key={`channel-func-${i}-${j}`}
										path={`${match.path}/${subFuncName}`}
										component={(routerProps) => <SubComp
											channelId={match.params.channelId}
											currentChannel={currentChannel}
											{...props}
											{...routerProps}
										/>}
									/>
								)
							}))
						}
						else {
							const Component = isChannelNeedLineConnect ? channelFuncList.unSet.component : aFunc.component
							pre.push(
								<Route
									key={`channel-func-${i}`}
									path={`${match.path}/${aFuncName}`}
									component={(routerProps) => <Component
										channelId={match.params.channelId}
										currentChannel={currentChannel}
										{...props}
										{...routerProps}
									/>}
								/>
							)
						}
						return pre
					}, [])}
					<Route render={() => <Redirect to="/channels" /> } />
				</Switch>
			</div>
		</div>
	);
}




const MobileSubHeader = (props) => {
	const [isMenuOpen, setMenuOpen] = useState(false)
	const {menuData} = props

	return (
		<div className="mobile-sub-header">
			{isMenuOpen ?
				<div className="channel-menu">
					<div className="icon-part" onClick={()=>{setMenuOpen(false)}}>
						<i className="fas fa-times" />
					</div>
					<div className="menu-content">
						<MobileMenu data={menuData}/>
					</div>

				</div>
			:

				<div className="float-bars" onClick={()=>{setMenuOpen(true)}}>
					<i className="fas fa-bars" />
				</div>
			}
		</div>
	)
}

export default Channel;
