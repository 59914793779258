import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrag, useDrop } from 'react-dnd'
import ReactTooltip from 'react-tooltip'
import cx from 'classnames'
import AutosizeInput from 'react-input-autosize';
import { fetchHelper } from 'tools/FetchHelper'

import TagControlModal from './TagControlModal';

const ENTER_KEY = 13;

const DNDTags = ({ tag, aDir, index, moveTag, deleteTag, deleteTagFromList, checkDuplicateTag, channelId }) => {
	const { t } = useTranslation();
	const dropRef = useRef(null);
	const [tagText, setTagText] = useState(tag.text)
	const tagTextRef = useRef(tag.text)
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
	const [isModifyModalOpen, setModifyModalOpen] = useState(false)
	const [isMergeModalOpen, setMergeModalOpen] = useState(false)

	const resolveDropTagDirection = (monitor) => {
		const hoverBoundingRect = dropRef.current.getBoundingClientRect()

		const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
		const clientOffset = monitor.getClientOffset()
		if (!clientOffset) return false
		const hoverClientX = clientOffset.x - hoverBoundingRect.left

		return hoverClientX >= hoverMiddleX ? true : false;
	}

	const [{ isDragging }, drag] = useDrag({
		item: {
			type: 'tag',
			draggedTag: tag,
			draggedTagIndex: index,
			fromDirGUID: aDir.guid,
		},
		collect: monitor => {

			return {
				isDragging: !!monitor.isDragging(),
			}
		},
	})
	const [{ isTagOver, shouldDropRight }, dropTag] = useDrop({
		accept: 'tag',
		drop: ({ draggedTag, draggedTagIndex, fromDirGUID }, monitor) => {

			if (dropRef.current && draggedTag.guid !== tag.guid) {
				const shouldDropRight = resolveDropTagDirection(monitor)
				moveTag(draggedTag, draggedTagIndex, fromDirGUID, shouldDropRight ? index + 1 : index)
			}
		},
		collect: monitor => ({
			isTagOver: !!monitor.isOver(),
			shouldDropRight: () => {

				if (dropRef.current && monitor.getItem() && monitor.getItem().draggedTag.guid !== tag.guid) {
					return resolveDropTagDirection(monitor)
				}

				return null
			},
		}),
	})

	const updateTagText = async (...[isMerge]) => {
		try {
			const result = await fetchHelper.post(`/channel/${channelId}/tag/${tag.guid}`, {
				text: tagText
			});
			const res = result.data
			if (res.status === "OK") {
				if (isMerge){
					deleteTagFromList(tag, index)
				}
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('update tag fail', error.message)
		}
	}

	dropTag(dropRef)

	const renameTag = (e) => {
		if (e.target.value !== tagTextRef.current) {
			if(checkDuplicateTag(e.target.value, index)){
				setMergeModalOpen(true)
			}
			else {
				setModifyModalOpen(true)
			}
		}
	}

	return (
		<div className="tag-n-tooltip" ref={dropRef}>
			<ReactTooltip
				delayHide={500}
				place="bottom"
				effect="solid"
				type="light"
				className='tag-friends-count'
				id={`dnd-tag-${tag.guid}`}
			>
				{t('tags.friendsHasTheTag', { friendsNumber: tag.count })}
				<i className="far fa-search" onClick={() => {
					window.location.href = `/console/channel/${channelId}/friends?search=${tag.text}&searchType=tag`
				}} />
			</ReactTooltip>
			{isTagOver && shouldDropRight() !== null && !shouldDropRight() &&
				<div className="temp-tag" />
			}
			<div
				ref={drag}
				className={cx(
					"a-dnd-tag",
					{ 'is-dragging': isDragging }
				)}
				data-tip
				data-for={`dnd-tag-${tag.guid}`}
			>
				<AutosizeInput
					value={tagText || ""}
					onChange={(e) => setTagText(e.target.value)}
					onBlur={(e) => renameTag(e)}
					onKeyDown={(e) => {
						if (e.keyCode === ENTER_KEY && e.target.value !== tagTextRef.current) {
							renameTag(e)
						}
					}}
				/>
				<i className="far fa-times icon-delete" onClick={() => setDeleteModalOpen(true)} />
	 		</div>
	 		{isTagOver && shouldDropRight() !== null && shouldDropRight() &&
				<div className="temp-tag" />
			}
			<TagControlModal
				title={t('tags.deleteTagConfirm', { friendsNumber: tag.count })}
				shouldConfirm={['delete', 'cancel']}
				isModalOpen={isDeleteModalOpen}
				onRequestClose={() => setDeleteModalOpen(false)}
				onDelete={() => {
					deleteTag(tag, index)
					setDeleteModalOpen(false)
				}}
			/>
			<TagControlModal
				title={t('tags.modifyTagConfirm', {
					friendsNumber: tag.count,
					tagNameA: tagTextRef.current,
					tagNameB: tagText
				})}
				shouldConfirm={['cancel', 'confirm']}
				isModalOpen={isModifyModalOpen}
				onRequestClose={() => {
					setTagText(tagTextRef.current)
					setModifyModalOpen(false)
				}}
				onConfirm={() => {
					updateTagText()
					setModifyModalOpen(false)
				}}
			/>
			<TagControlModal
				title={t('tags.mergeTagConfirm', {
					friendsNumber: tag.count,
					tagNameA: tagTextRef.current,
					tagNameB: tagText
				})}
				shouldConfirm={['cancel', 'confirm']}
				isModalOpen={isMergeModalOpen}
				onRequestClose={() => {
					setMergeModalOpen(false)
					setTagText(tagTextRef.current)
				}}
				onConfirm={() => {
					setMergeModalOpen(false)
					const isMerge = true
					updateTagText(isMerge)
				}}
			/>
		</div>
    )
}

export default DNDTags;