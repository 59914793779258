import React, { useState, useEffect } from 'reactn';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import zhTW from 'dayjs/locale/zh-tw'

import enums from 'enums'
import permissionEnum from 'enums/permission'

import { fetchHelper } from 'tools/FetchHelper'

import MessageCenterLeft from './MessageCenterLeft'
import MessageCenterRight from './MessageCenterRight'
import './index.css'

dayjs.locale('zh-tw', zhTW)
dayjs.extend(relativeTime)

const MessageCenter = (props) => {
	const { currentChannel } = props
	const isCustomerServiePermission = currentChannel.permissionType === permissionEnum.type.csr
	const [ owners, setOwners ] = useState([])

	// general var of message center
	const [ currentFriend, setCurrent ] = useState({})
	const [ changedFriendDetail, setChangedFriendDetail ] = useState(null)
	const [ updateCallOpDetail, setUpdateCallOpDetail ] = useState(null)
	const [ updateUnReadDetail, setUpdateUnReadDetail ] = useState(null)

	const [ isDisplayChat, setDisplayChat ] = useState(false) // for mobile


	const msgRead = async (friend) => {
		if (friend.guid && (friend.hasUnreadMsg || friend.unreadCount !== 0)) {
			try {
				const result = await fetchHelper.post(`/channel/${props.channelId}/chatRoom/${friend.guid}/read`, {
					hasUnreadMsg: false
				});
				const res = result.data

				if (res.status === "OK") {
					updateUnReadAlert(friend.guid, false)
				}

			} catch (error) {
				console.log('send msg read failed', error)
			}
		}
	}

	const toggleChatRoomStatus = async (chatRoom, updateStatus) => {
		try {
			const postbody = {
				"switchStatus" : [updateStatus]
			}
			const result = await fetchHelper.post(
				`/channel/${props.channelId}/chatRoom/${chatRoom.guid}/status`, postbody);

			if (result.statusText === 'OK') {
				setChangedFriendDetail({
					guid: chatRoom.guid,
					activateStatus: result.data.data
				})
			}

		} catch (error) {
			console.log('toggle status fail', error.message)
		}
	}

	const updateCallOpAlert = async (chatRoomGUID, callOpAlert) => {
		const isCallOpStatus = enums.ChatRoomActivateStatusType.isCallOp

		if (currentFriend.guid === chatRoomGUID) {
			let updateActivateStatus = currentFriend.activateStatus ? currentFriend.activateStatus.slice() : []
			if (callOpAlert && !updateActivateStatus.includes(isCallOpStatus)) {
				updateActivateStatus.push(isCallOpStatus)
			} else if (!callOpAlert && updateActivateStatus.includes(isCallOpStatus)) {
				updateActivateStatus = updateActivateStatus.filter(x => x !== isCallOpStatus)
			}

			setCurrent({
				...currentFriend,
				activateStatus: updateActivateStatus
			})
		}

		setUpdateCallOpDetail({
			guid: chatRoomGUID,
			callOpAlert: callOpAlert
		})
	}

	const updateUnReadAlert = async (chatRoomGUID, unReadAlert) => {
		setUpdateUnReadDetail({
			guid: chatRoomGUID,
			unReadAlert: unReadAlert
		})
	}

	const updateChatRoomMessage = async (replyMsg) => {
		const chatRoomGUID = replyMsg.recipient.chatRoomGuid
		updateUnReadAlert(chatRoomGUID, true)
		let updateChatRoomMessageDetail = {
			guid: chatRoomGUID,
			latestMessage: {
				createDtm: dayjs().toString(),
				message: replyMsg.data,
				sender: replyMsg.sender || {}
			},
			lastMessageDtm: dayjs().toString()
		}

		if (replyMsg.sender && replyMsg.sender.lineUserId) {
			updateChatRoomMessageDetail.userLastMsgDtm = dayjs().format()
		}

		setChangedFriendDetail(updateChatRoomMessageDetail)
	}

	useEffect(() => {
		if (changedFriendDetail && changedFriendDetail.guid === currentFriend.guid) {
			setCurrent({
				...currentFriend,
				...changedFriendDetail
			})
		}
	}, [changedFriendDetail, currentFriend])

	useEffect(() => {
		const getOperators = async () => {
			try {
				const result = await fetchHelper.get(`/channel/${props.channelId}/op/list`);
				const res = result.data.data

				setOwners(res.map((item) => {
					return {
						type: enums.ChatRoomAssignOwnerType.op,
						name: item.name,
						GUID: item.GUID
					}
				} ))
			} catch (error) {
				console.log('get channel op fail', error.message)
			}
		}
		getOperators()
	}, []);

	return (
		<div className="channel-message-center">
			<MessageCenterLeft
				channelId={props.channelId}
				location={props.location}
				owners={owners}
				isCustomerServiePermission={isCustomerServiePermission}
				msgRead={msgRead}
				currentFriend={currentFriend}
				setCurrent={setCurrent}
				changedFriendDetail={changedFriendDetail}
				setChangedFriendDetail={setChangedFriendDetail}
				toggleChatRoomStatus={toggleChatRoomStatus}
				updateCallOpDetail={updateCallOpDetail}
				setUpdateCallOpDetail={setUpdateCallOpDetail}
				updateUnReadDetail={updateUnReadDetail}
				setUpdateUnReadDetail={setUpdateUnReadDetail}
				isDisplayChat={isDisplayChat}
				setDisplayChat={setDisplayChat}
			/>
			<MessageCenterRight
				channelId={props.channelId}
				owners={owners}
				disableOwnerSelector={isCustomerServiePermission}
				msgRead={msgRead}
				currentFriend={currentFriend}
				changedFriendDetail={changedFriendDetail}
				setChangedFriendDetail={setChangedFriendDetail}
				toggleChatRoomStatus={toggleChatRoomStatus}
				updateCallOpAlert={updateCallOpAlert}
				updateUnReadAlert={updateUnReadAlert}
				updateChatRoomMessage={updateChatRoomMessage}
				isDisplayChat={isDisplayChat}
				setDisplayChat={setDisplayChat}
			/>
		</div>
	)
}

export default MessageCenter;