import React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import './index.css'

const Landing = (props) => {
	const { t } = useTranslation();

	return (
		<div className="landing-page">
			<header>
				<div className="top-section">
					<a href="https://knowledge.trip-ninja.io/books/line-bagel" target="_blank" rel="noreferrer noopener">
						{t('landing.tutorial')}
					</a>
					<Link to="/console" className="with-border">{t('landing.useNow')}</Link>
				</div>
				<div className="main-des">
					<h1>{t('landing.exprienceTitle')}</h1>
					<p>{t('landing.exprienceDes')}</p>
					<Link to="/console" className="c-btn c-btn--primary">{t('landing.useNow')}</Link>
				</div>
				<img src="https://lineline.s3.amazonaws.com/icons/banner1.png" alt=""/>
			</header>
			<div className="main-des-center">
				<div className="main-des">
					<h1>{t('landing.exprienceTitle')}</h1>
					<p>{t('landing.exprienceDes')}</p>
					<Link to="/console" className="c-btn c-btn--primary">{t('landing.useNow')}</Link>
				</div>
			</div>

			<div className="potential-customer">
				<div className="sub-title">{t('landing.customerTitle')}</div>
				<div className="sub-des">{t('landing.customerDes')}</div>
			</div>
			<div className="features-section">
				<div className="a-feature">
					<img src="https://lineline.s3.amazonaws.com/icons/forcowell.png" alt=""/>
					<div className="feature-info">
						<div className="platform-feature">{t('landing.feature1')} -</div>
						<div className="sub-title">{t('landing.featureTitle1')}</div>
						<div className="sub-des">{t('landing.featureDes1')}</div>
					</div>
				</div>
				<div className="a-feature reverse">
					<img src="https://lineline.s3.amazonaws.com/icons/qarobot.png" alt=""/>
					<div className="feature-info">
						<div className="platform-feature">{t('landing.feature2')} -</div>
						<div className="sub-title">{t('landing.featureTitle2')}</div>
						<div className="sub-des">{t('landing.featureDes2')}</div>
					</div>
				</div>
				<div className="a-feature">
					<img src="https://lineline.s3.amazonaws.com/icons/tagmarketing.png" alt=""/>
					<div className="feature-info">
						<div className="platform-feature">{t('landing.feature3')} -</div>
						<div className="sub-title">{t('landing.featureTitle3')}</div>
						<div className="sub-des">{t('landing.featureDes3')}</div>
					</div>
				</div>
			</div>
			<div className="user-recommend">
				<div className="a-recommend">
					<div className="recommend-title">
						<i className="fas fa-quote-left" />
						標籤精準推送
						<i className="fas fa-quote-right" />
					</div>
					<div className="recommend-des">
						自動或手動加入顧客標籤，發掘顧客真正需要、喜歡的，讓你的每則訊息都能正中紅心。
					</div>
					<hr/>
					<div className="recommender">
						Cowell, Aaron.
					</div>
				</div>
			</div>
			<footer>
				<div className="menus">
					<span>{t('landing.aboutUs')}</span>
					<span>{t('landing.tutorial')}</span>
					<span>{t('landing.privatePolicy')}</span>
					<span>{t('landing.termsOfService')}</span>
				</div>
				<div className="social-media">
					<div className="a-platform">
						<i className="fab fa-facebook-f" />
					</div>
					<div className="a-platform">
						<i className="fab fa-instagram" />
					</div>
					<div className="a-platform">
						<i className="fab fa-youtube" />
					</div>
				</div>
				<div className="copyright">
					Copyright &copy; 2019 trip-ninja.io ALL Rights Reserved.
				</div>
			</footer>
		</div>
	);
}

export default Landing;