import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs'

import Profile from './Profile'
import Files from './Files'
import { fetchHelper } from 'tools/FetchHelper'
import Tooltip from 'Components/Tooltip';

import './index.css'


const UserData = (props) => {
	const { t } = useTranslation();
	const [userData, setUserData] = useState(null)
	const [cowellData, setCowellData] = useState(null)

	const [tab, setTab] = useState('profile') // profile, files
	const [isFetching, setFetching] = useState(true)


	useEffect(() => {
		const getUserData = async (initialData) => {
			setFetching(true)
			try {
				const result = await fetchHelper.get(`/channel/${props.channelId}/chatRoom/${props.user.guid}`);
				const res = result.data
				// fetch cowell data here
				if (res.status === "OK") {
					const updateuUserDataValues = {
						...initialData,
						...res.data.userData
					}

					if (res.data.userData["tag"]) {
						const tagSorted = res.data.userData["tag"].sort((tagA, tagB) => {
							return dayjs(tagA.updateDtm) < dayjs(tagB.updateDtm) ? 1 : -1
						})
						updateuUserDataValues["tag"] = tagSorted
					}
					setUserData(updateuUserDataValues)

					setCowellData(res.data.cowellData)


					setFetching(false)
				} else {
					throw new Error(res.errorMessage)
				}
			} catch (error) {
				setFetching(false)
				console.log('get user data failed', error)
			}
		}
		const initialData = {
			phone: undefined,
			email: undefined,
			subName: undefined,
			birthday: '',
			remark: '',
			tag: [],
			addedTags: [],
			deletedTags: []
		}
		getUserData(initialData)
	}, [props.user, props.channelId])

	return (
		<div className="user-data">
			{isFetching ?
				<div className="icon-loading">
					<i className="fal fa-spinner-third fa-spin" />
				</div> :
				<>
					<div className="user-tabs">
						<div className="a-tab">
							<Tooltip>
								<i
									className={cx('fal fa-user-cog', { active: tab === 'profile'})}
									onClick={() => {
										if (tab === 'profile') return;
										setTab('profile')
									}}
									data-tip={t('message.userProfile')}
									data-class="icon-title"
								/>
							</Tooltip>
						</div>
						<div className="a-tab">
							<Tooltip>
								<i
									className={cx('fal fa-folder-open', { active: tab === 'files'})}
									onClick={() => {
										if (tab === 'files') return;
										setTab('files')
									}}
									data-tip={t('message.fileRecord')}
									data-class="icon-title"
								/>
							</Tooltip>
						</div>
					</div>
					<div className="user-tab-content">
						{tab === 'profile' && userData &&
							<Profile
								{...props}
								userData={userData}
								cowellData={cowellData}
							/>
						}
						{tab === 'files' &&
							<Files
								{...props}
								userData={userData}
								cowellData={cowellData}
							/>
						}
					</div>
				</>
			}
		</div>
	)
}

UserData.defaultProps = {
	user: {}
}

export default UserData;