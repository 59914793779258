import React, { useState, useEffect} from 'react';
import { fetchHelper } from 'tools/FetchHelper'
import styled from 'styled-components';

const DivWrapper = styled.div`
    display: flex;
    justify-content: center;
`;


const DivMessageBox = styled.div`
    background-color: #FFD0C5;
    padding: 10px;
    margin: auto 0;
    width: 600px;
    display: flex;
    justify-content: center;
    border: 1px solid #dc3545;
`;

const DivMessageContent = styled.div`
    color: #dc3545;
    font-size: 16px;
    margin: 20px;
    font-weight: bold;
`;

const Unavailable = (props) => {
    // /channel/<string:channelGUID>/status    
    return (
        <>        
            <p>&nbsp;</p>
            <DivWrapper>
                <DivMessageBox>                
                    <DivMessageContent>This channel is currently unavailable.</DivMessageContent>
                </DivMessageBox>
            </DivWrapper>
        </>
    );
};

export default Unavailable;