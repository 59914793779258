import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Switch } from 'react-router-dom'
import cx from 'classnames'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver';

import enums from 'enums'
import { fetchHelper } from 'tools/FetchHelper'
import ActivityDetail from './Detail'
import RadioInput from 'Components/RadioInput'

import './index.css'

const ActivityList = (props) => {
	const { t } = useTranslation();
	const [isFetching, setFetching] = useState(false)
	const [activities, setActivity] = useState([])
	const [isDeleting, setDelete] = useState(-1)
	const [isCopying, setCopy] = useState(-1)
	const [activityType, setActivityType] = useState(enums.ActivityType.normal)

	const deleteActivity = async (activityId, i) => {
		setDelete(i)
		try {
			const result = await fetchHelper.delete(`/activity/${activityId}`, {}, {
				fetchQAbot: true
			});
			const res = result.data
			if (res.status === "OK") {
				setDelete(-1);
				window.location.reload()
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setDelete(-1);
			console.log('delete broadcast fail', error.message)
		}
	}

	const copyActivity = async (activityId, i) => {
		setCopy(i)
		try {
			const result = await fetchHelper.post(`/activity/${activityId}/copy`, {}, {
				fetchQAbot: true
			});
			const res = result.data
			if (res.status === "OK") {
				setCopy(-1);
				window.location.reload()
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setCopy(-1);
			console.log('copy activity fail', error.message)
		}
	}

	const createActivity = () => {
		const pathParams = {
			pathname: `/console/channel/${props.channelId}/activity/create`,
			state: {
				type: activityType
			}
		}
		props.history.push(pathParams);
	}

	useEffect(() => {
		const getActivityList = async () => {
			setFetching(true);
			try {
				const result = await fetchHelper.get(`/channel/${props.channelId}/activity`, {
					params: {
						size: 100,
					},
					fetchQAbot: true
				});
				const res = result.data
				if (res.status === "OK") {
					setActivity(res.data.activity)
					setFetching(false);
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				setFetching(false);
				console.log('fecth activity list', error.message)
			}
		}
		getActivityList()
	}, [props.channelId])

	return (
		<div className="channel-activity">
			<div className="page-title-with-button">
				<div>
					<p>
						{t('channel.market')}
						<i className="far fa-chevron-right" />
						{t('activity.title')}
					</p>
				</div>
				<div className="buttons-section">
					<RadioInput
						onChange={(e) => {setActivityType(enums.ActivityType.normal)}}
						isChecked={activityType === enums.ActivityType.normal}
						labelText={t('activity.normalActivity')}
					/>
					<RadioInput
						onChange={(e) => {setActivityType(enums.ActivityType.vote)}}
						isChecked={activityType === enums.ActivityType.vote}
						labelText={t('activity.voteActivity')}
					/>
					<button
						className="c-btn c-btn--primary"
						onClick={()=>{createActivity()}}>
						{t('activity.newActivity')}
					</button>
				</div>
			</div>
			{isFetching ?
				<div className="icon-loading">
					<i className="fal fa-spinner-third fa-spin" />
				</div> :
				<div className="activity-list">
					{activities.map((act, i) => {
						const now = dayjs()
						let activityStatus = "default"
						const activityType = act.type || enums.ActivityType.normal

						if (now.isBefore(dayjs(act.startDtm))) {
							activityStatus = "unStart"
						} else {
							activityStatus = "active"
						}

						if (act.expireDateType === enums.activityExpiredDateType.specificDay && now.isAfter(dayjs(act.endDtm), 'date')) {
							activityStatus = "expired"
						}

						if (act.isDelete) {
							activityStatus = "deleted"
						}
						return (
							<div className="a-activity" key={`a-activity-${i}`}>
								<div className={cx('sending-status', activityStatus)}>
									{t(`activity.status.${activityStatus}`)}
								</div>
								<div className="activity-name-n-time">
									<div className="activity-type">
										{t(`activity.activityListType.${enums.ActivityType[activityType]}`)}
									</div>
									<Link
										className="activity-name"
										to={`${props.match.url}/${act.guid}`}
									>
										{act.name}
									</Link>
									<div className="op-name">{act.createOpName}</div>
									<div className="activity-date">
										{`${t('activity.activityDate')} : ${dayjs(act.startDtm).format('YYYY/MM/DD')} - `}
										{act.expireDateType === enums.activityExpiredDateType.specificDay ?
											dayjs(act.endDtm).format('YYYY/MM/DD') : t('activity.notAssign')}
										{act.isDelete &&
											`（ ${act.deleteOpName} ${t(
												'activity.deletedAt',
												{ deleteDate: dayjs(act.deleteDtm).format('YYYY-MM-DD') }
											)}）`
										}
									</div>
								</div>
								<div className="controls-n-atendees">
									<div className="controls-buttons">
										{act.isDelete ?
											<i className="far fa-pen disabled" /> :
											<Link
												to={`${props.match.url}/${act.guid}`}
											>
												<i className="far fa-pen" />
											</Link>
										}
										{isCopying === i ?
											<i className="fal fa-spinner-third fa-spin" /> :
											<i
												className={cx('far fa-copy')}
												onClick={() => {
													copyActivity(act.guid, i)
												}}
											/>
										}
										<i
											className={cx('far fa-download', { 'disabled': act.isDelete })}
											onClick={() => {
												if (act.isDelete) return;
												saveAs(act.imageUrl, `${act.name}.png`)
											}}
										/>
										{isDeleting === i ?
											<i className="fal fa-spinner-third fa-spin" /> :
											<i
												className={cx('far fa-trash', { 'disabled': act.isDelete })}
												onClick={() => {
													if (act.isDelete) return;
													deleteActivity(act.guid, i)
												}}
											/>
										}
									</div>
									<div className="activity-attendees">
										<div className="big-number">{act.attendeesCounts}</div>
										{ activityType === enums.ActivityType.normal ?
											t('activity.numberOfAttendees')
											:
											t('activity.numberOfVote')
										}
									</div>
								</div>
							</div>
						)
					})}
				</div>
			}
		</div>
	)
}

const Activity = (props) => {
	return (
		<Switch>
			<Route path={`${props.match.path}/create`} component={(routerProps) =>
				<ActivityDetail
					{...props}
					{...routerProps}
				/>
			} />
			<Route path={`${props.match.path}/:activityId`} component={(routerProps) =>
				<ActivityDetail
					{...props}
					{...routerProps}
				/>
			} />
			<Route path={props.match.path} render={(routerProps) => <ActivityList
				{...props}
				{...routerProps}
			/>} />
		</Switch>
	)
}

export default Activity;