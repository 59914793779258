import React from 'react';
import dayjs from 'dayjs';
import './index.css';


const DateLine = (props) => {
	const format = props.showyear ? 'MMM D, YYYY' : 'MMM D'

	return (
		<div className="date-horizon-line">
			<div className="line" />
			<div className="date">{dayjs(props.date).locale('en').format(format)}</div>
			<div className="line" />
		</div>
	);
}

export default DateLine;