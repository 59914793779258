import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { uploadFile } from './index';

import Modal from 'Components/Modal'

const UploadModal = (props) => {
	const { t } = useTranslation();
	const [file, setFile] = useState({})
	const [showDropable, setDropable] = useState(false)
	const [uploadPercent, setPercent]  = useState(0)
	const [step, setStep] = useState('initial') // initial, process, error, success
	const inputRef = useRef(null);

	const onFilesDrop = (files) => {
		setStep('process')
		setFile(files[0])

		if (props.uploadCSVFile) {
			const reader = new FileReader();

        	reader.addEventListener('load', function (e) {
        		let currentPercent = 10
        		setPercent(currentPercent)
				const lines = e.target.result.split('\n');
				const aStepPercent = (100 - 10) / lines.length

				const result = lines.reduce((pre, cur) => {
					pre.push(cur.split(','))
					currentPercent += aStepPercent
					setPercent(currentPercent)
					return pre
				}, [])
				if (result) {
					props.onUpLoaded(result)
					setStep('success')
				}
			});

        	reader.readAsText(files[0], 'UTF-8');
		} else {
			uploadFile({
				file: files[0],
				folderType: props.folderType,
				fileUploadFrom: props.fileUploadFrom,
				channelId: props.channelId,
				chatRoomId: props.chatRoomId,
				onUploaded: (result) => {
					props.onUpLoaded(result)
					initialState()
					props.onRequestClose(false)
				},
				onProgress: (percent) => setPercent(percent),
				onError: () => {setStep('error') }
			})
		}

	}

	const initialState = () => {
		setStep('initial')
		setPercent(0)
	}

	return (
		<Modal
			className="image-upload-modal"
			isOpen={props.isModalOpen}
			onRequestClose={() => {
				initialState()
				props.onRequestClose(false)
			}}
		>
			<div className="modal-title">{t('message.sendingFile')}</div>
			{['error', 'initial'].includes(step) &&
				<div
					className={cx(
						"file-drop-zone",
						{ 'can-drop': showDropable},
						{ error: step === 'error' }
					)}
				>
					{step === 'error' ?
						<i className="fal fa-exclamation-triangle" /> :
						<i className="fal fa-cloud-upload" />
					}
					{step === 'error' && <div className="error-msg">{t('error.fileTypeAndSize')}</div>}
					<div className="upload-statement">
						{t('message.reDragFileHereOr')}
						<span className="upload-text">
							{t('upload')}{t('message.file')}
						</span>
					</div>
					<div className="mask"
						onDragEnter={(e) => {
							e.preventDefault();
							setDropable(true)
						}}
						onDragLeave={(e) => {
							e.preventDefault();
							setDropable(false)
						}}
						onDragOver={(e) => {
							e.preventDefault();
						}}
						onDrop={(e) => {
							e.preventDefault();
							e.stopPropagation()
							setDropable(false)

							if (e.dataTransfer.files && e.dataTransfer.files[0]) {
								onFilesDrop(e.dataTransfer.files)
						    }
						}}
						onClick={() => {
							if (inputRef.current) {
								inputRef.current.click()
							}
						}}
					/>
					<input
						ref={inputRef}
						className="upload-input"
						type="file"
						onChange={(e)=> {
							if (e.target.files && e.target.files.length > 0) {
								onFilesDrop(e.target.files)
							}
						}}
						tilte="upload"
						// multiple
					/>
				</div>
			}
			{step === 'process' &&
				<div className="progress-block">
					<div className="file-name">{file.name}</div>
					<div
						className={cx("progress-bar", { complete: uploadPercent === 100 })}
					>
						<span style={{ width: `${uploadPercent}%`}} />
					</div>
				</div>
			}
			{step !== 'success' &&
				<div className="description">
					{props.uploadCSVFile ?
						t('bot.acceptFileExtension') :
						t('message.acceptFileExtension')}
				</div>
			}
			{step === 'success' &&
				<div className="success">
					<div className="big-icon">
						<i className="fal fa-thumbs-up" />
					</div>
					<div className="modal-title">{t('upload')}{t('success')}</div>
				</div>
			}
		</Modal>
	)
}

UploadModal.defaultProps = {
	chatRoomId: null,
	isModalOpen: false,
	onRequestClose: () => {},
	onUpLoaded: () => {}
}

export default UploadModal