import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

import ChatPreviewBlock from 'Components/ChatPreviewBlock'
import CreateMsg from 'Components/CreateMsg'
import { fetchHelper } from 'tools/FetchHelper'
import enums from 'enums'

import './index.css'

const Greeting = (props) => {
	const { t } = useTranslation();

	const [isFetching, setFetching] = useState(false)
	const [isUpdating, setUpdating] = useState(false)
	const [messages, setMsg] = useState([])


	const updateBotGreeting = async () => {
		setUpdating(true)

		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/bot/greeting`, {
				reply: messages
			}, {
				fetchQAbot: true
			});
			const res = result.data

			if (res.status === "OK") {
				setUpdating(false)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setUpdating(false)
			console.log('update bot greeting fail', error.message)
		}
	}

	useEffect(() => {
		const fetchBotGreeting = async () => {
			setFetching(true)
			try {
				const result = await fetchHelper.get(`/channel/${props.channelId}/bot/greeting`, {
					fetchQAbot: true
				});
				const res = result.data
				if (res.status === "OK") {
					setMsg(res.data.reply)
					setFetching(false)
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				console.log('get bot greeting fail', error.message)
			}
		}

		fetchBotGreeting()

	}, [props.channelId])

	return (
		<div className="greeting-page">
			<div className="page-title">
				<Link to={`/console/channel/${props.channelId}/bot`}>{t('bot.title')}</Link>
				<i className="far fa-chevron-right" />
				{t('bot.greeting')}
			</div>
			<div className="page-des">{t('bot.greetingDes')}</div>
			<div className="bot-setting-content">
				<div className="setting-part">
					{isFetching ?
						<div className="icon-loading">
							<i className="fal fa-spinner-third fa-spin" />
						</div> :
						<>
							<CreateMsg
								{...props}
								messages={messages}
								onChange={(messages) => setMsg(messages)}
								limit={5}
								fileUploadFrom={enums.FileUploadFrom.bot}
							/>
							<div className="button-group">
								<button className="c-btn">{t('cancel')}</button>
								<button className="c-btn c-btn--primary"  onClick={() => {
									if (isUpdating) return;
									updateBotGreeting()
								}}>
									{isUpdating ?
										<i className="fal fa-spinner-third fa-spin" /> :
										t('save')
									}
								</button>
							</div>
						</>
					}

				</div>
				<div className="preview-part">
					<div className="sub-title">{t('preview')}</div>
					<ChatPreviewBlock
						{...props}
						messages={messages}
						sender={{
							type: 'bot'
						}}
					/>
				</div>
			</div>
			<div className="button-group">
				<button className="c-btn">{t('cancel')}</button>
				<button className="c-btn c-btn--primary" onClick={() => {
					if (isUpdating) return;
					updateBotGreeting()
				}}>
					{isUpdating ?
						<i className="fal fa-spinner-third fa-spin" /> :
						t('save')
					}
				</button>
			</div>
		</div>
	)
}

export default Greeting;