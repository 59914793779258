import React, { setGlobal, useGlobal, useState, useEffect, getGlobal } from 'reactn';
import { Route, Switch } from 'react-router-dom'
import shortid from 'shortid';

import Header from './Header'
import SideMenu from './SideMenu'
import Channels from './Channels'
import Channel from './Channel'
import CreateChannel from './CreateChannel'
import { fetchHelper } from 'tools/FetchHelper'
import Bot from 'Components/BotWebSocket/Bot'
import { getUser } from 'Login'
import store from 'store';

import './index.css';

setGlobal({
	channels: [],
	channelsReplica: [],
	notifications: [],
	unReadNotify: 0,
	latestReadNotifyDtm: null,
	bot: new Bot(),
	handleWebSocketMessage: () => {},
	updateChannelMsgCount: (channelId, column, condition, number = 1) => {
		const { channelsReplica } = getGlobal()
		const channelCopy = channelsReplica.slice()
		const channelIndex = channelCopy.findIndex(x => x.guid === channelId)
		if (channelIndex > -1) {
			channelCopy[channelIndex][column] = condition ? channelCopy[channelIndex][column] + number : channelCopy[channelIndex][column] - number
			setGlobal({
				channelsReplica: channelCopy
			})
		}
	}
})

const Console = (props) => {
	const path = props.match.path;
	const [isFetching, setFetching] = useState(true)
	const [channels, setChannels] = useGlobal('channels');
	const { bot, updateChannelMsgCount } = getGlobal()

	const handleWebSocketMessage = (res) => {
		try{
			const socketMsg = JSON.parse(res.data);
			let isCurrentOp = false
			console.log('global handle webSocket', socketMsg)


			if (socketMsg.event === 'NOTIFY') {
				if (typeof socketMsg.recipient.id === 'string') {
					isCurrentOp = socketMsg.recipient.id === getUser().guid
				} else if (Array.isArray(socketMsg.recipient.id)) {
					isCurrentOp = socketMsg.recipient.id.includes(getUser().guid)
				}

				if (isCurrentOp) {
					const { notifications, unReadNotify } = getGlobal()

					setGlobal({
						unReadNotify: unReadNotify + 1,
						notifications: [].concat([{
							messageData: socketMsg.data,
							sender: socketMsg.sender,
							recipient: socketMsg.recipient,
							hash: shortid.generate()
						}], notifications)
					})

					const audio = new Audio('https://lineline.s3.amazonaws.com/systemFiles/button06.mp3');
					audio.play();
				}
			}

			if (socketMsg.event === 'ALERT') {
				updateChannelMsgCount(socketMsg.recipient.channelGuid, 'unreadCount', true)
			}

			if (socketMsg.event === "CALLOP") {
				updateChannelMsgCount(socketMsg.recipient.channelGuid, 'callOpCount', true)
			}
		}
		catch(e){
			console.log(res)
		}
	}
	bot.initWebSocket(handleWebSocketMessage)

	const loadAllChannels = async () => {
		let channels = null
		const loadFromStore = store.get("TRIP_NINJA_ALL_CHANNEL")
		if (loadFromStore && new Date(loadFromStore.expireDtm) > new Date()) {
			channels = loadFromStore.channels
		} else {
			const fetchResult = await fetchAllChannels()
			if(!fetchResult) return // fetch error
			channels = fetchResult
			const now = new Date();
			store.set('TRIP_NINJA_ALL_CHANNEL', {
				expireDtm: new Date(now.getTime() + (3 * 60 * 1000)), // set cache in 3 minutes
				channels: channels
			})
		}

		setChannels(channels)
		setGlobal({
			channelsReplica: channels
		})
		setFetching(false)
	}

	const fetchAllChannels = async () => {
		let res = null
		try {
			const result = await fetchHelper.get('/channel/list');
			const resultData = result.data
			if (resultData.status === "OK") {
				res = resultData.data
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('fecth all channels fail', error.message)
		}
		return res
	}

	useEffect(() => {
		loadAllChannels()
		setGlobal({
			handleWebSocketMessage
		})
	}, []);



	return (
	    <div className="console-root container-fluid">
			<Header {...props} />
			{isFetching ?
				<div className="icon-loading">
					<i className="fal fa-spinner-third fa-spin" />
				</div> :
				<div className="console-content">
					<div className="side">
						<SideMenu {...props} channels={channels} />
					</div>
					<div className="main-content">
						<Switch>
							<Route path={`${path}/channel/create`} component={(routerProps) =>
								<CreateChannel channels={channels} {...props} {...routerProps} />} />
							<Route path={`${path}/channel/:channelId`} component={(routerProps) =>
								<Channel channels={channels} {...props} {...routerProps}  />} />
							<Route path={`${path}/channels`} component={(routerProps) =>
								<Channels channels={channels} {...props} {...routerProps}  />} />
							<Route path={`${path}/`} component={(routerProps) =>
								<Channels channels={channels} {...props} {...routerProps}  />} />
						</Switch>
					</div>
				</div>
			}
		</div>
	);
};

export default Console;