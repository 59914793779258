import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './index.css'

/**
 * a Counter with plus icon on right and minus icon on left
 * @param	{int} max maximun number of counter, default to 24
 * @param	{int} value init value, default to 0
 * @param	{func} onChange function call on value change
 */
const Counter = (props) => {
	const max = props.max || 24;
	const [current, setCurrent] = useState(props.value || 0);

	const isFirstRun = useRef(true);

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		props.onChange(current)
	}, [current])

	return (
		<div className="time-chooser">
			<div className="button-minus" onClick={() => setCurrent(current - 1 >= 0 ? current - 1 : max)}>
				<i className="far fa-minus" />
			</div>
			<input
				className="current-number"
				type="number"
				min={0}
				max={max}
				value={current || 0}
				onChange={(e) => {
					const value = e.target.value;
					if (!value) return;
					if (isNaN(value)) return;
					if (value > max || value < 0) return;
					setCurrent(parseInt(value, 10))
				}}
			/>
			<div className="button-plus" onClick={() => setCurrent(current + 1 <= max ? current + 1 : 0)}>
				<i className="far fa-plus" />
			</div>

		</div>
	)
}

Counter.propTypes = {
    max: PropTypes.number,
    value: PropTypes.number,
    onChange: PropTypes.func
}

export default Counter;