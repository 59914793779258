import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { fetchHelper } from 'tools/FetchHelper'
import GeneralModal from 'Components/GeneralModal'
import TagDirView from 'Components/TagsInputer/TagDirView'

import './index.css'

const ENTER_KEY = 13;

const TagAddModal = (props) => {
	const { t } = useTranslation();
	const [isFetching, setFetching] = useState(false)
	const [inputValue, setValue] = useState(null)
	const [tagDirList, setTagDirList] = useState([])
	const [showAddTagButton, setAddTagButton] = useState(false)
	const isFirstRun = useRef(true);
	const tagDirListRef = useRef([]);

	const splitString = (str) => {
		return str.split(/[，,\s、\n\t]+/).map((aTagValue) => {return {
			"text": aTagValue,
			"count": 1
		}})
	}
	const searchTags = (inputValue) => {
		const origDirList = JSON.parse(JSON.stringify(tagDirListRef.current));

		if (inputValue) {
			let hasMatchTag = false;
			const partialMatchList = [];

			for (let aDir of origDirList) {
				if (hasMatchTag) break;
				let matchTagList = []
				let partialMatchCount = 0;

				let matchTagIndex = aDir.tags.findIndex(tag => tag.text === inputValue)
				if (matchTagIndex > -1) {
					aDir.tags = [aDir.tags[matchTagIndex]]
					setTagDirList([{
						...aDir,
						childs: []
					}])
					hasMatchTag = true;
					break;
				} else {
					aDir.tags = aDir.tags.filter(aTag => aTag.text.indexOf(inputValue) > -1)
					partialMatchCount += aDir.tags.length
				}

				matchTagList = [{
					...aDir,
					tags: []
				}]
				if (aDir.childs && aDir.childs.length > 0) {
					if (hasMatchTag) break;
					for (let subDir of aDir.childs) {
						matchTagIndex = subDir.tags.findIndex(tag => tag.text === inputValue)

						if (matchTagIndex > -1) {
							subDir.tags = [subDir.tags[matchTagIndex]]
							matchTagList[0].childs = [{
								...subDir,
								childs: []
							}]
							setTagDirList(matchTagList)
							hasMatchTag = true;
							break;
						} else {
							subDir.tags = subDir.tags.filter(aTag => aTag.text.indexOf(inputValue) > -1)
							partialMatchCount += subDir.tags.length
						}

						matchTagList[0].childs = [{
							...subDir,
							tags: []
						}]

						if (subDir.childs && subDir.childs.length > 0) {
							for (let thirdDir of subDir.childs)  {
								matchTagIndex = thirdDir.tags.findIndex(tag => tag.text === inputValue)

								if (matchTagIndex > -1) {
									thirdDir.tags = [thirdDir.tags[matchTagIndex]]
									matchTagList[0].childs[0].childs = [{
										...thirdDir,
										childs: []
									}]
									setTagDirList(matchTagList)
									hasMatchTag = true;
									break;
								} else {
									thirdDir.tags = thirdDir.tags.filter(aTag => aTag.text.indexOf(inputValue) > -1)
									partialMatchCount += thirdDir.tags.length
								}
							}
						}
					}
				}

				if (partialMatchCount > 0) {
					partialMatchList.push(aDir)
				}
			}

			if (!hasMatchTag) {
				setAddTagButton(!hasMatchTag)

				setTagDirList(partialMatchList)
			}
		} else {
			setTagDirList(origDirList)
		}
	}

	useEffect(() => {
		let isCanceled = false
		const getTags = async () => {
			setFetching(true)
			try {
				const result = await fetchHelper.get(`/channel/${props.channelId}/tag`);
				const res = result.data
				if (isCanceled) return
				if (res.status === "OK") {
					setTagDirList(res.data)
					tagDirListRef.current = res.data
					setFetching(false);
				} else {
					throw new Error(result.errorMessage);
				}
			} catch (error) {
				setFetching(false);
				console.log('get all tags fail', error.message)
			}
		}


		if (isFirstRun.current && !props.allTags) {
			getTags()
			isFirstRun.current = false;
			return () => {
				isCanceled = true
			}
		}

		searchTags(inputValue)
	}, [inputValue, props.allTags, props.channelId])

	useEffect(() => {
		if (props.allTags) {
			setTagDirList(props.allTags)
			tagDirListRef.current = props.allTags
		}
	}, [props.allTags])


	return (
		<GeneralModal id={"add-tag-container"} placement={props.placement} placeLeft={props.placeLeft}>
			<div className="add-tag-modal">
				<input
					type="text"
					placeholder={t('tags.searchTags')}
					value={inputValue || ""}
					onChange={(e) => {
						setValue(e.target.value)
						if (e.target.value === "") {
							setAddTagButton(false)
						}
					}}
					onKeyDown={(e) => {
						if (e.keyCode === ENTER_KEY && !!e.target.value) {
							const inputTags = splitString(e.target.value)
							props.onTagAdd(inputTags)
							setValue("")
						}
					}}
				/>
				{isFetching ?
					<div className="icon-loading">
						<i className="fal fa-spinner-third fa-spin" />
					</div> :
					<div className={cx("dir-list", {
						'have-button': showAddTagButton,
						'not-mac': !(window.navigator.platform.indexOf('Mac') > -1)
					})}>
						{inputValue && tagDirList.length === 0 &&
							<div className="not-create-tag">
								{t('tags.notCreateTag')}
							</div>
						}
						{tagDirList.reduce((pre, aDir, i) => {
							pre.push(
								<TagDirView
									key={`tag-Preview-${aDir.guid}-${i}`}
									dir={aDir}
									onTagClick={(tag) => {
										props.onTagAdd([{
											"text": tag.text,
											"count": 1
										}])
									}}
								/>
							)
							if (aDir.childs && aDir.childs.length > 0) {
								aDir.childs.map((subDir, j) => {
									pre.push(
										<TagDirView
											key={`tag-Preview-${subDir.guid}-${i}-${j}`}
											indent={1}
											dir={subDir}
											onTagClick={(tag) => {
												props.onTagAdd([{
													"text": tag.text,
													"count": 1
												}])
											}}
										/>
									)
									if (subDir.childs && subDir.childs.length > 0) {
										subDir.childs.map((thirdDir, k) => {
											pre.push(
												<TagDirView
													key={`tag-Preview-${thirdDir.guid}-${i}-${j}-${k}`}
													indent={2}
													dir={thirdDir}
													onTagClick={(tag) => {
														props.onTagAdd([{
															"text": tag.text,
															"count": 1
														}])
													}}
												/>
											)
											return thirdDir
										})
									}
									return subDir
								})
							}
							return pre
						}, [])}
					</div>
				}
				{showAddTagButton &&
					<button className="c-btn c-btn--primary" onClick={() => {
						const inputTags = splitString(inputValue)
						props.onTagAdd(inputTags)
						setValue("")
						setAddTagButton(false)
					}}>
						{t('tags.addTagsWithName', { tagName: inputValue })}
					</button>
				}
			</div>
		</GeneralModal>
	)
}

TagAddModal.defaultProps = {
	onTagAdd: () => {}
}

export default TagAddModal;