import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import ReactMarkdown from "react-markdown"
import qs from 'qs'

import './index.css'
import Step from 'Components/Step'

const LineNotifyTutorial = (props) => {
	const { t } = useTranslation();
	const urlParams = qs.parse(props.location.search, { ignoreQueryPrefix: true })
	const settingUrl = urlParams.channel ? `/console/channel/${urlParams.channel}/setting` : '/console'


	return (
		<div className="line-notify-tutorial">
			<div className="line-notify-tutorial-content">

			<div className="page-title">{t('setting.title')}</div>
			<div className="sub-title">{t('tutorial.notify.title')}</div>
			<div className="content-description">{t('tutorial.notify.des')}</div>

			<div className="step-list">

				<Step
					seqNum="1"
					//title="登入 LINE Notify 官網進行設定"
					titleKey="tutorial.notify.step1Title"
					DesComp={() => (
						<div className="des-paragraph">
							<ReactMarkdown
								source={t('tutorial.notify.step1Des')}
								renderers={{link: props => <a href={props.href} target="_blank">{props.children}</a>}}
							/>

						</div>
					)}
					imgUrl="https://lineline.s3.ap-northeast-2.amazonaws.com/line-notify-tutorial-images/step1.png"
				/>

				<Step
					seqNum="2"
					titleKey="tutorial.notify.step2Title"
					DesComp={() => (
						<>
						<div className="des-paragraph">
							<ReactMarkdown
								source={t('tutorial.notify.step2Des')} />
						</div>

						<div className="des-paragraph-image">
							<img className="step2-1" alt="" src={"https://s3.ap-northeast-2.amazonaws.com/lineline/line-notify-tutorial-images/step2_1.png"} />
							<img className="step2-2" alt="" src={"https://s3.ap-northeast-2.amazonaws.com/lineline/line-notify-tutorial-images/step2_2.png"} />
						</div>
						</>
					)}
				/>


				<Step
					seqNum="3"
					titleKey="tutorial.notify.step3Title"
					DesComp={() => (
						<>
							<div className="des-paragraph">
								{t('tutorial.notify.step3DesPart1')}

							</div>
								<div className="sub-des-paragraph">
									<div>
										{t('tutorial.notify.step3DesPart2')}

										<div className="des-paragraph-image">
											<img className="step3-1" alt="" src={"https://s3.ap-northeast-2.amazonaws.com/lineline/line-notify-tutorial-images/step3_2.png"} />
										</div>
									</div>
									<div>
										{t('tutorial.notify.step3DesPart3')}
										<div className="des-paragraph-image">
											<img className="step3-2" alt="" src={"https://s3.ap-northeast-2.amazonaws.com/lineline/line-notify-tutorial-images/step3_1.png"} />
										</div>
									</div>
								</div>
						</>
					)}
				/>

				<Step
					seqNum="4"
					titleKey="tutorial.notify.step4Title"
					DesComp={() => (
						<>
							<div className="des-paragraph">
								<ReactMarkdown
									source={t('tutorial.notify.step4Des', {settingUrl : settingUrl})}
									renderers={{link: props => <a href={props.href} target="_blank">{props.children}</a>}}
								/>
							</div>

							<div className="des-paragraph-image">
								<img className="step4" alt="" src={"https://s3.ap-northeast-2.amazonaws.com/lineline/line-notify-tutorial-images/step4.png"} />
							</div>
						</>
					)}
				/>



				<Step
					seqText={t('tutorial.notify.step5Seq')}
					titleKey={t('tutorial.notify.step5Title')}
					DesComp={() => (
						<>
							<div className="des-paragraph">
								{t('tutorial.notify.step5Des')}
							</div>
						</>
					)}
					imgUrl="https://s3.ap-northeast-2.amazonaws.com/lineline/line-notify-tutorial-images/step5.png"

				/>


			</div>
			</div>
		</div>


	)
}

export default LineNotifyTutorial;
