import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import './index.css'

/*
 * @param labelText - display as label if no labelKey
 * @param labelKey - try translate to i18n if given
 * @param class - set to outter div, optional
 * @param url - render Link if url is given, item with child not support
 * @param childs - array of object
 * @param childs.labelText, labelKey, class, url - just as parent
 */
const MobileMenuItem = (props) => {
	const { t } = useTranslation();
	const [isOpen , setOpen] = useState(false)

	const childs = props.childs || []
	const labelText = props.labelKey ? t(props.labelKey) : props.labelText

	if (!childs || childs.length < 1) {
		return (
			<div className={cx("mobile-menu-item", {[`${props.class}`]: !!props.class})}>
				<div className="text-section">
					{!!props.url ?
						<Link to={props.url}> {labelText} </Link>
						:
						{labelText}
					}
				</div>
			</div>
		)
	}

	return(
		<div
			className={cx("mobile-menu-item", {[`${props.class}`]: !!props.class})}
			onClick={()=>{setOpen(!isOpen)}}
		>
				<div className="text-section">
					{labelText}
					<i className="far fa-angle-down" />
				</div>

				<div className="child-section">
					{isOpen && childs.map((child, i) => {
						const labelText = child.labelKey ? t(child.labelKey) : child.labelText
						return (
							<div className={cx("mobile-menu-item-child", {[`${child.class}`]: !!child.class})}>
								{!!child.url ?
									<Link to={child.url}> {labelText} </Link>
									:
									{labelText}
								}
							</div>
						)
					})}
				</div>
		</div>
	)


}



const MobileMenu = (props) => {
	const ItemComponent = props.itemComponet

	return (
		<div className={cx("mobile-menu", {[`${props.class}`]: !!props.class})}>
			<div className="mobile-menu-content">
				{props.data.items.map((item, i) => {
					return (
						<ItemComponent key={`mobile-menu-content-item-${i}`} {...item}/>
					)
				})}

			</div>
		</div>
	)
}

MobileMenu.defaultProps = {
	data: {
		items: []
	},
	itemComponet: MobileMenuItem
}

export default MobileMenu;
