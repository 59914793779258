import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'

const UpdateMessage = (props) => {
	/*
		props:
		isUpdating: null/ true/ false
	*/
	const { t } = useTranslation();
	const { isUpdating, className } = props

	if(isUpdating == null) {
		return null
	}

	return (
		<div className={cx("update-msg icon-with-text culture-text success", className)}>
			{isUpdating ?
				<i className="far fa-spinner-third fa-spin" /> :
				<>
					<i className="far fa-check" />
					{t('update')}{t('success')}
				</>
			}
		</div>
	)
}

export default UpdateMessage