import React, { useGlobal, useEffect } from 'reactn';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import './index.css'

const ShowUpdating = (props) => {
	const { t } = useTranslation();
	const [showUpdating, setUpdate] = useGlobal('showUpdating'); // initial, saving, saved

	useEffect(() => {
		if (['saved', 'error'].includes(showUpdating)) {
			// setHide(true)

			setTimeout(() => {
				setUpdate('initial')
				// setHide(false)
			}, 2000);
		}
	}, [showUpdating, setUpdate])

	return (
		<div className={cx("show-updating", {
			saved: showUpdating === 'saved'
		})}>
			{showUpdating === 'saving' &&
				<>
					<i className="fal fa-spinner-third fa-spin" />
					<span>{t('saving')}</span>
				</>
			}
			{showUpdating === 'saved' &&
				<>
					<i className="fal fa-check-circle" />
					<span>{t('saved')}</span>
				</>
			}
			{showUpdating === 'error' &&
				<>
					<i className="fal fa-times" />
					<span>{`${t('save')}${t('fail')}`}</span>
				</>
			}
		</div>
	)
}

export default ShowUpdating