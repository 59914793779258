import React from 'react';
import Modal from 'Components/Modal'
import { useTranslation } from 'react-i18next';

const TagControlModal = (props) => {
	const { t } = useTranslation();

	return (
		<Modal
			className="tag-control-modal"
			isOpen={props.isModalOpen}
			onRequestClose={() => {
				props.onRequestClose()
			}}
		>
			{props.title && <div className="modal-title">{props.title}</div>}
			{props.des && <div className="modal-description">{props.des}</div>}
			{props.shouldConfirm ?
				<div className="button-group">
					{props.shouldConfirm.includes('delete') &&
						<button className="c-btn c-btn--danger" onClick={() => props.onDelete()}>
							{t('delete')}
						</button>
					}
					{props.shouldConfirm.includes('cancel') &&
						<button className="c-btn" onClick={() => props.onRequestClose()}>
							{t('cancel')}
						</button>
					}
					{props.shouldConfirm.includes('confirm') &&
						<button className="c-btn c-btn--primary" onClick={() => props.onConfirm()}>
							{t('confirm')}
						</button>
					}
				</div> :
				<button className="c-btn confirm-button" onClick={() => props.onRequestClose()}>
					{t('understand')}
				</button>
			}
		</Modal>
	)
}

TagControlModal.defaultProps = {
	shouldConfirm: null,
	onDelete: () => {},
	onConfirm: () => {}
}

export default TagControlModal;