
const checkEmailValid = (email) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email)
}

const checkLinkValid = (url) => {
	const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.%]+$/g
	return re.test(url)
}

const checkIsPhoneNumber = (phone) => {
	const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]{8,}$/g
	return re.test(phone)
}

export {
	checkEmailValid,
	checkLinkValid,
	checkIsPhoneNumber
}