import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';

import UpdateMessage from 'Components/UpdateMessage'

/**
 * 預設ＯＰ設定
 * @property {object} settings response of get(`/channel/${props.channelId}`)
 * @property {func} setSettings
 * @property {func} updateInitAssignOp
 * @property {bool} isInitOpUpdating
 */
const DefaultOpSetting = (props) => {
	const { t } = useTranslation();

	const { settings, setSettings, updateInitAssignOp, isInitOpUpdating } = props

	return (
		<div className="a-block default-op">
			<div className="sub-title">{t('setting.initAssignOp')}</div>
			<div className="sub-des">{t('setting.initOpDes')}</div>
			<ThemeProvider theme={window.menuTheme}>
				<Menu
					maxHeight="500px"
					trigger={({ ref, isOpen }) => {
						return (
							<div className={cx("c-txt__input--select op-select", { 'is-open': isOpen })} ref={ref}>
								{settings.initAssignOp ? settings.initAssignOp.name : t('selectDefault')}
							</div>
						)
					}}
				>
					{settings.operators.map((op, i) => (
						<Item
							className="c-txt__input--select-item"
							key={`a-ini-op-select-${i}`}
							onClick={()=> {
								setSettings(op)
							}}
						>
							{op.name}
						</Item>
					))}
				</Menu>
			</ThemeProvider>
			<div className="btn-group">
				<button className="c-btn c-btn--primary" onClick={updateInitAssignOp}>
					{t('save')}
				</button>
				<UpdateMessage isUpdating={isInitOpUpdating}/>
			</div>

		</div>
	)
}

export default DefaultOpSetting
