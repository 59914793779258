import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';
import cx from 'classnames';
import dayjs from 'dayjs';
import shortid from 'shortid';

import { fetchHelper } from 'tools/FetchHelper'
import FilterBlock from './FilterBlock'
import DateInput from 'Components/DateInput'
import Counter from 'Components/Counter'
import CreateMsg from 'Components/CreateMsg'
import ChatPreviewBlock from 'Components/ChatPreviewBlock'
import RadioInput from 'Components/RadioInput'
import enums from 'enums'

import './index.css'

const BroadcastDetail = (props) => {
	const { t } = useTranslation();
	const locationState = props.location.state;
	const initialData = (locationState && locationState.broadcastData) ? locationState.broadcastData : null
	const status = initialData ? initialData.status : 'not sent';

	const mode = !!initialData
		?  initialData.status === 'not sent' ? 'edit': 'view'
		: 'create'


	const [groupMsgName, setMsgName] = useState(
		!!initialData ? initialData.title : null
	)
	const [fetching, setFetching] = useState({
		createCast: false,
		filtering: false
	})
	const [recipientsType, setType] = useState(
		initialData && initialData.type ? enums.broadcastType[initialData.type] : 'chatRooms'
	) // line or chatRooms or filter
	const [filters, setFilters] = useState(
		(!!initialData && initialData.sendTo.filter.length > 0) ? initialData.sendTo.filter.map(x => ({
			...x,
			hash: shortid.generate()
		})) : [{}])
	const [target, setTarget] = useState({
		percent: 0,
		number: 0
	})
	const [messages, setMsg] = useState(!!initialData ? initialData.message : [])

	const hourLater = dayjs().add(1, 'hour');
	const [sendingTime, setSendingTimeState] = useState(!!initialData && initialData.reservationDtm ? {
		type: 'setSendingTime',
		date:  dayjs(initialData.reservationDtm).format('YYYY-MM-DD'),
		hour: dayjs(initialData.reservationDtm).hour(),
		minute: dayjs(initialData.reservationDtm).minute()
	} : {
		type: 'sendNow', // sendNow or setSendingTime
		date: hourLater.format('YYYY-MM-DD'),
		hour: hourLater.hour(),
		minute: hourLater.minute()
	})

	const createBroadCast = async () => {
		setFetching({
			...fetching,
			createCast: true
		})

		try {
			const url = mode === 'edit' ?
				`/channel/${props.channelId}/multiCast/${initialData._id}` :
				`/channel/${props.channelId}/multiCast`
			const result = await fetchHelper.post(url, {
				type: enums.broadcastType[recipientsType],
				title: groupMsgName || t('broadcast.untitled'),
				message: messages,
				filter: recipientsType === 'filter' ? filters.filter(x => !!x.name) : [],
				reservationDtm: sendingTime.type === 'sendNow' ?
					dayjs().add(1, 'minute').format('YYYY-MM-DD HH:mm:ss ZZ') :
					dayjs(sendingTime.date)
					.set('hour', sendingTime.hour)
					.set('minute', sendingTime.minute).format('YYYY-MM-DD HH:mm:ss ZZ')
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				setFetching({
					...fetching,
					createCast: false
				})
				// window.location = `/console/channel/${props.channelId}/broadcast`\
				props.history.goBack()
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching({
				...fetching,
				createCast: false
			})
			console.log('create broadcast fail', error.message)
		}
	}

	const deleteCast = async () => {
		setFetching({
			...fetching,
			createCast: true
		})

		try {
			const result = await fetchHelper.delete(`/channel/${props.channelId}/multiCast/${initialData._id}`);
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				setFetching({
					...fetching,
					createCast: false
				})
				props.history.goBack()
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching({
				...fetching,
				createCast: false
			})
			console.log('delete broadcast fail', error.message)
		}
	}

	const copyCast = async () => {
		setFetching({
			...fetching,
			createCast: true
		})
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/multiCast/${initialData._id}/copy`);
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				setFetching({
					...fetching,
					createCast: false
				})
				props.history.goBack()
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching({
				...fetching,
				createCast: false
			})
			console.log('copy broadcast fail', error.message)
		}
	}

	const searchingMultiCastFilter = async () => {
		setFetching({
			...fetching,
			filtering: true
		})

		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/multiCast/filter/search`, {
				type: enums.broadcastType[recipientsType],
				filters: recipientsType === 'line' ? [] : filters.filter(x => !!x.name)
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				// line, chatRooms, filter
				let number, percent;
				if (recipientsType === 'line') {
					number = res.data.followers
					percent = 100
				} else {
					number = res.data.filteringChatRoom
					percent =  Math.ceil((res.data.filteringChatRoom / res.data.followers) * 100)
				}
				setTarget({
					number: number,
					percent: percent
				})
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching({
				...fetching,
				filtering: false
			})
			console.log('use filter to search people fail', error.message)
		}
	}

	useEffect(() => {
		searchingMultiCastFilter()
	}, [filters, recipientsType])

	useEffect(() => {
		const linkState = props.location.state

		if (linkState && linkState.guids && linkState.guids.length > 0) {
			let filterCopy = filters.slice()

			filterCopy = filterCopy.filter(x => !!x.name)
			filterCopy.push({
				name: 'chatRoomGuid',
				value: linkState.guids
			})

			setFilters(filterCopy)
			setType('filter')
		}
	}, [])
	return (
		<div className="broadcast-detail">
			{ mode === 'create' &&
				<div className="page-title">{t('broadcast.newGroupMsg')}</div>
			}
			{ mode === 'edit' &&
				<div className="page-title">{t('broadcast.editGroupMsg')}</div>
			}
			{ mode === 'view' &&
				<div className="page-title">{t('broadcast.viewGroupMsg')}</div>
			}

			<div className="sub-title">{t('broadcast.groupMsgName')}</div>
			<input
				type="text"
				className="c-txt__input group-msg-name"
				value={groupMsgName || ""}
				onChange={(e) => setMsgName(e.target.value)}
			/>
			<div className="msg-recipient-block">
				<div className="choose-recipient">
					<div className="select-title">{t('broadcast.msgRecipient')}</div>
					<RadioInput
						isDisabled={mode === 'view'}
						onChange={(e) => {setType('line')}}
						isChecked={recipientsType === 'line'}
						labelText={t('broadcast.allFriends')}
						radioToolTip={t('broadcast.allFriendsToolTip')}
					></RadioInput>
					<RadioInput
						isDisabled={mode === 'view'}
						onChange={(e) => {setType('chatRooms')}}
						isChecked={recipientsType === 'chatRooms'}
						labelText={t('broadcast.trueFriends')}
						radioToolTip={t('broadcast.trueFriendsToolTip')}
					></RadioInput>
					<RadioInput
						isDisabled={mode === 'view'}
						onChange={(e) => {setType('filter')}}
						isChecked={recipientsType === 'filter'}
						labelText={t('broadcast.byFilters')}
					></RadioInput>
				</div>
				<div className="recipient-result">
					<div className="result-title">{t('broadcast.friendsFilterResult')}</div>
					<div className="result-percent">{target.percent}%</div>
					<div className="result-numbers">
						{t('broadcast.aboutNumberOfFriends', { friendsNumber: target.number })}
					</div>
				</div>
			</div>
			{recipientsType === 'filter' &&
				<>
					<div className="sub-title">{t('broadcast.friendsByFilters')}</div>
					{filters.map((aFilter, i) => (
						<FilterBlock
							hideDeleteIcon={mode==='view'}
							filter={aFilter}
							{...props}
							key={`target-filter-${aFilter.name}-${i}`}
							onUpdate={(filter) => {
								const filtCopy = filters.slice()
								filtCopy[i] = filter
								setFilters(filtCopy)
							}}
							onDelete={() => {
								const filtCopy = filters.slice()
								filtCopy.splice(i, 1)
								setFilters(filtCopy)
							}}
						/>
					))}
					{ mode !== 'view' &&
						<button
							className="c-btn c-btn--primary add-filter-block"
							onClick={() => {
								const filtCopy = filters.slice()
								filtCopy.push({
									hash: shortid.generate()
								})
								setFilters(filtCopy)
							}}
						>
							{t('broadcast.addFliterBlock')}
						</button>
					}
				</>
			}
			<div className="sending-time-block">
				<div className="sub-title">{t('broadcast.sendingTime')}</div>
				<div className="setting">
					<ThemeProvider theme={window.menuTheme}>
						<Menu
							trigger={({ ref, isOpen }) => {
								return (
									<div className={cx(
										"c-txt__input--select sending-time-type-select",
										{ 'is-open': isOpen })}
									ref={ref}>
										{t(`broadcast.${sendingTime.type}`)}
									</div>
								)
							}}
						>
							<Item
								className="c-txt__input--select-item"
								key="sendNow"
								onClick={() => setSendingTimeState({
									...sendingTime,
									type: 'sendNow'
								})}>
									{t(`broadcast.sendNow`)}
							</Item>
							<Item
								className="c-txt__input--select-item"
								key="setSendingTime"
								onClick={() => setSendingTimeState({
									...sendingTime,
									type: 'setSendingTime'
								})}>
									{t(`broadcast.setSendingTime`)}
							</Item>
						</Menu>
					</ThemeProvider>
					{status === 'sent' &&
						<div className="sending-time">
							{dayjs(initialData.reservationDtm).format('YYYY/MM/DD HH:mm:ss')}
						</div>
					}
					{status === 'not sent' && sendingTime.type === 'setSendingTime' &&
						<>
							<DateInput
								mode="schedule"
								selectedDate={sendingTime.date || null}
								onSelected={(date) => setSendingTimeState({
									...sendingTime,
									'date': date
								})}
							/>
							<Counter
								max={23}
								value={sendingTime.hour}
								onChange={(hour) => setSendingTimeState({
									...sendingTime,
									'hour': hour
								})}
							/>
							<div className="seperater">:</div>
							<Counter
								max={59}
								value={sendingTime.minute}
								onChange={(minute) => setSendingTimeState({
									...sendingTime,
									'minute': minute
								})}
							/>
						</>
					}
				</div>
			</div>
			<div className="msg-content">
				<div className="sub-title">{t('broadcast.msgContent')}</div>
				<CreateMsg
					isLock={mode==='view'}
					{...props}
					messages={messages}
					sender={{
						bot: {
							picture: "https://lineline.s3.amazonaws.com/icons/robot.svg",
							name: "訊息機器人"
						}
					}}
					onChange={(messages) => setMsg(messages)}
					fileUploadFrom={enums.FileUploadFrom.broadcast}
				/>
			</div>
			<div className="preview-block">
				<div className="sub-title">{t('preview')}</div>
				<ChatPreviewBlock
					{...props}
					messages={messages}
					sender={{
						type: 'bot'
					}}
				/>
			</div>
			<div className="button-group">

				<button className="c-btn" onClick={() => props.history.goBack()}>
					{t('back')}
				</button>

				{mode === 'edit' &&
					<button className="c-btn c-btn--danger" onClick={() => deleteCast()}>
						{t('delete')}
					</button>
				}

				{mode === 'view' &&
					<button className="c-btn c-btn--primary" onClick={() => copyCast()}>
						{t('copy')}
					</button>
				}
				{ (mode === 'edit' || mode ==='create') &&
					<button
					className="c-btn c-btn--primary"
					onClick={() => {
						if (messages.length < 1) return
						createBroadCast()
					}}
					disabled={messages.length < 1}
					>
						{mode === 'edit' ? t('save') : t('send')}
					</button>
				}

			</div>
			{fetching.createCast &&
				<div className="chiffon">
					<div className="icon-loading">
						<i className="fal fa-spinner-third fa-spin" />
					</div>
				</div>
			}
		</div>
	);
}

export default BroadcastDetail;