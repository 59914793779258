import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import { Link } from 'react-router-dom';

import { fetchHelper } from 'tools/FetchHelper'

import GeneralModal from 'Components/GeneralModal'
import TagDirView from 'Components/TagsInputer/TagDirView'

import './index.css'

const ENTER_KEY = 13
/**
 *
 * @property {array} recentKeywordDir [{"name":"常用關鍵字", "tags": [{"text": ""}]}]
 * @property {function} onTagClick param: keyword
 */
const AddKeywordModal = (props) => {
    const { t } = useTranslation();

    const { recentKeywordDir, onTagClick } = props
    const [searchText, setSearchText] = useState("")
    const [keywordDirList, setKeywordDirList] = useState(recentKeywordDir)
	const [isFetching, setFetching] = useState(false)
    const isUnmount = useRef(false)
    const [showEditKeywordButton, setShowEditKeywordButton] = useState(false)//搜尋不到關鍵字時顯示按鈕

    const getKeywords = async () => {
        if (!searchText) return
        setFetching(true)
        try {
            const result = await fetchHelper.get(`/channel/${props.channelId}/bot/autoAssign/keywords`, {
                params: {
                    "type" : "search",
                    "keyword" : searchText
                },
                fetchQAbot: true
            });
            const res = result.data
            if (isUnmount.current) return
            if (res.status === "OK") {
                // group by parent
                setKeywordDirList(groupKeywordToDir(res.data.keywords))
                if (!!res.data.keywords && res.data.keywords.length > 0) {
					setShowEditKeywordButton(false)
                } else {
                    setShowEditKeywordButton(true)
                }

            } else {
                throw new Error(result.errorMessage);
            }
        } catch (error) {
            console.log('get all tags fail', error.message)
        } finally {
            setFetching(false)
        }
    }
	/**
	 * @param {object} data result of `/channel/${channelId}/bot/autoAssign/keywords`(res.data.keywords)
	 * @returns {array}
	 * 		- name: group/dir name
	 * 		- guid: guid of parent, or 'root'
	 * 		- tags: keywords data
	 */
	const groupKeywordToDir = (data) => {
        const reduceData = data.reduce((result, item) => {
            const parentKey = item.parent? item.parent.guid : 'root'
            result[parentKey] =result[parentKey] || []
            result[parentKey].push(item)
            return result
        }, {})

        const result = Object.keys(reduceData).map((key, i )=> {
            const firstChild = reduceData[key][0]
            const suffix = {
                0: "國家",
                1: "地區",
                2: "景點"
            }
            let parentName = ""
            if (firstChild.parent) {
                parentName += firstChild.parent.text
                if (firstChild.parent.parent) {
                    parentName += '/ ' + firstChild.parent.parent.text
                }
            }
            return {
                name: `${parentName}${parentName ? '/ ': ""}${suffix[firstChild.level]}`,
                guid: key,
                tags: reduceData[key]
            }
        })
        return result
    }

    return (
        <GeneralModal id={"keyord-add-wrap"} placement={'bottom-start'}>
            <div className="keyword-add-modal">
                <input
                    type="text"
                    placeholder={t('bot.autoAssignText.autoAssignSetting.searchKeyword')}
                    value={searchText || ""}
                    onChange={(e) => {
                        setSearchText(e.target.value)
                        if (e.target.value === "") {
                            setKeywordDirList(recentKeywordDir)
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.keyCode === ENTER_KEY && !!e.target.value) {
                            getKeywords()
                            e.preventDefault() // prevent menu close
                        }
                    }}
                />
				{isFetching &&
					<div className="icon-loading">
						<i className="fal fa-spinner-third fa-spin" />
					</div>
                }

				{!isFetching &&
					<div className={cx("dir-list", {
						'have-button': showEditKeywordButton,
						'not-mac': !(window.navigator.platform.indexOf('Mac') > -1)
					})}>
						{searchText && keywordDirList.length === 0 &&
							<div className="not-create-tag">
								{t('bot.autoAssignText.autoAssignSetting.notFoundKeyword')}
							</div>
						}
                        {keywordDirList.map((aDir, i)=>{
                            return(
								<TagDirView
                                    key={`tag-Preview-${aDir.guid}-${i}`}
                                    dir={aDir}
                                    onTagClick={onTagClick}
                                    defaultOpen={keywordDirList.length === 1}
                                />
                            )
                        })
                        }
                    </div>
				}

                {showEditKeywordButton &&
				    <Link to={`/console/channel/${props.channelId}/autoAssignKeyword`} target="_blank" >
                        <button className="c-btn c-btn--primary">
                            {t('bot.autoAssignText.autoAssignSetting.gotoEditKeyword')}
                        </button>
                    </Link>
				}

            </div>
        </GeneralModal>
    )
}

export default AddKeywordModal