import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DndProvider, useDrop } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

import { fetchHelper } from 'tools/FetchHelper'
import TagControlModal from './TagControlModal';
import TagSection from './TagSection';

import './index.css'


const DirDropBlank = ({ index, moveDirToBlank }) => {
	const [{ isOver }, drop] = useDrop({
		accept: 'list',
		drop: ({ type, draggedDir, draggedIndent, guid }) => {

			moveDirToBlank(draggedDir)
		},
		collect: monitor => ({
			isOver: !!monitor.isOver(),
		}),
	})

	return (
		<div
			ref={drop}
			className="a-blank-section"
		>
			{isOver && <hr />}
		</div>
	)
}

const TagManagement = (props) => {
	const { t } = useTranslation();
	const [isFetching, setFetching] = useState(true)
	const [isCreating, setCreating] = useState(false)
	const [dirList, setDirList] = useState([])
	const [duplicateTagModal , setDuplicateTagModal] = useState(false)
	const dirListRef = useRef([])
	const existTagPathRef = useRef("")

    const fetchAllTags = async () => {
		setFetching(true);
		try {
			const result = await fetchHelper.get(`/channel/${props.channelId}/tag`);
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				dirListRef.current = res.data
				setDirList(res.data)
				setFetching(false);
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			setFetching(false);
			console.log('fecth all channels fail', error.message)
		}
	}

	const getIndexByDirGUID = (dirGUID) => {
		let output = [];
		dirList.map((aDir, i) => {
			if (output.length > 0) return aDir;
			if (aDir.guid === dirGUID) {
				output = [i]
			}

			if (aDir.childs) {
				aDir.childs.map((subDir, j) => {
					if (output.length > 0) return aDir;
					if (subDir.guid === dirGUID) {
						output = [i, j]
					}

					if (subDir.childs) {
						if (output.length > 0) return aDir;
						subDir.childs.map((thirdDir, k) => {
							if (thirdDir.guid === dirGUID) {
								output = [i, j, k]
							}
							return aDir;
						})
					}
					return aDir;
				})
			}
			return aDir;
		})

		return output
	}

	const getPathByTagText = (tagText) => {

		for (let aDir of dirList) {
			if (aDir.tags.map(x => x.text).includes(tagText)) return aDir.name

			if (aDir.childs) {
				for (let subDir of aDir.childs) {
					if (subDir.tags.map(x => x.text).includes(tagText)) return `${aDir.name} - ${subDir.name}`

					if (subDir.childs) {
						for (let thirdDir of subDir.childs) {
							if (thirdDir.tags.map(x => x.text).includes(tagText)) return `${aDir.name} - ${subDir.name} - ${thirdDir.name}`
						}
					}
				}
			}
		}

		// should fetch alltags and refind
		return ""
	}

	const createTag = async (text, parantGUID, ...[i, j, k]) => {

		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/tag`, {
				text: text,
				parent: parantGUID || ""
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				const listCopy = dirList.slice()

				const tag = {
					text: text,
					guid: res.data.guid,
					count: 0
				}

				if (k !== undefined) {
					listCopy[i].childs[j].childs[k].tags.push(tag)
				} else if (j !== undefined) {
					listCopy[i].childs[j].tags.push(tag)
				} else if (i !== undefined) listCopy[i].tags.push(tag)

				setDirList(listCopy)
			} else if (res.errorCode === 1105) {
				// Duplicate Tag Name Not Allow

				existTagPathRef.current = getPathByTagText(text)
				setDuplicateTagModal(true)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('create tag fail', error.message)
		}
	}

	const moveTag = async (tag, tagIndex, fromDirGUID, afterSequence, ...[i, j, k]) => {
		const listCopy = dirList.slice()
		let parentDir = null;

		if (k !== undefined) {
			parentDir = listCopy[i].childs[j].childs[k]
		} else if (j !== undefined) {
			parentDir = listCopy[i].childs[j]
		} else if (i !== undefined) {
			parentDir = listCopy[i]
		}

		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/tag/${tag.guid}`, {
				parent: parentDir.guid,
				prev: afterSequence === 0 ? '' : parentDir.tags[afterSequence - 1].guid
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {

				const targetDirIndex = getIndexByDirGUID(fromDirGUID)

				if (targetDirIndex.length > 0) {
					const [x, y, z] = targetDirIndex
					if (z !== undefined) {
						listCopy[x].childs[y].childs[z].tags.splice(tagIndex, 1)
					} else if (y !== undefined) {
						listCopy[x].childs[y].tags.splice(tagIndex, 1)
					} else if (x !== undefined) listCopy[x].tags.splice(tagIndex, 1)
				}

				if (k !== undefined) {
					listCopy[i].childs[j].childs[k].tags.splice(afterSequence, 0, tag)
				} else if (j !== undefined) {
					listCopy[i].childs[j].tags.splice(afterSequence, 0, tag)
				} else if (i !== undefined) listCopy[i].tags.splice(afterSequence, 0, tag)

				setDirList(listCopy)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('update tag fail', error.message)
		}
	}

	const deleteTag = async (tag, tagIndex, ...[i, j, k]) => {
		try {
			const result = await fetchHelper.delete(`/channel/${props.channelId}/tag/${tag.guid}`, {});
			const res = result.data
			if (res.status === "OK") {
				deleteTagFromList(tag, tagIndex, ...[i, j, k])
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('delete tag fail', error.message)
		}
	}

	const deleteTagFromList = (tag, tagIndex, ...[i, j, k]) => {
		const listCopy = dirList.slice() // shallow copy
		if (k !== undefined) {
			listCopy[i].childs[j].childs[k].tags.splice(tagIndex, 1)
		} else if (j !== undefined) {
			listCopy[i].childs[j].tags.splice(tagIndex, 1)
		} else if (i !== undefined) listCopy[i].tags.splice(tagIndex, 1)

		setDirList(listCopy)
	}

	const checkDuplicateTag = (tagName, tagIndex, ...[i, j, k]) => {
		/* check if tag exist*/
		if (dirList.length === 0) {
			return false
		}

		const listCopy = JSON.parse(JSON.stringify(dirList))
		if (k !== undefined) {
			listCopy[i].childs[j].childs[k].tags.splice(tagIndex, 1)
		} else if (j !== undefined) {
			listCopy[i].childs[j].tags.splice(tagIndex, 1)
		} else if (i !== undefined) listCopy[i].tags.splice(tagIndex, 1)

		const check = listCopy.some((item) => {
			return checkDuplicateTagInDir(tagName, item)
		})
		return check
	}

	const checkDuplicateTagInDir = (tagName, dir) => {
		if (dir.tags) {
			const dirContainTag = dir.tags.some((item) => {
				return item.text === tagName
			})
			if (dirContainTag) {
				return true
			}
		}

		if (dir.childs) {
			const childContainTag = dir.childs.some((item) => {
				return checkDuplicateTagInDir(tagName, item)
			})
			if (childContainTag) {
				return true
			}
		}

		return false
	}

	const deleteDir = async (dir, ...[i, j, k]) => {
		try {
			const result = await fetchHelper.delete(`/channel/${props.channelId}/tagFolder/${dir.guid}`, {});
			const res = result.data
			if (res.status === "OK") {
				const listCopy = dirList.slice()

				if (k !== undefined) {
					listCopy[i].childs[j].childs.splice(k, 1)
				} else if (j !== undefined) {
					listCopy[i].childs.splice(j, 1)
				} else if (i !== undefined) listCopy.splice(i, 1)

				setDirList(listCopy)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('delete dir fail', error.message)
		}
	}

	const updateDir = async (newDir, ...[i, j, k]) => {
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/tagFolder/${newDir.guid}`, {
				name: newDir.name,
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				const listCopy = dirList.slice()
				const updateData = {
					name: newDir.name,
				}

				if (k !== undefined) {
					Object.assign(listCopy[i].childs[j].childs[k], updateData)
				} else if (j !== undefined) {
					Object.assign(listCopy[i].childs[j], updateData)
				} else if (i !== undefined) Object.assign(listCopy[i], updateData)

				setDirList(listCopy)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('update dir fail', error.message)
		}
	}

	const newDir = async (parentGUID, ...[i, j]) => {
		setCreating(true)

		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/tagFolder`, {
				name: '分類名稱',
				parent: parentGUID || ""
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				const listCopy = dirList.slice()

				const newDirData = {
					name: '分類名稱',
					isEditable: true,
					tags: [],
					guid: res.data.guid,
					childs: []
				}

				if (j !== undefined) {
					listCopy[i].childs[j].childs.push(newDirData)
				} else if (i !== undefined) {
					listCopy[i].childs.push(newDirData)
				} else {
					listCopy.push(newDirData)
				}

				setDirList(listCopy)
				setCreating(false)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('create new dir fail', error.message)
		}
	}

	const mergeDir = async (draggedDir, ...[i, j]) => {
		const listCopy = dirList.slice()
		let parentDir = null;

		if (j !== undefined) {
			parentDir = listCopy[i].childs[j]
		} else if (i !== undefined) {
			parentDir = listCopy[i]
		}

		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/tagFolder/${draggedDir.guid}`, {
				parent: parentDir.guid,
				prev: ""
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				const targetDirIndex = getIndexByDirGUID(draggedDir.guid)

				if (targetDirIndex.length > 0) {
					const [x, y, z] = targetDirIndex

					if (z !== undefined) {
						listCopy[x].childs[y].childs.splice(z, 1)
					} else if (y !== undefined) {
						listCopy[x].childs.splice(y, 1)
					} else if (x !== undefined) listCopy.splice(x, 1)
				}

				if (j !== undefined) {
					listCopy[i].childs[j].childs.push(draggedDir)
				} else if (i !== undefined) {
					listCopy[i].childs.push(draggedDir)
				}

				setDirList(listCopy)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('merge dir fail', error.message)
		}
	}

	const moveDirToBlank = async (draggedDir, preDirGUID, index) => {
		try {
			const result = await fetchHelper.post(`/channel/${props.channelId}/tagFolder/${draggedDir.guid}`, {
				parent: '',
				prev: preDirGUID,
			});
			const res = result.data
			// console.log(res)
			if (res.status === "OK") {
				const listCopy = dirList.slice()
				const targetDirIndex = getIndexByDirGUID(draggedDir.guid)
				if (targetDirIndex.length > 0) {
					const [x, y, z] = targetDirIndex
					if (z !== undefined) {
						listCopy[x].childs[y].childs.splice(z, 1)
					} else if (y !== undefined) {
						listCopy[x].childs.splice(y, 1)
					} else if (x !== undefined) listCopy.splice(x, 1)
				}

				listCopy.splice(index, 0, draggedDir)

				setDirList(listCopy)
			} else {
				throw new Error(result.errorMessage);
			}
		} catch (error) {
			console.log('move dir to blank fail', error.message)
		}
	}


	useEffect(() => {
		fetchAllTags()
	}, [])

	return (
		<div className="tag-management">
			<div className="page-title">
				{t('channel.members')}
				<i className="far fa-chevron-right" />
				{t('tags.title')}
			</div>
			<div className="page-des">
				{t('tags.tagsManagementDes')}
			</div>
			{isFetching ?
				<div className="icon-loading">
					<i className="fal fa-spinner-third fa-spin" />
				</div> :
				<DndProvider backend={HTML5Backend} context={window}>
					<div className="tag-management-list">
						{dirList.reduce((pre, aDir, i) => {
							if (i === 0) {
								pre.push(
									<DirDropBlank
										key={`dir-drop-blank-${i}`}
										moveDirToBlank={(draggedDir) => moveDirToBlank(draggedDir, '', i)}
									/>
								)
							}

							const hasChild = aDir.childs && aDir.childs.length > 0
							pre.push(
								<TagSection
									{...props}
									key={`aDir-section-${aDir.guid}-${i}`}
									aDir={aDir}
									allTags={dirListRef.current}
									createTag={(text) => createTag(text, aDir.guid, i)}
									moveTag={(tag, tagIndex, fromDirGUID, afterSequence) =>
										moveTag(tag, tagIndex, fromDirGUID, afterSequence, i)}
									deleteTag={(tag, tagIndex) => deleteTag(tag, tagIndex, i)}
									deleteTagFromList={(tag, tagIndex) => deleteTagFromList(tag, tagIndex, i)}
									checkDuplicateTag={(tagName, tagIndex) => checkDuplicateTag(tagName, tagIndex, i)}
									createDir={() => newDir(aDir.guid, i)}
									updateDir={(newDir) => updateDir(newDir, i)}
									deleteDir={() => deleteDir(aDir, i)}
									mergeDir={(draggedDir) => mergeDir(draggedDir, i)}
									hasChild={hasChild}
									isDirDraggable
								/>
							)

							if (hasChild) {
								aDir.childs.map((subDir, j) => {
									const hasThirdChild = subDir.childs && subDir.childs.length > 0
									const hasSiblings = j < (aDir.childs.length - 1)

									pre.push(
										<TagSection
											{...props}
											key={`tag-section-${subDir.guid}-${i}-${j}`}
											indent={1}
											aDir={subDir}
											allTags={dirListRef.current}
											createTag={(text) => createTag(text, subDir.guid, i, j)}
											moveTag={(tag, tagIndex, fromDirGUID, afterSequence) =>
												moveTag(tag, tagIndex, fromDirGUID, afterSequence, i, j)}
											deleteTag={(tag, tagIndex) => deleteTag(tag, tagIndex, i, j)}
											deleteTagFromList={(tag, tagIndex) => deleteTagFromList(tag, tagIndex, i, j)}
											checkDuplicateTag={(tagName, tagIndex) => checkDuplicateTag(tagName, tagIndex, i, j)}
											createDir={() => newDir(subDir.guid, i, j)}
											updateDir={(newDir) => updateDir(newDir, i, j)}
											deleteDir={() => deleteDir(subDir, i, j)}
											mergeDir={(draggedDir) => mergeDir(draggedDir, i, j)}
											hasChild={(hasThirdChild || hasSiblings)}
											isDirDraggable
										/>
									)

									if (hasThirdChild) {
										subDir.childs.map((thirdDir, k) => {
											let hasThirdSiblings = k < (subDir.childs.length - 1)

											pre.push(
												<TagSection
													{...props}
													key={`tag-section-${thirdDir.guid}-${i}-${j}-${k}`}
													indent={2}
													aDir={thirdDir}
													allTags={dirListRef.current}
													createTag={(text) => createTag(text, thirdDir.guid, i, j, k)}
													moveTag={(tag, tagIndex, fromDirGUID, afterSequence) =>
														moveTag(tag, tagIndex, fromDirGUID, afterSequence, i, j, k)}
													deleteTag={(tag, tagIndex) => deleteTag(tag, tagIndex, i, j, k)}
													deleteTagFromList={(tag, tagIndex) => deleteTagFromList(tag, tagIndex, i, j, k)}
													checkDuplicateTag={(tagName, tagIndex) => checkDuplicateTag(tagName, tagIndex, i, j, k)}
													createDir={() => newDir(thirdDir.guid, i, j, k)}
													updateDir={(newDir) => updateDir(newDir, i, j, k)}
													deleteDir={() => deleteDir(thirdDir, i, j, k)}
													mergeDir={(draggedDir) => {}}
													hasChild={hasThirdSiblings}
													isDirDraggable
												/>
											)
											return thirdDir
										})
									}
									return subDir
								})
							}

							pre.push(
								<DirDropBlank key={`dir-drop-blank-${i+1}`}
									index={i+1} moveDirToBlank={(draggedDir) => moveDirToBlank(draggedDir, aDir.guid, i+1)} />
							)

							return pre
						}, [])}
					</div>
				</DndProvider>
			}

			<button className="c-btn c-btn--primary" onClick={() => {
				if (!isCreating) {
					newDir()
				}
			}}>
				{isCreating ?
					<i className="fal fa-spinner-third fa-spin" /> :
					t('tags.addClass')
				}
			</button>
			<TagControlModal
				title={t('tags.duplicateTag')}
				des={t('tags.duplicateTagDes', { tagDirCrumblePath: existTagPathRef.current })}
				isModalOpen={duplicateTagModal}
				onRequestClose={() => {
					existTagPathRef.current = ""
					setDuplicateTagModal(false)
				}}
			/>
		</div>
	)
}

export default TagManagement;