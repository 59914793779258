import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Menu, Item } from '@zendeskgarden/react-menus';
import cx from 'classnames';

import enums from 'enums';
import UploadModal from 'Components/Uploader/UploadModal';
import TagsInputer from 'Components/TagsInputer'


import './index.css'

const FormCard = (props) => {
	const { t } = useTranslation();
	const [selectType, setType] = useState(props.form.type || null)
	const [selectSubType, setSubType] = useState(props.form.subType || null)
	const [form, setForm] = useState(props.form || {})
	const [textInputValue, setText] = useState(props.form.value || null)
	const [displayTitle, setDisplayTitle] = useState(props.form.displayTitle || null)
	const [displayDescription, setDisplayDescription] = useState(props.form.displayDescription || null)
	const [showUploadModal, setUploadModal] = useState()
	const isFirstRun = useRef(true);

	useEffect(() => {
		if (isFirstRun.current) return

		if (['image'].includes(selectType)) {
			setUploadModal(true)
		}
	}, [selectType, selectSubType])

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}

		props.onFormUpdate({
			...form,
			type: selectType,
			subType: selectSubType
		})
	}, [form])

	const renderOptionName = (type, subType) => {
		if (subType === 'tags') {
			return t(`bot.dataColumnName.${subType}`)
		}
		if (type === 'image') {
			return t(`bot.sheetFieldType.${type}`) + ' / ' + t(`bot.dataColumnName.${type}`)
		}

		return t(`bot.sheetFieldType.${type}`) + ' / ' + t(`bot.dataColumnName.${subType}`)
	}

	return (
		<div className="form-card">
			<div className="top-section">
				<div className="left-section">
					<span>{t('bot.msgType')}</span>
					<ThemeProvider theme={{
						...window.menuTheme,
						'menus.menu_view': `
							&& {
								max-width: 300px;
								min-width: 300px;
							}
						`,
					}}>
						<Menu
							trigger={({ ref, isOpen }) => {
								return (
									<div className={cx("c-txt__input--select filter-select", { 'is-open': isOpen })} ref={ref}>
										{selectType ?
											renderOptionName(selectType, selectSubType) :
											t('bot.chooseType')
										}
									</div>
								)
							}}
						>
							{Object.keys(enums.fieldType).reduce((pre, aType, i) => {
								enums.fieldType[aType].map((subType, j) => {
									pre.push(
										<Item
											className="c-txt__input--select-item"
											key={`a-field-type-${i}-${j}-`}
											onClick={() => {
												setType(aType)
												setSubType(subType)
											}}>
											{renderOptionName(aType, subType)}
										</Item>
									)
									return subType
								})

								return pre
							}, [])}
						</Menu>
					</ThemeProvider>
					{['image'].includes(selectType) &&
						<button
							className="c-btn c-btn--primary"
							onClick={() => setUploadModal(true)}
						>
							{t('upload')}
						</button>
					}
				</div>
				<div className="right-section">
					<i className={cx("fal fa-arrow-up", { disabled: props.disableGoAbove })}
						onClick={() => {
							if (!props.disableGoAbove) {
								props.swapFormCard(props.index, props.index-1)
							}
						}} />
					<i className={cx("fal fa-arrow-down", { disabled: props.disableGoBelow })}
						onClick={() => {
							if (!props.disableGoBelow) {
								props.swapFormCard(props.index, props.index+1)
							}
						}} />
					<i className="fal fa-trash" onClick={() => props.onDelete()} />
				</div>
			</div>
			<div className="select-content">
				{selectType === 'text' &&
					<input
						className="c-txt__input text-input"
						type="text"
						value={textInputValue || ""}
						onChange={(e) => setText(e.target.value)}
						onBlur={(e) => setForm({
							...form,
							value: e.target.value
						})}
					/>
				}
				{selectType === 'image' &&
					<div className="file-upload">
						<UploadModal
							isModalOpen={showUploadModal}
							onRequestClose={() => setUploadModal(false)}
							folderType={enums.FileUploadType.channel}
							onUpLoaded={(result) => {
								setForm({
									...form,
									imagePath: result.url,
									...result
								})
							}}
							channelId={props.channelId}
						/>
						{form.imagePath &&
							<div className="uploaded-file">
								{['jpeg', 'jpg', 'png'].includes(form.extension) &&
									<figure>
										<img src={form.imagePath} alt=""/>
									</figure>
								}
								{['m4a', 'x-m4a'].includes(form.extension) &&
									<div className="media-icon">
										<i className="fal fa-file-audio" />
									</div>
								}
								{form.extension === 'mp4' &&
									<div className="media-icon">
										<i className="fal fa-file-video" />
									</div>
								}
								<div className="file-info">
									{form.name}
								</div>
							</div>
						}
					</div>
				}
				{selectType === 'input' &&
					<div className="sheet-input" >
						<div className="field-row">
							<span className="field-label">{t('bot.displayTitle')}</span>
							<input
								className="c-txt__input"
								type="text"
								value={displayTitle || ""}
								onChange={(e) => setDisplayTitle(e.target.value)}
								onBlur={(e) => setForm({
									...form,
									displayTitle: e.target.value
								})}
							/>
						</div>
						<div className="field-row">
							<span className="field-label">{t('bot.displayDescription')}</span>
							<input
								className="c-txt__input"
								type="text"
								value={displayDescription || ""}
								onChange={(e) => setDisplayDescription(e.target.value)}
								onBlur={(e) => setForm({
									...form,
									displayDescription: e.target.value
								})}
							/>
						</div>
						{selectSubType === 'tags' &&
							<div className="field-row">
								<span className="field-label">{t('bot.displayTags')}</span>
								<TagsInputer
									tags={form.tags}
									onChange={(tags) => setForm({
										...form,
										tags: tags
									})}
									channelId={props.channelId}
								/>
							</div>
						}
					</div>
				}
			</div>
		</div>
	)
}

FormCard.defaultProps = {
	form: {},
	onFormUpdate: () => {}
}

export default FormCard ;